<template>
	<div class="LiftAreaAuthority">
		<!-- 内容 -->
		<div class="content">
			<!-- 左侧 -->
			<div class="content_left boxshadow" v-loading="PageLoad.Leftload">
				<div class="left_tree">
					<!-- 功能栏 -->
					<div class="left_tree_input">
						<el-input :placeholder="$t('LiftAreaAuthority.SearchLiftArea')" size="medium" v-model="filterText"></el-input>
					</div>

					<!-- 树形菜单 -->
					<el-tree class="content_left_tree" :highlight-current="true" :data="data" :props="defaultProps" default-expand-all :filter-node-method="filterNode" :expand-on-click-node="false" icon-class="el-icon-s-promotion" node-key="liftAreaId" ref="tree">
						<div class="custom-tree-node" slot-scope="{data}" style="height: 100%;" @click="SelectLiftArea(data)">
							<div style="width:100%;font-size:15px">
								<el-popover v-if="data.remark!='' && data.remark!=undefined" placement="left" :title="$t('LiftAreaAuthority.Remark')" width="200" trigger="hover" :content="data.remark">
									<div slot="reference" >
										{{data.liftAreaName}}
										<i v-if="data.children.length==0 && data.status!=1" class="el-icon-remove-outline" style="color: red;font-size: 15px;padding:3px;"></i>
									</div>
								</el-popover>
								<div v-else slot="reference">
									{{data.liftAreaName}}
									<i v-if="data.children.length==0 && data.status!=1" class="el-icon-remove-outline" style="color: red;font-size: 15px;padding:3px;"></i>
								</div>
							</div>
						</div>
					</el-tree>
				</div>
			</div>
			
			<!-- 右侧 -->
			<div class="content_right" v-loading="PageLoad.Rightload">
				<!-- 头部 -->
				<div class="content_right_top flex-items-center">
					<!--条件 -->
					<div class="right_top_card1 flex-items-center boxshadow">
						<!-- 搜索框 -->
						<div v-if="SearchCriteria !='4'" class="right_top_search flex-items-center">
							<i class="el-icon-search"></i>
							<el-input size="medium" v-model="FloorIndexValue" :placeholder="$t('LiftAreaAuthority.SearchTips')"></el-input>
						</div>
						<div v-if="SearchCriteria =='4'" class="right_top_Date" >
							<select-tree v-model="FloorIndexValue" :placeholder="$t('SelectTree.Select')" :options="DepartmentList" :props="DepartmentProps" />
						</div>

						<!-- 条件类型 -->
						<div class="right_top_search_type flex-items-center">
							<el-button slot="append" size="mini" class="el-icon-search" @click="QueryFloor"></el-button>
							<i></i>
							<el-select v-model="SearchCriteria" @change="FloorIndexValue=''" size="medium" slot="prepend">
								<el-option :label="$t('LiftAreaAuthority.SearchCriteria1')" value="1"></el-option>
								<el-option :label="$t('LiftAreaAuthority.SearchCriteria2')" value="2"></el-option>
								<el-option :label="$t('LiftAreaAuthority.SearchCriteria3')" value="3"></el-option>
								<el-option :label="$t('LiftAreaAuthority.SearchCriteria4')" value="4"></el-option>
							</el-select>
						</div>
							
							
					</div>
					<!--操作1 -->
					<div class="right_top_card2 flex-center boxshadow">
						<el-button size="medium" type="primary" icon="el-icon-circle-plus-outline" :disabled="!userGroupDetails.RoleLiftArea" @click="AddPermissionVisible()">{{$t('LiftAreaAuthority.Add')}}</el-button>
						<el-button size="medium" type="danger" style="margin-left: 20px;" slot="reference" icon="el-icon-delete" :disabled="!userGroupDetails.RoleLiftArea" @click="DeleteFloorAuthority(IdData)">{{$t('LiftAreaAuthority.BatchesDelete')}}</el-button>
					</div>
					<!--操作2 -->
					<div class="right_top_card3 flex-center boxshadow">
						<el-button size="medium" icon="el-icon-refresh-right" @click="QueryFloor()">{{$t('LiftAreaAuthority.Refresh')}}</el-button>
					</div>
				</div>
				<!-- 底部 -->
				<div class="content_right_bottom flex-center boxshadow">
					<div>
						<div class="content_right_bottom_title">{{$t('LiftAreaAuthority.PermissionsList')}}</div>
						<el-table :data="FloorAuthorityList"  height="100%" @selection-change="handleSelectionChange">
							<el-table-column type="selection" align="center" prop="recordID"></el-table-column>
							<el-table-column align="center" prop="number" :label="$t('LiftAreaAuthority.Number')" width="160"></el-table-column>
							<el-table-column align="center" prop="name" :label="$t('LiftAreaAuthority.Name')"></el-table-column>
							<el-table-column align="center" prop="sex" :label="$t('LiftAreaAuthority.Sex')">
								<template slot-scope="scope">
									<el-tag v-if="scope.row.sex==1" effect="plain">{{$t('LiftAreaAuthority.Sex1')}}</el-tag>
									<el-tag v-else el-tag type="danger" effect="plain">{{$t('LiftAreaAuthority.Sex2')}}</el-tag>
								</template>
							</el-table-column>
							<el-table-column align="center" prop="q" :label="$t('LiftAreaAuthority.Card1')">
								<template slot-scope="scope">
									<span v-if="scope.row.q!='' && scope.row.q!=undefined">{{scope.row.q}}</span>
									<span v-else><el-tag type="info">{{$t('LiftAreaAuthority.No')}}</el-tag></span>
								</template>
							</el-table-column>
							<el-table-column align="center" prop="c" :label="$t('LiftAreaAuthority.Card2')">
								<template slot-scope="scope">
									<span v-if="scope.row.c!='' && scope.row.c!=undefined">{{scope.row.c}}</span>
									<span v-else><el-tag type="info">{{$t('LiftAreaAuthority.No')}}</el-tag></span>
								</template>
							</el-table-column>
							<el-table-column align="center" prop="k" :label="$t('LiftAreaAuthority.Card3')">
								<template slot-scope="scope">
									<span v-if="scope.row.k!='' && scope.row.k!=undefined">{{scope.row.k}}</span>
									<span v-else><el-tag type="info">{{$t('LiftAreaAuthority.No')}}</el-tag></span>
								</template>
							</el-table-column>
							<el-table-column align="center" prop="departmentName" :label="$t('LiftAreaAuthority.Department')"></el-table-column>
							<el-table-column align="center" prop="liftAreaName" :label="$t('LiftAreaAuthority.LiftArea')"></el-table-column>
							<el-table-column align="center" prop="liftFloorCode" :label="$t('LiftAreaAuthority.FloorPermissions')">
								<template slot-scope="scope">
									<div v-if="scope.row.liftFloorCode=='00'"><el-tag type="success">{{$t('LiftAreaAuthority.All')}}</el-tag></div>
									<el-popover v-else placement="left" trigger="click">
										<div :class="FloorTransform(scope.row.liftFloorCode).length>60?'FloorTransform2':'FloorTransform1'">
											<el-button v-for="items in FloorTransform(scope.row.liftFloorCode)" :key="items" type="primary" plain size="small" style="width: 80px;text-align:center;margin:3px 3px">{{items}}{{$t('LiftAreaAuthority.Floor')}}</el-button>
										</div>
										<el-button  size="small" slot="reference">{{$t('LiftAreaAuthority.ToView')}}</el-button>
									</el-popover >
								</template>
							</el-table-column>
							<el-table-column align="center" prop="liftAreaName" width="180" :label="$t('LiftAreaAuthority.Operation')">
								<template slot-scope="scope">
									<el-tooltip class="item" effect="dark" :content="$t('LiftAreaAuthority.ModifyPermissions')" placement="top" :enterable="false">
										<el-button  type="primary" size="mini" icon="el-icon-edit" :disabled="!userGroupDetails.RoleLiftArea" @click="UpdateFloorPermissionsBut(scope.row)"></el-button>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" :content="$t('LiftAreaAuthority.RemovePermissions')" placement="top" :enterable="false">
										<el-button type="danger" size="mini" icon="el-icon-delete" :disabled="!userGroupDetails.RoleLiftArea" @click="DeleteFloorAuthority([scope.row.recordID])"></el-button>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" :content="$t('LiftAreaAuthority.SendICCard')" placement="top" :enterable="false">
										<el-button type="success" size="mini" icon="el-icon-bank-card" :disabled="!userGroupDetails.RoleLiftArea" :loading="scope.row.recordID==ButtonLoad.WriteCard?true:false" @click="WriteCard(scope.row)"></el-button>
									</el-tooltip>
								</template>
							</el-table-column>
						</el-table>
						<div class="content_right_bottom_page flex-items-center">
							<el-pagination @size-change="FloorNumber" @current-change="FloorPages" :page-sizes="[20, 50, 100]"
							:page-size="Count" :current-page="Pages" layout="total, sizes, prev, pager, next, jumper" :total="FloorAuthorityListSize">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 弹框 -->
		<!-- 添加权限 -->
			<el-dialog :title="$t('LiftAreaAuthority.AddPermissions')" top="2%" destroy-on-close :visible.sync="EditPermissionVisible" width="65%" :close-on-click-modal="false">
			  <div style="display: flex;">
				<div style="width: 20%;margin-right: 2%;">
					<el-card shadow="hover" v-loading="PageLoad.DepartmentTreeload">
						<div class="Area_input">
							<el-input size="small" :placeholder="$t('LiftAreaAuthority.SearchDepartment')" v-model="filterTextDepartment"></el-input><el-button @click="Department();ChooseDepartment('');" style="margin-left: 5px;" size="mini">{{$t('LiftAreaAuthority.Refresh')}}</el-button>
						</div>
						<el-tree :data="DepartmentList" :filter-node-method="filterNodeDepartment" ref="Department"
						 default-expand-all :expand-on-click-node="false" icon-class="el-icon-s-promotion" style="height:246px;overflow:auto;">
							<span class="custom-tree-node" slot-scope="{ data }" style="height: 100%;" @click="ChooseDepartment(data.departmentCode)" >
								<div style="width: 100%;">{{ data.departmentName }}</div>
							</span>
						</el-tree>
					</el-card>
					<el-card shadow="hover" v-loading="PageLoad.Leftload"  style="margin-top: 66px;">
						<div class="Area_input">
							<el-input size="small" :placeholder="$t('LiftAreaAuthority.SearchLiftArea')" v-model="filterText1"></el-input><el-button @click="QueryLiftArea()" style="margin-left: 5px;" size="mini">{{$t('LiftAreaAuthority.Refresh')}}</el-button>
						</div>
						<el-tree class="filter-tree" :data="data" :props="defaultProps" default-expand-all :filter-node-method="filterNode" :expand-on-click-node="false" icon-class="el-icon-s-promotion" node-key="liftAreaId" ref="tree1" style="height:257px;overflow:auto;">
							<div class="custom-tree-node" slot-scope="{ data }" style="height: 100%;" @click="LiftFloorEntityList(data.liftAreaCode)">
								<div>
									<el-popover v-if="data.remark!='' && data.remark!=undefined" placement="left" :title="$t('LiftAreaAuthority.Remark')" width="200" trigger="hover" :content="data.remark">
										<div slot="reference" >
											{{data.liftAreaName}}
											<i v-if="data.children.length==0 && data.status!=1" class="el-icon-remove-outline" style="color: red;font-size: 15px;padding:3px;"></i>
										</div>
									</el-popover>
									<div v-else slot="reference">
										{{data.liftAreaName}}
										<i v-if="data.children.length==0 && data.status!=1" class="el-icon-remove-outline" style="color: red;font-size: 15px;padding:3px;"></i>
									</div>
								</div>
							</div>
						</el-tree>
					</el-card>
				</div>
				<div style="width:78%">
					
					<ShuttleBox  v-loading="PageLoad.EmployeesList" :Data1="EmployeesData" :placeholder="$t('LiftAreaAuthority.SearchTips')" :checkboxName1="$t('LiftAreaAuthority.Unselected')" :checkboxName2="$t('LiftAreaAuthority.HasChosen')" :ButtomName1="$t('LiftAreaAuthority.AddPermissions')" :ButtomName2="$t('LiftAreaAuthority.RemovePermissions')" @model="AddEmployeesValue"></ShuttleBox>
					
					<div style="display: flex;align-items: center;margin-top: 16px;">
						<el-pagination background layout="total,prev, pager, next" :page-size="1000" @current-change="EmployeesNumber" :total="EmployeesCount"></el-pagination>
					</div>
					
					<el-card shadow="hover" v-loading="PageLoad.FloorLoad" style="margin-top: 17px;">
						<div style="height:298px;overflow:auto">
							<div style="width: 100%;display: flex;justify-content: center"><el-checkbox v-if="LiftFloorList.length>1" @change="checkall" v-model="FloorCountChecka" border >{{$t('LiftAreaAuthority.All')}}</el-checkbox></div>
							<el-checkbox-group v-model="SaveFloorList" @change="checkedFloor">
							<div class="checkbox" v-for="(items) in LiftFloorList" :key="items.liftAreaId">
								<el-checkbox @change="checkFloor(items.liftAreaId)" :label="items.liftAreaId" size="medium"  style="margin: 10px 0px;" >{{items.liftAreaName}}</el-checkbox>
								<div class="checkbox_son">
									<div v-for="items1 in items.list" :key="items1.liftFloorId">
										 <el-checkbox :label="items1.liftFloorId" size="medium" border>{{items1.floorName!=''&& items1.floorName!=undefined?items1.floorName:items1.floorIndex+ $t('LiftAreaAuthority.Floor')}}</el-checkbox>
									</div>
								</div>
							</div>
							</el-checkbox-group>
						</div>
					</el-card>
				  </div>
			  </div>
			   <span slot="footer" class="dialog-footer">
					<el-button @click="SaveFloorPermissions()" :loading="ButtonLoad.AddUpdateAuthority" type="primary">{{$t('LiftAreaAuthority.Save')}}</el-button>
			    </span>
			</el-dialog>
			
			<el-dialog :title="$t('LiftAreaAuthority.ModifyPermissions')" :visible.sync="UpdateEditPermissionVisible" :close-on-click-modal="false">
			   <div style="display: flex;" v-loading="PageLoad.FloorLoad">
					<div style="height:400px;overflow:auto">
						<div style="width: 100%;display: flex;justify-content: center"><el-checkbox v-if="LiftFloorList.length>1" @change="checkall" v-model="FloorCountChecka" border >{{$t('LiftAreaAuthority.All')}}</el-checkbox></div>
						<el-checkbox-group v-model="SaveFloorList" @change="checkedFloor">
						<div class="checkbox" v-for="(items) in LiftFloorList" :key="items.liftAreaId">
							<el-checkbox @change="checkFloor(items.liftAreaId)" :label="items.liftAreaId" size="medium"  style="margin: 10px 0px;" >{{items.liftAreaName}}</el-checkbox>
							<div class="checkbox_son">
								<div v-for="items1 in items.list" :key="items1.liftFloorId">
									 <el-checkbox :label="items1.liftFloorId" size="medium" border>{{items1.floorName!=''&& items1.floorName!=undefined?items1.floorName:items1.floorIndex + $t('LiftAreaAuthority.Floor')}}</el-checkbox>
								</div>
							</div>
						</div>
						</el-checkbox-group>
					</div>
			   </div>
			   <span slot="footer" class="dialog-footer">
					<el-button  @click="UpdateFloorPermissions()" :loading="ButtonLoad.AddUpdateAuthority" type="primary">{{$t('LiftAreaAuthority.Confirm')}}</el-button>
			    </span>
			</el-dialog>
	</div>
</template>

<script>
	import SelectTree from './../components/SelectTree.vue';
	import ShuttleBox from '../components/ShuttleBox.vue';

	export default {
		components: {
			SelectTree,
			ShuttleBox,
		},
		watch: {
			
			//电梯组查询过滤使用
			filterText(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.tree.filter(val);
			},
			//电梯组查询过滤使用
			filterText1(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.tree1.filter(val);
			},
			//部门查询过滤使用
			filterTextDepartment(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.Department.filter(val);
			},
		},
		data() {
		  return {
			//权限组
			userGroupDetails: {RoleLiftArea: false},
			// 页面加载
			PageLoad:{
   				Leftload: false, //左侧加载状态
				Rightload: false, //右侧加载状态
				EmployeesList:false,//人员列表加载状态
				FloorLoad:false, //人员楼层权限加载状态
				DepartmentTreeload:false, //部门加载状态 
			},
			// 按钮加载状态
			ButtonLoad:{
				//新增修改权限
 				AddUpdateAuthority: false,
				//写卡
 				WriteCard: 0,
			},
			//电梯组
			filterText: "",//搜索电梯组
			SearchCriteria:"1",//搜索条件
			data: [],//电梯组数据
			defaultProps: { children: 'children',label: 'liftAreaName'},//电梯组数据参数
			LiftAreaData:{},//选择的电梯组
			//楼层
			FloorIndexValue:"",//查询框楼号楼层名
			FloorAuthorityList:[],//楼层列表
			Count:20,//分页条数
			Pages:1,//分页页数
			FloorAuthorityListSize:0,//楼层总数
			DepartmentId:"",//部门ID
			DepartmentList:[],//部门
			DepartmentProps: {
				parent: 'parentDepartmentId', // 父级唯一标识
				value: 'departmentCode', // 唯一标识
				label: 'departmentName', // 标签显示
				children: 'children', // 子级
			},
			//添加权限
			EditPermissionVisible:false,//添加权限弹框显示状态
			filterTextDepartment:'',//部门查询条件
			DepartmentCode:"",//部门编码
			DepartmentList:[],//部门列表
			EmployeesData:[],//穿梭框人员列表
			EmployeesValue:[],//穿梭框选中人员列表
			EmployeesCount:0,//人员总数
			EmployeesPages:1,//人员分页页数
			filterText1:"",//搜索电梯组
			//人员列表
			EmployeesfilterMethod(query, item) {
				return item.pinyin.indexOf(query) > -1;
			},
			FloorCount:0,//楼层总数
			FloorCountChecka:false,//全选楼层按钮
			LiftFloorList:[],//楼层列表
			SaveFloorList:[],//保存的楼层列表
			UpdateEditPermissionVisible:false,//修改楼层弹框
			IdData:[],//权限ID
			RecordID:0,//权限ID
		  };
		},
		mounted() {
			//获取操作权
			this.Role();
			
			//获取电梯组
			this.QueryLiftArea();
			
			//获取部门
			this.Department();
			
			//获取人员权限
			this.QueryFloor("");
		},
		//事件
		methods: {
			//获取操作权
			Role() {
				//获操作取权限组
				var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
				for (var i = 0; i < userGroupDetails.length; i++) {
					if (userGroupDetails[i].roleName == "LiftArea") { //操作权
						this.userGroupDetails.RoleLiftArea = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
				}
			},
			
			//获取电梯组搜索过滤
			filterNode(value, data) {
				if (!value) return true;
				return data.liftAreaName.indexOf(value) !== -1;
			},
			
			//获取电梯组
			async QueryLiftArea(){
				try
				{
					this.PageLoad.Leftload = true;
					var {data: res} = await this.$http.post('QueryLiftArea')
					if (res.resultCode == 1)//成功
					{ 
						var LiftArea = JSON.parse(res.body);
						this.data = LiftArea;
					}
					this.PageLoad.Leftload = false;
				}
				catch (err)
				{
					this.PageLoad.Leftload = false;
					console.log(err)
				}
			},
			
			//选择电梯组
			SelectLiftArea(LiftAreaData){
				//拨码开关
				this.LiftAreaData = LiftAreaData;//选择的电梯组
				this.QueryFloor();//获取人员权限
			},
			
			//楼层信息显示状态
			FloorDisplayStatus(LiftAreaData){
				if(LiftAreaData.children!=undefined)
				{
					if(LiftAreaData.children.length==0)
					{
						return true;
					}
					else
					{
						return false;
					}
				}
				else
				{
					return false;
				}
			},
			
			//查询部门
			async Department() {
				try 
				{
					var {data: res} = await this.$http.post('Departments')
					if (res.resultCode == 1)//成功
					{ 
						this.DepartmentList = JSON.parse(res.body);
					}
				} 
				catch (err)
				{
					console.log(err)
				}
			},
			
			//获取楼层
			async QueryFloor(){
				try
				{
					this.PageLoad.Rightload = true;
					var FloorAuthorityVlaue = 
					{
						"LiftAreaCode":this.LiftAreaData.liftAreaCode!=undefined?this.LiftAreaData.liftAreaCode:"",
						"Value":this.FloorIndexValue,
						"Type":this.SearchCriteria,
						"Count":this.Count,
						"Pages":this.Pages,
					};
					var {data: res} = await this.$http.post('FloorAuthority',FloorAuthorityVlaue)
					if (res.resultCode == 1)//成功
					{ 
						var LiftFloor = JSON.parse(res.body);
						if(LiftFloor.length>0) this.FloorAuthorityListSize = LiftFloor[0].size;
						this.FloorAuthorityList = LiftFloor;
					}
					this.PageLoad.Rightload = false;
				} 
				catch (err)
				{
					this.PageLoad.Rightload = false;
					console.log(err)
				}
			},
			
			//分页条数
			FloorNumber(val) {
				this.Count = val;
				this.QueryFloor();
			},
			
			//分页页数
			FloorPages(val) {
				this.Pages = val;
				this.QueryFloor();
			},
			
			//楼层转换
			FloorTransform(liftFloorCode){
				var FloorCode = "";
				for(var i=0;i<liftFloorCode.length-1;i+=2){
					var code = liftFloorCode.substring(i,i+2);
					code = parseInt(code,16).toString(2);
					var count = (8-code.length);
					for(var k=0;k<count;k++)
					{
						code = "0" + code;
					}
					FloorCode = code+FloorCode;
				}
				//翻转顺序
				FloorCode = FloorCode.split("").reverse().join("");
				var OriginalList = FloorCode.split('');
				var liftFloorCodeList = [];
				var index = -8;
				for(var i=0;i<128;i++)
				{
					if(index==0) index=1;
					if(OriginalList[i]==1)
					{
						liftFloorCodeList[liftFloorCodeList.length] = index;
					}
					index++;
				}
				return liftFloorCodeList;
			},
			
			//添加权限弹框
			AddPermissionVisible(){
				this.EmployeesPages = 1;
				this.EmployeesData = [];
				this.EmployeesValue = [];
				this.LiftFloorList = [];
				this.SaveFloorList = [];//清空保存的楼层
				this.SelectTimeTemplate = '';
				this.Department();//部门
				this.ChooseDepartment('');//查询人员
				this.LiftFloorEntityList('');//查询楼层
				this.FloorCountChecka = false;
				this.EditPermissionVisible = true;
			},
			
			//查询部门
			async Department() {
				try 
				{
					this.PageLoad.DepartmentTreeload = true;
					var {data: res} = await this.$http.post('Departments')
					if (res.resultCode == 1)//成功
					{ 
						this.DepartmentList = JSON.parse(res.body);
					}
					this.PageLoad.DepartmentTreeload = false;
				} 
				catch (err)
				{
					this.PageLoad.DepartmentTreeload = false;
					console.log(err)
				}
			},
			
			//部门过滤
			filterNodeDepartment(value, data) {
				if (!value) return true;
				return data.departmentName.indexOf(value) !== -1;
			},
			
			//选择部门
			ChooseDepartment(DepartmentCode){
				this.DepartmentCode = DepartmentCode;
				this.EmployeesPages = 1;
				this.Employees();
			},
			
			//查询人员
			async Employees() {
				try
				{
					this.PageLoad.EmployeesList = true;
					var {data: res} = await this.$http.post('SelectEmployees', {"DepartmentCode": "" + this.DepartmentCode + "","Count":1000,"Pages":this.EmployeesPages,"Type":0,"Value":"","EmployeeId":0})
					if (res.resultCode == 1) {
						var body = JSON.parse(res.body);
						var Employees = [];
						this.EmployeesCount = body.length>0 ? body[0].size:0;
						for(var i = 0;i<body.length;i++)
						{
							Employees[i] = { key: body[i].employeeId,label:body[i].number+" - "+body[i].name,pinyin:body[i].number+","+body[i].name+","+body[i].q+","+body[i].c+","+body[i].k}
						}
						this.EmployeesData = Employees;
					}
					this.PageLoad.EmployeesList = false;
				}
				catch (err)
				{
					this.PageLoad.EmployeesList = false;
					console.log(err)
				}
			},
			
			//人员分页页数
			EmployeesNumber(val) {
				this.EmployeesPages = val;
				this.Employees();
			},
			
			//选择电梯组获取楼层
			async LiftFloorEntityList(LiftAreaCode){
				try
				{
					this.PageLoad.FloorLoad = true;
					this.FloorCountChecka = false;
					var {data: res} = await this.$http.post('LiftFloorEntityList',{"LiftAreaCode":LiftAreaCode})
					if (res.resultCode == 1)//成功
					{
						var LiftFloor = JSON.parse(res.body);
						var FloorDataList = [];
						var FloorCount = 0;
						for(var i=0;i<LiftFloor.length;i++)
						{
							var status = false;
							LiftFloor[i].checkbox = false;
							for(var k=0;k<FloorDataList.length;k++){
								if(LiftFloor[i].liftAreaId == FloorDataList[k].liftAreaId)
								{
									FloorCount++;
									FloorDataList[k].list[FloorDataList[k].list.length] = LiftFloor[i];
									status = true;
									break;
								}
							}
							if(!status)
							{
								FloorCount++;
								FloorCount++;
								FloorDataList[FloorDataList.length] = {"liftAreaId":LiftFloor[i].liftAreaId,"liftIndex":LiftFloor[i].liftIndex,"controllerId":LiftFloor[i].controllerId,"liftFloorId":LiftFloor[i].liftFloorId,"liftAreaName":LiftFloor[i].liftAreaName,"checkbox":false,"list":[LiftFloor[i]]}
							}
						}
						
						//排序
						for(var i=0;i<FloorDataList.length;i++)
						{
							var list = FloorDataList[i].list;
							var val = "";
							for(var k=0;k<list.length;k++){
								val = val+list[k].floorIndex;
							}
							for(var k = 0;k < list.length-1;k++) 
							{
								// 内层循环,控制比较的次数，并且判断两个数的大小
								for (var j = 0; j < list.length - 1 - k; j++)
								{
								    //如果前面的数大，放到后面
								    if (list[j].floorIndex > list[j + 1].floorIndex) {
								        var temp = list[j];
								        list[j] = list[j + 1];
								        list[j + 1] = temp;
								    }
								}
							}
							val = "";
							for(var k=0;k<list.length;k++){
								val = val+list[k].floorIndex;
							}
							FloorDataList[i].list = list;
						}
						this.FloorCount = FloorCount;
						this.LiftFloorList = FloorDataList;
					}
					this.PageLoad.FloorLoad = false;
				} 
				catch (err)
				{
					this.PageLoad.FloorLoad = false;
					console.log(err)
				}
			},
			
			//选择指定的电梯组
			checkFloor(liftAreaId){
				var Type = false;
				var SaveFloorList = this.SaveFloorList;
				for(var i=0;i<SaveFloorList.length;i++)
				{
					if(liftAreaId == SaveFloorList[i])
					{
						Type = true;
					}
				}
				var index = 0;
				var LiftFloorList = this.LiftFloorList;
				for(var i=0;i<LiftFloorList.length;i++)
				{
					if(liftAreaId == LiftFloorList[i].liftAreaId)
					{
						index = i;
					}
				}
				var count = LiftFloorList[index].list.length;
				for(var i=0;i<count;i++){
					var liftFloorId = LiftFloorList[index].list[i].liftFloorId;
					var Status = false;
					for(var k=0;k<SaveFloorList.length;k++)
					{
						if(liftFloorId == SaveFloorList[k])
						{
							Status = true;
							if(!Type)//未选中
							{
								SaveFloorList.splice(k, 1);
								break;
							}
						}
					}
					if(Type)//选中
					{
						if(!Status)//不存在
						{
							SaveFloorList[SaveFloorList.length] = liftFloorId;
						}
					}
				}
				this.SaveFloorList = SaveFloorList;
			},
			
			//全选
			checkall(Status){
				if(Status)
				{
					var SaveFloorList = [];
					var LiftFloorList = this.LiftFloorList;
					for(var i=0;i<LiftFloorList.length;i++)
					{
						SaveFloorList[SaveFloorList.length] = LiftFloorList[i].liftAreaId;
						var list = LiftFloorList[i].list;
						for(var k=0;k<list.length;k++)
						{
							SaveFloorList[SaveFloorList.length] = list[k].liftFloorId;
						}
					}
					this.SaveFloorList = SaveFloorList;
				}
				else
				{
					this.SaveFloorList = [];
				}
			},
			
			//选中的楼层
			checkedFloor(arr){
				if(arr.length>=this.FloorCount)
				{
					this.FloorCountChecka = true;
				}
				else
				{
					this.FloorCountChecka = false;
				}
			},
			
			//保存权限
			async SaveFloorPermissions(){
				var Employees = this.EmployeesValue;
				var SaveFloorList = this.SaveFloorList;
				var LiftFloorList = this.LiftFloorList;
				var LiftFloor = [];
				var Controller = [];
				for(var i=0;i<SaveFloorList.length;i++)
				{
					for(var k=0;k<LiftFloorList.length;k++)
					{
						var Status = false;
						var LiftAreaId = "";
						var list = LiftFloorList[k].list;
						for(var j=0;j<list.length;j++)
						{
							if(SaveFloorList[i] == list[j].liftFloorId)
							{
								LiftAreaId = list[j].floorIndex;
								Status = true;
								break;
							}
						}
						if(Status)
						{
							Status = false;
							for(var j=0;j<LiftFloor.length;j++)
							{
								if(LiftFloorList[k].liftAreaId==LiftFloor[j].liftAreaId)
								{
									LiftFloor[j].FloorIndex[LiftFloor[j].FloorIndex.length] = LiftAreaId;
									Status = true;
								}
							}
							if(!Status)
							{
								Controller[Controller.length] = LiftFloorList[k].controllerId;
								LiftFloor[LiftFloor.length] = {"liftAreaId":LiftFloorList[k].liftAreaId,"controllerId":LiftFloorList[k].controllerId,"FloorIndex":[LiftAreaId]}
							}
						}
					}
				}
				var Permissions = []
				for(var i=0;i<Employees.length;i++)
				{
					for(var k=0;k<LiftFloor.length;k++)
					{
						Permissions[Permissions.length] = {"employeeId":Employees[i],"liftAreaId":LiftFloor[k].liftAreaId,"controllerId":LiftFloor[k].controllerId,"liftAreaCode":this.FloorPermissionsTransition(LiftFloor[k].FloorIndex)}
					}
				}
				
				try
				{
					if(Employees.length==0) return this.$message({message: this.$t('LiftAreaAuthority.SelectEmployee'),type: 'error',offset:10});
					if(Permissions.length==0) return this.$message({message: this.$t('LiftAreaAuthority.SelectPermissionsLiftArea'),type: 'error',offset:10});
					this.ButtonLoad.AddUpdateAuthority=true;
					var {data: res} = await this.$http.post('AddFloorAuthorityEntity',{"Employees":Employees,"Controller":Controller,"FloorAuthority":Permissions})
					if (this.FilteringOperation(res))//成功
					{
						if(res.resultCode==1)
						{
							this.$message({message: this.$t('LiftAreaAuthority.AddPermissionsSucceed'),type: 'success',offset:10});
							this.QueryFloor("");
							this.EditPermissionVisible = false;
						}
						else
						{
							this.$message({message: this.$t('LiftAreaAuthority.AddPermissionsFailure'),type: 'error',offset:10});
						}
					}
					this.ButtonLoad.AddUpdateAuthority=false;
				}
				catch (err)
				{
					this.ButtonLoad.AddUpdateAuthority=false;
					console.log(err)
				}
			},
			
			//权限转换
			FloorPermissionsTransition(FloorIndex){
				var FloorIndexCode = "";
				for(var i=-8;i<121;i++)
				{
					if(i!=0)
					{
						var Status = false;
						for(var k=0;k<FloorIndex.length;k++)
						{
							if(i==FloorIndex[k])
							{
								FloorIndexCode = FloorIndexCode+"1";
								Status = true;
								break;
							}
						}
						if(!Status) FloorIndexCode = FloorIndexCode+"0";
					}
				}
				var FloorIndexCode16 = "";
				FloorIndexCode = FloorIndexCode.split("").reverse().join("");
				for(var i=0;i<FloorIndexCode.length/8;i++){
					var Code = FloorIndexCode.substring(i*8,(i+1)*8);
					var Code10 = parseInt(Code,2);//转10进制
					var Code16 = Code10.toString(16);//转16进制
					if(Code16.length<2) Code16 = "0"+Code16;
					FloorIndexCode16 = Code16+FloorIndexCode16;
				}
				return FloorIndexCode16;
			 },
			 
			 //修改权限按钮事件
			 UpdateFloorPermissionsBut(FloorAuthority){
				 this.LiftFloorList = [];
				 this.SaveFloorList = [];
				 this.RecordID = FloorAuthority.recordID;
				 this.UpdateEditPermissionVisible = true;
				 this.EmployeesValue = [FloorAuthority.employeeId];
				 var FloorCodeList = FloorAuthority.liftFloorCode!="00"?this.FloorTransform(FloorAuthority.liftFloorCode):"00";
				 this.LiftFloorPermissions(FloorAuthority.liftAreaCode,FloorAuthority.liftAreaId,FloorCodeList);//查询人员权限楼层
			 },
			 
			 //查询人员权限楼层
			async LiftFloorPermissions(liftAreaCode,liftAreaId,FloorCodeList){
				 try
				 {
				 	this.PageLoad.FloorLoad = true;
					var SaveFloorList = [];
				 	this.FloorCountChecka = false;
				 	var {data: res} = await this.$http.post('LiftFloorEntityList',{"LiftAreaCode":liftAreaCode})
				 	if (res.resultCode == 1)//成功
				 	{ 
				 		var LiftFloor = JSON.parse(res.body);
				 		var FloorDataList = [];
				 		for(var i=0;i<LiftFloor.length;i++)
				 		{
				 			var status = false;
				 			LiftFloor[i].checkbox = false;
				 			for(var k=0;k<FloorDataList.length;k++){
				 				if(LiftFloor[i].liftAreaId == FloorDataList[k].liftAreaId)
				 				{
				 					status = true;
				 					FloorDataList[k].list[FloorDataList[k].list.length] = LiftFloor[i];
				 					break;
				 				}
				 			}
				 			if(!status)
				 			{
				 				FloorDataList[FloorDataList.length] = {"liftAreaId":LiftFloor[i].liftAreaId,"controllerId":LiftFloor[i].controllerId,"liftFloorId":LiftFloor[i].liftFloorId,"liftAreaName":LiftFloor[i].liftAreaName,"checkbox":false,"list":[LiftFloor[i]]}
				 			}
				 		}
						if(FloorCodeList!="00")
						{
							for(var i=0;i<FloorCodeList.length;i++)
							{
								for(var k=0;k<LiftFloor.length;k++)
								{
									if(liftAreaId == LiftFloor[k].liftAreaId)
									{
										if(FloorCodeList[i]==LiftFloor[k].floorIndex){
											SaveFloorList[SaveFloorList.length] = LiftFloor[k].liftFloorId;
										}
									}
								}
							}
						}
						else
						{
							for(var k=0;k<LiftFloor.length;k++)
							{
							 	if(liftAreaId == LiftFloor[k].liftAreaId)
							 	{
							 		SaveFloorList[SaveFloorList.length] = LiftFloor[k].liftFloorId;
							 	}
							}
							SaveFloorList[SaveFloorList.length] = liftAreaId;
						}
						this.SaveFloorList = SaveFloorList;
				 		this.LiftFloorList = FloorDataList;
				 	}
				 	this.PageLoad.FloorLoad = false;
				 } 
				 catch (err)
				 {
				 	this.PageLoad.FloorLoad = false;
				 	console.log(err)
				 }
			 },
			
			//修改权限
			async UpdateFloorPermissions(){
				this.ButtonLoad.AddUpdateAuthority=true;
				var Employees = this.EmployeesValue;
				var SaveFloorList = this.SaveFloorList;
				var LiftFloorList = this.LiftFloorList;
				var LiftFloor = [];
				var Controller = [];
				for(var i=0;i<SaveFloorList.length;i++)
				{
					for(var k=0;k<LiftFloorList.length;k++)
					{
						var Status = false;
						var LiftAreaId = "";
						var list = LiftFloorList[k].list;
						for(var j=0;j<list.length;j++)
						{
							if(SaveFloorList[i] == list[j].liftFloorId)
							{
								LiftAreaId = list[j].floorIndex;
								Status = true;
								break;
							}
						}
						if(Status)
						{
							Status = false;
							for(var j=0;j<LiftFloor.length;j++)
							{
								if(LiftFloorList[k].liftAreaId==LiftFloor[j].liftAreaId)
								{
									LiftFloor[j].FloorIndex[LiftFloor[j].FloorIndex.length] = LiftAreaId;
									Status = true;
								}
							}
							if(!Status)
							{
								Controller[Controller.length] = LiftFloorList[k].controllerId;
								LiftFloor[LiftFloor.length] = {"liftAreaId":LiftFloorList[k].liftAreaId,"controllerId":LiftFloorList[k].controllerId,"FloorIndex":[LiftAreaId]}
							}
						}
					}
				}
				
				try
				{
					var LiftFloorCode = this.FloorPermissionsTransition(LiftFloor[0].FloorIndex);
					var RecordID = this.RecordID;
					var {data: res} = await this.$http.post('UpdateFloorPermissions',{"RecordID":RecordID,"LiftFloorCode":LiftFloorCode})
					if (this.FilteringOperation(res))//成功
					{
						if(res.resultCode==1)
						{
							this.$message({message: this.$t('LiftAreaAuthority.UpdatePermissionsSucceed'),type: 'success',offset:10});
							this.QueryFloor("");
							this.UpdateEditPermissionVisible = false;
						}
						else
						{
							this.$message({message: this.$t('LiftAreaAuthority.UpdatePermissionsFailure'),type: 'error',offset:10});
						}
					}
					this.ButtonLoad.AddUpdateAuthority=false;
				}
				catch (err)
				{
					this.ButtonLoad.AddUpdateAuthority=false;
					console.log(err)
				}
			},
			
			//保存选中结果
			handleSelectionChange(val) {
				if(val.length>0)
				{
					for (var i = 0; i < val.length; i++) {
						this.IdData[i] = val[i].recordID;
					}
				}
				else{
					this.IdData = [];
				}
			},
			
			//删除权限
			async DeleteFloorAuthority(RecordID){
				if(RecordID.length==0) 
				return this.$message({message: this.$t('LiftAreaAuthority.SelectDleteEmployee'),type: 'warning',offset:10});
				this.$confirm(this.$t('LiftAreaAuthority.WhetherDelete'), this.$t('LiftAreaAuthority.Hint'), {type: 'warning'}).then(async () => {
					try
					{
						var {data: res} = await this.$http.post('DeleteFloorAuthority',{"RecordID":RecordID})
						if (this.FilteringOperation(res))//成功
						{
							if(res.resultCode==1)
							{
								this.$message({message: this.$t('LiftAreaAuthority.DeleteSucceed'),type: 'success',offset:10});
								this.RecordID = [];
								this.IdData = [];
								this.QueryFloor("");
							}
							else
							{
								this.$message({message: this.$t('LiftAreaAuthority.DeleteFailure'),type: 'error',offset:10});
							}
						}
					}
					catch (err)
					{
						console.log(err)
					}
				}).catch(() => {
					//取消
				});
			},
			
			//写卡
			async WriteCard(e){
				this.ButtonLoad.WriteCard = e.recordID;
				var LiftIndex = e.liftIndex;//电梯组号
				var liftFloorCode = e.liftFloorCode;//楼层权限编码
				var {data:res} = await this.$http.post('WriteLiftArea',{"EmployeeId":e.employeeId})
				if(res.Status==0) this.$message({message: this.$t('LiftAreaAuthority.FailedObtainData'),type: 'error',offset:10});
				var WriteLiftArea = JSON.parse(res.body);
				var EID = WriteLiftArea.eID;//物业代码
				var ICSerAdd = WriteLiftArea.iCSerAdd;//扇区地址
				var ICSerKey = WriteLiftArea.iCSerKey;//扇区密码
				var startTime = WriteLiftArea.startTime;//人员有效开始时间
				var endTime = WriteLiftArea.endTime;//人员有效结束时间
				try
				{
					this.$message({message: '<div style="margin-left:10px;color:#909399">'+this.$t('LiftAreaAuthority.Connection')+'</div>',iconClass:"el-icon-loading","dangerouslyUseHTMLString":true,offset:10,duration:0});
					var {data: res} = await this.$http({method: 'post',url:'http://127.0.0.1:1565',data:{"Type":1,"CardBit":10,"Seq":0},withCredentials:false});
					// var {data: res} = await this.$http.post('http://localhost:1565',{"Type":1,"CardBit":10,"Seq":0});
					if(res.Status==1)
					{
						//转16进制
						var Card = Number(res.CardNumber).toString(16);//卡号
						LiftIndex = Number(LiftIndex).toString(16);//电梯组号
						if(LiftIndex.toString().length<2) LiftIndex= "0"+LiftIndex;
						var ICSerValue = [];
						var CardType = "04"//普通楼层卡
						if(endTime!=undefined && endTime!="")
						{
							var DateTime = this.stringToDate(endTime);
							endTime = DateTime[4].toString(16).length<2?"0"+DateTime[4].toString(16):DateTime[4].toString(16);//分
							endTime = endTime + (DateTime[3].toString(16).length<2?"0"+DateTime[3].toString(16):DateTime[3].toString(16));//时
							endTime = endTime + (DateTime[2].toString(16).length<2?"0"+DateTime[2].toString(16):DateTime[2].toString(16));//日
							endTime = endTime + "00";
							endTime = endTime + (DateTime[1].toString(16).length<2?"0"+DateTime[1].toString(16):DateTime[1].toString(16));//月
							endTime = endTime + ((DateTime[0]-2000).toString(16).length<2?"0"+(DateTime[0]-2000).toString(16):(DateTime[0]-2000).toString(16));//年
							CardType = "0A";//限时卡
						}
						else
						{
							endTime = "000000000000";
						}
						ICSerValue[0] = Card+"0000"+EID+"00"+LiftIndex+"0000000000"+CardType;
						ICSerValue[1] = liftFloorCode;
						ICSerValue[2] = "00000000000000000000"+endTime;
						this.$message({message: '<div style="margin-left:10px;color:#909399">'+this.$t('LiftAreaAuthority.WriteCard')+'</div>',iconClass:"el-icon-loading","dangerouslyUseHTMLString":true,offset:10,duration:0});
						var {data: res} = await this.$http({method: 'post',url:'http://localhost:1565',data:{"Type":2,"ICSerAdd":ICSerAdd,"ICSerKey":ICSerKey,"Loadmode":0,"ICSerValue":ICSerValue},withCredentials:false});
						if(res.Status==1)
						{
							this.$message({message: this.$t('LiftAreaAuthority.WriteCardSucceed'),type: 'success',offset:10});
						}
						else
						{
							this.$message({message: this.$t('LiftAreaAuthority.WriteCardFailure'),type: 'error',offset:10});
						}
					}
					else
					{
						this.$message({message: this.$t('LiftAreaAuthority.ReadCardFailure'),type: 'error',offset:10});
					}
					this.ButtonLoad.WriteCard = 0;
				}
				catch (err)
				{
					console.log(err)
					this.ButtonLoad.WriteCard = 0;
					this.$message({message: '<div style="color:#F56C6C">'+ this.$t('LiftAreaAuthority.ConnectionServerFailure')+'&nbsp;&nbsp;<span style="font-size:10px">('+ this.$t('LiftAreaAuthority.ConnectionServerFailureHint')+')</span>',type: 'error',"dangerouslyUseHTMLString":true,offset:10});
				}
				
			},
			
			//时间转换
			stringToDate(str){
			    var tempStrs = str.split(" ");
			    var dateStrs = tempStrs[0].split("-");
			    var year = parseInt(dateStrs[0], 10);
			    var month = parseInt(dateStrs[1], 10) - 1;
			    var day = parseInt(dateStrs[2], 10);
			    var timeStrs = tempStrs[1].split(":");
			    var hour = parseInt(timeStrs [0], 10);
			    var minute = parseInt(timeStrs[1], 10);
			    var second = parseInt(timeStrs[2], 10);
			    var date = new Date(year, month, day, hour, minute, second);
			    return [date.getFullYear(),date.getMonth()+1,date.getDate(),date.getHours(),date.getMinutes(),date.getSeconds()];
			},
			
			//获取选择的人员
			AddEmployeesValue(e){
				this.EmployeesValue = e;
			},
			
			//操作过滤
			FilteringOperation(res){
				var State = true;
				if (res.stateCode == 0) {
					State = false;
					this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
				} else if (res.stateCode == 2) {
					State = false;
					this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
				} else if (res.stateCode == 3) {
					State = false;
					this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
				}
				return State;
			}
		}
	};
</script>

<style scoped>
	
	.LiftAreaAuthority{
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	/* 内容 */
	.content{
		display: flex;
		flex-grow: 1;
		height: 100%;
	}
	
	/* 左侧 */
	.content_left{
		background-color: #FFFFFF;
		width: 290px;
		height: 100%;
		margin-right: 20px;
	}

	.left_tree {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.left_tree_input {
		width:90%;
		margin:0px auto;
		margin:15px 0px;

	}

	.custom-tree-node{
		font-size: 13px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.custom-tree-node i{
		padding: 5px 10px;
	}

	.content_left_tree{
		width:90%;
		flex-grow: 1;
		margin: 0px auto;
		margin-bottom: 15px;
		overflow: auto;
	}

	.left_tree{
		display: flex;
		align-items:center;
		justify-content: space-between;
		width: 100%;
		font-size: 15px;
	}

	.left_tree_icon{
		padding: 0px 5px;
	}

	/* 右侧 */
	.content_right{
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	/* 右侧-头部 */
	.content_right_top{
		width: 100%;
		height: 70px;
		display: flex;
		margin-bottom: 20px;
	}

	.right_top_card1{
		flex-grow: 1;
		height: 100%;
		background-color: #FFFFFF;
		justify-content: space-between;
	}

	.right_top_search>i{
		margin-top: -3px;
		font-size: 25px;
		margin-left:25px;
		margin-right:10px;
		color: #909399;
	}

	.right_top_Date{
		margin-left:21px;
		margin-top: 3px;
	}

	.right_top_search>>>.el-input__inner {
    	border: 0;
		font-size: 15px;
  	}

	.right_top_search_type{
		margin-right: 15px;
	}

	.right_top_search_type>i{
		margin-left: 20px;
		height: 20px;
		display: flex;
		border-left:1px solid #c7c7c7;
	}

	.right_top_search_type>>>.el-input__inner{
		border:0;
	}

	.right_top_card2{
		margin: 0px 20px;
		width: 300px;
		height: 100%;
		background-color: #FFFFFF;
	}

	.right_top_card3{
		width: 140px;
		height: 100%;
		background-color: #FFFFFF;
	}


	/* 右侧-底部 */
	.content_right_bottom{
		flex-grow: 1;
		height: 0px;
		width: 100%;
		background-color: #FFFFFF;
	}

	.content_right_bottom>div{
		display: flex;
		flex-direction: column;
		width: 96%;
		height: 95%;
	}

	.content_right_bottom_title{
		font-weight: 600;
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 10px;
	}

	.content_right_bottom_page{
		margin-top: 12px;
	}

	.Table-column{
		height: 26px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	/* 添加权限 */
	.Area_input {
		display: flex;
		margin-bottom: 10px;
	}
	
	.EquipmentOption{
		width: 98%;
		margin: 0px auto;
		margin-bottom: 10px;
	}
	
	.EquipmentType{
		width: 140px;
		padding-left: 20px;
		padding-right: 20px;
		margin: 20px;
	}
	
	.EquipmentType img{
		cursor:pointer
	}
	
	.EquipmentType div{
		height: 25px;
		display: flex;
		align-items:flex-end;
		justify-content: center;
	}
	
	.EquipmentImg{
		width: 100%;
		height: 100%;
	}
	
	.InputWidth{
		width: 45%;
	}
	
	.EquipmentForm{
		width:98%;
		margin-top: 30px;
	}
	
	.TableP{
		display: flex;
		align-items: center;
		margin-right: 10px;
	}
	
	.TableP div{
		margin-top: 10px;
		margin-bottom: 10px;
		width: 100px;
	}
	.FloorTransform1{
		display: flex;
		width: 260px;
		flex-wrap: wrap;
	}
	.FloorTransform2{
		display: flex;
		width: 605px;
		flex-wrap: wrap;
	}
	
	.checkbox{
		width: 100%;
	}
	
	.checkbox_son{
		padding: 10px;
		display: flex;
		flex-wrap:wrap;
	}
	
	.checkbox_son>div{
		margin: 5px 5px;
	}
	.checkbox_son label{
		width: 97px;
	}
</style>
