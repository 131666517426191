<template>
  <div class="liftArea">
    <!-- 内容 -->
    <div class="content">
      <!-- 左侧 -->
      <div class="content_left boxshadow" v-loading="PageLoad.Leftload">
        <div class="left_tree">
          <!-- 功能栏 -->
          <div class="left_tree_input">
            <el-input
              maxlength="32"
              :placeholder="$t('LiftArea.SearchLiftArea')"
              size="medium"
              v-model="filterText"
              style="width: 155px"
            ></el-input>
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-circle-plus-outline"
              style="margin-left: 15px"
              @click="AddUpdLiftAreaDialog(1)"
              :disabled="!userGroupDetails.RoleLiftArea"
              >{{ $t("LiftArea.Add") }}</el-button
            >
          </div>

          <!-- 拨码开关 -->
          <div class="Left-DialSwitch" @click="DialSwitchListDialog">
            <div>
              <i class="el-icon-set-up"></i
              ><span>{{ $t("LiftArea.DialSwitch") }}</span
              ><i class="el-icon-right"></i>
            </div>
          </div>

          <!-- 树形菜单 -->
          <el-tree
            class="content_left_tree"
            :highlight-current="true"
            :data="data"
            :props="defaultProps"
            default-expand-all
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            icon-class="el-icon-s-promotion"
            node-key="liftAreaId"
            ref="tree"
          >
            <div
              class="custom-tree-node"
              slot-scope="{ data }"
              style="height: 100%"
              @click="SelectLiftArea(data)"
            >
              <div style="width: 100%; font-size: 15px">
                <el-popover
                  v-if="data.remark != '' && data.remark != undefined"
                  placement="left"
                  :title="$t('LiftArea.Remark')"
                  width="200"
                  trigger="hover"
                  :content="data.remark"
                >
                  <div slot="reference">
                    {{ data.liftAreaName }}
                    <i
                      v-if="data.children.length == 0 && data.status != 1"
                      class="el-icon-remove-outline"
                      style="color: red; font-size: 15px; padding: 3px"
                    ></i>
                  </div>
                </el-popover>
                <div v-else slot="reference">
                  {{ data.liftAreaName }}
                  <i
                    v-if="data.children.length == 0 && data.status != 1"
                    class="el-icon-remove-outline"
                    style="color: red; font-size: 15px; padding: 3px"
                  ></i>
                </div>
              </div>
              <div>
                <el-popover placement="right" trigger="hover" :open-delay="200">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="AddUpdLiftAreaDialog(1, data)"
                    :disabled="!userGroupDetails.RoleLiftArea"
                    >{{ $t("LiftArea.Add") }}</el-button
                  >
                  <el-button
                    type="info"
                    size="mini"
                    @click="AddUpdLiftAreaDialog(2, data)"
                    :disabled="!userGroupDetails.RoleLiftArea"
                    >{{ $t("LiftArea.Update") }}</el-button
                  >
                  <el-button
                    type="danger"
                    size="mini"
                    @click="DeleteLiftArea(data.liftAreaId, data.controllerId,data.liftAreaName)"
                    :disabled="!userGroupDetails.RoleLiftArea"
                    >{{ $t("LiftArea.Delete") }}</el-button
                  >
                  <i class="el-icon-more" slot="reference"></i>
                </el-popover>
              </div>
            </div>
          </el-tree>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="content_right" v-if="FloorDisplayStatus(LiftAreaData)" v-loading="PageLoad.Rightload">
        <!-- 头部 -->
        <div class="content_right_top flex-items-center">
          <!-- 搜索框 -->
          <div class="right_top_card1 flex-items-center boxshadow">
            <div class="DialSwitch-Title">{{ $t("LiftArea.DialSwitch") }}</div>
            <div class="DialSwitch">
              <div
                v-for="items in DialSwitch"
                :key="items.liftAreaId"
                :class="
                  items == 1 ? 'border-DialSwitch-On' : 'border-DialSwitch-Off'
                "
              >
                <div v-if="items == 1" class="DialSwitch-On">
                  <i
                    class="el-icon-bottom"
                    style="transform: rotate(180deg)"
                  ></i>
                </div>
                <div v-else class="DialSwitch-Off">
                  <i class="el-icon-bottom" style="transform: rotate(0deg)"></i>
                </div>
              </div>
              <div style="border: 1px solid #a4acb4">
                <i class="el-icon-error"></i>
              </div>
            </div>
          </div>
          <!--操作1 -->
          <div class="right_top_card2 flex-center boxshadow">
            <div class="DialSwitch-Title">{{ $t("LiftArea.FloorNumber") }}</div>
            <el-input
              placeholder="-7"
              style="width: 60px; margin-right: 15px"
              maxlength="3"
              v-model="least"
            ></el-input>
            <div class="To">-</div>
            <el-input
              placeholder="120"
              style="width: 60px; margin: 0px 15px"
              maxlength="3"
              v-model="max"
            ></el-input>
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-receiving"
              :loading="ButtonLoad.AddUpdateLiftArea"
              @click="SaveFloor"
              :disabled="!userGroupDetails.RoleLiftFloor"
              >{{ $t("LiftArea.Create") }}</el-button
            >
          </div>
          <!--操作2 -->
          <div class="right_top_card3 flex-center boxshadow">
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-menu"
              @click="LiftAreaConfigQrCode"
              :disabled="!userGroupDetails.RoleLiftFloor"
              >{{ $t("LiftArea.ConfigCode") }}</el-button
            >
            <el-button
              size="medium"
              icon="el-icon-refresh-right"
              @click="QueryFloor(LiftAreaData.liftAreaId)"
              >{{ $t("LiftArea.Refresh") }}</el-button
            >
          </div>
        </div>
        <!-- 底部 -->
        <div class="content_right_bottom flex-center boxshadow">
          <div>
            <div class="content_right_bottom_title">
              {{ $t("LiftArea.LiftArea") }}
            </div>
            <el-table :data="tableData" height="100%">
              <el-table-column
                align="center"
                prop="date"
                :label="$t('LiftArea.Building')"
              >
                <template slot-scope="scope">
                  {{ scope.row.floorIndex }}&nbsp;{{ $t("LiftArea.Floor") }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="name"
                :label="$t('LiftArea.Name')"
              >
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.type == undefined || scope.row.type == 0"
                  >
                    <span
                      v-if="
                        scope.row.floorName == '' ||
                        scope.row.floorName == undefined
                      "
                      >{{ scope.row.floorIndex }}&nbsp;{{
                        $t("LiftArea.Floor")
                      }}</span
                    >
                    <span v-else>{{ scope.row.floorName }}</span>
                    <el-button
                      size="mini"
                      type="text"
                      v-if="userGroupDetails.RoleLiftFloor"
                      style="margin-left: 5px"
                      class="el-icon-edit UpdateFloorName"
                      @click="UpdateFloorName(scope.row, scope.$index)"
                    ></el-button>
                  </div>
                  <div v-else class="flex-center">
                    <el-input
                      class="FloorCount_input"
                      maxlength="32"
                      style="width: 150px; margin-right: 10px"
                      size="mini"
                      v-model="scope.row.floorName"
                    ></el-input>
                    <el-button
                      slot="append"
                      size="mini"
                      @click="UpdateLiftFloor(scope.row, scope.$index)"
                      >{{ $t("LiftArea.Save") }}</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="liftAreaName"
                :label="$t('LiftArea.Their')"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="address"
                :label="$t('LiftArea.Status')"
              >
                <template slot-scope="scope">
                  <div class="Table-column">
                    <el-switch
                      style="display: block"
                      :disabled="!userGroupDetails.RoleLiftFloor"
                      v-model="scope.row.status"
                      @change="UpdateLiftFloor(scope.row, scope.$index)"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-text="$t('LiftArea.On')"
                      :inactive-text="$t('LiftArea.Off')"
                    >
                    </el-switch>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div v-else class="content_right2 flex-center boxshadow">
        <div>
          <i class="el-icon-discount" style="font-size: 100px"></i>
          <div style="text-align: center; font-size: 17px">
            {{ $t("LiftArea.SelectLiftArea") }}
          </div>
        </div>
      </div>
    </div>

    <!-- 弹框 -->
    <!-- 拨码开关列表 -->
    <el-dialog
      :title="$t('LiftArea.DialSwitch')"
      :visible.sync="DialSwitchListStatus"
      :close-on-click-modal="false"
    >
      <el-table
        :data="DialSwitchList"
        height="600px"
        v-loading="PageLoad.Dialog"
      >
        <el-table-column
          align="center"
          prop="date"
          :label="$t('LiftArea.Their')"
        >
          <template slot-scope="scope">
            {{
              scope.row.parentLiftAreaName != undefined
                ? scope.row.parentLiftAreaName
                : $t("LiftArea.No")
            }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="date"
          :label="$t('LiftArea.LiftAreaNumber')"
        >
          <template slot-scope="scope">
            {{ scope.row.liftIndex }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="date"
          :label="$t('LiftArea.LiftAreaName')"
        >
          <template slot-scope="scope">
            {{ scope.row.liftAreaName }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="date"
          width="320"
          :label="$t('LiftArea.DialSwitch')"
        >
          <template slot-scope="scope">
            <div class="DialSwitchList_DialSwitch">
              <div
                v-for="items in DialSwitchTransition(scope.row.liftIndex)"
                :key="items.liftAreaId"
                :class="
                  items == 1 ? 'border-DialSwitch-On' : 'border-DialSwitch-Off'
                "
              >
                <div v-if="items == 1" class="DialSwitch-On">
                  <i
                    class="el-icon-bottom"
                    style="transform: rotate(180deg)"
                  ></i>
                </div>
                <div v-else class="DialSwitch-Off">
                  <i class="el-icon-bottom" style="transform: rotate(0deg)"></i>
                </div>
              </div>
              <div style="border: 1px solid #a4acb4">
                <i class="el-icon-error" style="color: #a4acb4"></i>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 新增修改电梯组 -->
    <el-dialog
      :title="AddUpdLiftTitle"
      :visible.sync="AddUpdLiftStatus"
      width="35%"
      :close-on-click-modal="false"
    >
      <el-form
        ref="AddUpdLift_Ref"
        :model="AddUpdLift_model"
        :rules="AddUpdLift_rules"
        label-width="100px"
      >
        <el-form-item
          :label="$t('LiftArea.Their')"
          v-if="ParentLiftAreaIdStatus"
        >
          <el-select
            v-model="AddUpdLift_model.ParentLiftAreaId"
            :placeholder="$t('LiftArea.Select')"
            style="width: 100%"
          >
            <el-option
              v-for="item in data"
              :key="item.liftAreaId"
              :label="item.liftAreaName"
              :value="item.liftAreaId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('LiftArea.LiftAreaName')" prop="LiftAreaName">
          <el-input
            maxlength="32"
            :placeholder="$t('LiftArea.placeholder')"
            v-model="AddUpdLift_model.LiftAreaName"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('LiftArea.LiftAreaRemark')" prop="Remark">
          <el-input
            maxlength="32"
            type="textarea"
            v-model="AddUpdLift_model.Remark"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('LiftArea.LiftAreaStatus')" v-if="LiftStatus">
          <el-switch
            style="margin-top: -4.2px"
            v-model="AddUpdLift_model.LiftStatus"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="$t('LiftArea.On')"
            :inactive-text="$t('LiftArea.Off')"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddUpdLiftStatus = false">{{
          $t("LiftArea.Cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="AddUpdLiftArea"
          :loading="AddUpdStatus"
          >{{ $t("LiftArea.Confirm") }}</el-button
        >
      </span>
    </el-dialog>

    <!-- 配置码 -->
    <el-dialog
      title="配置码"
      :visible.sync="ConfigStatus"
      width="740px"
      :close-on-click-modal="false"
    >
      <el-card class="box-card" shadow="never">
        <div class="ConfigQrForme">
          <div style="width: 320px; height: 300px" v-loading="PageLoad.QrCode">
            <qrcode
              :value="ConfigQrCode"
              :options="{ width: 300, errorCorrectionLevel: 'L' }"
            ></qrcode>
          </div>
          <div class="QRConfig">
            <el-radio-group v-model="activeName" @change="LiftAreaConfigQrCode">
              <div class="QRRadio">
                <div>
                  <el-radio label="1" border>{{
                    $t("LiftArea.ConfigCode1")
                  }}</el-radio>
                </div>
                <div>
                  <el-radio label="2" border>{{
                    $t("LiftArea.ConfigCode2")
                  }}</el-radio>
                </div>
                <div>
                  <el-radio label="3" border>{{
                    $t("LiftArea.ConfigCode3")
                  }}</el-radio>
                </div>
              </div>
            </el-radio-group>
            <div class="QRDescribe">
              <div class="QrDescribeValue" v-if="activeName == 1">
                {{ $t("LiftArea.ConfigCodeLiftAreaStatus") }}：
                <span style="color: #f56c6c" v-if="QrDescribeValue == 0">{{
                  $t("LiftArea.Off")
                }}</span>
                <span style="color: #67c23a" v-else>{{
                  $t("LiftArea.On")
                }}</span>
              </div>
              <div class="QrDescribeValue" v-else-if="activeName == 2">
                {{ $t("LiftArea.ConfigCode2Date") }}：{{ QrDescribeValue }}
              </div>
              <div class="QrDescribeValue" v-else-if="activeName == 3">
                {{ $t("LiftArea.Reset") }}
              </div>
              <div class="QRRadioDescribe">
                <div>1.{{ $t("LiftArea.Describe1") }}</div>
                <div>2.{{ $t("LiftArea.Describe2") }}</div>
                <div>3.{{ $t("LiftArea.Describe3") }}</div>
                <div>4.{{ $t("LiftArea.Describe4") }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ConfigStatus = false">{{
          $t("LiftArea.Cancel")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  watch: {
    //电梯组查询过滤使用
    filterText(val) {
      //每当输入的数据有变化就触发原生的过滤节点这个函数
      this.$refs.tree.filter(val);
    },
  },
  data() {
    //校验名称
    var LiftAreaName_verify = (rule, value, callback) => {
      if (!value)
        return callback(new Error(this.$t("LiftArea.placeholder")));
      callback();
    };
    return {
      //权限组
      userGroupDetails: { RoleLiftArea: false, RoleLiftFloor: false },
      // 页面加载
			PageLoad:{
   			Leftload: false, //左侧加载状态
				Rightload: false, //右侧加载状态
        Dialog:false,//弹出层加载状态
        QrCode:false,//配置码加载状态
			},
      // 按钮加载状态
			ButtonLoad:{
					//新增修改电梯组
 					AddUpdateLiftArea: false,
					//楼层生成
 					AddLift: false,
			},
      //电梯组
      filterText: "", //搜索电梯组
      QueryLiftAreaLoad: false, //加载动画
      data: [], //电梯组数据
      defaultProps: { children: "children", label: "liftAreaName" }, //电梯组数据参数
      DialSwitchList: [], //电梯组拨码开关列表
      DialSwitchListStatus: false, //电梯组弹框显示状态
      AddUpdLiftStatus: false, //电梯组新增修改弹框
      AddUpdLiftTitle: "", //电梯组新增修改弹框标题
      ParentLiftAreaIdStatus: true, //父电梯组框显示状态
      LiftStatus: true, //是否启用电梯组显示状态
      AddUpdStatus: false, //提交按钮动画
      AddUpdLift_model: {
        LiftAreaId: 0, //电梯组Id
        LiftAreaName: "", //电梯组名称
        ParentLiftAreaId: "", //父电梯组Id
        LiftStatus: false, //电梯组状态
        Remark: "", //备注
      },
      //电梯组表单验证规则
      AddUpdLift_rules: {
        LiftAreaName: [
          {
            required: true,
            validator: LiftAreaName_verify,
            trigger: "change",
          },
        ],
        Remark: [],
      },
      LiftAreaData: {}, //选择的电梯组
      //楼层
      DialSwitch: [0, 0, 0, 0, 0, 0, 0], //拨码开关
      QueryFloorLoad: false, //表格加载动画
      least: "", //最小楼层
      max: "", //最大楼层
      tableData: [], //楼层列表
      ConfigStatus: false, //配置码弹框显示状态
      //配置码
      activeName: "1",
      ConfigQrCode: "{}", //码内容
      QrDescribeValue: "",
    };
  },
  mounted() {
    //获取操作权
    this.Role();
    //获取电梯组
    this.QueryLiftArea();
  },
  //事件
  methods: {
    //获取操作权
    Role() {
      //获操作取权限组
      var userGroupDetails = JSON.parse(
        window.sessionStorage.getItem("userGroupDetails")
      );
      for (var i = 0; i < userGroupDetails.length; i++) {
        if (userGroupDetails[i].roleName == "LiftArea") {
          //操作权
          this.userGroupDetails.RoleLiftArea =
            userGroupDetails[i].roleOperateValue == "1" ? true : false;
        } else if (userGroupDetails[i].roleName == "LiftFloor") {
          //操作权
          this.userGroupDetails.RoleLiftFloor =
            userGroupDetails[i].roleOperateValue == "1" ? true : false;
        }
      }
    },

    //获取电梯组搜索过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.liftAreaName.indexOf(value) !== -1;
    },

    //获取电梯组
    async QueryLiftArea() {
      try 
      {
        this.PageLoad.Leftload = true;
        var { data: res } = await this.$http.post("QueryLiftArea");
        if (res.resultCode == 1) {
          //成功
          var LiftArea = JSON.parse(res.body);
          this.data = LiftArea;
        }
        this.PageLoad.Leftload = false;
      } 
      catch (err) 
      {
        this.PageLoad.Leftload = false;
        console.log(err);
      }
    },

    //拨码开关转换
    DialSwitchTransition(LiftIndex) {
      LiftIndex = LiftIndex.toString(2);
      var ZeroFill = "";
      for (var i = 1; i < 8 - LiftIndex.length; i++) {
        ZeroFill = ZeroFill + "0";
      }
      LiftIndex = ZeroFill + LiftIndex;
      LiftIndex = LiftIndex.split("").reverse().join("");
      return LiftIndex.split("");
    },

    //显示拨码开关列表
    async DialSwitchListDialog() {
      try {
        this.PageLoad.Dialog = true;
        this.DialSwitchListStatus = true;
        var { data: res } = await this.$http.post("QueryDialSwitchLiftArea");
        if (res.resultCode == 1) {
          //成功
          var DialSwitchList = JSON.parse(res.body);
          this.DialSwitchList = DialSwitchList;
        }
        this.PageLoad.Dialog = false;
      } catch (err) {
        this.PageLoad.Dialog = false;
        console.log(err);
      }
    },

    //显示新增修改弹框
    AddUpdLiftAreaDialog(Type, LiftAreaData) {
      this.AddUpdLiftStatus = true;
      if (Type == 1) {
        //新增
        this.AddUpdLiftTitle = this.$t("LiftArea.AddLiftArea"); //电梯组新增修改弹框标题
        this.EmptyLiftArea_model(); //清空电梯组参数
        this.ParentLiftAreaIdStatus = true; //电梯组弹框显示状态
        var ParentLiftAreaId = ""; //父电梯组Id
        if (LiftAreaData != undefined) {
          if (LiftAreaData.parentLiftAreaId == 0) {
            ParentLiftAreaId = LiftAreaData.liftAreaId;
          } else {
            ParentLiftAreaId = LiftAreaData.parentLiftAreaId;
          }
        }
        this.AddUpdLift_model.ParentLiftAreaId = ParentLiftAreaId;
      } //修改
      else {
        this.AddUpdLiftTitle = this.$t("LiftArea.UpdateLiftArea"); //电梯组新增修改弹框标题
        this.ParentLiftAreaIdStatus = false; //电梯组弹框显示状态
        if (
          LiftAreaData.parentLiftAreaId == 0 &&
          LiftAreaData.children.length > 0
        )
          this.LiftStatus = false;
        //是否启用电梯组显示状态;
        else this.LiftStatus = true; //是否启用电梯组显示状态;
        this.AddUpdLift_model.LiftAreaId = LiftAreaData.liftAreaId; //电梯组Id
        this.AddUpdLift_model.LiftAreaName = LiftAreaData.liftAreaName; //电梯组名称
        this.AddUpdLift_model.ParentLiftAreaId = LiftAreaData.parentLiftAreaId; //父电梯组Id
        this.AddUpdLift_model.LiftStatus =
          LiftAreaData.status == "0" ? false : true; //电梯组状态
        this.AddUpdLift_model.Remark = LiftAreaData.remark; //备注
      }
    },

    //清空电梯组参数
    EmptyLiftArea_model() {
      this.AddUpdLift_model.LiftAreaId = 0; //电梯组Id
      this.AddUpdLift_model.LiftAreaName = ""; //电梯组名称
      this.AddUpdLift_model.ParentLiftAreaId = ""; //父电梯组Id
      this.AddUpdLift_model.LiftStatus = true; //电梯组状态
      this.AddUpdLift_model.Remark = ""; //备注
    },

    //新增修改电梯组信息
    AddUpdLiftArea() {
      this.$refs.AddUpdLift_Ref.validate(async (valid) => {
        //验证
        if (!valid) return;
        this.AddUpdStatus = true;
        var AddUpdLift_Form = {
          LiftAreaId:
            this.AddUpdLift_model.LiftAreaId != ""
              ? this.AddUpdLift_model.LiftAreaId
              : 0,
          LiftAreaName: this.AddUpdLift_model.LiftAreaName,
          ParentLiftAreaId:
            this.AddUpdLift_model.ParentLiftAreaId != ""
              ? this.AddUpdLift_model.ParentLiftAreaId
              : 0,
          Remark: this.AddUpdLift_model.Remark,
          Status: this.AddUpdLift_model.LiftStatus ? 1 : 0,
        };
        var { data: res } = await this.$http.post(
          "AddUpdLiftArea",
          AddUpdLift_Form
        );
        if (this.FilteringOperation(res)) {
          this.AddUpdStatus = false;
          if (AddUpdLift_Form.LiftAreaId == 0) {
            var LiftAreaId = this.AddUpdLift_model.ParentLiftAreaId;
            this.EmptyLiftArea_model(); //清空电梯组参数
            this.AddUpdLift_model.ParentLiftAreaId = LiftAreaId;
            this.$message({
              message: this.$t("LiftArea.AddSucceed"),
              type: "success",
              offset: 10,
            });
          } else {
            this.AddUpdLiftStatus = false;
            this.$message({
              message: this.$t("LiftArea.UpdateSucceed"),
              type: "success",
              offset: 10,
            });
          }
          this.AddUpdLiftStatus = false;
          this.QueryLiftArea(); //刷新电梯组
        }
      });
    },

    //删除电梯组
    DeleteLiftArea(LiftAreaId, controllerId,liftAreaName) {
      this.$confirm(
        this.$t("LiftArea.WhetherDelete"),
        this.$t("LiftArea.Hint"),
        { type: "warning" }
      )
        .then(async () => {
          var LiftArea = {
            LiftAreaId: LiftAreaId,
            Controllers: [controllerId],
			LiftAreaName:liftAreaName,
          };
          var { data: res } = await this.$http.post("DeleteLiftarea", LiftArea);
          if (this.FilteringOperation(res)) {
            if (res.resultCode == 1) {
              //成功
              this.$message({
                message: this.$t("LiftArea.DeleteSucceed"),
                type: "success",
                offset: 10,
              });
              this.QueryLiftArea(); //刷新电梯组
            } else if (res.resultCode == 3) {
              //查询电梯组下存在数据
              this.$message({
                message: this.$t("LiftArea.LiftAreaThereData"),
                type: "error",
                offset: 10,
              });
            } //删除失败
            else {
              this.$message({
                message: this.$t("LiftArea.DeleteFailure"),
                type: "error",
                offset: 10,
              });
            }
          }
        })
        .catch(() => {
          //取消
        });
    },

    //选择电梯组
    SelectLiftArea(LiftAreaData) {
      //拨码开关
      this.LiftAreaData = LiftAreaData; //选择的电梯组
      this.DialSwitch = this.DialSwitchTransition(LiftAreaData.liftIndex);
      this.QueryFloor(LiftAreaData.liftAreaId); //获取楼层
    },

    //楼层信息显示状态
    FloorDisplayStatus(LiftAreaData) {
      if (LiftAreaData.children != undefined) {
        if (LiftAreaData.children.length == 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    //获取楼层
    async QueryFloor(LiftAreaId) {
      try {
        this.PageLoad.Rightload = true;
        var { data: res } = await this.$http.post("QueryFloor", {
          LiftAreaId: LiftAreaId,
        });
        if (res.resultCode == 1) {
          //成功
          var LiftFloor = JSON.parse(res.body);
          var least = 120;
          var max = -8;
          for (var i = 0; i < LiftFloor.length; i++) {
            if (LiftFloor[i].floorIndex < least)
              least = LiftFloor[i].floorIndex;
            if (LiftFloor[i].floorIndex > max) max = LiftFloor[i].floorIndex;
            LiftFloor[i].status = LiftFloor[i].status == 0 ? false : true;
            LiftFloor[i].type = 0;
          }
          this.least = LiftFloor.length > 0 ? least : ""; //最小楼层
          this.max = LiftFloor.length > 0 ? max : ""; //最大楼层
          this.tableData = LiftFloor;
        }
        this.PageLoad.Rightload = false;
      } catch (err) {
        this.PageLoad.Rightload = false;
        console.log(err);
      }
    },

    //保存楼层
    SaveFloor() {
      var least = this.least; //最小楼层
      var max = this.max; //最大楼层
      var LiftAreaId = this.LiftAreaData.liftAreaId;
      var regPos = /^-?\d+(?:\.\d+)?$/; //判断是否是数字。
      if (LiftAreaId == undefined || LiftAreaId == "")
        return this.$message({
          message: this.$t("LiftArea.SelectLiftArea"),
          type: "error",
          offset: 10,
        });
      if (!regPos.test(least) || !regPos.test(max))
        return this.$message({
          message: this.$t("LiftArea.Message1"),
          type: "error",
          offset: 10,
        });
      if (least < -7 || least > 120 || max < -7 || max > 120)
        return this.$message({
          message: this.$t("LiftArea.Message2"),
          type: "error",
          offset: 10,
        });
      this.ButtonLoad.AddLift = true;
      least = Number(this.least);
      max = Number(this.max) + 1;
      var FloorData = [];
      var LiftAreaId = this.LiftAreaData.liftAreaId; //电梯组ID
      var LiftAreaName = this.LiftAreaData.liftAreaName; //电梯组名称
      var TableData = this.tableData; //获取现有楼层
      var Tableleast = 120;
      var Tablemax = -8;
      for (var i = 0; i < TableData.length; i++) {
        if (TableData[i].floorIndex < Tableleast)
          Tableleast = TableData[i].floorIndex;
        if (TableData[i].floorIndex > Tablemax)
          Tablemax = TableData[i].floorIndex;
      }
      for (var i = least; i < max; i++) {
        if (i != 0) {
          var value = {
            floorIndex: i,
            floorName: "",
            liftAreaId: LiftAreaId,
            liftAreaName: LiftAreaName,
            status: true,
          };
          for (var k = 0; k < TableData.length; k++) {
            if (i == TableData[k].floorIndex) {
              value = {
                floorIndex: i,
                floorName: TableData[k].floorName,
                liftAreaId: TableData[k].liftAreaId,
                liftAreaName: TableData[k].liftAreaName,
                status: TableData[k].status,
              };
              break;
            }
          }
          FloorData[FloorData.length] = value;
        }
      }
      //保存楼层数据
      this.SaveLiftFloor(FloorData);
    },

    //保存楼层数据
    async SaveLiftFloor(LiftFloor) {
      try {
        this.ButtonLoad.AddLift = true;
        var LiftAreaId = this.LiftAreaData.liftAreaId;
        var { data: res } = await this.$http.post("SaveLiftFloor", {
          LiftFloor: LiftFloor,
          LiftAreaId: LiftAreaId,
        });
        if (res.resultCode == 1) {
          //成功
          this.$message({
            message: this.$t("LiftArea.CreatingSuccessful"),
            type: "success",
            offset: 10,
          });
          this.QueryFloor(this.LiftAreaData.liftAreaId); //重新获取楼层数据
        } else {
          this.$message({
            message: this.$t("LiftArea.CreateFailure"),
            type: "error",
            offset: 10,
          });
        }
        this.ButtonLoad.AddLift = false;
      } catch (err) {
        this.ButtonLoad.AddLift = false;
        console.log(err);
      }
    },

    //修改楼层名称
    UpdateFloorName(Floor, index) {
      this.tableData[index].type = 1;
    },

    //修改楼层信息
    async UpdateLiftFloor(LiftFloor, index) {
      try {
        this.QueryFloorLoad = true;
        LiftFloor = {
          LiftFloorId: LiftFloor.liftFloorId,
          FloorName: LiftFloor.floorName,
          Status: LiftFloor.status ? 1 : 0,
        };
        var { data: res } = await this.$http.post("UpdateLiftFloor", LiftFloor);
        if (res.resultCode == 1) {
          //成功
          this.$message({
            message: this.$t("LiftArea.UpdateSucceed"),
            type: "success",
            offset: 10,
          });
          this.tableData[index].type = 0;
        } else {
          this.$message({
            message: this.$t("LiftArea.UpdateFailure"),
            type: "error",
            offset: 10,
          });
        }
        this.QueryFloorLoad = false;
      } catch (err) {
        this.QueryFloorLoad = false;
        console.log(err);
      }
    },

    //配置码
    async LiftAreaConfigQrCode() {
      try {
        this.PageLoad.QrCode = true;
        this.ConfigStatus = true;
        this.QrDescribeValue = "";
        var parameter = {
          LiftAreaId: this.LiftAreaData.liftAreaId,
          Type: this.activeName,
        };
        var { data: res } = await this.$http.post(
          "LiftAreaConfigQrCode",
          parameter
        );
        if (res.resultCode == 1) {
          //成功
          var body = JSON.parse(res.body);
          this.ConfigQrCode = body.QrValue;
          this.QrDescribeValue = body.Value;
        }
        this.PageLoad.QrCode = false;
      } catch (err) {
        this.PageLoad.QrCode = false;
        console.log(err);
      }
    },

    //操作过滤
    FilteringOperation(res) {
      var State = true;
      if (res.stateCode == 0) {
        State = false;
        this.$message({
          message: this.$t("LiftArea.Session"),
          type: "warning",
          offset: 10,
        });
      } else if (res.stateCode == 2) {
        State = false;
        this.$message({
          message: this.$t("LiftArea.Failure"),
          type: "error",
          offset: 10,
        });
      } else if (res.stateCode == 3) {
        State = false;
        this.$message({
          message: this.$t("LiftArea.AccessDenied"),
          type: "error",
          offset: 10,
        });
      }
      return State;
    },
  },
};
</script>

<style scoped>
.liftArea {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* 内容 */
.content {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

/* 左侧 */
.content_left {
  background-color: #ffffff;
  width: 290px;
  height: 100%;
  margin-right: 20px;
}

.left_tree {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.left_tree_input {
  width: 90%;
  margin: 0px auto;
  margin-top: 15px;
  display: flex;
}

.Left-DialSwitch {
  width: 90%;
  margin: 6px auto;
  display: flex;
  align-items: center;
  height: 42px;
}

.Left-DialSwitch > div {
  cursor: pointer;
  padding: 0px 10px;
  width: 100%;
  height: 70%;
  border-radius: 3px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e4e7ed;
  color: #909399;
}

.custom-tree-node {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.custom-tree-node i {
  padding: 5px 10px;
}

.content_left_tree {
  width: 90%;
  flex-grow: 1;
  margin: 0px auto;
  margin-bottom: 15px;
  overflow: auto;
}

.left_tree {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 15px;
}

.left_tree_icon {
  padding: 0px 5px;
}

/* 右侧 */
.content_right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content_right2 {
  flex-grow: 1;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  color: #909399;
}

.content_right2 > div {
  text-align: center;
}

/* 右侧-头部 */
.content_right_top {
  width: 100%;
  height: 70px;
  display: flex;
  margin-bottom: 20px;
}

.right_top_card1 {
  flex-grow: 1;
  height: 100%;
  background-color: #ffffff;
}

.DialSwitch-Title {
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  color: #909399;
  margin: 0px 20px;
}

.DialSwitch {
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.DialSwitch > div {
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
}
.DialSwitch > div > div {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DialSwitch > div > i {
  font-size: 29px;
  color: #a4acb4;
}
.DialSwitch > div > div > i {
  font-size: 20px;
  color: #ffffff;
}

.right_top_card2 {
  margin: 0px 20px;
  width: 400px;
  height: 100%;
  background-color: #ffffff;
}

.right_top_card3 {
  width: 270px;
  height: 100%;
  background-color: #ffffff;
}

/* 右侧-底部 */
.content_right_bottom {
  flex-grow: 1;
  height: 0px;
  width: 100%;
  background-color: #ffffff;
}

.content_right_bottom > div {
  display: flex;
  flex-direction: column;
  width: 96%;
  height: 95%;
}

.content_right_bottom_title {
  font-weight: 600;
  color: #6f6f6f;
  font-size: 14.5px;
  margin-bottom: 10px;
}

.content_right_bottom_page {
  margin-top: 12px;
}

.Table-column {
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 拨码开关 */
.DialSwitchList_DialSwitch {
  width: 300px;
  display: flex;
}
.DialSwitchList_DialSwitch > div {
  margin: 0px 4px;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DialSwitchList_DialSwitch > div > i {
  font-size: 24px;
}
.DialSwitchList_DialSwitch > div > div {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DialSwitchList_DialSwitch > div > div > i {
  color: #ffffff;
}

.border-DialSwitch-On {
  border: 1px solid #67c23a;
}
.DialSwitch-On {
  background-color: #67c23a;
}
.border-DialSwitch-Off {
  border: 1px solid #409eff;
}
.DialSwitch-Off {
  background-color: #409eff;
}

/* 配置码 */
.ConfigQrForme {
  display: flex;
  align-items: center;
}
.QRConfig {
  height: 250px;
}
.QRRadio {
  display: flex;
}
.QRRadio > div {
  margin: 0px 10px;
}
.QRDescribe {
  margin: 10px;
  margin-top: 15px;
}
.QrDescribeValue {
  height: 30px;
  display: flex;
  align-items: center;
}
.QRRadioDescribe > div {
  margin: 10px 0px;
}
.NotFloor {
  height: 100%;
}
.NotFloor > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #909399;
}
</style>
