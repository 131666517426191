<template>
  <div class="home">
    <!-- 头部数据 -->
    <div class="home_top">
      <div class="home_top_value boxshadow">
        <div>
          <div class="top_value">
            <div>
              <div class="top_value_title">{{ $t("Home.TopTitle1") }}</div>
              <div class="top_value_data color1">
                {{ this.employeesCountStr }}
              </div>
            </div>
            <i
              class="el-icon-user top_value_icon flex-center background_color1"
            ></i>
          </div>
          <div class="top_value_describe flex-items-center">
            <div class="top_value_card flex-center background_color1">
              <i class="el-icon-user"></i>
            </div>
            {{ $t("Home.TopDescribe1") }}
          </div>
        </div>
      </div>
      <div class="home_top_value boxshadow">
        <div>
          <div class="top_value">
            <div>
              <div class="top_value_title">{{ $t("Home.TopTitle2") }}</div>
              <div class="top_value_data color2">
                {{ this.verifyEmployeesCountStr }}
              </div>
            </div>
            <i
              class="
                el-icon-bank-card
                top_value_icon
                flex-center
                background_color2
              "
            ></i>
          </div>
          <div class="top_value_describe flex-items-center">
            <div class="top_value_card flex-center background_color2">
              <i class="el-icon-bank-card"></i>
            </div>
            {{ $t("Home.TopDescribe2") }}
          </div>
        </div>
      </div>
      <div class="home_top_value boxshadow">
        <div>
          <div class="top_value">
            <div>
              <div class="top_value_title">{{ $t("Home.TopTitle3") }}</div>
              <div class="top_value_data color3">
                {{ this.visitorsCountStr }}
              </div>
            </div>
            <i
              class="
                el-icon-coordinate
                top_value_icon
                flex-center
                background_color3
              "
            ></i>
          </div>
          <div class="top_value_describe flex-items-center">
            <div class="top_value_card flex-center background_color3">
              <i class="el-icon-coordinate"></i>
            </div>
            {{ $t("Home.TopDescribe3") }}
          </div>
        </div>
      </div>
      <div class="home_top_value boxshadow">
        <div>
          <div class="top_value">
            <div>
              <div class="top_value_title">{{ $t("Home.TopTitle4") }}</div>
              <div class="top_value_data color4">
                {{ this.onlineControllersCountStr }}
              </div>
            </div>
            <i
              class="el-icon-mobile-phone top_value_icon flex-center background_color4"
            ></i>
          </div>
          <div class="top_value_describe flex-items-center">
            <div class="top_value_card flex-center background_color4">
              <i class="el-icon-mobile-phone"></i>
            </div>
            {{ $t("Home.TopDescribe4") }}
          </div>
        </div>
      </div>
    </div>

    <!-- 中部数据 -->
    <div class="Statistics">
      <div class="Statistics-Left boxshadow">
        <div class="Statistics-Title">
          <div>{{ $t("Home.MiddleTitle1") }}</div>
          <div class="Card-hint">
            <div>
              <i class="el-icon-remove" style="color: #23ccb3"></i>&nbsp;{{
                $t("Home.Card1")
              }}
            </div>
            <div>
              <i class="el-icon-remove" style="color: #409eff"></i>&nbsp;{{
                $t("Home.Card2")
              }}
            </div>
            <div>
              <i class="el-icon-remove" style="color: #8354c8"></i>&nbsp;{{
                $t("Home.Card3")
              }}
            </div>
            <div>
              <i class="el-icon-remove" style="color: #e94b73"></i>&nbsp;{{
                $t("Home.Card4")
              }}
            </div>
          </div>
        </div>
        <div id="homeCardRecord" class="homeCardRecord"></div>
      </div>
      <div class="Statistics-Right boxshadow">
        <div id="homecontrollerType" class="homecontrollerType"></div>
      </div>
    </div>

    <!-- 底部数据 -->
    <div class="home_bottom">
      <div class="home_bottom_left boxshadow">
        <div id="Equipment"></div>
      </div>
      <div class="home_bottom_right flex-center boxshadow">
        <div>
          <div class="Apply-Title flex-items-center">
            {{ $t("Home.ControllerStatus") }}
            <div>{{ tableData.length }}</div>
          </div>
          <el-table :data="tableData" style="width: 100%" height="318px">
            <el-table-column align="center" width="50">
              <template slot="header">
                <i class="el-icon-news Apply-Table-Icon"></i>
              </template>
              <template>
                <i class="el-icon-news Apply-Table-Icon"></i>
              </template>
            </el-table-column>
            <el-table-column
              prop="controllersId"
              label="ID"
              width="80"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="controllerName"
              :label="$t('Home.ControllerName')"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="location"
              :label="$t('Home.OnLineStatus')"
              width="110"
              align="center"
            >
              <template slot-scope="scope">
                <i
                  v-if="scope.row.online == 1"
                  style="font-size: 25px; color: #409eff"
                  class="el-icon-monitor"
                ></i>
                <i
                  v-else
                  style="font-size: 25px; color: #f56c6c"
                  class="el-icon-warning"
                ></i>
              </template>
            </el-table-column>
            <el-table-column
              prop="controllersId"
              :label="$t('Home.LongRangeOpen')"
              width="170"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag effect="dark" size="small" v-if="scope.row.open == 0">{{
                  $t("Home.ToPerform")
                }}</el-tag>
                <el-tag effect="dark" size="small" v-else type="info">{{
                  $t("Home.NoCommand")
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="controllersId"
              :label="$t('Home.SyncInfor')"
              width="145"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag
                  effect="dark"
                  size="small"
                  v-if="
                    scope.row.controllerType == 1 && scope.row.information == 0
                  "
                  >{{ $t("Home.ToPerform") }}</el-tag
                >
                <el-tag effect="dark" size="small" v-else type="info">{{
                  $t("Home.NoCommand")
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="controllersId"
              :label="$t('Home.SyncDelete')"
              width="110"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag
                  effect="dark"
                  size="small"
                  v-if="
                    scope.row.controllerType == 1 && scope.row.syncDelete == 0
                  "
                  >{{ $t("Home.ToPerform") }}</el-tag
                >
                <el-tag effect="dark" size="small" v-else type="info">{{
                  $t("Home.NoCommand")
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="controllersId"
              :label="$t('Home.SyncEmployee')"
              width="145"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag
                  effect="dark"
                  size="small"
                  v-if="scope.row.addUpdDel == 0"
                  >{{ $t("Home.ToPerform") }}</el-tag
                >
                <el-tag effect="dark" size="small" v-else type="info">{{
                  $t("Home.NoCommand")
                }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      //定时器
      timer: "",
      //设备状态数据
      tableData: [],
      //人员数
      employeesCount: 0,
      //人员数
      employeesCountStr: "0",
      //到场人数
      verifyEmployeesCount: 0,
      //到场人数
      verifyEmployeesCountStr: "0",
      //访客数
      visitorsCount: 0,
      //访客数
      visitorsCountStr: "0",
      //在线设备数
      onlineControllersCount: 0,
      //在线设备数
      onlineControllersCountStr: "0",
      //设备数
      controllersCount: 0,
      //今日验证
      option1: {},
      //验证类型
      option2: {},
      //设备占比
      option3: {},
    };
  },
  //创建实例时执行
  created() {},
  //监听数据变化
  watch: {
    //语言切换时触发
    "$i18n.locale"(newValue) {
      //重新渲染今日验证
      this.myCard1(this.option1);
      //重新渲染验证类型统计
      this.myCard2(this.option2);
      //重新渲染设备占比统计
      this.myCard3(this.option3);
    },
  },
  //页面加载后执行
  mounted: function () {
    this.language1 = 1;
    //头部数据统计
    this.HomeCount();
    //今天验证统计
    this.drawLine();
    //验证统计
    this.HomeCardTypeCount();
    //设备占比统计
    this.Equipment();
    //设备状态信息
    this.HomeControllersStatus();
    //获取当前this
    var dataThis = this;
    //创建定时器
    this.timer = setInterval(function () {
      //获取top数量统计
      dataThis.HomeCount();
      //今日验证统计
      dataThis.drawLine();
      //按验证类型统计
      dataThis.HomeCardTypeCount();
      //设备待处理
      dataThis.HomeControllersStatus();
    }, 1000 * 10);
  },
  //离开界面事件
  destroyed() {
    //清除定时器
    clearTimeout(this.timer);
  },
  methods: {
    //头部数据统计
    async HomeCount() {
      try {
        var { data: res } = await this.$http.post("HomeCount");
        if (res.resultCode == 1) {
          //成功
          var body = JSON.parse(res.body);
          //人员数
          this.employeesCountStr = this.Transform(
            body.employeesCount.toString()
          );
          //到场人数
          this.verifyEmployeesCountStr = this.Transform(
            body.verifyEmployeesCount.toString()
          );
          //访客数
          this.visitorsCountStr = this.Transform(body.visitorsCount.toString());
          //在线设备数
          this.onlineControllersCountStr = this.Transform(
            body.onlineControllersCount.toString()
          );
          //设备数
          this.controllersCount = body.controllersCount;
        }
      } catch (err) {
        console.log(err);
      }
    },

    //数字转字符串逗号隔开
    Transform(value) {
      var arr = "";
      //循环截取的次数,向上取整
      var counts = Math.ceil(value.length / 3);
      for (var i = 0; i < counts; i++) {
        var part = value.substring(value.length - 3);
        value = value.substring(0, value.length - 3);
        arr = "," + part + arr;
      }
      return arr.substring(1);
    },

    //今日验证统计
    async drawLine() {
      try {
        var { data: res } = await this.$http.post("HomeVerifyRecord");
        if (res.resultCode == 1) {
          //成功
          var Record = JSON.parse(res.body);
          this.option1 = Record;
          //今日验证统计--渲染
          this.myCard1(Record);
        }
      } catch (err) {
        console.log(err);
      }
    },

    //今日验证统计--渲染
    myCard1(Record) {
      let myCard = this.$echarts.init(
        document.getElementById("homeCardRecord"),
        "light"
      );
      var option = {
        color: ["#23CCB3", "#409EFF", "#8354C8", "#E94B73"],
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "3%",
          top: "5%",
          right: "3%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.TimeQuantum(),
        },
        yAxis: {
          type: "value",
          splitNumber: 3,
          minInterval: 1,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            name: this.$t("Home.Card1"),
            smooth: true,
            data: this.CardCount(Record, 1),
            type: "line",
            areaStyle: {},
            symbolSize: 10,
            symbol: "circle",
            lineStyle: {
              color: "#23CCB3",
              width: 3,
            },
            areaStyle: {
              color: "rgba(246,248,253, 0.5)",
            },
          },
          {
            name: this.$t("Home.Card2"),
            smooth: true,
            data: this.CardCount(Record, 2),
            type: "line",
            areaStyle: {},
            symbolSize: 10,
            symbol: "circle",
            lineStyle: {
              color: "#409EFF",
              width: 3,
            },
            areaStyle: {
              color: "rgba(246,248,253, 0.5)",
            },
          },
          {
            name: this.$t("Home.Card3"),
            smooth: true,
            data: this.CardCount(Record,3),
            type: "line",
            areaStyle: {},
            symbolSize: 10,
            symbol: "circle",
            lineStyle: {
              color: "#8354C8",
              width: 3,
            },
            areaStyle: {
              color: "rgba(246,248,253, 0.5)",
            },
          },
          {
            name: this.$t("Home.Card4"),
            smooth: true,
            data: this.CardCount(Record,4),
            type: "line",
            areaStyle: {},
            symbolSize: 10,
            symbol: "circle",
            lineStyle: {
              color: "#E94B73",
              width: 3,
            },
            areaStyle: {
              color: "rgba(246,248,253, 0.5)",
            },
          },
        ],
      };
      myCard.setOption(option);
      //设置自动适应页面大小
      window.addEventListener("resize", () => {
        myCard.resize();
      });
    },

    //时间段
    TimeQuantum() {
      var Time = [];
      for (var i = 0; i < 24; i++) {
        var StartHH = i < 10 ? "0" + i + ":00" : i.toString() + ":00";
        var endHH = "";
        if (i == 23) {
          endHH = "23:00";
        } else {
          endHH =
            i + 1 < 10 ? "0" + (i + 1) + ":00" : (i + 1).toString() + ":00";
        }
        Time[i] = StartHH + " - " + endHH;
      }
      return Time;
    },

    //刷卡次数
    CardCount(Record, Type) {
      var Data = {};
      for (var i = 0; i < Record.length; i++) {
        if (Record[i].cardType == Type) {
          Data = Record[i];
          break;
        }
      }

      var ReturnData = [];
      var HH = new Date().getHours()+1;
      for (var i = 0; i < 24; i++) {
        if (i <= HH) {
          if (Data["time" + i] != undefined) {
            ReturnData[ReturnData.length] = Data["time" + i];
          } else {
            ReturnData[ReturnData.length] = 0;
          }
        }
      }
      return ReturnData;
    },

    //按验证类型统计
    async HomeCardTypeCount() {
      try {
        var { data: res } = await this.$http.post("HomeCardTypeCount");
        if (res.resultCode == 1) {
          //成功
          var body = JSON.parse(res.body);
          this.option2 = body;
          //按验证类型统计--渲染
          this.myCard2(body);
        }
      } catch (err) {
        console.log(err);
      }
    },

    //按验证类型统计--渲染
    myCard2(body) {
      let myCard = this.$echarts.init(
        document.getElementById("homecontrollerType"),
        "light"
      );
      var option = {
        color: ["#23CCB3", "#409EFF", "#8354C8", "#E94B73"],
        title: {
          text: this.$t("Home.MiddleTitle2"),
          subtext: this.$t("Home.MiddleDescribe2"),
          left: "5%",
          top: "4%",
          textStyle: {
            fontSize: 15,
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "85%",
          left: "center",
        },
        series: [
          {
            name: this.$t("Home.MiddleTitle2"),
            type: "pie",
            radius: ["45%", "65%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: this.ControllerCount(body),
          },
        ],
      };
      myCard.setOption(option);
      //设置自动适应页面大小
      window.addEventListener("resize", () => {
        myCard.resize();
      });
    },

    //数量
    ControllerCount(body) {
      var Data = [];
      for (var i = 0; i < body.length; i++) {
        if (body[i].cardType == 1) {
          Data[i] = { name: this.$t("Home.Card1"), value: body[i].size };
        } else if (body[i].cardType == 2) {
          Data[i] = { name: this.$t("Home.Card2"), value: body[i].size };
        } else if (body[i].cardType == 3) {
          Data[i] = { name: this.$t("Home.Card3"), value: body[i].size };
        } else if (body[i].cardType == 4) {
          Data[i] = { name: this.$t("Home.Card4"), value: body[i].size };
        }
      }
      return Data;
    },

    //设备占比统计
    async Equipment() {
      try {
        var { data: res } = await this.$http.post("HomeControllersRatio");
        if (res.resultCode == 1) {
          //成功
          var body = JSON.parse(res.body);
          this.option3 = body;
          //设备占比统计--渲染
          this.myCard3(body);
        }
      } catch (err) {
        console.log(err);
      }
    },

    //设备占比统计--渲染
    myCard3(body) {
      let myCard = this.$echarts.init(
        document.getElementById("Equipment"),
        "light"
      );
      var NameData = ["CR02E","CR02EU","CR02EN","RD02EU","CR03E","CR05EU","CR07EU","CR08EU","F5","F6","F8","F108"];
      var Controllers = body.homeControllers;
      var value = [0, 0, 0, 0, 0];
      for (var j = 0; j < NameData.length; j++) {
        for (var i = 0; i < Controllers.length; i++) {
          if (NameData[j] == Controllers[i].controllerSubType) {
            NameData[j] = Controllers[i].controllerSubType;
            value[j] = Controllers[i].size;
            break;
          }
        }
      }

      var option = {
        title: {
          text: this.$t("Home.ControllerType"),
          left: "3%",
          top: "5%",
          textStyle: {
            fontSize: 15,
          },
        },
        grid: {
          left: "7%",
          top: "18%",
          right: "7%",
          bottom: "7%",
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "85%",
          left: "center",
        },
        xAxis: {
          type: "category",
          data: NameData,
        },
        yAxis: {
          type: "value",
          minInterval: 1,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: value,
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: "#409EFF",
            },
          },
        ],
      };
      myCard.setOption(option);
      //设置自动适应页面大小
      window.addEventListener("resize", () => {
        myCard.resize();
      });
    },

    //设备状态信息
    async HomeControllersStatus() {
      try {
        var { data: res } = await this.$http.post("HomeControllersStatus");
        if (res.resultCode == 1) {
          //成功
          this.tableData = JSON.parse(res.body);
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.top_breadcrumb_title {
  color: #5b626b;
  font-weight: 600;
  margin-bottom: 12px;
}

/* 头部数据 */
.home_top {
  display: flex;
  justify-content: space-between;
}

.home_top_value {
  width: 23.9%;
  color: #333;
  height: 150px;
  background-color: #fff;
  /* background-image: url("../assets/images/home_top.png"); */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_top_value > div {
  width: 88%;
  height: 70%;
}

.top_value {
  display: flex;
  justify-content: space-between;
}

.top_value_title {
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 600;
}

.top_value_data {
  margin-bottom: 19px;
  font-size: 28px;
  font-weight: 600;
}

.top_value_icon {
  width: 65px;
  height: 65px;
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  border-radius: 50%;
}

.top_value_describe {
  font-size: 12px;
}

.top_value_card {
  width: 30px;
  height: 15px;
  margin-right: 5px;
  border-radius: 3px;
  color: #fff;
}

.color1 {
  color: #23ccb3;
}

.color2 {
  color: #409eff;
}

.color3 {
  color: #8354c8;
}

.color4 {
  color: #e94b73;
}

.background_color1 {
  background-color: #23ccb3;
}

.background_color2 {
  background-color: #409eff;
}

.background_color3 {
  background-color: #8354c8;
}

.background_color4 {
  background-color: #e94b73;
}

/* 中部数据 */
.Statistics {
  margin-top: 20px;
  height: 420px;
  display: flex;
  justify-content: space-between;
}

.Statistics-Left {
  width: 74.6%;
  height: 100%;
  background-color: #ffffff;
}
.Statistics-Right {
  width: 23.9%;
  height: 100%;
  background-color: #ffffff;
}

.Statistics-Title {
  height: 14%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 25px;
  font-size: 15px;
  color: #666666;
  font-weight: 700;
  border-radius: 10px 10px 0px 0px;
}

.Card-hint {
  display: flex;
  align-items: center;
}

.Card-hint > div {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0px 10px;
  color: #909399;
  font-weight: 500;
}

.Card-hint i {
  font-size: 20px;
  margin-top: 1px;
}

.homeCardRecord {
  width: 100%;
  height: 86%;
}

.homecontrollerType {
  width: 100%;
  height: 100%;
}

/* 底部数据 */
.home_bottom {
  height: 400px;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
}

.home_bottom_left {
  height: 100%;
  width: 37%;
  background-color: #ffffff;
}

.home_bottom_left > div {
  width: 100%;
  height: 100%;
}

.home_bottom_right {
  height: 100%;
  width: 61.5%;
  background-color: #ffffff;
}

.home_bottom_right > div {
  width: 95%;
  height: 90%;
}

.Apply-Title {
  margin-bottom: 10px;
  font-size: 15px;
  color: #58666e;
  font-weight: 700;
}

.Apply-Title > div {
  padding: 1px 12px;
  margin-left: 10px;
  border-radius: 3px;
  font-size: 14px;
  color: #ffffff;
  background-color: #409eff;
}

.bottom_right_icon1 {
  font-size: 25px;
  color: #409eff;
}

.bottom_right_icon2 {
  font-size: 25px;
}
</style>