<template>
	<div class="frombut boxshadow">
		<el-card class="box-card" shadow="never">
			<p class="P">
				<span>{{$t('BulkImport.Step1')}}：</span>
				<el-button type="primary" size="small" @click="Download()">{{$t('BulkImport.Describe1')}}</el-button>
			</p>
			<p class="P">
				<span>{{$t('BulkImport.Step2')}}：</span>
				{{$t('BulkImport.Describe2')}}<span style="color: red;"> * </span>{{$t('BulkImport.Describe3')}}
				<el-table border :data="tableData" style="width: 100%;margin-top: 10px;">
					<el-table-column prop="DepartmentName" width="110" align="center">
						<template slot="header">
							{{$t('BulkImport.Department')}} <span style="color: red;">*</span>
						</template>
					</el-table-column>
					<el-table-column prop="Number" width="160" align="center">
						<template slot="header">
							{{$t('BulkImport.Number')}} <span style="color: red;">*</span>
						</template>
					</el-table-column>
					<el-table-column prop="Name" width="100" align="center">
						<template slot="header">
							{{$t('BulkImport.Name')}} <span style="color: red;">*</span>
						</template>
					</el-table-column>
					<el-table-column prop="Sex" width="175" align="center">
						<template slot="header">
							{{$t('BulkImport.Sex')}}{{$t('BulkImport.SexHint')}} <span style="color: red;">*</span>
						</template>
					</el-table-column>
					<el-table-column prop="Q" width="130" align="center">
						<template slot="header">
							{{$t('BulkImport.Card1')}} <span style="color: red;">*</span>
						</template>
					</el-table-column>
					<el-table-column prop="C" :label="$t('BulkImport.Card2')" width="130" align="center"></el-table-column>
					<el-table-column prop="K" :label="$t('BulkImport.Card3')" width="130" align="center"></el-table-column>
					<el-table-column prop="IdentityCard" :label="$t('BulkImport.IdentityCard')" width="170" align="center"></el-table-column>
					<el-table-column prop="Address" :label="$t('BulkImport.Location')" align="center"></el-table-column>
					<el-table-column prop="StartTime" :label="$t('BulkImport.StartDate')" align="center"></el-table-column>
					<el-table-column prop="EndTime" :label="$t('BulkImport.EndDate')" align="center"></el-table-column>
				</el-table>
			</p>
			<p class="P" style="display: flex;align-items: center;">
				<span>{{$t('BulkImport.Step3')}}：</span>
				{{$t('BulkImport.Describe4')}} <span style="font-size: 13px;margin-left: 10px;color: red;">{{$t('BulkImport.Describe5')}}</span>
			</p>
			<div>
				<el-upload :disabled="!userGroupDetails.RoleImportData"
					ref="upload"
					class="upload-demo"
					:action="ServerUrl()"
					:with-credentials='true'
					multiple
					:limit="99999"
					:on-success="handleAvatarSuccess"
					:before-upload="beforeAvatarUpload"
					:file-list="fileList">
					<el-button slot="trigger" v-if="ToLeadState==0" :disabled="!userGroupDetails.RoleImportData" size="small" type="primary" icon="el-icon-circle-plus-outline">{{$t('BulkImport.button1')}}</el-button>
					<el-button slot="trigger" v-if="ToLeadState==1" disabled size="small" type="primary" icon="el-icon-loading">{{$t('BulkImport.button2')}}</el-button>
					<el-button slot="trigger" v-if="ToLeadState==2" size="small" type="success" icon="el-icon-circle-check">{{$t('BulkImport.button3')}}</el-button>
					<el-button style="margin-left: 10px;" :disabled="ToLeadState==1" size="small" @click="Qin()" icon="el-icon-s-open">{{$t('BulkImport.button4')}}</el-button>
				</el-upload>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default {
			data() {
				return {
					//权限组
     				userGroupDetails: { RoleImportData: false},
					tableData:[{
						"DepartmentName":this.$t('BulkImport.Value1'),
						"Number":"2020813104358362",
						"Name":this.$t('BulkImport.Value2'),
						"Sex":this.$t('BulkImport.Value3'),
						"Q":"15674145822",
						"C":"666666",
						"K":"123456",
						"IdentityCard":"430225200001112415",
						"Address":this.$t('BulkImport.Value4'),
						"StartTime":"2020-08-13 00:00:00",
						"EndTime":"2020-08-14 00:00:00"
					}],
					ToLeadState:0,//操作状态提示
					fileList: [],//选择的文件
					Listsize:0,
					fileListsize:0,//上传完成的文件
					fileName:"",//徐导入的文件
				}
			},
			mounted() {
				//获取操作权
				this.Role();
			},
			methods: {
				//获取操作权
				Role() {
					//获操作取权限组
					var userGroupDetails = JSON.parse(
						window.sessionStorage.getItem("userGroupDetails")
					);
					for (var i = 0; i < userGroupDetails.length; i++) {
						if (userGroupDetails[i].roleName == "ImportData") {
							//操作权
							this.userGroupDetails.RoleImportData = userGroupDetails[i].roleOperateValue == "1" ? true : false;
						}
					}
				},
				//获取服务器地址
				ServerUrl(){
					return this.$ServerUrl+"/uploadExecl";
				},
				
				//下载导入模板
				Download(){
					window.location.href = this.$image +'/File/Execl/'+this.$t('BulkImport.Xlsx')+'.xlsx';
				},
				
				//上传成功
				async handleAvatarSuccess(Data,fileList,list){
					this.fileName = "";
					var value = JSON.parse(Data.body)
					if(value.Type==1)
					{
						this.fileName = value.fileName;
					}
					if (this.FilteringOperation(Data)) { //请求结果过滤
						if (Data.resultCode == 1 && this.fileName!="") //上传成功
						{
							this.ToLeadState = 1;
							var jsonData = {
								"body":this.fileName,
								"header1":this.$t('BulkImport.Department'),
								"header2":this.$t('BulkImport.Number'),
								"header3":this.$t('BulkImport.Name'),
								"header4":this.$t('BulkImport.Sex'),
								"header5":this.$t('BulkImport.Card1'),
								"header6":this.$t('BulkImport.Card2'),
								"header7":this.$t('BulkImport.Card3'),
								"header8":this.$t('BulkImport.IdentityCard'),
								"header9":this.$t('BulkImport.Location'),
								"header10":this.$t('BulkImport.StartDate'),
								"header11":this.$t('BulkImport.EndDate'),
								"header12":this.$t('BulkImport.ReasonFailure'),
								"table":this.$t('BulkImport.Error'),
								"error1":this.$t('BulkImport.DepartmentNotExist'),
								"error2":this.$t('BulkImport.QrCodeExist'),
								"error3":this.$t('BulkImport.ICCardExist'),
								"error4":this.$t('BulkImport.PasswordExist'),
								"error5":this.$t('BulkImport.NumberExist'),
								"error6":this.$t('BulkImport.ImportFailure'),
								"error7":this.$t('BulkImport.DataParsingError'),
								"error8":this.$t('BulkImport.dataFormatIncorrect'),
								"error9":this.$t('BulkImport.MaxError'),
								// 达到人员限制数错误提示
								"error500":this.$t('BulkImport.PersonNumError'),
							}
							var {data: res} = await this.$http.post('ReadExecl', jsonData ,{ responseType: 'blob' });
							this.ToLeadState = 2;
							console.log("长度：" + res.size)
							if(res.size>4096)
							{
								const blob = new Blob([res]);
								const fileName = this.$t('BulkImport.Error')+'.xls';
								const elink = document.createElement('a');
								elink.download = fileName;
								elink.style.display = 'none';
								elink.href = URL.createObjectURL(blob);
								document.body.appendChild(elink);
								elink.click();
								URL.revokeObjectURL(elink.href);
								document.body.removeChild(elink);
							}
						} 
						else if (Data.resultCode == 2) 
						{
							this.$message({type: 'error',message: this.$t('BulkImport.Status1'),offset:10});
						}
					}
					else
					{
						this.ToLeadState = 0;
					}
				},
				
				//上传文件之前的钩子
				beforeAvatarUpload(file) {
				   this.fileName = "";//清空原导入的文件名称
				   const isJPG = file.type === 'image/jpeg';
				   const isxlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
				   const isxls = file.type === 'xls';
				   const isLt2M = file.size / 1024 / 1024 < 5;
				   
				   if (!isJPG && !isxlsx && !isxls)
				   {
					   this.$message({type: 'error',message: this.$t('BulkImport.restrict1'),offset:10});
				   }
				   if(isJPG && !isLt2M)
				   {
					   this.$message({type: 'error',message:this.$t('Employee.PhotoRequire2'),offset:10});
				   }
				   return isxlsx || isxls || (isJPG && isLt2M);
				},
				
				//清空文件
				Qin()
				{
					this.$refs.upload.clearFiles()
				},
				//操作过滤
				FilteringOperation(res){
					var State = true;
					if (res.stateCode == 0) {
						State = false;
						this.$message({message: this.$t('BulkImport.Session'),type: 'warning',offset:10});
					} else if (res.stateCode == 2) {
						State = false;
						this.$message({message: this.$t('BulkImport.Failure'),type: 'error',offset:10});
					} else if (res.stateCode == 3) {
						State = false;
						this.$message({message: this.$t('BulkImport.AccessDenied'),type: 'error',offset:10});
					}
					return State;
				}
			}
		}
</script>

<style scoped>
	
	.P{
		font-size: 14px;
		margin-bottom: 30px;
		color: #606266;
	}
	
	.P>span{
		font-weight: 900;
	}
</style>
