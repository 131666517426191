<template>
  <div id="app">
    <!-- 路由占位符 -->
   <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted: function(){
    var load = document.getElementById("Loading");
    load.classList.add("load2");
  },
}
</script>

<style>
</style>
