<template>
	<div class="ControllerDate">
		<div class="content">
			<!-- 左侧 -->
			<div class="content_left boxshadow" v-loading="PageLoad.Leftload">
				<div class="left_tree">
					<!-- 功能栏 -->
					<div class="left_tree_input">
						<el-input maxlength="32" :placeholder="$t('ControllerDate.SearchTemplate')" size="medium" v-model="filterText" style="width:66%;"></el-input>
						<el-button size="medium" type="primary" icon="el-icon-circle-plus-outline" style="margin-left:15px" @click="addtemplateStaet"  :disabled="!userGroupDetails.RoleVacationTemplates">{{$t('ControllerDate.Add')}}</el-button>
					</div>

					<!-- 树形菜单 -->
					<el-tree class="content_left_tree" :highlight-current="true" :data="HolidayTemplate" :props="defaultProps" default-expand-all :filter-node-method="filterNode" :expand-on-click-node="false" icon-class="el-icon-s-promotion" node-key="templatesId" ref="tree">
						<div class="custom-tree-node" slot-scope="{ data }" style="height: 100%;" @click="optional(data)">
							<div style="width:100%;font-size:15px">
								<el-popover v-if="data.code!='' && data.code!=undefined" placement="left" :title="$t('ControllerDate.Remark')" width="200" trigger="hover" :content="data.code">
									<div slot="reference">
										{{data.templateName}}
									</div>
								</el-popover>
								<div v-else slot="reference" style="width: 100%;">
									{{data.templateName}}
								</div>
							</div>
							<span v-if="userGroupDetails.RoleVacationTemplates && data.companyId!=0">
								<el-popover placement="right" trigger="hover" :open-delay="300">
								<el-button type="info" size="mini" @click="updtemplateStaet(data)">
									{{$t('ControllerDate.Update')}}
								</el-button>
								<el-button type="danger" size="mini" @click="DeleteTemolates(data)">
									{{$t('ControllerDate.Delete')}}
								</el-button>
								<i class="el-icon-more left_tree_icon" slot="reference"></i>
							</el-popover>
							</span>
						</div>
					</el-tree>
				</div>
			</div>

			<!-- 右侧 -->
			<div class="content_right" v-if="TemplatesId!=0" v-loading="PageLoad.Rightload">
				<!-- 头部 -->
				<div class="content_right_top flex-items-center">
					<!-- 搜索框 -->
					<div class="right_top_card1 flex-items-center boxshadow">
						<div>
							<div class="right_top_search flex-items-center">
								<i class="el-icon-search"></i>
								<el-input maxlength="32" size="medium" v-model="SeekName" :placeholder="$t('ControllerDate.InputHoliday')"></el-input>
							</div>
							<div class="right_top_search_type flex-items-center">
								<el-button size="mini" slot="append" icon="el-icon-search" @click="seek"></el-button>
							</div>
							<el-button type="primary" style="margin-left: 20px;" :disabled="!userGroupDetails.RoleTemplateHoliday || TemplatesId==0"
							icon="el-icon-circle-plus-outline" size="medium" @click="addholidayStaet">{{$t('ControllerDate.AddHoliday')}}</el-button>
							<el-button type="danger" style="margin-left: 20px;" size="medium" :disabled="!userGroupDetails.RoleTemplateHoliday || TemplatesId==0"
								slot="reference" icon="el-icon-remove-outline" @click="Delholidays">{{$t('ControllerDate.BatchesDelete')}}</el-button>
						</div>
					</div>
				</div>
				<!-- 底部 -->
				<div class="content_right_bottom flex-center boxshadow">
					<div>
						<div class="content_right_bottom_title">{{$t('ControllerDate.Holiday')}}</div>
						<el-table :data="HolidayTemplateList" height="100%" @selection-change="handleSelectionChange">
						<el-table-column type="selection" align="center"></el-table-column>
						<el-table-column :label="$t('ControllerDate.HolidayName')" align="center" prop="holidayName"></el-table-column>
						<el-table-column :label="$t('ControllerDate.CorrespondingDate')" align="center" prop="holidayDate"></el-table-column>
						<el-table-column :label="$t('ControllerDate.Operation')" fixed="right" width="120" align="center">
							<template slot-scope="scope">
								<el-tooltip class="item" effect="dark" :content="$t('ControllerDate.Update')" placement="top" :enterable="false">
									<el-button type="primary" @click="UpdholidayStaet(scope.row)" :disabled="!userGroupDetails.RoleTemplateHoliday || TemplatesId==0"
									 size="mini" icon="el-icon-edit"></el-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" :content="$t('ControllerDate.Delete')" placement="top" :enterable="false">
									<el-button type="danger" @click="Delholiday(scope.row)" :disabled="!userGroupDetails.RoleTemplateHoliday || TemplatesId==0"
									 size="mini" icon="el-icon-delete"></el-button>
								</el-tooltip>
							</template>
						</el-table-column>
					</el-table>
					</div>
				</div>
			</div>
			<div v-else class="content_right2 flex-center boxshadow">
				<div>
					<div style="font-size: 100px;" class="el-icon-date"></div>
					<div style="margin-top: 20px;">{{$t('ControllerDate.HolidayTable')}}</div>
				</div>
			</div>
		</div>

		<!-- 模板弹框 -->
		<el-dialog :title="templateTitle" :visible.sync="templateVisible" width="30%" :close-on-click-modal="false">
			<el-form ref="templateformRef" label-width="100px" class="demo-ruleForm" :model="templateForm" :rules="templateFormRules"
			 label-position="left">
				<el-form-item :label="$t('ControllerDate.TemplateName')" prop="TemplateName">
					<el-input maxlength="32" v-model="templateForm.TemplateName" :placeholder="$t('ControllerDate.InputTemplateName')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('ControllerDate.Remark')">
					<el-input maxlength="32" v-model="templateForm.Code" type="textarea"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="templateVisible = false">{{$t('ControllerDate.Cancel')}}</el-button>
				<el-button type="primary" v-if="templateForm.TemplatesId==0" :loading="buttonState" @click="AddTemplates">{{ButtonsName}}</el-button>
				<el-button type="primary" v-if="templateForm.TemplatesId!=0" :loading="buttonState" @click="UpdTemplates">{{ButtonsName}}</el-button>
			</span>
		</el-dialog>

		<!-- 模板详情弹框 -->
		<el-dialog :title="HolidayTitle" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
			<el-form ref="HolidayformRef" label-width="100px" class="demo-ruleForm" :model="HolidayForm" :rules="HolidayFormRules"
			 label-position="left">
				<el-form-item :label="$t('ControllerDate.HolidayName')" prop="HolidayName">
					<el-input maxlength="32" v-model="HolidayForm.HolidayName" :placeholder="$t('ControllerDate.InputHoliday')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('ControllerDate.CorrespondingDate')" required>
					<div style="display: flex;">
						<el-form-item prop="Month">
							<el-input  maxlength="2" v-model="HolidayForm.Month" prefix-icon="el-icon-date" :placeholder="$t('ControllerDate.InputMoon')"></el-input>
						</el-form-item>
						<div style="width: 30px;text-align: center;"> - </div>
						<el-form-item prop="Date">
							<el-input maxlength="2" v-model="HolidayForm.Date" prefix-icon="el-icon-date" :placeholder="$t('ControllerDate.InputDate')"></el-input>
						</el-form-item>
					</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">{{$t('ControllerDate.Cancel')}}</el-button>
				<el-button type="primary" v-if="HolidayForm.RecordId==0" @click="Addholiday" :loading="buttonState">{{ButtonsName}}</el-button>
				<el-button type="primary" v-else @click="Upaholiday" :loading="buttonState">{{ButtonsName}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			//校验名称
			var DataName_verify = (rule, value, callback) => {
			if (!value)
				return callback(new Error(this.$t('ControllerDate.PleaseInput')));
			callback();
			};
			//校验月份
			var Month = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error(this.$t('ControllerDate.PleaseInput')));
				}
				var reg = /^[0-9]*$/;
				if (!reg.test(value))
				{
				     return callback(new Error(this.$t('ControllerDate.IncorrectFormat')));
				}
				if (value > 12 || value == 0) 
				{
					return callback(new Error(this.$t('ControllerDate.IncorrectFormat')));
				}
			};
			//校验日期
			var Date = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error(this.$t('ControllerDate.PleaseInput')));
				}
				var reg = /^[0-9]*$/;
				if (!reg.test(value))
				{
				     return callback(new Error(this.$t('ControllerDate.IncorrectFormat')));
				}
				if (value > 31 || value == 0) {
					return callback(new Error(this.$t('ControllerDate.IncorrectFormat')));
				}
			};
			return {
				//权限组
				userGroupDetails: {
					RoleVacationTemplates: false, //假期模板操作权
					RoleTemplateHoliday: false //假期模板详情操作权
				},

				// 页面加载
				PageLoad:{
   					Leftload: false, //左侧加载状态
					Rightload: false, //右侧加载状态
				},
				screenHeight: window.innerHeight, //高度
				buttonState: false, //按钮状态
				ButtonsName: '', //文本
				TemplatesId: 0,

				//假期模板
				filterText: '', //搜索过滤
				HolidayTemplate: [], //模板
				defaultProps: { //模板格式
					children: 'templatesId',
					label: 'templateName'
				},
				templateTitle: "", //弹框标题
				Leftloading: false, //是否显示加载状态
				templateVisible: false, // 新增修改假期模板弹框显示状态
				templateForm: { // 添加修改模板表单参数
					TemplateType:1,
					TemplatesId: 0,
					TemplateName: "",
					Code: ""
				},
				templateFormRules: { //添加修改模板表单验证
					TemplateName: [{
						required: true,
						validator: DataName_verify,
						trigger: "change",
					}]
				},

				//模板详情
				HolidayTitle: '', //弹框标题
				companyId: 0,
				tableTitle: '', //表格标题
				dialogVisible: false, //是否显示模板详情新增修改弹框
				HolidayTemplateList: [], //模板详情
				HolidayForm: { //添加修改详情表单参数
					RecordId: 0,
					TemplatesId: '',
					HolidayName: '', //假期名
					Month: '', //对应月份
					Date: '', //对应日期
				},
				HolidayFormRules: {
					HolidayName: [{
						required: true,
						validator: DataName_verify,
						trigger: "change",
					}],
					Month: [{
						required: true,
						validator: Month,
						trigger: 'change'
					}],
					Date: [{
						required: true,
						validator: Date,
						trigger: 'change'
					}]
				},
				IdData: [],
				SeekName: '',
				Seek: [],
				
				ControllersVisible:false,
				ControllersList: []
			};
		},
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val);
			}
		},
		mounted() {
			const that = this;
			window.onresize = () => {
				return (() => {
					that.screenHeight = window.innerHeight
				})()
			}
		},
		//加载事件
		created() {

			//获取操作权
			this.Role();

			//查询假期模板
			this.VacationTemplates();

		},
		methods: {
			//获取操作权
			Role() {
				//获取权限组
				var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
				for (var i = 0; i < userGroupDetails.length; i++) {
					if (userGroupDetails[i].roleName == "VacationTemplates") { //假期模板操作权
						this.userGroupDetails.RoleVacationTemplates = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
					if (userGroupDetails[i].roleName == "TemplateHoliday") { //假期模板详情操作权
						this.userGroupDetails.RoleTemplateHoliday = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
				}
			},

			//查询假期模板
			async VacationTemplates() {
				try {
					this.PageLoad.Leftload = true; //显示加载动画
					var {data : res} = await this.$http.post('VacationTemplates');
					if (res.resultCode == 1) 
					{
						this.HolidayTemplate = JSON.parse(res.body)
					}
					this.PageLoad.Leftload = false; //显示加载动画
				}
				catch (err) 
				{
					this.PageLoad.Leftload = false; //显示加载动画
					console.log(err)
				}
			},

			//模板查询过滤
			filterNode(value, data) {
				if (!value) return true;
				return data.templateName.indexOf(value) !== -1;
			},

			//显示新增模板弹框
			addtemplateStaet() {
				this.ButtonsName =  this.$t('ControllerDate.Confirm');
				this.templateTitle = this.$t('ControllerDate.AddTemplate');
				this.templateVisible = true;
				this.templateForm.TemplatesId = 0;
				this.templateForm.TemplateName = '';
				this.templateForm.Code = '';
			},

			//新增假期模板
			AddTemplates() {
				this.$refs.templateformRef.validate(async valid => { //验证
					if (!valid) return;
					//新增假期模板
					this.ButtonsName = this.$t('ControllerDate.InSubmit');
					this.buttonState = true;
					var {data:res} = await this.$http.post('AddTemplates', this.templateForm)
					this.ButtonsName =  this.$t('ControllerDate.Confirm');
					this.buttonState = false;
					if (this.FilteringOperation(res)) 
					{
						if (res.resultCode == 0 || res.resultCode == 2) //新增失败
						{ 
							this.$message({message: this.$t('ControllerDate.Confirm'),type: 'error',offset:10});
						} 
						else if (res.resultCode == 1) //新增成功
						{ 
							this.$message({message: this.$t('ControllerDate.AddSucceed'),type: 'success',offset:10});
							this.templateForm.TemplateName = '';
							this.VacationTemplates();
							this.templateVisible = false;
						}
						else if (res.resultCode == 3) //模板名称
						{ 
							this.$message({message:  this.$t('ControllerDate.TemplateExists'),type: 'warning',offset:10});
						}
					}
				})
			},

			//显示修改模板弹框
			updtemplateStaet(data) {
				this.ButtonsName = this.$t('ControllerDate.Confirm');
				this.templateTitle = this.$t('ControllerDate.UpdateTemplate');
				this.templateVisible = true;
				this.HolidayForm.RecordId = 0;
				this.templateForm.TemplateName = data.templateName;
				this.templateForm.Code = data.code;
				this.templateForm.TemplatesId = data.templatesId;
			},

			//修改假期模板
			UpdTemplates() {
				this.$refs.templateformRef.validate(async valid => { //验证
					if (!valid) return;
					this.ButtonsName = this.$t('ControllerDate.InSubmit');
					this.buttonState = true;
					var {data: res} = await this.$http.post('UpdTemplates', this.templateForm)
					this.ButtonsName =  this.$t('ControllerDate.Confirm');
					this.buttonState = false;
					if (this.FilteringOperation(res)) 
					{
						if (res.resultCode == 0 || res.resultCode == 2)//修改失败
						{
							this.$message({message: this.$t('ControllerDate.UpdateFailure'),type: 'error',offset:10});
						} 
						else if (res.resultCode == 1)//修改成功 
						{ 
							this.$message({message: this.$t('ControllerDate.UpdateSucceed'),type: 'success',offset:10});
							this.templateForm.TemplateName = '';
							this.VacationTemplates();
							this.templateVisible = false;
						}
						else if (res.resultCode == 3) //模板名称
						{ 
							this.$message({message:  this.$t('ControllerDate.TemplateExists'),type: 'warning',offset:10});
						}
					}
				})
			},

			//删除模板
			DeleteTemolates(data) {
				this.$confirm(this.$t('ControllerDate.WhetherDelete'),this.$t('ControllerDate.Hint'), {type: 'warning'}).then(async () => {
					//删除模板
					var {data: res} = await this.$http.post('DelTemplates', {"TemplatesId": data.templatesId,"TemplateType":1,"TemplateName":data.templateName});
					if (this.FilteringOperation(res)) 
					{
						if (res.resultCode == 0) //删除失败
						{ 
							this.$message({message: this.$t('ControllerDate.DeleteFailure'),type: 'error',offset:10});
						} 
						else if (res.resultCode == 1) //删除成功
						{ 
							this.$message({message: this.$t('ControllerDate.DeleteSucceed'),type: 'success',offset:10});
							if(data.templatesId = this.TemplatesId){
								this.TemplatesId = 0;
								this.HolidayTemplateList = [];
							}
							this.VacationTemplates();
						}
					}
				}).catch(() => {
					//取消
				});
			},

			//选择模板
			optional(data) {
				this.HolidayForm.TemplatesId = data.templatesId;
				this.tableTitle = data.templateName;
				this.Templateholiday(data.templatesId);
				this.TemplatesId = data.templatesId;
				this.companyId = data.companyId;
			},

			//查询假期模板详情
			async Templateholiday(Id) {
				try 
				{
					this.PageLoad.Rightload = true;
					var {data: res} = await this.$http.post('TemplateHoliday', {"TemplatesId": Id})
					this.PageLoad.Rightload = false;
					if (res.resultCode==1)
					{ 
						this.Seek = JSON.parse(res.body)
						this.HolidayTemplateList = JSON.parse(res.body)
					}
				}
				catch (err)
				{
					this.PageLoad.Rightload = false;
					console.log(err)
				}
			},

			//显示新增模板详情弹框
			addholidayStaet() {
				this.HolidayForm.RecordId = 0;
				this.HolidayForm.HolidayName = '';
				this.HolidayForm.Month = '';
				this.HolidayForm.Date = '';
				this.dialogVisible = true;
				this.HolidayTitle = this.$t('ControllerDate.AddHoliday');
				this.ButtonsName = this.$t('ControllerDate.Confirm');
			},

			//新增详情
			Addholiday() {
				this.$refs.HolidayformRef.validate(async valid => { //验证
					if (!valid) return;

					this.ButtonsName = this.$t('ControllerDate.InSubmit');
					this.buttonState = true;
					if (this.HolidayForm.Month.length == 1) {
						this.HolidayForm.Month = 0 + this.HolidayForm.Month;
					}
					if (this.HolidayForm.Date.length == 1) {
						this.HolidayForm.Date = 0 + this.HolidayForm.Date;
					}
					var {data: res} = await this.$http.post('AddTemplateHoliday', this.HolidayForm)
					this.ButtonsName = this.$t('ControllerDate.Confirm');
					this.buttonState = false;
					if (this.FilteringOperation(res))
					{
						if (res.resultCode == 0) //新增失败
						{ 
							this.$message({message: this.$t('ControllerDate.AddFailure'),type: 'error',offset:10});
						} 
						else if (res.resultCode == 1)  //新增成功
						{
							this.$message({message: this.$t('ControllerDate.AddSucceed'),type: 'success',offset:10});
							this.$refs.HolidayformRef.resetFields()
							this.Templateholiday(this.TemplatesId);
							this.dialogVisible = false;
						} 
						else if (res.resultCode == 2) //日期已存在
						{ 
							this.$message({message:this.$t('ControllerDate.AddSucceed'),offset:10});
						}
					}
				})
			},

			//显示修改模板详情弹框
			UpdholidayStaet(Data) {
				var MonthDate = Data.holidayDate.split('-');
				this.HolidayForm.TemplatesId = Data.templatesId;
				this.HolidayForm.RecordId = Data.recordId;
				this.HolidayForm.HolidayName = Data.holidayName;
				this.HolidayForm.Month = MonthDate[0];
				this.HolidayForm.Date = MonthDate[1];
				this.dialogVisible = true;
				this.HolidayTitle = this.$t('ControllerDate.UpdateHoliday');
				this.ButtonsName = this.$t('ControllerDate.Confirm');
			},

			//修改详情
			Upaholiday() {
				this.$refs.HolidayformRef.validate(async valid => { //验证
					if (!valid) return;
					this.ButtonsName =  this.$t('ControllerDate.InSubmit');
					this.buttonState = true;
					if (this.HolidayForm.Month.length == 1) {
						this.HolidayForm.Month = 0 + this.HolidayForm.Month;
					}
					if (this.HolidayForm.Date.length == 1) {
						this.HolidayForm.Date = 0 + this.HolidayForm.Date;
					}
					var {
						data: res
					} = await this.$http.post('UpdTemplateHoliday', this.HolidayForm)
					this.ButtonsName = this.$t('ControllerDate.Confirm');
					this.buttonState = false;
					if (this.FilteringOperation(res))
					{
						if (res.resultCode == 0) //修改失败
						{ 
							this.$message({message: this.$t('ControllerDate.UpdateFailure'),type: 'error',offset:10});
						} 
						else if (res.resultCode == 1) //修改成功
						{ 
							this.$message({message: this.$t('ControllerDate.UpdateSucceed'),type: 'success',offset:10});
							this.$refs.HolidayformRef.resetFields()
							this.Templateholiday(this.TemplatesId);
							this.dialogVisible = false;
						} 
						else if (res.resultCode == 2) //日期已存在
						{ 
							this.$message({message:DateExists,offset:10});
						}
					}
				})
			},

			//删除详情
			Delholiday(Data) {
				this.$confirm(this.$t('ControllerDate.WhetherDelete'), this.$t('ControllerDate.Hint'), {type: 'warning'}).then(async () => {
					var {data: res} = await this.$http.post('DelTemplateHoliday', {"RecordId": [Data.recordId],"HollidayName":[Data.holidayName]});
					if (this.FilteringOperation(res)) {
						if (res.resultCode == 0) //删除失败
						{ 
							this.$message({message: this.$t('ControllerDate.DeleteFailure'),type: 'error',offset:10});
						}
						else if (res.resultCode == 1) //删除成功
						{
							this.$message({message: this.$t('ControllerDate.DeleteSucceed'),type: 'success',offset:10});
							this.Templateholiday(this.TemplatesId);
						}
					}
				}).catch(() => {
					//取消
				});
			},

			//保存选中结果
			handleSelectionChange(val) {
				for (var i = 0; i < val.length; i++) {
					this.IdData[i] = val[i].recordId;
				}
			},

			//删除
			async Delholidays() {
				if (this.IdData.length > 0) {
					//删除
					this.$confirm(this.$t('ControllerDate.WhetherDelete'), this.$t('ControllerDate.Hint'), {type: 'warning'}).then(async () => {
						var {data: res} = await this.$http.post('DelTemplateHoliday', {"RecordId": this.IdData});
						if (this.FilteringOperation(res)) {
							if (res.resultCode == 0) //删除失败
							{ 
								this.$message({message: this.$t('ControllerDate.DeleteFailure'),type: 'error',offset:10});
							} 
							else if (res.resultCode == 1) //删除成功
							{ 
								this.$message({message: this.$t('ControllerDate.DeleteSucceed'),type: 'success',offset:10});
								this.IdData = [];
								this.Templateholiday(this.TemplatesId);
							}
						}
					}).catch(() => {
						//取消
					});
				}
				else 
				{
					this.$message({message: this.$t('ControllerDate.Select'), type: 'warning',offset:10});
				}
			},

			//搜索
			seek() {
				var Name = this.SeekName;
				var list = [];
				if(Name!='')
				{
					for (var i = 0; i < this.Seek.length; i++) {
						if (this.Seek[i].holidayName.indexOf(Name) != -1) {
							list.push(this.Seek[i]);
						}
					}
					this.HolidayTemplateList=list;
				}
				else{
					this.HolidayTemplateList=this.Seek;
				}
			},

			//请求结果过滤
			FilteringOperation(res) {
				var State = true;
				if (res.stateCode == 0) {
					State = false;
					this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
				} else if (res.stateCode == 2) {
					State = false;
					this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
				} else if (res.stateCode == 3) {
					State = false;
					this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
				} 
				return State;
			},
		}
	};
</script>

<style scoped>

	.ControllerDate{
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	/* 内容 */
	.content{
		display: flex;
		flex-grow: 1;
		height: 100%;
	}

	/* 左侧 */
	.content_left{
		background-color: #FFFFFF;
		width: 290px;
		height: 100%;
		margin-right: 20px;
	}

	.left_tree {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.left_tree_input {
		width:90%;
		margin:0px auto;
		margin-top:15px;
		display: flex;
	}

	.custom-tree-node{
		font-size: 13px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.custom-tree-node i{
		padding: 5px 10px;
	}

	.content_left_tree{
		width:90%;
		flex-grow: 1;
		margin: 10px auto;
		overflow: auto;
	}

	.left_tree{
		display: flex;
		align-items:center;
		justify-content: space-between;
		width: 100%;
		font-size: 15px;
	}

	/* 右侧 */

	.content_right{
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	.content_right2{
		flex-grow: 1;
		display: flex;
		background-color: #FFFFFF;
		flex-direction: column;
		color: #909399;
	}

	.content_right2>div{
		text-align: center;
	}

	/* 右侧-头部 */
	.content_right_top{
		width: 100%;
		height: 70px;
		display: flex;
		margin-bottom: 20px;
	}

	.right_top_card1{
		flex-grow: 1;
		height: 100%;
		background-color: #FFFFFF;
		justify-content: space-between;
	}

	.right_top_card1>div{
		display: flex;
	}

	.right_top_search>i{
		margin-top: -3px;
		font-size: 25px;
		margin-left:25px;
		margin-right:10px;
		color: #909399;
	}

	.right_top_search>>>.el-input__inner {
    	border: 0;
		font-size: 15px;
  	}

	.right_top_search_type{
		margin-right: 15px;
	}

	.right_top_search_type>i{
		margin-left: 20px;
		height: 20px;
		display: flex;
		border-left:1px solid #c7c7c7;
	}

	.right_top_search_type>>>.el-input__inner{
		border:0;
	}


	/* 右侧-底部 */
	.content_right_bottom{
		flex-grow: 1;
		height: 0px;
		width: 100%;
		background-color: #FFFFFF;
	}

	.content_right_bottom>div{
		display: flex;
		flex-direction: column;
		width: 96%;
		height: 95%;
	}

	.content_right_bottom_title{
		font-weight: 600;
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 10px;
	}

	.content_right_bottom_page{
		margin-top: 12px;
	}

	.Table-column{
		height: 26px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

</style>