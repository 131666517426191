<template>
  <div class="OpenClosed">
    <!-- 左侧 -->
    <div class="left boxshadow" v-loading="PageLoad.Leftload">
      <div class="left_top">
        <el-input
          :placeholder="$t('OpenClosed.TemplateName')"
          size="medium"
          maxlength="32"
          v-model="filterText"
        ></el-input>
        <el-button
          icon="el-icon-circle-plus-outline"
          size="medium"
          type="primary"
          @click="AddTemplateDialog()"
          >{{ $t("OpenClosed.Add") }}</el-button
        >
      </div>
      <el-tree
        class="left_tree"
        :highlight-current="true"
        :data="TreeList"
        :filter-node-method="filterNode"
        ref="tree"
        node-key="templatesId"
        default-expand-all
        :expand-on-click-node="false"
      >
        <div class="custom-tree-node" slot-scope="{ data }" @click="SelectTemolates(data)">
          <el-popover class="left_tree_left1" v-if="data.code!='' && data.code!=undefined" placement="left" :title="$t('OpenClosed.Remark')" width="200" trigger="hover" :content="data.code">
            <div slot="reference" class="left_tree_left1_div" >
              <i class="el-icon-wallet"></i>
              <span>{{ data.templateName }}</span>
            </div>
					</el-popover>
          <div v-else class="left_tree_left2">
              <i class="el-icon-wallet"></i>
              <span>{{ data.templateName }}</span>
            </div>
          <el-popover placement="right" trigger="hover" :open-delay="300">
                <el-button
                  type="info"
                  size="mini"
                  @click="UpdateTemplateDialog(data)"
                  >{{ $t("Employee.Update") }}</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  @click="DeleteTemolates(data)"
                  >{{ $t("Employee.Delete") }}</el-button
                >
          <!-- <div class="left_tree_button flex-center" @click="UpdateTemplateDialog(data)">
            <i class="el-icon-edit"></i>
          </div>
          <div class="left_tree_button flex-center" @click="DeleteTemolates(data)">
            <i class="el-icon-delete"></i>
          </div> -->
          <i class="el-icon-more left_tree_icon" slot="reference"></i>
        </el-popover>
        </div>
      
      </el-tree>
    </div>
    <!-- 右侧 -->
    <div class="right" v-loading="PageLoad.Rightload" v-if="QueryControllerOpenClose.TemplateId!=0">
      <div class="right_top boxshadow">
        <div class="right_top_left flex-items-center">
          <i class="el-icon-search"></i>
          <el-input
            maxlength="32"
            size="medium"
            v-model="QueryControllerOpenClose.Name"
            :placeholder="$t('OpenClosed.SearchTips')"
          ></el-input>
        </div>
        <div>
          <el-button size="medium" icon="el-icon-search" @click="controllerOpenClose()"></el-button>
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="AddControllerOpenCloseDialog()"
            >{{ $t("OpenClosed.AddControl") }}</el-button
          >
        </div>
      </div>
      <div class="right_bottom boxshadow" >
        <div class="table_Title">{{ $t("OpenClosed.ControlList") }}</div>
        <el-table :data="TableData" height="100%" style="width: 100%">
          <el-table-column :label="$t('OpenClosed.Week')" align="center">
            <template slot-scope="scope">
              {{ weekDayTransform(scope.row.weekDay) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('OpenClosed.Name')" align="center">
            <template slot-scope="scope">
              {{scope.row.name}}
            </template>
          </el-table-column>
          <el-table-column :label="$t('OpenClosed.Status')" align="center">
            <template slot-scope="scope">
              {{ StatusTransform(scope.row.status) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('OpenClosed.StartTime')" align="center">
            <template slot-scope="scope">
              {{ TimeTransform(scope.row.startTime) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('OpenClosed.EndTime')" align="center">
            <template slot-scope="scope">
              {{ TimeTransform(scope.row.entTime) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('OpenClosed.Operation')" align="center">
            <template slot-scope="scope">
              <div class="table_operation">
                <el-link :underline="false" @click="UpdateControllerOpenCloseDialog(scope.row)"><i class="el-icon-edit"></i></el-link>
                <el-link :underline="false" @click="DeleteControllerOpenClose(scope.row)"><i class="el-icon-delete"></i></el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else class="rightNoData flex-center boxshadow">
      <el-empty :description="$t('OpenClosed.NoData')"></el-empty>
    </div>

    <!-- 弹框 -->
    <el-dialog
      width="28%"
      :title="TemplateDialogTitle"
      :visible.sync="TemplateDialog"
      :close-on-click-modal="false"
    >
      <div>
        <el-form
          ref="TemplateForm"
          :rules="TemplateFormRules"
          :model="TemplateForm"
          label-width="80px"
        >
          <el-form-item :label="$t('OpenClosed.TemplateName')" prop="TemplateName">
            <el-input v-model="TemplateForm.TemplateName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('OpenClosed.TemplateCode')">
            <el-input v-model="TemplateForm.Code" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="TemplateDialog = false">{{$t('OpenClosed.Cancel')}}</el-button>
        <el-button v-if="TemplateForm.TemplatesId==0" type="primary" @click="AddTemplates()">{{$t('OpenClosed.Confirm')}}</el-button>
        <el-button v-else type="primary" @click="UpdateTemplates()">{{$t('OpenClosed.Confirm')}}</el-button>
      </span>
    </el-dialog>

    <!-- 弹框 -->
    <el-dialog
      width="30%"
      :title="ControlleropencloseDialogTitle"
      :visible.sync="ControlleropencloseDialog"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="OpencloseForm" :rules="OpencloseFormRules" :model="OpencloseForm" label-width="95px">
          <el-form-item :label="$t('OpenClosed.ControlStatus')" prop="status">
             <el-radio-group v-model="OpencloseForm.status">
              <el-radio label="1">{{$t('OpenClosed.ControlStatus1')}}</el-radio>
              <el-radio label="2">{{$t('OpenClosed.ControlStatus2')}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('OpenClosed.ControlName')">
            <el-input v-model="OpencloseForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('OpenClosed.WeekWeek')" prop="weekDay">
            <el-select style="width:100%" v-model="OpencloseForm.weekDay">
              <el-option :label="$t('OpenClosed.Week1')" value="1"></el-option>
              <el-option :label="$t('OpenClosed.Week2')" value="2"></el-option>
              <el-option :label="$t('OpenClosed.Week3')" value="3"></el-option>
              <el-option :label="$t('OpenClosed.Week4')" value="4"></el-option>
              <el-option :label="$t('OpenClosed.Week5')" value="5"></el-option>
              <el-option :label="$t('OpenClosed.Week6')" value="6"></el-option>
              <el-option :label="$t('OpenClosed.Week7')" value="7"></el-option>
          </el-select>
          </el-form-item>
          <el-form-item :label="$t('OpenClosed.ExecutionTime')" prop="time">
             <el-time-picker style="width:100%" v-model="OpencloseForm.time" format="HH:mm" :clearable="false" is-range value-format="HH:mm"></el-time-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ControlleropencloseDialog = false">{{$t('OpenClosed.Cancel')}}</el-button>
        <el-button v-if="OpencloseForm.recordId==0" type="primary" @click="AddUpdatecontrollerOpenClose()">{{$t('OpenClosed.Confirm')}}</el-button>
        <el-button v-else type="primary" @click="AddUpdatecontrollerOpenClose()">{{$t('OpenClosed.Confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>

import 'driver.js/dist/driver.min.css'
import Driver from 'driver.js'

export default {
  data() {
    //校验
    var verify = (rule, value, callback) => {
      if (!value) return callback(new Error(this.$t("OpenClosed.PleaseInput")));
      callback();
    };
    return {
      PageLoad: {
        Leftload: false,
        Rightload: false,
      },
      filterText: "",
      TreeList: [],
      TemplateDialog: false,
      TemplateDialogTitle: "",
      TemplateForm: {
        TemplatesId: 0,
        TemplateType:5,
        TemplateName: "",
        Code: "",
      },
      TemplateFormRules: {
        TemplateName: [
          { required: true, validator: verify, trigger: "blur" },
        ],
      },
      TemplatesId:0,
      TableData: [],
      QueryControllerOpenClose: {
        TemplateId:0,
        Name: "",
      },
      ControlleropencloseDialogTitle:"",
      ControlleropencloseDialog:false,
      OpencloseFormRules:{
        weekDay: [{required: true, validator: verify, trigger: "blur" }],
        status: [{required: true, validator: verify, trigger: "blur" }],
        time: [{required: true, validator: verify, trigger: "blur" }],
      },
      OpencloseForm:{
        recordId:0,
        name:"",
        weekDay:"1",
        status:"1",
        time:["",""]
      }
    };
  },
  //创建实例时执行
  created() {},
  //简体数据变化
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  //页面加载后执行
  mounted: function () {
    //查询模板
    this.Template();
    let cookieOpenAndCloseGuide = this.getCookie()
			 if(cookieOpenAndCloseGuide === undefined){
				setTimeout(()=>{this.openAndClose_guide()},1000)
		}
  },
  //自定义方法
  methods: {

    //常开常闭引导
			openAndClose_guide () {
			let driver = new Driver({
				className: 'scoped-class', // 包裹driver.js弹窗的类名 className to wrap driver.js popover
				animate: true, // 高亮元素改变时是否显示动画 Animate while changing highlighted element
				opacity: 0.75, // 背景透明度(0 表示只有弹窗并且没有遮罩) Background opacity (0 means only popovers and without overlay)
				padding: 10, //  元素与边缘的距离 Distance of element from around the edges
				allowClose: false, // 是否允许点击遮罩时关闭 Whether clicking on overlay should close or not
				overlayClickNext: false, // 是否允许点击遮罩时移到到下一步 Should it move to next step on overlay click
				doneBtnText: '完成', // 最终按钮上的文本 Text on the final button
				closeBtnText: '关闭', // 当前步骤关闭按钮上的文本 Text on the close button for this step
				nextBtnText: '下一步', // 当前步骤下一步按钮上的文本 Next button text for this step
				prevBtnText: '上一步', // 当前步骤上一步按钮上的文本 Previous button text for this step
				showButtons: true, // 是否在底部显示控制按钮 Do not show control buttons in footer
				keyboardControl: true, // 是否允许通告键盘控制(escape关闭，箭头键用于移动)Allow controlling through keyboard (escape to close, arrow keys to move)
				scrollIntoViewOptions: {}, //  `scrollIntoView()` 方法的选项 We use `scrollIntoView()` when possible, pass here the options for it if you want any
			})
			// Define the steps for introduction
			let steps = [
				{
				element: '.left_top',
				popover: {
					title: this.$t('OpenClosed.OpenCloseTitle1'),
					description: this.$t('OpenClosed.OpenCloseContent1'),
					position: 'right'
				}
				},
				{
				element: '.left_tree',
				popover: {
					title: this.$t('OpenClosed.OpenCloseTitle2'),
					description: this.$t('OpenClosed.OpenCloseContent2'),
					position: 'right'
				}
				},
				{
				element: '.rightNoData',
				popover: {
					title: this.$t('OpenClosed.OpenCloseTitle3'),
					description: this.$t('OpenClosed.OpenCloseContent3'),
					position: 'left'
				}
				},
			]
			driver.defineSteps(steps)
			// Start the introduction
			driver.start()
			// 保存
			 this.setCookie()
			},

			
			setCookie(){
				//设置10年后过期
				let expires = new Date();
				expires.setTime(expires.getTime()+10*365*24*60*60*1000);
				document.cookie = "openAndCloseGuide="+escape('1')+";expires="+expires.toGMTString();
			},
			getCookie(){
				//获取cookie值
				let str = document.cookie;
				//将值切割成数组
				let arr = str.split(";");
				let openAndCloseGuide;
				//遍历数组
				for(var i=0;i<arr.length;i++){
					var value = arr[i].split("=");
					//避免输入中含有空格，消除文本框空格
          			value[0] = value[0].replace(/\s+/g, "");
					if(value[0] == 'openAndCloseGuide'){
						openAndCloseGuide = value[1];
					}
				}
				console.log("openAndCloseGuide:" + openAndCloseGuide)
				return openAndCloseGuide;
			},


    //模板查询过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.templateName.indexOf(value) !== -1;
    },
    //查询模板
    async Template() {
      try 
      {
        this.PageLoad.Leftload = true; //显示加载动画
        var { data: res } = await this.$http.post("openClosedTemplates");
        if (res.resultCode == 1) {
          this.TreeList = JSON.parse(res.body);
        }
        this.PageLoad.Leftload = false; //显示加载动画
      } 
      catch (err) 
      {
        this.PageLoad.Leftload = false; //显示加载动画
        console.log(err);
      }
    },
    // 状态转换
    StatusTransform(label) {
      if (label == 1) return this.$t("OpenClosed.ControlStatus1");
      else if (label == 2) return this.$t("OpenClosed.ControlStatus2");
    },
    weekDayTransform(lable){
      if(lable==1) return this.$t("OpenClosed.Week1");
      else if (lable==2) return this.$t("OpenClosed.Week2");
      else if (lable==3) return this.$t("OpenClosed.Week3");
      else if (lable==4) return this.$t("OpenClosed.Week4");
      else if (lable==5) return this.$t("OpenClosed.Week5");
      else if (lable==6) return this.$t("OpenClosed.Week6");
      else if (lable==7) return this.$t("OpenClosed.Week7");
    },
    //时间转换
    TimeTransform(time) {
      time = time.split(":");
      return time[0] + ":" + time[1];
    },
    //新增模板弹框
    AddTemplateDialog() {
      this.TemplateDialogTitle = this.$t("OpenClosed.AddTemplate");
      this.TemplateForm = {TemplatesId: 0,TemplateType:5,TemplateName: "",Code: ""}
      this.TemplateForm.TemplatesId = 0;
      this.TemplateDialog = true;
    },
    //新增模板弹框
    UpdateTemplateDialog(Data) {
      this.TemplateDialogTitle = this.$t("OpenClosed.UpdateTemplate");
      this.TemplateForm.TemplatesId = Data.templatesId;
      this.TemplateForm.TemplateName = Data.templateName;
      this.TemplateForm.Code = Data.code;
      this.TemplateDialog = true;
    },
    //新增模板
    AddTemplates() {
      this.$refs.TemplateForm.validate(async (valid) => {
        //验证
        if (!valid) return;
        var { data: res } = await this.$http.post("AddTemplates",this.TemplateForm);
        if (this.FilteringOperation(res)) 
        {
          if (res.resultCode == 0 || res.resultCode == 2) 
          {
            //新增失败
            this.$message({message: this.$t("OpenClosed.AddFailure"),type: "error",offset: 10,});
          } 
          else if (res.resultCode == 1) 
          {
            //新增成功
            this.$message({message: this.$t("OpenClosed.AddSucceed"),type: "success",offset: 10,});
            this.TemplateForm.TemplateName = '';
						this.TemplateForm.Code = '';
            this.TemplateDialog = false;
            this.Template();
          } 
          else if (res.resultCode == 3)
          {
            //模板名称
            this.$message({message: this.$t("OpenClosed.TemplateExists"),type: "warning",offset: 10,});
          }
        }
      });
    },
    //修改模板
		UpdateTemplates() {
				this.$refs.TemplateForm.validate(async (valid) => {
					if (!valid) return;
					var {data: res} = await this.$http.post('UpdTemplates', this.TemplateForm)
					if(this.FilteringOperation(res))
					{
						if (res.resultCode == 0 || res.resultCode == 2) //修改失败
						{ 
							this.$message({message:this.$t('OpenClosed.UpdateFailure'),type: 'error',offset:10});
						} 
						else if (res.resultCode == 1)  //修改成功
						{
							this.$message({message:this.$t('OpenClosed.UpdateSucceed'),type: 'success',offset:10});
							this.TemplateForm.TemplateName = '';
							this.TemplateForm.Code = '';
							this.TemplateDialog = false;
							this.Template();
						}
						else if (res.resultCode == 3) //模板名称
						{ 
							this.$message({message: this.$t('OpenClosed.TemplateExists'),type: 'warning',offset:10});
						}
					}
				})
		},
    //删除模板
		DeleteTemolates(data) {
				this.$confirm(this.$t('ControllerTime.WhetherDelete'),this.$t('OpenClosed.Hint'), {type: 'warning'}).then(async () => {
					//删除模板
					var {data: res} = await this.$http.post('DelTemplates', {"TemplatesId": data.templatesId,"TemplateName":data.templateName,"TemplateType":5});
					if(this.FilteringOperation(res))
					{
						if (res.resultCode == 0)//删除失败 
						{ 
							this.$message({message: this.$t('OpenClosed.DeleteFailure'),type: 'error',offset:10});
						} 
						else if (res.resultCode == 1) //删除成功
						{ 
							this.$message({message: this.$t('OpenClosed.DeleteSucceed'),type: 'success',offset:10});
							if(data.templatesId==this.QueryControllerOpenClose.TemplateId) this.QueryControllerOpenClose.TemplateId=0;
							this.Template();
						}
					}
				}).catch(() => {
					//取消
				});
		},
    // 选择模板
    SelectTemolates(Data){
      this.QueryControllerOpenClose.TemplateId = Data.templatesId;
      this.controllerOpenClose();
    },
    //查询常开常闭详情
    async controllerOpenClose() {
      try 
      {
        this.PageLoad.Rightload = true; //显示加载动画
        var { data: res } = await this.$http.post("controllerOpenClose",this.QueryControllerOpenClose);
        if (res.resultCode == 1) {
          this.TableData = JSON.parse(res.body);
        }
        this.PageLoad.Rightload = false; //显示加载动画
      } 
      catch (err) 
      {
        this.PageLoad.Rightload = false; //显示加载动画
        console.log(err);
      }
    },
    //时间转名称
    TimeTransformName(Data){
      var startTime = this.TimeTransform(Data.startTime)
      var entTime = this.TimeTransform(Data.entTime)
      var Time1 = startTime.split(":");
      var Time2 = entTime.split(":");
      var startAll = parseInt(Time1[0]*60) + parseInt(Time1[1]);
      var emdAll = parseInt(Time2[0]*60) + parseInt(Time2[1]);
      var minute = emdAll-startAll;
      return this.StatusTransform(Data.status)+" ("+minute+" "+this.$t('OpenClosed.Minute')+")";
    },
    //新增弹框
    AddControllerOpenCloseDialog(){
      this.ControlleropencloseDialogTitle = this.$t('OpenClosed.AddContro')
      this.OpencloseForm = {recordId:0,name:"",weekDay:"1",status:"1",time:""}
      this.ControlleropencloseDialog = true;
    },
    //修改弹框
    UpdateControllerOpenCloseDialog(Data){
      this.ControlleropencloseDialogTitle = this.$t('OpenClosed.UpdateContro')
      this.OpencloseForm = {recordId:Data.recordId,name:Data.name,weekDay:Data.weekDay.toString(),status:Data.status.toString(),time:[this.TimeTransform(Data.startTime),this.TimeTransform(Data.entTime)]}
      this.ControlleropencloseDialog = true;
    },
    //新增修改控制
    AddUpdatecontrollerOpenClose() {
      this.$refs.OpencloseForm.validate(async (valid) => {
        if (!valid) return;
        var Data = {
          recordId:this.OpencloseForm.recordId,
          templateId:this.QueryControllerOpenClose.TemplateId,
          name:this.OpencloseForm.name,
          weekDay:this.OpencloseForm.weekDay,
          status:this.OpencloseForm.status,
          startTime:this.OpencloseForm.time[0]+":00",
          entTime:this.OpencloseForm.time[1]+":00",
        }
        if(this.OpencloseForm.recordId==0) Data.name = this.TimeTransformName(Data);
        var { data: res } = await this.$http.post("addUpdatecontrollerOpenClose",Data);
        if (this.FilteringOperation(res)) 
        {
          if (res.resultCode == 0) 
          {
            //失败
            if(Data.recordId==0)
            this.$message({message: this.$t("OpenClosed.AddFailure"),type: "error",offset: 10,});
            else
            this.$message({message: this.$t("OpenClosed.UpdateFailure"),type: "error",offset: 10,});
          } 
          else if (res.resultCode == 1) 
          {
            //成功
            if(Data.recordId==0)
            this.$message({message: this.$t("OpenClosed.AddSucceed"),type: "success",offset: 10,});
            else
            this.$message({message: this.$t("OpenClosed.UpdateSucceed"),type: "success",offset: 10,});
            this.controllerOpenClose();
            this.ControlleropencloseDialog = false;
          } 
          else if (res.resultCode == 2)
          {
            //时间段存在
            this.$message({message: this.$t("OpenClosed.TimeExistence"),type: "warning",offset: 10,});
          }
        }
      });
    },
    //删除详情
		DeleteControllerOpenClose(Data) {
			this.$confirm(this.$t('ControllerDate.WhetherDelete'), this.$t('ControllerDate.Hint'), {type: 'warning'}).then(async () => {
				var {data: res} = await this.$http.post('deleteControllerOpenClose', Data);
				if (this.FilteringOperation(res)) {
					if (res.resultCode == 0) //删除失败
					{ 
						this.$message({message: this.$t('OpenClosed.DeleteFailure'),type: 'error',offset:10});
					}
					else if (res.resultCode == 1) //删除成功
					{
						this.$message({message: this.$t('OpenClosed.DeleteSucceed'),type: 'success',offset:10});
						this.controllerOpenClose();
					}
				}
			}).catch(() => {
				//取消
			});
		},
    //请求结果过滤
    FilteringOperation(res) {
      var State = true;
      if (res.stateCode == 0) {
        State = false;
        this.$message({
          message: this.$t("Axios.Session"),
          type: "warning",
          offset: 10,
        });
      } else if (res.stateCode == 2) {
        State = false;
        this.$message({
          message: this.$t("Axios.Failure"),
          type: "error",
          offset: 10,
        });
      } else if (res.stateCode == 3) {
        State = false;
        this.$message({
          message: this.$t("Axios.AccessDenied"),
          type: "error",
          offset: 10,
        });
      }
      return State;
    },
  },
};
</script>
<style scoped>
.OpenClosed {
  height: 100%;
  display: flex;
}
/* 左侧 */
.left {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 15px;
  width: 260px;
  margin-right: 20px;
}

.left_top {
  display: flex;
  margin-bottom: 10px;
}

.left_top > button {
  margin-left: 15px;
}

.left_tree {
  overflow: auto;
  flex-grow: 1;
}

.custom-tree-node {
  width: 100%;
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left_tree_left1 {
  flex-grow: 1;
}

.left_tree_left1_div{
  width: 100%;
  padding: 3px 0px;
}

.left_tree_left2 {
  flex-grow: 1;
}

.left_tree_left1 i{
  margin-left: -15px;
  margin-right: 10px;
}

.left_tree_left2 i{
  margin-left: -15px;
  margin-right: 10px;
}

.left_tree_button {
  height: 100%;
  width: 33px;
}

.left_tree_button:hover {
  background-color: #d9ecff;
}

/* 右侧 */
.right {
  width: 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.right_top {
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  background-color: #ffffff;
}

.right_top_left {
  flex-grow: 1;
}

.right_top_left > i {
  margin-left: 10px;
  margin-right: 5px;
  font-size: 22px;
}
.right_top_left >>> .el-input__inner {
  border: 0;
  font-size: 15px;
}

.right_bottom {
  padding: 15px;
  flex-grow: 1;
  height: 0px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.table_Title {
  font-weight: 600;
  color: #6f6f6f;
  font-size: 14.5px;
  margin-bottom: 10px;
}

.table_operation > .el-link {
  margin: 0px 10px;
}

.rightNoData{
  width: 0px;
  flex-grow: 1;
  background-color: #ffffff;
}
</style>
