<template>
	<div class="ControllerTime">
		<div class="content">
			<!-- 左侧 -->
			<div class="content_left boxshadow" v-loading="PageLoad.Leftload">
				<div class="left_tree">
					<!-- 功能栏 -->
					<div class="left_tree_input">
						<el-input :placeholder="$t('ControllerTime.SearchTemplate')" size="medium" v-model="filterText" style="width:66%;"></el-input>
						<el-button size="medium" type="primary" icon="el-icon-circle-plus-outline" style="margin-left:15px" @click="AddtemplateStaet"  :disabled="!userGroupDetails.RoleTimeTemplates">{{$t('ControllerTime.Add')}}</el-button>
					</div>

					<!-- 树形菜单 -->
					<el-tree class="content_left_tree" :highlight-current="true" :data="TimeTemplatesData" default-expand-all :filter-node-method="filterNode" :expand-on-click-node="false" icon-class="el-icon-s-promotion" node-key="templatesId" ref="tree">
						<div class="custom-tree-node" slot-scope="{ data }" style="height: 100%;" @click="optional(data)">
							<div style="width:100%;font-size:15px">
								<el-popover v-if="data.code!='' && data.code!=undefined" placement="left" :title="$t('ControllerTime.Remark')" width="200" trigger="hover" :content="data.code">
									<div slot="reference">
										{{data.templateName}}
									</div>
								</el-popover>
								<div v-else slot="reference" style="width: 100%;">
									{{data.templateName}}
								</div>
							</div>
							<span v-if="userGroupDetails.RoleTimeTemplates && data.companyId!=0">
								<el-popover placement="right" trigger="hover" :open-delay="300">
								<el-button type="info" size="mini" @click="UpdateTemolate(data)">
									{{$t('ControllerTime.Update')}}
								</el-button>
								<el-button type="danger" size="mini" @click="DeleteTemolate(data)">
									{{$t('ControllerTime.Delete')}}
								</el-button>
								<i class="el-icon-more left_tree_icon" slot="reference"></i>
							</el-popover>
							</span>
						</div>
					</el-tree>
				</div>
			</div>

			<div class="content_right" v-loading="PageLoad.Rightload">
				<!-- 底部 -->
				<div v-if="TemplatesId!=0" class="content_right_bottom flex-center boxshadow">
					<div>
						<el-table :data="TimeTemplateList" height="100%">
							<el-table-column :label="$t('ControllerTime.Week')" align="center" width="135" fit="true">
								<template slot-scope="scope">
									<span v-if="scope.row.weekDay!=0">{{scope.row.weekDay}}</span>
									<el-popover v-else
										placement="left"
										:title="$t('ControllerTime.Hint')"
										width="200"
										trigger="hover"
										:content="$t('ControllerTime.HintDescribe')">
										<el-select :disabled="TimeFrom.WeekDay != scope.row.weekDay+''"  slot="reference" v-model="SelectHolidayTemplate" clearable :placeholder="$t('ControllerTime.Schedule')" @change="SelectEvents" size="mini">
											<el-option
											v-for="item in HolidayTemplate"
											:key="item.templatesId"
											:label="item.templateName"
											:value="item.templatesId">
											</el-option>
										</el-select>
									</el-popover>
								</template>
							</el-table-column>
							<el-table-column :label="$t('ControllerTime.Time1')" align="center">
								<el-table-column prop="startTime1" :label="$t('ControllerTime.Start')" align="center">
									<template slot-scope="scope">
										<div class="ValueHeig">
											<el-tag v-if="TimeFrom.WeekDay != scope.row.weekDay+''" :type="scope.row.startTime1!=undefined? '':'info'">{{CutOut(scope.row.startTime1)}}</el-tag>
											<el-time-picker v-else v-model="TimeFrom.StartTime1" :picker-options="SelectableRange" format="HH:mm"
											size="mini" 
											value-format="HH:mm"
											:placeholder="$t('ControllerTime.Start')">
											</el-time-picker>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="endTime1" :label="$t('ControllerTime.End')" align="center">
									<template slot-scope="scope">
										<div class="ValueHeig">
											<el-tag v-if="TimeFrom.WeekDay != scope.row.weekDay+''" :type="scope.row.endTime1!=undefined? 'warning':'info'">{{CutOut(scope.row.endTime1)}}</el-tag>
											<el-time-picker v-else v-model="TimeFrom.EndTime1" :picker-options="SelectableRange" format="HH:mm"
											size="mini" 
											value-format="HH:mm"
											:placeholder="$t('ControllerTime.End')">
											</el-time-picker>
										</div>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column :label="$t('ControllerTime.Time2')" align="center" :width="TableWidth">
								<el-table-column prop="startTime2" :label="$t('ControllerTime.Start')" align="center">
									<template slot-scope="scope">
										<div class="ValueHeig">
											<el-tag v-if="TimeFrom.WeekDay != scope.row.weekDay+''" :type="scope.row.startTime2!=undefined? '':'info'">{{CutOut(scope.row.startTime2)}}</el-tag>
											<el-time-picker v-else v-model="TimeFrom.StartTime2" :picker-options="SelectableRange" format="HH:mm"
											size="mini" 
											value-format="HH:mm"
											:placeholder="$t('ControllerTime.Start')">
											</el-time-picker>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="endTime2" :label="$t('ControllerTime.End')" align="center">
									<template slot-scope="scope">
										<div class="ValueHeig">
											<el-tag v-if="TimeFrom.WeekDay != scope.row.weekDay+''" :type="scope.row.endTime2!=undefined? 'warning':'info'">{{CutOut(scope.row.endTime2)}}</el-tag>
											<el-time-picker v-else v-model="TimeFrom.EndTime2" :picker-options="SelectableRange" format="HH:mm"
											size="mini" 
											value-format="HH:mm"
											:placeholder="$t('ControllerTime.End')">
											</el-time-picker>
										</div>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column :label="$t('ControllerTime.Time3')" align="center" :width="TableWidth">
								<el-table-column prop="startTime3" :label="$t('ControllerTime.Start')" align="center">
									<template slot-scope="scope">
										<div class="ValueHeig">
											<el-tag v-if="TimeFrom.WeekDay != scope.row.weekDay+''" :type="scope.row.startTime3!=undefined? '':'info'">{{CutOut(scope.row.startTime3)}}</el-tag>
											<el-time-picker v-else v-model="TimeFrom.StartTime3" :picker-options="SelectableRange" format="HH:mm"
											size="mini" 
											value-format="HH:mm"
											:placeholder="$t('ControllerTime.Start')">
											</el-time-picker>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="endTime3" :label="$t('ControllerTime.End')" align="center">
									<template slot-scope="scope">
										<div class="ValueHeig">
											<el-tag v-if="TimeFrom.WeekDay != scope.row.weekDay+''" :type="scope.row.endTime3!=undefined? 'warning':'info'">{{CutOut(scope.row.endTime3)}}</el-tag>
											<el-time-picker v-else v-model="TimeFrom.EndTime3" :picker-options="SelectableRange" format="HH:mm"
											size="mini" 
											value-format="HH:mm"
											:placeholder="$t('ControllerTime.End')">
											</el-time-picker>
										</div>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column :label="$t('ControllerTime.Time4')" align="center" :width="TableWidth">
								<el-table-column prop="startTime4" :label="$t('ControllerTime.Start')" align="center">
									<template slot-scope="scope">
										<div class="ValueHeig">
											<el-tag v-if="TimeFrom.WeekDay != scope.row.weekDay+''" :type="scope.row.startTime4!=undefined? '':'info'">{{CutOut(scope.row.startTime4)}}</el-tag>
											<el-time-picker v-else v-model="TimeFrom.StartTime4" :picker-options="SelectableRange" format="HH:mm"
											size="mini" 
											value-format="HH:mm"
											:placeholder="$t('ControllerTime.Start')">
											</el-time-picker>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="endTime4" :label="$t('ControllerTime.End')" align="center">
									<template slot-scope="scope">
										<div class="ValueHeig">
											<el-tag v-if="TimeFrom.WeekDay != scope.row.weekDay+''" :type="scope.row.endTime4!=undefined? 'warning':'info'">{{CutOut(scope.row.endTime4)}}</el-tag>
											<el-time-picker v-else v-model="TimeFrom.EndTime4" :picker-options="SelectableRange" format="HH:mm"
											size="mini" 
											value-format="HH:mm"
											:placeholder="$t('ControllerTime.End')">
											</el-time-picker>
										</div>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column :label="$t('ControllerTime.Time5')" align="center" :width="TableWidth">
								<el-table-column prop="startTime5" :label="$t('ControllerTime.Start')" align="center">
									<template slot-scope="scope">
										<div class="ValueHeig">
											<el-tag v-if="TimeFrom.WeekDay != scope.row.weekDay+''"  :type="scope.row.startTime5!=undefined? '':'info'">{{CutOut(scope.row.startTime5)}}</el-tag>
											<el-time-picker v-else v-model="TimeFrom.StartTime5" :picker-options="SelectableRange" format="HH:mm"
											size="mini" 
											value-format="HH:mm"
											:placeholder="$t('ControllerTime.Start')">
											</el-time-picker>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="endTime5" :label="$t('ControllerTime.End')" align="center">
									<template slot-scope="scope">
										<div class="ValueHeig">
											<el-tag v-if="TimeFrom.WeekDay != scope.row.weekDay+''"  :type="scope.row.endTime5!=undefined? 'warning':'info'">{{CutOut(scope.row.endTime5)}}</el-tag>
											<el-time-picker v-else v-model="TimeFrom.EndTime5" :picker-options="SelectableRange" format="HH:mm"
											size="mini" 
											value-format="HH:mm"
											:placeholder="$t('ControllerTime.End')">
											</el-time-picker>
										</div>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column :label="$t('ControllerTime.Operation')" align="center">
								<template slot-scope="scope">
									<div class="flex-center table_button">
										<div>
											<el-tooltip class="item" effect="dark" :content="TimeFrom.WeekDay+''==''? $t('ControllerTime.Edit'):$t('ControllerTime.Save')" placement="top" :enterable="false">
												<el-button v-if="TimeFrom.WeekDay  != scope.row.weekDay+''" :disabled="CompanyId==0 || !userGroupDetails.RoleTemplateTime" type="primary" @click="UpdateSelect(scope.row)" size="mini" icon="el-icon-edit"></el-button>
												<el-button v-else type="success" @click="UpdTemplateTime(scope.row.recordId)" :loading="ButtonLoad.AddUpdateTime==scope.row.recordId" :disabled="CompanyId==0 || !userGroupDetails.RoleTemplateTime" size="mini" icon="el-icon-circle-check"></el-button>
											</el-tooltip>
										</div>
										<div>
											<el-tooltip class="item" effect="dark" :content="$t('ControllerTime.Empty')" placement="top" :enterable="false">
												<el-button type="danger" @click="Empty(scope.row)" :disabled="CompanyId==0 || !userGroupDetails.RoleTemplateTime" size="mini" icon="el-icon-circle-close"></el-button>
											</el-tooltip>
										</div>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>

				<div v-else class="content_right2 flex-center boxshadow">
					<div style="text-align: center;color: #909399;"><!-- 67C23A -->
						<div style="font-size: 60px;" class="el-icon-time"></div>
						<div style="margin-top: 20px;">{{$t('ControllerTime.Schedule')}}</div>
					</div>
				</div>
			</div>
		</div>


		<!-- 模板弹框 -->
		<el-dialog :title="TemplateTitle" :visible.sync="TemplateVisible" width="30%" :close-on-click-modal="false">
			<el-form ref="TemplateformRef" label-width="100px" class="demo-ruleForm" :model="TemplateForm" :rules="TemplateFormRules"
			 label-position="left">
				<el-form-item :label="$t('ControllerTime.TemplateName')" prop="TemplateName">
					<el-input v-model="TemplateForm.TemplateName" :placeholder="$t('ControllerTime.InputTemplateName')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('ControllerTime.Remark')">
					<el-input v-model="TemplateForm.Code" type="textarea"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="TemplateVisible = false">{{$t('ControllerTime.Cancel')}}</el-button>
				<el-button type="primary" v-if="TemplateForm.TemplatesId==0" :loading="ButtonState" @click="AddTemplates">{{ButtonsName}}</el-button>
				<el-button type="primary" v-if="TemplateForm.TemplatesId!=0" :loading="ButtonState" @click="UpdTemplates">{{ButtonsName}}</el-button>
			</span>
		</el-dialog>
		
	</div>
</template>

<script>
	
import 'driver.js/dist/driver.min.css'
import Driver from 'driver.js'

	export default {
		data() {
			//校验名称
			var TimeName_verify = (rule, value, callback) => {
			if (!value)
				return callback(new Error(this.$t('ControllerTime.InputTemplateName')));
			callback();
			};
			return {
				//权限组
				userGroupDetails: {
					RoleTimeTemplates: false, //时间模板操作权
					RoleTemplateTime: false //时间模板详情操作权
				},

				// 页面加载
				PageLoad:{
   					Leftload: false, //左侧加载状态
					Rightload: false, //右侧加载状态
				},

				// 按钮加载状态
				ButtonLoad:{
 					AddUpdateTime: 0,
				},
				
				TemplatesId:0,//Id
				TemplateName:'',//名称
				
				HolidayTemplate: [], //假期模板
				SelectHolidayTemplate:'',//选择的假期模板
				CompanyId:0,
				
				filterText: '',//时间模板搜索
				TimeTemplatesData: [],//时间模板
				defaultProps: { //模板格式
					children: 'templatesId',
					label: 'templateName'
				},
				
				TableWidth:1131,//表格宽度
				TimeTemplateList: [],//时间模板详情
				
				SelectableRange:{selectableRange: '00:00:00 - 23:59:59'},
				TimeFrom:{
					RecordId:0,
					HolidayTemplateId:0,
					WeekDay:'',//修改详情的星期
					StartTime1: '',
					EndTime1: '',
					StartTime2: '',
					EndTime2: '',
					StartTime3: '',
					EndTime3: '',
					StartTime4: '',
					EndTime4: '',
					StartTime5: '',
					EndTime5: '',
				},
				
				TemplateTitle:'',//模板弹框标题
				TemplateVisible:false,//模板弹框显示状态
				ButtonState:false,//弹框按钮状态
				ButtonsName:'',//弹框按钮名称
				TemplateForm: { // 添加修改模板表单参数
					TemplateType:2,
					TemplatesId: 0,
					TemplateName: "",
					Code: ""
				},
				TemplateFormRules: { //添加修改模板表单验证
					TemplateName: [{
						required: true,
						validator: TimeName_verify,
						trigger: "change",
					}]
				},
				
			};
		},
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val);
			}
		},
		mounted() {
			//获取操作权
			this.Role();
		
			//查询假期模板
			this.TimeTemplates(1);//刷新时间详情
			
			let cookieControllerTimeGuide = this.getCookie()
			if(cookieControllerTimeGuide === undefined){
				setTimeout(()=>{this.controllertime_guide()},1000)
			}
		},
		methods: {
			

			//时间表引导
			controllertime_guide () {
			let driver = new Driver({
				className: 'scoped-class', // 包裹driver.js弹窗的类名 className to wrap driver.js popover
				animate: true, // 高亮元素改变时是否显示动画 Animate while changing highlighted element
				opacity: 0.75, // 背景透明度(0 表示只有弹窗并且没有遮罩) Background opacity (0 means only popovers and without overlay)
				padding: 10, //  元素与边缘的距离 Distance of element from around the edges
				allowClose: false, // 是否允许点击遮罩时关闭 Whether clicking on overlay should close or not
				overlayClickNext: false, // 是否允许点击遮罩时移到到下一步 Should it move to next step on overlay click
				doneBtnText: '完成', // 最终按钮上的文本 Text on the final button
				closeBtnText: '关闭', // 当前步骤关闭按钮上的文本 Text on the close button for this step
				nextBtnText: '下一步', // 当前步骤下一步按钮上的文本 Next button text for this step
				prevBtnText: '上一步', // 当前步骤上一步按钮上的文本 Previous button text for this step
				showButtons: true, // 是否在底部显示控制按钮 Do not show control buttons in footer
				keyboardControl: true, // 是否允许通告键盘控制(escape关闭，箭头键用于移动)Allow controlling through keyboard (escape to close, arrow keys to move)
				scrollIntoViewOptions: {}, //  `scrollIntoView()` 方法的选项 We use `scrollIntoView()` when possible, pass here the options for it if you want any
			})
			// Define the steps for introduction
			let steps = [
				{
				element: '.left_tree_input',
				popover: {
					title: this.$t('ControllerTime.ControllerTimeGuideTitle1'),
					description: this.$t('ControllerTime.ControllerTimeGuideContent1'),
					position: 'right'
				}
				},
				{
				element: '.content_left_tree',
				popover: {
					title: this.$t('ControllerTime.ControllerTimeGuideTitle2'),
					description: this.$t('ControllerTime.ControllerTimeGuideContent2'),
					position: 'right'
				}
				},
				{
				element: '.content_right2',
				popover: {
					title: this.$t('ControllerTime.ControllerTimeGuideTitle3'),
					description: this.$t('ControllerTime.ControllerTimeGuideContent3'),
					position: 'left'
				}
				},
			]
			driver.defineSteps(steps)
			// Start the introduction
			driver.start()
			// 保存
			 this.setCookie()
			},

			
			setCookie(){
				//设置10年后过期
				let expires = new Date();
				expires.setTime(expires.getTime()+10*365*24*60*60*1000);
				document.cookie = "controllertimeGuide="+escape('1')+";expires="+expires.toGMTString();
			},
			getCookie(){
				//获取cookie值
				let str = document.cookie;
				//将值切割成数组
				let arr = str.split(";");
				let controllertimeGuide;
				//遍历数组
				for(var i=0;i<arr.length;i++){
					var value = arr[i].split("=");
					//避免输入中含有空格，消除文本框空格
          			value[0] = value[0].replace(/\s+/g, "");
					if(value[0] == 'controllertimeGuide'){
						controllertimeGuide = value[1];
					}
				}
				console.log("controllertimeGuide:" + controllertimeGuide)
				return controllertimeGuide;
			},

			//获取操作权
			Role() {
				
				//获取权限组
				var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
				for (var i = 0; i < userGroupDetails.length; i++) {
					if (userGroupDetails[i].roleName == "TimeTemplates") { //假期模板操作权
						this.userGroupDetails.RoleTimeTemplates = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
					if (userGroupDetails[i].roleName == "TemplateTime") { //假期模板详情操作权
						this.userGroupDetails.RoleTemplateTime = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
				}
			},
			
			//查询时间模板
			async TimeTemplates(Type) {
				try {
					this.PageLoad.Leftload = true; //显示加载动画
					var {data: res} = await this.$http.post('TimeTemplates')
					if (res.resultCode == 1) 
					{
						this.TimeTemplatesData = JSON.parse(res.body)
						this.PageLoad.Leftload = false; //隐藏加载动画
						this.VacationTemplates();//查询假期模板
					}
				} 
				catch (err) 
				{
					this.PageLoad.Leftload = false;
					console.log(err)
				}
			},
			
			//选择模板
			optional(data) {
				this.TemplatesId = data.templatesId;
				this.TemplateName = data.templateName;
				this.CompanyId = data.companyId;
				this.TimeFrom.WeekDay = '';
				this.TemplateTime(data.templatesId);
			},
			
			
			//显示新增模板弹框
			AddtemplateStaet() {
				this.ButtonsName = this.$t('ControllerTime.Confirm');
				this.TemplateTitle = this.$t('ControllerTime.AddTemplate');
				this.TemplateVisible = true;
				this.TemplateForm.TemplatesId = 0;
				this.TemplateForm.TemplateName = '';
				this.TemplateForm.Code = '';
			},
			
			//新增模板
			AddTemplates() {
				this.$refs.TemplateformRef.validate(async valid => { //验证
					if (!valid) return;
					this.ButtonsName = this.$t('ControllerTime.InSubmit');
					this.ButtonState = true;
					var {data: res} = await this.$http.post('AddTemplates', this.TemplateForm)
					this.ButtonsName = this.$t('ControllerTime.Confirm');
					this.ButtonState = false;
					if(this.FilteringOperation(res))
					{
						if (res.resultCode == 0 || res.resultCode == 2) //新增失败
						{ 
							this.$message({message: this.$t('ControllerTime.AddFailure'),type: 'error',offset:10});
						} 
						else if (res.resultCode == 1) //新增成功
						{
							this.$message({message: this.$t('ControllerTime.AddSucceed'),type: 'success',offset:10});
							this.TemplateForm.TemplateName = '';
							this.TimeTemplates(0);//查询时间模板不刷新详情
							this.TemplateVisible = false;
						}
						else if (res.resultCode == 3) //模板名称
						{ 
							this.$message({message: this.$t('ControllerTime.TemplateExists'),type: 'warning',offset:10});
						}
					}
				})
			},
			
			//显示修改模板弹框
			UpdateTemolate(data) {
				this.ButtonsName = this.$t('ControllerTime.Confirm');
				this.TemplateTitle =  this.$t('ControllerTime.UpdateTemplate');
				this.TemplateVisible = true;
				this.TemplateForm.TemplatesId = data.templatesId;
				this.TemplateForm.TemplateName = data.templateName;
				this.TemplateForm.Code = data.code;
			},
			
			//修改模板
			UpdTemplates() {
				this.$refs.TemplateformRef.validate(async valid => { //验证
					if (!valid) return;
					this.ButtonsName =  this.$t('ControllerTime.InSubmit');
					this.buttonState = true;
					var {data: res} = await this.$http.post('UpdTemplates', this.TemplateForm)
					this.ButtonsName = this.$t('ControllerTime.Confirm');
					this.buttonState = false;
					if(this.FilteringOperation(res))
					{
						if (res.resultCode == 0 || res.resultCode == 2) //修改失败
						{ 
							this.$message({message:this.$t('ControllerTime.UpdateFailure'),type: 'error',offset:10});
						} 
						else if (res.resultCode == 1)  //修改成功
						{
							this.$message({message:this.$t('ControllerTime.UpdateSucceed'),type: 'success',offset:10});
							this.TemplateForm.TemplateName = '';
							this.TemplateForm.Code = '';
							this.TemplateVisible = false;
							this.TimeTemplates(1);//查询时间模板刷新详情
						}
						else if (res.resultCode == 3) //模板名称
						{ 
							this.$message({message: this.$t('ControllerTime.TemplateExists'),type: 'warning',offset:10});
						}
					}
				})
			},
			
			//删除模板(传模板名称用于日志记录)
			DeleteTemolate(data) {
				this.$confirm(this.$t('ControllerTime.WhetherDelete'),this.$t('ControllerTime.Hint'), {type: 'warning'}).then(async () => {
					//删除模板
					var {data: res} = await this.$http.post('DelTemplates', {"TemplatesId": data.templatesId,"TemplateType":2,"TemplateName":data.templateName});
					if(this.FilteringOperation(res))
					{
						if (res.resultCode == 0)//删除失败 
						{ 
							this.$message({message: this.$t('ControllerTime.DeleteSucceed'),type: 'error',offset:10});
						} 
						else if (res.resultCode == 1) //删除成功
						{ 
							this.$message({message: this.$t('ControllerTime.DeleteFailure'),type: 'success',offset:10});
							if(data.templatesId=this.TemplatesId)
							{
								this.TemplatesId=0;
							}
							this.TimeTemplates(1);//查询时间模板刷新详情
						}
					}
				}).catch(() => {
					//取消
				});
			},
			
			//查询假期模板
			async VacationTemplates() {
				try
				{
					var {data: res} = await this.$http.post('VacationTemplates')
					if (res.resultCode == 1) //请求结果过滤
					{
						this.HolidayTemplate = JSON.parse(res.body);
					}
				} 
				catch (err)
				{
					console.log(err);
				}
			},
			
			//查询时间模板详情
			async TemplateTime(TemplatesId) {
				try 
				{
					this.PageLoad.Rightload = true; //开启加载动画
					var {data: res} = await this.$http.post('TemplateTime',{"TemplatesId":TemplatesId})
					if (res.resultCode == 1) //请求结果过滤
					{ 
						this.TimeTemplateList=JSON.parse(res.body);
						if(JSON.parse(res.body)[0].holidayTemplateId!='')
						{
							this.SelectHolidayTemplate = JSON.parse(res.body)[0].holidayTemplateId;
							this.TimeFrom.HolidayTemplateId = JSON.parse(res.body)[0].holidayTemplateId;
						}
						this.PageLoad.Rightload = false; //关闭加载动画
					}
				} 
				catch (err) 
				{
					console.log(err)
				}
			},
			
			filterNode(value, data) {
				if (!value) return true;
				return data.templateName.indexOf(value) !== -1;
			},
			
			//选择假期模板
			SelectEvents(){
				this.TimeFrom.HolidayTemplateId = this.SelectHolidayTemplate;//选择的假期模板
			},
			
			//截取时间
			CutOut(Time){
				if(Time==undefined){
					return this.$t('ControllerTime.NotSet');
				}
				else{
					var arr = Time.split(':');
					return arr[0]+":"+arr[1];
				}
			},
			
			//编辑详情
			UpdateSelect(data){
				this.TimeFrom.RecordId = data.recordId;
				this.TimeFrom.WeekDay = data.weekDay;
				this.TimeFrom.HolidayTemplateId = data.holidayTemplateId == undefined ? 0:data.holidayTemplateId;
				this.TimeFrom.StartTime1 = data.startTime1 == undefined ? '':this.UpdateTime(data.startTime1);
				this.TimeFrom.EndTime1 = data.endTime1 == undefined ? '':this.UpdateTime(data.endTime1);
				this.TimeFrom.StartTime2 = data.startTime2 == undefined ? '':this.UpdateTime(data.startTime2);
				this.TimeFrom.EndTime2 = data.endTime2 == undefined ? '':this.UpdateTime(data.endTime2);
				this.TimeFrom.StartTime3 = data.startTime3 == undefined ? '':this.UpdateTime(data.startTime3);
				this.TimeFrom.EndTime3 = data.endTime3 == undefined ? '':this.UpdateTime(data.endTime3);
				this.TimeFrom.StartTime4 = data.startTime4 == undefined ? '':this.UpdateTime(data.startTime4);
				this.TimeFrom.EndTime4 = data.endTime4 == undefined ? '':this.UpdateTime(data.endTime4);
				this.TimeFrom.StartTime5 = data.startTime5 == undefined ? '':this.UpdateTime(data.startTime5);
				this.TimeFrom.EndTime5 = data.endTime5 == undefined ? '':this.UpdateTime(data.endTime5);
			},
			
			//清空
			Empty(data){
				this.TimeFrom.StartTime1 = "";
				this.TimeFrom.EndTime1 = "";
				this.TimeFrom.StartTime2 = "";
				this.TimeFrom.EndTime2 = "";
				this.TimeFrom.StartTime3 = "";
				this.TimeFrom.EndTime3 = "";
				this.TimeFrom.StartTime4 = "";
				this.TimeFrom.EndTime4 = "";
				this.TimeFrom.StartTime5 = "";
				this.TimeFrom.EndTime5 = "";
			},
			
			
			UpdateTime(Time){
				if(Time !='')
				{
					var arr = Time.split(':');
					return arr[0]+":"+arr[1];
				}
				else{
					return '';
				}
			},
			
			//修改日期详情
			async UpdTemplateTime(Id){

				this.TimeFrom.StartTime1 = this.TimeFrom.StartTime1!=null?this.TimeFrom.StartTime1:"";
				this.TimeFrom.EndTime1 = this.TimeFrom.EndTime1!=null?this.TimeFrom.EndTime1:"";
				this.TimeFrom.StartTime2 = this.TimeFrom.StartTime2!=null?this.TimeFrom.StartTime2:"";
				this.TimeFrom.EndTime2 = this.TimeFrom.EndTime2!=null?this.TimeFrom.EndTime2:"";
				this.TimeFrom.StartTime3 = this.TimeFrom.StartTime3!=null?this.TimeFrom.StartTime3:"";
				this.TimeFrom.EndTime3 = this.TimeFrom.EndTime3!=null?this.TimeFrom.EndTime3:"";
				this.TimeFrom.StartTime4 = this.TimeFrom.StartTime4!=null?this.TimeFrom.StartTime4:"";
				this.TimeFrom.EndTime4 = this.TimeFrom.EndTime4!=null?this.TimeFrom.EndTime4:"";
				this.TimeFrom.StartTime5 = this.TimeFrom.StartTime5!=null?this.TimeFrom.StartTime5:"";
				this.TimeFrom.EndTime5 = this.TimeFrom.EndTime5!=null?this.TimeFrom.EndTime5:"";

				if(this.TimeFrom.HolidayTemplateId=='')
				this.TimeFrom.HolidayTemplateId = 0;
				this.ButtonLoad.AddUpdateTime = Id;
				var {data: res} = await this.$http.post('UpdTemplateTime',this.TimeFrom);
				if( this.FilteringOperation(res))
				{
					if (res.resultCode == 0) //修改失败
					{
						this.$message({message: this.$t('ControllerTime.UpdateFailure'),type: 'error',offset:10});
					} 
					else if (res.resultCode == 1) //修改成功
					{ 
						this.$message({message: this.$t('ControllerTime.UpdateSucceed'),type: 'success',offset:10});
						this.TimeFrom.WeekDay = '';
						this.TimeFrom.HolidayTemplateId = 0;
						this.TemplateTime(this.TemplatesId)
					}
				}
				this.ButtonLoad.AddUpdateTime = 0;
			},
			
			//请求结果过滤
			FilteringOperation(res) {
				var State = true;
				if (res.stateCode == 0) {
					State = false;
					this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
				} else if (res.stateCode == 2) {
					State = false;
					this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
				} else if (res.stateCode == 3) {
					State = false;
					this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
				} 
				return State;
			},
		}
	};
</script>

<style scoped>
	.ControllerTime{
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	/* 内容 */
	.content{
		display: flex;
		flex-grow: 1;
		height: 100%;
	}

	/* 左侧 */
	.content_left{
		background-color: #FFFFFF;
		width: 290px;
		height: 100%;
		margin-right: 20px;
	}

	.left_tree {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.left_tree_input {
		width:90%;
		margin:0px auto;
		margin-top:15px;
		display: flex;
	}

	.custom-tree-node{
		font-size: 13px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.custom-tree-node i{
		padding: 5px 10px;
	}

	.content_left_tree{
		width:90%;
		flex-grow: 1;
		margin: 10px auto;
		overflow: auto;
	}

	.left_tree{
		display: flex;
		align-items:center;
		justify-content: space-between;
		width: 100%;
		font-size: 15px;
	}

	/* 右侧 */
	.content_right{
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	.content_right2{
		flex-grow: 1;
		display: flex;
		background-color: #FFFFFF;
		flex-direction: column;
		color: #909399;
	}

	.content_right2>div{
		text-align: center;
	}

	/* 右侧-底部 */
	.content_right_bottom{
		flex-grow: 1;
		height: 0px;
		width: 100%;
		background-color: #FFFFFF;
	}

	.content_right_bottom>div{
		display: flex;
		flex-direction: column;
		width: 96%;
		height: 95%;
	}

	.content_right_bottom_title{
		font-weight: 600;
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 10px;
	}

	.content_right_bottom_page{
		margin-top: 12px;
	}

	.Table-column{
		height: 26px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.table_button{
		flex-wrap: wrap;
	}
	
	.table_button>div{
		margin:2px;
	}

	.ValueHeig>>>.el-input__inner{
		padding:0px;
		text-align: center;
	}





	.TimeTemplateList {
		width: 84%;
		margin-left: 10px;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}

	.el-pagination {
		margin-top: 15px;
	}

	.from {
		width: 100%;
		display: flex;
	}


	.TimeTemplate {
		width: 255px;
		padding-right: 15px;
		border-right: 1px solid #eeeeee;
	}

	.TimeTemplate_input {
		display: flex;
		margin-bottom: 10px;
	}

	.TimeTemplate_input button {
		margin-left: 15px;
	}

	.el-date-editor.el-input,
	.el-date-editor.el-input__inner {
		width: 100%;
	}
	
	.ValueHeig{
		height: 32px;
	}
</style>