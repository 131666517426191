<template>
	<div class="floorblacklist" v-loading="PageLoad.BlacklistLoad">
		<div class="Top_menu boxshadow">
			<div class="flex-items-center Card1">
				<!-- 左 -->
				<i class="el-icon-search card_icon"></i>
				<el-input maxlength="32" size="medium" v-model="value" style="width: 250px;"  :placeholder="$t('LiftFloorBlacklist.SearchTips')"></el-input>
				<el-button slot="append" size="mini" icon="el-icon-search" @click="LiftAreaBlacklist"></el-button>
				<el-button size="medium" type="primary" style="margin-left: 15px;" icon="el-icon-circle-plus-outline" :disabled="!userGroupDetails.RoleLiftAreaBlacklist" @click="visibleShow">{{$t('LiftFloorBlacklist.BlacklistConfig')}}</el-button>
			</div>
			<div>
				<!-- 右 -->
				<el-button size="medium" icon="el-icon-refresh-right" @click="LiftAreaBlacklist">{{$t('LiftFloorBlacklist.Refresh')}}</el-button>
			</div>
		</div>
		<div class="centre boxshadow">
			<div>
				<div class="centre_title">{{$t('LiftFloorBlacklist.TableName')}}</div>
				<el-table :data="EmployeesList" height="100%" class="centre_table">
					<el-table-column :label="$t('LiftFloorBlacklist.Card2')" prop="card" align="center"></el-table-column>	
					<el-table-column :label="$t('LiftFloorBlacklist.Name')" prop="name" align="center">
						<template slot-scope="scope">
							<span style="color:#409EFF" v-if="scope.row.sex==1">{{ scope.row.name }}</span>
							<span style="color:#F56C6C;" v-if="scope.row.sex==2" type="danger">{{ scope.row.name }}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('LiftFloorBlacklist.Number')" prop="number" align="center"></el-table-column>
					<el-table-column :label="$t('LiftFloorBlacklist.Department')" prop="departmentName" align="center"></el-table-column>
					<el-table-column :label="$t('LiftFloorBlacklist.Operation')" fixed="right" width="180" align="center">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" :content="$t('LiftFloorBlacklist.AddBlacklist')" placement="top" :enterable="false">
								<el-button type="info" size="mini" icon="el-icon-s-release" :disabled="!userGroupDetails.RoleLiftAreaBlacklist" @click="LiftAreaConfigQrCode(5,scope.row)"></el-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" :content="$t('LiftFloorBlacklist.RemoveBlacklist')" placement="top" :enterable="false">
								<el-button type="primary" size="mini" icon="el-icon-s-promotion" :disabled="!userGroupDetails.RoleLiftAreaBlacklist" @click="LiftAreaConfigQrCode(6,scope.row)"></el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		
		<!-- 弹框 -->
		<!-- 黑名单配置码 -->
			<el-dialog :title="$t('LiftFloorBlacklist.BlacklistConfig')" :visible.sync="visible" top="3%" width="65%" :close-on-click-modal="false">
				<div style="display: flex;">
					<div style="width: 20%;margin-right: 2%;">
						<el-card shadow="hover" v-loading="PageLoad.DepartmentLoad">
							<div class="Area_input">
								<el-input maxlength="32" size="small" :placeholder="$t('LiftFloorBlacklist.SearchDepartment')" v-model="filterTextDepartment"></el-input><el-button @click="Department();ChooseDepartment('');" style="margin-left: 5px;" size="mini">{{$t('LiftFloorBlacklist.Refresh')}}</el-button>
							</div>
							<el-tree :data="DepartmentList" :filter-node-method="filterNodeDepartment" ref="Department" node-key="departmentId"
							 default-expand-all :expand-on-click-node="false" icon-class="el-icon-s-promotion" style="height:265px;margin-top: 5px;overflow:auto;">
								<span class="custom-tree-node" slot-scope="{ data }" style="height: 100%;display: flex;align-items: center;" @click="ChooseDepartment(data.departmentCode)" >
									<div style="width: 100%;">{{ data.departmentName }}</div>
								</span>
							</el-tree>
						</el-card>
					</div>
					<div style="width:78%">
						<el-transfer style="display: flex;"
						v-loading="PageLoad.EmployeesLoad"
						@change="handleChange"
						filterable
						:titles="[$t('LiftFloorBlacklist.Unselected'), $t('LiftFloorBlacklist.HasChosen')]"
						:filter-method="EmployeesfilterMethod"
						:filter-placeholder="$t('LiftFloorBlacklist.SearchTips')"
						v-model="EmployeesValue"
						:data="EmployeesData">
						</el-transfer>
						
						<div style="display: flex;align-items: center;margin-top: 16px;">
							<el-pagination background layout="total,prev, pager, next" :page-size="1000" @current-change="EmployeesNumber" :total="EmployeesCount"></el-pagination>
						</div>
					  </div>
				</div>
				<div class="QrCode">
					<div v-loading="PageLoad.QrCodeLoad">
						<div class="NoQrCode" >
							<div v-if="QrCdoe==''"><i class="el-icon-picture-outline-round"></i></div>
							<qrcode v-else :value="QrCdoe" :options="{ width: 250 , errorCorrectionLevel: 'L'}"></qrcode>
						</div>
					</div>
					<div class="menu">
						<el-radio v-model="radio" @change="httpQrCode" border label="5">{{$t('LiftFloorBlacklist.AddBlacklist')}}</el-radio>
						<el-radio v-model="radio" @change="httpQrCode" border label="6">{{$t('LiftFloorBlacklist.RemoveBlacklist')}}</el-radio>
						<div class="menu_hint">
							<div>1.{{$t('LiftFloorBlacklist.Describe1')}}</div>
							<div>2.{{$t('LiftFloorBlacklist.Describe2')}}</div>
							<div>3.{{$t('LiftFloorBlacklist.Describe3')}}</div>
							<div>4.{{$t('LiftFloorBlacklist.Describe4')}}</div>
							<div>5.{{$t('LiftFloorBlacklist.Describe5')}}</div>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="addupdLiftAreaBlacklist(radio)" :loading="ButtonLoad.AddDeleteBlacklist" type="primary">{{$t('LiftFloorBlacklist.Confirm')}}</el-button>
			    </span>
			</el-dialog>
		
			<!-- 黑名单配置码 -->
			<el-dialog :title="title" :visible.sync="BlacklistQrCode" width="25%" :close-on-click-modal="false">
				<div class="ConfigQrForme" v-loading="PageLoad.QrCodeLoad" >
					<div v-if="Type==5">
						<qrcode :value="ConfigQrCode" :options="{ width: 300 , errorCorrectionLevel: 'L'}"></qrcode>
						<div class="ConfigQrFormeDescribe">{{$t('LiftFloorBlacklist.Describe6')}}</div>
					</div>
					<div v-if="Type==6">
						<qrcode :value="ConfigQrCode" :options="{ width: 300 , errorCorrectionLevel: 'L'}"></qrcode>
						<div class="ConfigQrFormeDescribe">{{$t('LiftFloorBlacklist.Describe7')}}</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button v-if="Type==5" @click="BlacklistQrCode = false">{{$t('LiftFloorBlacklist.Off')}}</el-button>
					<el-button v-if="Type==6" @click="ShiftOutLiftAreaBlacklist([RecordID])">{{$t('LiftFloorBlacklist.Confirm')}}</el-button>
				</span>
			</el-dialog>
	</div>
</template>

<script>
	export default {
		watch: {
			//部门查询过滤使用
			filterTextDepartment(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.Department.filter(val);
			},
		},
		data() {
		  return {
			//权限组
			userGroupDetails: {
				RoleLiftAreaBlacklist: false, //黑名单操作权
			},
			// 页面加载
			PageLoad:{
   				BlacklistLoad: false, //加载状态
				DepartmentLoad:false, //部门加载状态
				EmployeesLoad:false, //人员加载状态
				QrCodeLoad:false,//配置码加载状态
			},
			// 按钮加载状态
			ButtonLoad:{
				//新增删除黑名单
 				AddDeleteBlacklist: false,
			},
			//表格参数
			EmployeesList:[],//人员列表
			IdData:[],//选中的人员ID
			InputValue:"",//条件值
			visible:false,//弹框状态
			EmployeesListSize:0,//数量
			value:"",//条件值
			
			// 黑名单弹框
			BlacklistQrCode:false,
			QrEmployeesData:[],//黑名单
			QrCdoe:"",
			Type:"5",
			ConfigQrCode:"0",
			title:"",
			radio:"5",
			RecordID:0,//ID
			
			//弹框参数
			filterTextDepartment:"",//部门搜索值
			DepartmentList:[],//部门列表
			DepartmentCode:"",//部门编码
			defaultProps: { //部门列表参数
				children: 'children',
				label: 'departmentName'
			},
			
			EmployeesPages:0,//页数
			EmployeesData:[],//人员列表
			EmployeesDataList:[],//人员列表
			EmployeesValue:[],//选中的人员
			LastEmployeesValue:[],//上一次选择的人员
			EmployeesCount:0,//人员数量
			EmployeesTreeload:false,//人员加载动画
			EmployeesfilterMethod(query, item) {//人员列表
				return item.pinyin.indexOf(query) > -1;
			},
		  };
		},
		mounted() {
			//获取权限
			this.Role();
			//加载黑名单
			this.LiftAreaBlacklist();
		},
		methods: 
		{
			//获取操作权
			Role() {
				//获操作取权限组
				var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
				for (var i = 0; i < userGroupDetails.length; i++) {
					if (userGroupDetails[i].roleName == "LiftAreaBlacklist") { //操作权
						this.userGroupDetails.RoleLiftAreaBlacklist = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
				}
			},
			//查询黑名单人员
			async LiftAreaBlacklist() {
				try
				{
					this.PageLoad.BlacklistLoad = true;
					var {data: res} = await this.$http.post('LiftAreaBlacklist', {"value":this.value})
					if (res.resultCode == 1) 
					{
						this.EmployeesList = JSON.parse(res.body);
					}
					this.PageLoad.BlacklistLoad = false;
				}
				catch (err)
				{
					this.PageLoad.BlacklistLoad = false;
					console.log(err)
				}
			},
			
			//显示添加黑名单
			visibleShow(){
				this.QrCdoe = "";
				this.QrEmployeesData=[];
				this.visible = true;
				this.EmployeesPages = 1;
				this.EmployeesValue = [];
				this.Department();//部门
				this.ChooseDepartment('');//选择部门
			},
			
			//部门过滤
			filterNodeDepartment(value, data) {
				if (!value) return true;
				return data.departmentName.indexOf(value) !== -1;
			},
			
			//查询部门
			async Department() {
				try 
				{
					this.PageLoad.DepartmentLoad = true;
					var {data: res} = await this.$http.post('Departments')
					if (res.resultCode == 1)//成功
					{ 
						this.DepartmentList = JSON.parse(res.body);
					}
					this.PageLoad.DepartmentLoad = false;
				} 
				catch (err)
				{
					this.PageLoad.DepartmentLoad = false;
					console.log(err)
				}
			},
			
			//选择部门
			ChooseDepartment(DepartmentCode){
				this.DepartmentCode = DepartmentCode;
				this.EmployeesPages = 1;
				this.Employees();
			},
			
			//查询人员
			async Employees() {
				try
				{
					this.PageLoad.EmployeesLoad = true;
					var {data: res} = await this.$http.post('SelectEmployees', {"DepartmentCode": "" + this.DepartmentCode + "","Count":1000,"Pages":this.EmployeesPages,"Type":0,"Value":"","EmployeeId":0})
					if (res.resultCode == 1) {
						var body = JSON.parse(res.body);
						var Employees = [];
						this.EmployeesDataList = body;
						this.EmployeesCount = body.length>0 ? body[0].size:0;
						for(var i = 0;i<body.length;i++)
						{
							Employees[i] = { key: body[i].employeeId,label:body[i].number+" - "+body[i].name,pinyin:body[i].number+","+body[i].name+","+body[i].q+","+body[i].c+","+body[i].k}
						}
						this.EmployeesData = Employees;
					}
					this.PageLoad.EmployeesLoad = false;
				}
				catch (err)
				{
					this.PageLoad.EmployeesLoad = false;
					console.log(err)
				}
			},
			
			//人员分页页数
			EmployeesNumber(val) {
				this.EmployeesPages = val;
				this.Employees();
			},
			
			//右侧列表元素变化时触发
			async handleChange(data){
				if(data.length>0 &&data.length<7)
				{
					this.PageLoad.QrCodeLoad = true;
					var QrEmployeesData = this.QrEmployeesData;
					var EmployeesDataList = this.EmployeesDataList;
					for(var i=0;i<data.length;i++)
					{
						for(var k=0;k<EmployeesDataList.length;k++)
						{
							if(data[i]==EmployeesDataList[k].employeeId)
							{
								if(EmployeesDataList[k].c!='' && EmployeesDataList[k].c!=undefined)
								{
									QrEmployeesData[QrEmployeesData.length] = {"employeeId":EmployeesDataList[k].employeeId,"q":"","c":EmployeesDataList[k].c};
								}
							}
						}
					}
					this.QrEmployeesData = this.minus(QrEmployeesData,data);
					this.httpQrCode();
				}
				else
				{
					this.QrEmployeesData = [];
					this.QrCdoe = "";
				}
			},
			
			//请求服务获取配置码
			async httpQrCode(){
				try
				{
					var QrEmployeesData = this.QrEmployeesData;
					var Data = [];
					for(var i=0;i<QrEmployeesData.length;i++)
					{
						//转16进制
						var strHex = Number(QrEmployeesData[i].c).toString(16);
						var strHex1 = strHex.substring(0,2);
						var strHex2 = strHex.substring(2,4);
						var strHex3 = strHex.substring(4,6);
						var strHex4 = strHex.substring(6,8);
						var Card = strHex4+""+strHex3+""+strHex2+""+strHex1;
						Data[Data.length] = {"employeeId":QrEmployeesData[i].employeeId,"c":parseInt(Card,16),"q":QrEmployeesData[i].q};
					}
					if(Data.length==0) return this.PageLoad.QrCodeLoad = false;
					var parameter = {"Type":this.radio,"Employees":Data}
					var {data: res} = await this.$http.post('LiftAreaConfigQrCode',parameter)
					if (res.resultCode == 1)//成功
					{
						this.QrCdoe = res.body;
					}
					this.PageLoad.QrCodeLoad = false;
				}
				catch (err)
				{
					console.log(err)
				}
			},
			
			
			minus(array1,array2) {
				var result = [];
				for(var i = 0; i < array2.length; i++)
				{
				    for(var j = 0; j < array1.length; j++)
					{
				        if(array2[i] == array1[j].employeeId)
						{
				            result[result.length] = array1[j];
				            break;
				        }
				    }
				}
				return result;
			},
			
			//新增删除黑白名单
			addupdLiftAreaBlacklist(Type){
				if(Type==5)
				{
					this.AddLiftAreaBlacklist();
				}
				else
				{
					this.deleteLiftAreaBlacklist();
				}
			},
			
			
			//新增黑名单人员
			async AddLiftAreaBlacklist() {
				try
				{
					if (this.EmployeesValue.length==0) return this.$message({message: this.$t('LiftFloorBlacklist.SelectEmployee'),type: 'error',offset:10});
					this.ButtonLoad.AddDeleteBlacklist = true;
					var {data: res} = await this.$http.post('AddLiftAreaBlacklist', {"EmployeeId": this.EmployeesValue})
					if (this.FilteringOperation(res)) 
					{
						if(res.resultCode=="1")
						{
							this.$message({message: this.$t('LiftFloorBlacklist.AddSucceed'),type: 'success',offset:10});
							this.visible = false;//关闭弹框
							this.EmployeesValue = [];//清空选择的人员
							this.LiftAreaBlacklist();//加载黑名单
						}
						else
						{
							this.$message({message: this.$t('LiftFloorBlacklist.AddFailure'),type: 'error',offset:10});
						}
					}
					this.ButtonLoad.AddDeleteBlacklist = false;
				}
				catch (err)
				{
					this.ButtonLoad.AddDeleteBlacklist = false;
					console.log(err)
				}
			},
			
			//删除黑名单
			async deleteLiftAreaBlacklist(){
				try
				{
					if (this.EmployeesValue.length==0) return this.$message({message: this.$t('LiftFloorBlacklist.SelectEmployee'),type: 'error',offset:10});
					this.ButtonLoad.AddDeleteBlacklist = true;
					var {data: res} = await this.$http.post('deleteLiftAreaBlacklist', {"Employee": this.EmployeesValue})
					if (this.FilteringOperation(res)) 
					{
						if(res.resultCode=="1")
						{
							this.$message({message: this.$t('LiftFloorBlacklist.RemoveSucceed'),type: 'success',offset:10});
							this.visible = false;//关闭弹框
							this.EmployeesValue = [];//清空选择的人员
							this.LiftAreaBlacklist();//加载黑名单
						}
						else
						{
							this.$message({message: this.$t('LiftFloorBlacklist.RemoveFailure'),type: 'error',offset:10});
						}
					}
					this.ButtonLoad.AddDeleteBlacklist = false;
				}
				catch (err)
				{
					this.ButtonLoad.AddDeleteBlacklist = false;
					console.log(err)
				}
			},
			
			//移除黑名单
			async ShiftOutLiftAreaBlacklist(RecordID) {
				if (RecordID.length==0) return this.$message({message: this.$t('LiftFloorBlacklist.SelectEmployee'),type: 'error',offset:10});
				this.$confirm(this.$t('LiftFloorBlacklist.WhetherRemove'),this.$t('LiftFloorBlacklist.Hint'), {type: 'warning'}).then(async () => {
					try
					{
						var {data: res} = await this.$http.post('ShiftOutLiftAreaBlacklist', {"RecordID": RecordID})
						if (this.FilteringOperation(res)) 
						{
							if(res.resultCode==1)
							{
								this.$message({message: this.$t('LiftFloorBlacklist.RemoveSucceed'),type: 'success',offset:10});
								this.BlacklistQrCode = false;//关闭弹框
								this.EmployeesValue = [];//清空选择的人员
								this.LiftAreaBlacklist();//加载黑名单
							}
							else
							{
								this.$message({message: this.$t('LiftFloorBlacklist.RemoveFailure'),type: 'error',offset:10});
							}
						}
					}
					catch (err)
					{
						console.log(err)
					}
				}).catch(() => {
					//取消
				});
			},
			
			//配置码
			async LiftAreaConfigQrCode(Type,Employees){
				try
				{
					this.PageLoad.QrCodeLoad = true;
					this.RecordID = Employees.recordID;
					this.Type = Type;
					this.title = Type==5?this.$t('LiftFloorBlacklist.AddBlacklist'):this.$t('LiftFloorBlacklist.RemoveBlacklist');
					this.BlacklistQrCode = true;
					
					var Data = {};
					//转16进制
					var strHex = Number(Employees.card).toString(16);
					var strHex1 = strHex.substring(0,2);
					var strHex2 = strHex.substring(2,4);
					var strHex3 = strHex.substring(4,6);
					var strHex4 = strHex.substring(6,8);
					var Card = strHex4+""+strHex3+""+strHex2+""+strHex1;
					
					var parameter = {"Type":Type,"Employees":[{"employeeId":Employees.employeeId,"q":"","c":parseInt(Card,16)}]}
					var {data: res} = await this.$http.post('LiftAreaConfigQrCode',parameter)
					if (res.resultCode == 1)//成功
					{
						this.ConfigQrCode = res.body;
					}
					this.PageLoad.QrCodeLoad = false;
				} 
				catch (err)
				{
					this.PageLoad.QrCodeLoad = false;
					console.log(err)
				}
			},
			
			//操作过滤
			FilteringOperation(res){
				var State = true;
				if (res.stateCode == 0) {
					State = false;
					this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
				} else if (res.stateCode == 2) {
					State = false;
					this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
				} else if (res.stateCode == 3) {
					State = false;
					this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
				}
				return State;
			}
		}
	};
</script>

<style scoped>
	/* 框架 */
	.floorblacklist{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	
	.Top_menu{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 18px;
		margin-bottom: 20px;
		background-color: #FFFFFF;
	}

	.card_icon{
		margin-top: -3px;
		font-size: 25px;
		margin-left: 5px;
		margin-right:10px;
		color: #909399;
	}

	.Card1>>>.el-input__inner {
    	border: 0;
		font-size: 15px;
  	}

	.Area_input{
		display: flex;
	}

	.centre{
		flex-grow: 1;
		height: 0px;
		background-color: #FFFFFF;
		display: flex;
	}

	.centre>div{
		height: 97%;
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.centre_title{
		font-weight: 600;
		color: #6f6f6f;
		font-size: 14.5px;
		margin: 20px 20px;
	}

	.centre_table{
		width: 97.5%;
		margin: 0px auto;
	}

	.QrCode{
		width: 100%;
		margin-top: 15px;
		display: flex;
		align-items: center;
		border: 1px solid #dee3ec;
		border-radius: 5px;
	}
	.ConfigQrForme{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.NoQrCode{
		height: 300px;
		width: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.NoQrCode>div{
		width: 81%;
		height: 81%;
		display:flex;
		align-items: center;
		justify-content: center;
		background-color: #F5F7FA;
	}
	.NoQrCode i{
		font-size: 30px;
	}
	.menu{
		height: 220px;
	}
	.menu_hint{
		margin-top: 20px;
	}
	.menu_hint>div{
		margin: 15px 0px;
	}
	.ConfigQrFormeDescribe{
		width: 300px;
		text-align: center;
	}

	/* 穿梭框样式修改 */
	.el-transfer>>>.el-transfer-panel{
		width: 45%;
	}

	.el-transfer>>>.el-transfer-panel__body{
		height: 290px;
	}

	.el-transfer>>>.el-transfer__buttons{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.el-transfer>>>.el-transfer__button{
		margin: 0px 5px;
	}

	.el-transfer>>>.el-transfer-panel__list{
		height: 240px;
    	overflow: auto;
	}
</style>
