<template>
  <div class="from_ground flex-center">
    <div class="copyright"></div>
    <div class="from flex-center">
      <!-- 左侧内容 -->
      <div class="leftFrom">
        <img class="logo" src="../assets/images/logo.png" />
      </div>

      <!-- 右侧内容 -->
      <div class="rightFrom">
        <!-- 头部语言选择 -->
        <div class="rightFrom_language">
          <el-dropdown @command="language">
            <span class="el-dropdown-link">
              {{ $t("Login.LanguageName")
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">简体中文</el-dropdown-item>
              <el-dropdown-item command="2">繁體中文</el-dropdown-item>
              <el-dropdown-item command="3">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!-- 登录表单 -->
        <div v-if="Index == 1" class="form flex-center">
          <div>
            <el-form
              ref="loginFormRef"
              :model="loginForm"
              :rules="loginFormRules"
            >
              <div :class="ISIE ? 'from_title2' : 'from_title'">
                <h2>{{ $t("Login.TitleName") }}</h2>
                <div v-if="ISIE" class="ISIE">
                  <i class="el-icon-warning"></i>{{ $t("Login.IEMessage") }}
                </div>
              </div>
              <transition name="el-zoom-in-bottom">
                <el-alert
                  @close="alertClose"
                  style="margin-bottom: 15px"
                  v-if="LoginButtonPopover.LoginNameStatu"
                  :title="LoginButtonPopover.LoginNameStatuName"
                  type="error"
                  center
                  show-icon
                ></el-alert>
              </transition>
              <div class="UserName">
                <el-form-item prop="UserName">
                  <el-input
                    maxlength="32"
                    v-model="loginForm.UserName"
                    prefix-icon="el-icon-user"
                    :placeholder="$t('Login.UserNameHint')"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="UserPassword">
                <el-form-item prop="Password">
                  <el-input
                    maxlength="32"
                    v-model="loginForm.Password"
                    prefix-icon="el-icon-unlock"
                    :placeholder="$t('Login.PasswordHint')"
                    type="password"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="grop">
                <el-checkbox
                  v-model="checked"
                  :label="$t('Login.Remember')"
                  name="type"
                ></el-checkbox>
              </div>
              <div class="from_button">
                <el-button
                  type="primary"
                  @click="login"
                  @keyup.enter="login"
                  @keyup.enter.native="submit"
                  :loading="LoginButton.LoginStatu"
                  >{{
                    LoginButton.LoginStatu
                      ? $t("Login.LoginsNutton")
                      : $t("Login.LoginNutton")
                  }}</el-button
                >
              </div>
            </el-form>
          </div>
        </div>

        <div v-if="Index == 2" class="form flex-center">
          <div>
            <div class="activate-Title">
              <i class="el-icon-key"></i> {{ $t("Login.SoftwareActivate") }}
            </div>
            <div>
              <el-alert
                v-if="activateStatus == 1"
                :title="$t('Login.ActivateStatus1')"
                type="success"
                center
                :closable="false"
                show-icon
              ></el-alert>
              <el-alert
                v-if="activateStatus == 2"
                :title="$t('Login.ActivateStatus2')"
                type="success"
                center
                :closable="false"
                show-icon
              ></el-alert>
              <el-alert
                v-if="activateStatus == 3"
                :title="$t('Login.ActivateStatus3')"
                type="error"
                center
                :closable="false"
                show-icon
              ></el-alert>
              <el-alert
                v-if="activateStatus == 4"
                :title="$t('Login.ActivateStatus4')"
                type="error"
                center
                :closable="false"
                show-icon
              ></el-alert>
            </div>
            <el-form
              ref="RuleForm"
              :rules="Rules"
              :model="Form"
              label-position="top"
              label-width="100px"
            >
              <el-form-item :label="$t('Login.ActivationCode')" prop="keyCode">
                <el-input
                  maxlength="32"
                  v-model="Form.keyCode"
                  :placeholder="$t('Login.ActivationCodeMessage')"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="activate-button">
              <el-button
                type="primary"
                @click="activate"
                :loading="activateStatusLoad"
              >
                {{ $t("Login.Activate") }}
              </el-button>
            </div>
          </div>
        </div>

        <!-- 激活 -->
        <div class="rightFrombBottom">
          <el-link type="primary" v-if="Index == 1" @click="Index = 2"
            >{{ $t("Login.SoftwareActivate") }} ></el-link
          >
          <el-link type="primary" v-if="Index == 2" @click="Index = 1"
            >{{ $t("Login.LoginNutton") }} ></el-link
          >
        </div>
      </div>
    </div>
    <!-- 版权 -->
    <div class="copyright flex-center"></div>
  </div>
</template>

<script>
export default {
  data() {
    // 验证登录名
    var UserName_verify = (rule, value, callback) => {
				if (!value) return callback(new Error(this.$t("Login.UserNameHint")));
        callback();
		};
    // 验证密码
    var Password_verify = (rule, value, callback) => {
				if (!value) return callback(new Error(this.$t("Login.Remember")));
        callback();
		};
    // 验证激活码
    var keyCode_verify = (rule, value, callback) => {
				if (!value) return callback(new Error(this.$t("Login.ActivationCodeMessage")));
        callback();
		};
    return {
      //表单类型
      Index: 1,
      //表单数据绑定对象
      loginForm: {
        UserName: "",
        Password: "",
      },
      //是否是IE内核浏览器
      ISIE: false,
      //表单验证规则
      loginFormRules: {
        //验证用户名
        UserName: [
          {
            validator: UserName_verify,
            trigger: "change",
          },
        ],
        //验证密码
        Password: [
          {
            validator: Password_verify,
            trigger: "change",
          },
        ],
      },
      //记住密码
      checked: false,
      //登录按钮状态
      LoginButton: {
        LoginStatu: false,
      },
      LoginButtonPopover: {
        //登录结果显示提示信息
        LoginNameStatuName: "",
        //主题
        type: "error",
        //是否登录结果显示提示
        LoginNameStatu: false,
      },
      //表单参数
      Form: {
        //激活码
        keyCode: "",
      },
      //表单校验
      Rules: {
        keyCode: [
          {
            validator: keyCode_verify,
            trigger: "change",
          },
        ],
      },
      //激活结果
      activateStatus: 0,
      //激活状态
      activateStatusLoad: false,
    };
  },
  //创建实例时执行
  created() {
    //回车事件
    this.submit();
  },
  //页面加载后执行
  mounted: function () {
    //读取保存的Cookie
    this.getCookie();
    //判断是否IE浏览器
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
      this.ISIE = true;
    } else {
      this.ISIE = false;
    }
  },
  //所有方法
  methods: {
    //回车事件
    submit() {
      document.onkeydown = (e) => {
        if (e.key === "Enter") {
          this.login();
        }
      };
    },
    //语言选择
    language(command) {
      if (command == 1) {
        (this.$i18n.locale = "J"), localStorage.setItem("languages", "J");
      } else if (command == 2) {
        (this.$i18n.locale = "F"), localStorage.setItem("languages", "F");
      } else {
        (this.$i18n.locale = "E"), localStorage.setItem("languages", "E");
      }
    },
    // 登录校验
    async login() {
      this.LoginButtonPopover.LoginNameStatu = false; //隐藏结果提示
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        this.LoginButton.LoginStatu = true; //登录按钮为登录中状态
        try {
          //请求服务验证账号密码
          const { data: res } = await this.$http.post("verify", this.loginForm);
          this.LoginButton.LoginStatu = false; //登录按钮为未登录状态
          this.LoginButtonPopover.LoginNameStatu = true; //是否登录结果显示提示
          if (res.verifyState == 0) {
            //账号密码错误
            this.Popover(this.$t("Login.AccountPasswordError"), true, "");
          } else if (res.verifyState == 1) {
            //登录成功
            //是否存储账号密码
            if (this.checked) {
              //将账号密码存Cookie
              this.setCookie(
                this.loginForm.UserName,
                this.loginForm.Password,
                365
              );
            } else {
              //将账号密码存Cookie
              this.setCookie(
                this.loginForm.UserName,
                this.loginForm.Password,
                0
              );
            }
            //将语言信息保存到sessionStorage中
            window.sessionStorage.setItem("language", res.language);
            //跳转
            this.$router.push("/index");
          } else if (res.verifyState == 2) {
            //公司授权过期
            this.Popover(this.$t("Login.AuthorizationExpires"), true, ""); //提示;
          } else if (res.verifyState == 3) {
            //用户状态为不可登录
            this.Popover(this.$t("Login.Forbid"), true, ""); //提示;
          } else if (res.verifyState == 4) {
            //异常
            this.Popover(this.$t("Login.Abnormal"), true, ""); //提示;
          }
        } catch (err) {
          console.log(err)
          this.LoginButton.LoginStatu = false; //登录按钮状态
          this.Popover(this.$t("Login.ConnectionTimeout"), true, ""); //隐藏提示
        }
      });
    },

    //关闭登录结果提示
    alertClose() {
      this.Popover("", false, ""); //隐藏提示
    },

    //隐藏登录结果提示
    //Name-登录结果显示提示信息，State-是否登录结果显示提示，Type-主题
    Popover(Name, State, Type) {
      var thi = this;
      if (Type != "") {
        this.LoginButtonPopover.type = Type; //主题
      }
      this.LoginButtonPopover.LoginNameStatuName = Name; //登录结果显示提示信息
      this.LoginButtonPopover.LoginNameStatu = State; //是否登录结果显示提示
    },

    //记住登录的账号密码
    //参数：用户名-密码-到期天数
    setCookie(UserName, Password, num) {
      const time = new Date();
      time.setTime(time.getTime() + 24 * 60 * 60 * 1000 * num);
      //加上window.btoa是为了对用户名和密码进行编码，不让cookie明文展示
      window.document.cookie = `userName=${window.btoa(
        UserName
      )}; path=/; expires=${time.toGMTString()}`;
      window.document.cookie = `passWord=${window.btoa(
        Password
      )}; path=/; expires=${time.toGMTString()}`;
    },

    //读取cookie
    getCookie() {
      if (document.cookie.length > 0) {
        const cookieArr1 = document.cookie.split(";");
        for (let i in cookieArr1) {
          const cookieArr2 = cookieArr1[i].split("=");
          //避免输入中含有空格，消除文本框空格
          cookieArr2[0] = cookieArr2[0].replace(/\s+/g, "");
          cookieArr2[1] = cookieArr2[1].replace(/\s+/g, "");
          if (cookieArr2[0] == "userName") {
            //读取cookie然后进行解码
            this.checked = true;
            this.loginForm.UserName = window.atob(cookieArr2[1]);
          } else if (cookieArr2[0] == "passWord") {
            this.loginForm.Password = window.atob(cookieArr2[1]);
          }
        }
      }
    },
    //激活
    activate() {
      this.$refs.RuleForm.validate(async (valid) => {
        if (!valid) return;
        try {
          //激活
          this.activateStatus = 0;
          this.activateStatusLoad = true;
          const { data: res } = await this.$http.post("activate", this.Form);
          this.activateStatusLoad = false;
          if (res.Code == "100001") {
            //激活成功
            this.activateStatus = 1;
          } else if (res.Code == "100002") {
            //更新成功
            this.activateStatus = 2;
          } else if (res.Code == "100003") {
            //信息不一致
            this.activateStatus = 3;
          } else {
            //失败
            this.activateStatus = 4;
          }
        } catch (err) {
          //失败
          this.activateStatus = 4;
          this.activateStatusLoad = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.from_ground {
  background-color: #3b5d78;
  background-size: 100% 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.from {
  width: 95%;
  height: 100%;
  box-shadow: #2a4963 0px 0px 6px 0px;
}

.leftFrom {
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url("../assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;
}

.logo {
  margin: 20px;
}

@media (max-width: 715px) {
  .logo {
    display: none;
  }
}

.rightFrom {
  display: flex;
  flex-direction: column;
  width: 490px;
  background-color: #ffffff;
}

.rightFrom_language {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  height: 40px;
}

.el-dropdown-link {
  cursor: pointer;
}

.form {
  flex-grow: 1;
  width: 100%;
}

.form > div {
  margin-top: -20%;
  width: 60%;
}

.from_title {
  height: 80px;
  text-align: center;
}

.from_title2 {
  height: 100px;
  text-align: center;
}

.ISIE {
  font-size: 13px;
  color: #f56c6c;
}

.UserName {
  margin: 0px auto;
  color: #989898;
  height: 45px;
}

.UserPassword {
  margin: 0px auto;
  margin-top: 30px;
  color: #989898;
  height: 45px;
}

.grop {
  height: 50px;
  display: flex;
  align-items: center;
}

.from_button {
  margin: 0px auto;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.from_button button {
  width: 99%;
  font-size: 18px;
}

.inputTitle {
  width: 25%;
}

.input {
  width: 75%;
}

.other {
  font-size: 15px;
  color: #8a8a8a;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.other span {
  color: #4cc3ff;
}

.ioc {
  width: 10%;
}

.from > div {
  height: 100%;
}

.copyright {
  text-align: center;
  width: 100%;
  font-size: 15px;
  color: #ffffff;
  height: 47px;
}

.LoginNameStatu {
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
}

.LoginNameStatu i {
  font-size: 18px;
  margin-right: 10px;
}

.rightFrombBottom {
  height: 40px;
  display: flex;
  padding-right: 20px;
  align-items: center;
  justify-content: flex-end;
  color: #409eff;
  font-size: 15px;
}

.activate-Title {
  text-align: center;
  font-size: 25px;
  margin: 20px 0px;
}

.activate-Title > i {
  color: #409eff;
}

.activate-button {
  margin-top: 30px;
}

.activate-button > button {
  width: 100%;
}
</style>
