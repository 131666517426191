<template>
	<div class="visitor" v-loading="PageLoad.Load">
		<div class="Operation">
			<div class="card1 boxshadow">
				<el-button size="medium" :plain="CheckStatus==0?false:true" @click="CheckStatus=0;VisitorApplyDetails()" type="primary">{{$t('Visitor.Status1')}}</el-button>
				<el-button size="medium" :plain="CheckStatus==1?false:true" @click="CheckStatus=1;VisitorApplyDetails()" type="primary">{{$t('Visitor.Status2')}}</el-button>
				<el-button size="medium" :plain="CheckStatus==2?false:true" @click="CheckStatus=2;VisitorApplyDetails()" type="primary">{{$t('Visitor.Status3')}}</el-button>
			</div>
			<div class="card2 boxshadow">
				<div v-if="Type =='1'" class="right_top_search flex-items-center">
					<i class="el-icon-search"></i>
					<el-input maxlength="32"  v-model="Value" size="medium" style="width:355px" :placeholder="$t('Visitor.SearchTips')"></el-input>
				</div>

				<div v-if="Type=='2' || Type=='3'" class="right_top_search flex-items-center right_top_Date">
					<el-date-picker size="medium" v-model="Value" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" type="datetimerange" range-separator="-" :start-placeholder="$t('Visitor.StartDate')" :end-placeholder="$t('Visitor.EndDate')"></el-date-picker>
				</div>

				<!-- 条件类型 -->
				<div class="right_top_search_type flex-items-center">
					<el-button slot="append" size="mini" class="el-icon-search" @click="VisitorApplyDetails"></el-button>
					<i></i>
					<el-select size="medium" v-model="Type" @change="Value=''" slot="prepend">
						<el-option :label="$t('Visitor.NameApplicant')" value="1"></el-option>
						<el-option :label="$t('Visitor.ApplyTime')" value="2"></el-option>
						<el-option :label="$t('Visitor.VisitingTime')" value="3"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="content flex-center boxshadow">
			<div>
				<div class="content_right_bottom_title">{{$t('Visitor.VisitorsApply')}}</div>
				<el-table :data="VisitorList" height="100%">
					<el-table-column :label="$t('Visitor.Applicant')" width="250"  align="center">
						<template slot-scope="scope">
							{{scope.row.name}}
						</template>
					</el-table-column>
					<el-table-column :label="$t('Visitor.Interviewee')" prop="employeeName" align="center"></el-table-column>
					<el-table-column :label="$t('Visitor.ApplyForTime')" prop="applyTime" align="center"></el-table-column>
					<el-table-column :label="$t('Visitor.VisitingTime2')" prop="ArriveTime" align="center">
						<template slot-scope="scope">
							<div>{{scope.row.aCSStartTime}}</div>
							<div>{{scope.row.aCSEndTime}}</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('Visitor.ApplicationArea')" prop="visitorAreaName" align="center"></el-table-column>
					<el-table-column :label="$t('Visitor.ApplicationType')" prop="ArriveType" align="center">
						<template slot-scope="scope">
							<el-tag style="margin-right: 5px;" type="info" disable-transitions>{{scope.row.visitorReason}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column :label="$t('Visitor.CreateType')" prop="EstablishTypr" align="center">
						<template slot-scope="scope">
							<el-tag style="margin-right: 5px;" type="info" disable-transitions>{{scope.row.applySrc==1? $t('Visitor.WeChat'):$t('Visitor.VisitorsEquipment')}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column :label="$t('Visitor.Status')" prop="Status" align="center">
						<template slot-scope="scope">
							<el-tag v-if="scope.row.checkStatus==0"  disable-transitions>{{$t('Visitor.Status1')}}</el-tag>
							<el-tag type="success" v-if="scope.row.checkStatus==1"  disable-transitions>{{$t('Visitor.HasAgree')}}</el-tag>
							<el-tag type="danger" v-if="scope.row.checkStatus==2"  disable-transitions>{{$t('Visitor.NotPass')}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column :label="$t('Visitor.Operation')"  width="175" align="center">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" :content="$t('Visitor.Agree')" placement="top" :enterable="false">
								<el-button :disabled="getValidState(scope.row)" type="primary" size="mini" icon="el-icon-check" @click="AuthorizationDialog(scope.row.applyId)"></el-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" :content="$t('Visitor.Refuse')" placement="top" :enterable="false">
								<el-button  type="danger" size="mini" :disabled="!userGroupDetails.RoleFloorAuthority || getValidState(scope.row)" icon="el-icon-close" @click="RefuseDialog(scope.row)"></el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>

				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[20, 50, 100]"
				:page-size="Count" :current-page="Pages" layout="total, sizes, prev, pager, next, jumper" :total="VisitorListCount">
				</el-pagination>
			</div>
		</div>
		
		<!-- 授权弹框 -->
		<el-dialog :title="$t('Visitor.Agree')" :visible.sync="AuthorizationDialogState" top="10vh" width="50%" :close-on-click-modal="false">
			<div v-loading="PageLoad.DialogLoad">
				<div id="visitorapply">
					<div id="visitorapplyBody">
						<div>{{$t('Visitor.ApplyForTime')}}：{{VisitorDetails.applyTime}}</div>
						<div>
							{{$t('Visitor.VisitingArea')}}：{{VisitorDetails.visitorAreaName}}
							<el-tooltip class="item" effect="dark" :content="$t('Visitor.ChangeArea')" placement="right">
								<i class="el-icon-edit" @click="innerVisible()"></i>
							</el-tooltip>
						</div>
						<div>{{$t('Visitor.ApplicationType')}}：{{VisitorDetails.visitorReason}}</div>
						<div>
							{{$t('Visitor.VerifyLimit')}}：{{VisitorDetails.aCSTimes==0?$t('Visitor.DonLimit'):$t('Visitor.Limit')+VisitorDetails.aCSTimes+$t('Visitor.Time')}} 
							<el-tooltip class="item" effect="dark" :content="$t('Visitor.SetTimes')" placement="right">
								<i class="el-icon-edit" @click="innerVisible()"></i>
							</el-tooltip>
						</div>
						<div>{{$t('Visitor.NumberVisitors')}}：{{VisitorCount}}人</div>
						<div>{{$t('Visitor.CreateType')}}：{{VisitorDetails.applySrc==1?$t('Visitor.WeChat'):$t('Visitor.VisitorsEquipment')}}</div>
						<div>{{$t('Visitor.ApplicationRemarks')}}：{{VisitorDetails.visitorRemark!='' && VisitorDetails.visitorRemark!=undefined?VisitorDetails.visitorRemark:'无'}}</div>
						<div>
							{{$t('Visitor.ApprovalRemarks')}}：
							{{VisitorDetails.checkRemark!='' && VisitorDetails.checkRemark!=undefined?VisitorDetails.checkRemark:$t('Visitor.Not')}}
							<el-tooltip class="item" effect="dark" :content="$t('Visitor.EditApprovalRemarks')" placement="top">
								<i class="el-icon-edit" @click="innerVisible()"></i>
							</el-tooltip>
						</div>
						<div>
							{{$t('Visitor.ValidTime')}}：{{VisitorDetails.aCSStartTime}} - {{VisitorDetails.aCSEndTime}} ({{$t('Visitor.VisitingTime')}})
							<el-tooltip class="item" effect="dark" :content="$t('Visitor.EditValidTime')" placement="top">
								<i class="el-icon-edit" @click="innerVisible()"></i>
							</el-tooltip>
						</div>
					</div>
					<div>
						<el-tag v-if="VisitorDetails.checkStatus==0"  disable-transitions>{{$t('Visitor.Status3')}}</el-tag>
						<el-tag type="success" v-if="VisitorDetails.checkStatus==1"  disable-transitions>{{$t('Visitor.HasAgree')}}</el-tag>
						<el-tag type="danger"  v-if="VisitorDetails.checkStatus==2"  disable-transitions>{{$t('Visitor.NotPass')}}</el-tag>
					</div>
				</div>
				<div style="border:1px solid #EAEEFB;margin-top: 10px;">
					<div id="visitorapplydetails">
						<div  v-for="items in VisitorDetails.ApplyDetails" :key="items.photo">
							<div class="Photo">
								<el-image v-if="items.photo!='' && items.photo!=undefined" :src="img(VisitorDetails.companyId,items.photo)"></el-image>
								<div v-else>{{$t('Visitor.NoPhoto')}}</div>
							</div>
							<div class="applydetails">
								<div>{{$t('Visitor.Unit')}}：{{items.company}}</div>
								<div>{{$t('Visitor.Name')}}：{{items.name}}</div>
								<div>{{$t('Visitor.IDNumber')}}：{{items.iDNumber}}</div>
								<div>{{$t('Visitor.Phone')}}：{{items.telephone}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<el-dialog width="30%" :title="$t('Visitor.AlterationAuthority')" :visible.sync="innerVisibleState" append-to-body :close-on-click-modal="false">
				<div>
					<el-form ref="ruleForm" label-position="left" label-width="80px" class="demo-ruleForm">
						<el-form-item :label="$t('Visitor.VisitingArea')" prop="region">
							<el-select v-model="VisitorAreaValue" :placeholder="$t('Visitor.Select')" style="width: 100%;">
								<el-option
								v-for="item in VisitorArea"
								:key="item.visitorAreaId"
								:label="item.visitorAreaName"
								:value="item.visitorAreaId">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="$t('Visitor.VerifyLimit')">
							 <el-input-number style="width: 100%;" v-model="ACSTimesValue" :min="0" :max="timeMax" :label="$t('Visitor.VerifyLimit')"></el-input-number>
						</el-form-item>
						<el-form-item :label="$t('Visitor.ValidTime')">
							<el-date-picker v-model="StartEnd"
							:clearable="false"
							style="width: 100%;"
							type="datetimerange"
							value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']"
							range-separator="-"
							:start-placeholder="$t('Visitor.StartDate')"
							:end-placeholder="$t('Visitor.EndDate')">
							</el-date-picker>
						</el-form-item>
						<el-form-item :label="$t('Visitor.ApprovalRemarks')">
							<el-input
							type="textarea"
							:autosize="{ minRows: 3}"
							v-model="CheckRemark"
							maxlength="128"
							show-word-limit>
							</el-input>
						</el-form-item>
					</el-form>
				</div>
				<span slot="footer" class="dialog-footer">
				  <el-button @click="innerVisibleState = false">{{$t('Visitor.Cancel')}}</el-button>
				  <el-button type="primary"  @click="confirmUpdate()">{{$t('Visitor.Confirm')}}</el-button>
				</span>
			</el-dialog>
			
			<span slot="footer" class="dialog-footer">
			  <el-button @click="AuthorizationDialogState = false">{{$t('Visitor.Cancel')}}</el-button>
			  <el-button type="primary" :disabled="!userGroupDetails.RoleFloorAuthority" :loading="ButtonLoad.Agree" @click="ISCSUpdateVisitorApply()">{{$t('Visitor.Confirm')}}</el-button>
			</span>
		</el-dialog>
		
		<!-- 拒绝弹框 -->
		<el-dialog :title="$t('Visitor.RefuseApply')" :visible.sync="RefuseDialogState" width="30%" :close-on-click-modal="false">
		  <el-input
		    type="textarea"
			:autosize="{ minRows: 5}"
		    :placeholder="$t('Visitor.RefuseReason')"
		    v-model="CheckRemark"
		    maxlength="128"
		    show-word-limit>
		  </el-input>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="RefuseDialogState = false">{{$t('Visitor.Cancel')}}</el-button>
		    <el-button type="danger" :loading="ButtonLoad.Refuse" @click="ISCSrefuseVisitor()">{{$t('Visitor.Confirm')}}</el-button>
		  </span>
		</el-dialog>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				//权限组
				userGroupDetails: {
					RoleFloorAuthority: false, //操作权
				},
				// 页面加载
				PageLoad:{
   					Load: false, //加载状态
					DialogLoad: false, //弹出层加载状态
				},
				// 按钮加载状态
				ButtonLoad:{
					//同意
 					Agree: false,
					//拒绝
 					Refuse: false,
				},
				Type:"1",//条件类型
				Value:"",//条件值
				Pages:1,//页数
				Count:20,//条数
				CheckStatus:0,//申请状态
				VisitorListCount:0,//总条数
				VisitorList: [],//申请数据
				VisitorDetails:{},//申请详情
				VisitorCount:0,
				AuthorizationDialogState:false,//授权对话框
				
				innerVisibleState:false,//访客信息修改（嵌套弹出层）
				VisitorArea:[],//访客区域
				VisitorAreaValue:"",//选中的区域
				ACSTimesValue:0,//次数
				StartEnd:[],//日期
				
				RefuseDialogState:false,//拒绝弹框状态
				CheckRemark:"",//审批备注
				applyId:0,//申请ID
				name:"",//访客名称
				employeeName:"",//被访人名称
				timeMax:0,
			};
		},
		mounted() {
			//获取操作权
			this.Role();
			//查询访客申请
			this.VisitorApplyDetails();
			//查询访客区域
			this.ISCSVisitorArea();
		},
		//获取数量
		count(e){
			return e.length;
		},
		
		//自定义事件
		methods: {
			//获取操作权
			Role() {
				//获操作取权限组
				var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
				for (var i = 0; i < userGroupDetails.length; i++) {
					if (userGroupDetails[i].roleName == "FloorAuthority") { //部门操作权
						this.userGroupDetails.RoleFloorAuthority = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
				}
			},
			//照片
			img(companyId,photo) {
				try 
				{
					console.log(this.$image+"/Visitor/"+companyId+"/"+photo)
					return this.$image+"/Visitor/"+companyId+"/"+photo;
				}
				catch (e) 
				{
					return ''
				}
			},
			
			//分页条数
			handleSizeChange(val) {
				this.Count = val;
				this.VisitorApplyDetails();
			},
			
			//分页页数
			handleCurrentChange(val) {
				this.Pages = val;
				this.VisitorApplyDetails();
			},
			
			//查询访客申请
			async VisitorApplyDetails(){
				try
				{
					this.PageLoad.Load = true;
					var {data: res} = await this.$http.post('VisitorApplyDetails', {"Pages":this.Pages,"Count":this.Count,"CheckStatus":this.CheckStatus,"Type":this.Type,"Value":this.Value})
					if (res.resultCode == 1) {
						var VisitorList = [];
						var List = JSON.parse(res.body);
						if(List.length>0) this.VisitorListCount = List[0].size;
						this.VisitorList = List;
					}
					this.PageLoad.Load = false;
				}
				catch (err)
				{
					this.PageLoad.Load = false;
					console.log(err)
				}
			},

			//获取有效状态
			getValidState(item){
				var date = new Date().valueOf();
				var newDate = new Date(item.aCSEndTime);
				var time = newDate.valueOf();
				if(date>time){
					return true;
				}else{
					return false;
				}
			},
			
			//查询访客区域
			async ISCSVisitorArea(){
				try
				{
					var {data: res} = await this.$http.post('ISCSVisitorArea')
					if (res.resultCode == 1) {
						this.VisitorArea = JSON.parse(res.body);
					}
				}
				catch (err)
				{
					console.log(err)
				}
			},
			
			//显示申请详情弹框
			async AuthorizationDialog(ApplyId){
				try
				{	
					this.AuthorizationDialogState = true;
					this.PageLoad.DialogLoad = true;
					var {data: res} = await this.$http.post('VisitorDetails',{"ApplyId":ApplyId})
					if (res.resultCode == 1) 
					{
						var body = JSON.parse(res.body);
						
						var VisitorList = this.VisitorList;
						for(var i=0;i<VisitorList.length;i++)
						{
							if(VisitorList[i].applyId == ApplyId)
							{
								VisitorList[i].ApplyDetails = body;
								this.VisitorDetails = VisitorList[i];
								break;
							}
						}
					}
					this.PageLoad.DialogLoad = false;
				}
				catch (err)
				{
					this.PageLoad.DialogLoad = false;
					console.log(err)
				}
				this.VisitorCount = body.length;
			},
			
			//访客信息修改（嵌套弹出层）
			innerVisible(){
				this.innerVisibleState = true;
				this.VisitorAreaValue = this.VisitorDetails.visitorAreaId;
				this.ACSTimesValue = this.VisitorDetails.aCSTimes;
				this.StartEnd = [this.VisitorDetails.aCSStartTime,this.VisitorDetails.aCSEndTime];
				//获取最大限次
				this.getTimeMax();
			},

			//获取最大限次
			async getTimeMax(){
				try{
					var applyId = this.VisitorDetails.applyId;
					var {data: res} = await this.$http.post('getTimeMax',{"applyId":applyId})
					var body = JSON.parse(res.body);
					this.timeMax = body.time;
				}
				catch (err)
				{
					console.log(err)
				}
				this.ButtonLoad.Agree = false;
			},
			
			
			//保存访客权限
			confirmUpdate(){
				var VisitorArea = this.VisitorArea;
				for(var i=0;VisitorArea.length;i++)
				{
					if(this.VisitorAreaValue == VisitorArea[i].visitorAreaId)
					{
						this.VisitorDetails.visitorAreaName =  VisitorArea[i].visitorAreaName;
						break;
					}
				}
				this.VisitorDetails.visitorAreaId = this.VisitorAreaValue;
				this.VisitorDetails.aCSTimes = this.ACSTimesValue;
				this.VisitorDetails.aCSStartTime = this.StartEnd[0];
				this.VisitorDetails.aCSEndTime = this.StartEnd[1];
				this.VisitorDetails.checkRemark = this.CheckRemark;
				this.innerVisibleState = false;
			},
			//授权
			async ISCSUpdateVisitorApply(){
				try
				{
					this.ButtonLoad.Agree = true;
					var applyId = this.VisitorDetails.applyId;
					var ACSTimes = this.VisitorDetails.aCSTimes;
					var VisitorAreaId = this.VisitorDetails.visitorAreaId;
					var ACSEndTime = this.VisitorDetails.aCSEndTime;
					var ACSStartTime = this.VisitorDetails.aCSStartTime;
					var checkRemark = this.VisitorDetails.checkRemark;
					var name = this.VisitorDetails.name;
					var employeeName = this.VisitorDetails.employeeName;
					var {data: res} = await this.$http.post('ISCSUpdateVisitorApply',{"applyId":applyId,"ACSTimes":ACSTimes,"VisitorAreaId":VisitorAreaId,"ACSEndTime":ACSEndTime,"ACSStartTime":ACSStartTime,"checkRemark":checkRemark,"name":name,"employeeName":employeeName})
					if (this.FilteringOperation(res))
					{
						if(res.resultCode==1)
						{
							this.$message({message: this.$t('Visitor.AuthorizationSuccess'),type: 'success',offset:10});
							this.AuthorizationDialogState = false;
							this.VisitorApplyDetails();
						}
						else
						{
							this.$message({message: this.$t('Visitor.AuthorizationFailure'),type: 'error',offset:10});
						}
					}
				}
				catch (err)
				{
					console.log(err)
				}
				this.ButtonLoad.Agree = false;
			},
			
			//拒绝弹框
			RefuseDialog(e){
				this.RefuseDialogState = true;
				this.applyId = e.applyId;
				this.CheckRemark = e.checkRemark;
				this.employeeName = e.employeeName;
				this.name = e.name;
			},
			
			//拒绝
			async ISCSrefuseVisitor(){
				try
				{
					this.ButtonLoad.Refuse = true;
					var applyId = this.applyId;
					var CheckRemark = this.CheckRemark;
					var name = this.name;
					var employeeName = this.employeeName;
					var {data: res} = await this.$http.post('ISCSrefuseVisitor',{"applyId":applyId,"CheckRemark":CheckRemark,"name":name,"employeeName":employeeName})
					if (this.FilteringOperation(res))
					{
						if(res.resultCode==1)
						{
							this.$message({message: this.$t('Visitor.HasRefused'),type: 'success',offset:10});
							this.RefuseDialogState = false;
							this.VisitorApplyDetails();
						}
						else
						{
							this.$message({message: this.$t('Visitor.operationFailure'),type: 'error',offset:10});
						}
					}
				}
				catch (err)
				{
					console.log(err)
				}
				this.ButtonLoad.Refuse = false;
			},
			
			//操作过滤
			FilteringOperation(res){
				var State = true;
				if (res.stateCode == 0) {
					State = false;
					this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
				} else if (res.stateCode == 2) {
					State = false;
					this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
				} else if (res.stateCode == 3) {
					State = false;
					this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
				}
				return State;
			}
		}
	};
</script>

<style scoped>

	.visitor{
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	/* 头部 */
	.Operation {
		display: flex;
		margin-bottom: 20px;
	}

	.card1{
		background-color: #FFFFFF;
		padding: 16px 18px;
		margin-right: 20px;
	}
	
	.card2{
		background-color: #FFFFFF;
		padding: 16px 18px;
		flex-grow: 1;
		display: flex;
	}

	.right_top_search>i{
		margin-top: -3px;
		font-size: 25px;
		margin-right:10px;
		margin-left: 10px;
		color: #909399;
	}

	.right_top_Date>>>i{
		font-size: 25px;
		color: #909399;
	}

	.right_top_search>>>.el-input__inner {
    	border: 0;
		font-size: 15px;
  	}

	.right_top_search_type{
		margin-right: 15px;
	}

	.right_top_search_type>i{
		margin-left: 20px;
		height: 20px;
		display: flex;
		border-left:1px solid #c7c7c7;
	}

	.right_top_search_type>>>.el-input__inner{
		border:0;
	}

	/* 内容 */
	.content{
		background-color: #FFFFFF;
		height: 0px;
		flex-grow: 1;
	}

	.content>div{
		display: flex;
		flex-direction: column;
		width: 97%;
		height: 95%;
	}

	.content_right_bottom_title{
		font-weight: 600;
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 10px;
	}

	.el-table {
		margin-top: 15px;
	}

	.el-pagination {
		margin-top: 15px;
	}
	
	#visitorapply{
		display: flex;
		justify-content: space-between;
	}
	
	#visitorapplyBody{
		font-size: 15px;
	}
	
	#visitorapplyBody>div{
		display: flex;
		align-items:center;
		margin: 5px 0px;
	}
	
	#visitorapplyBody i {
		margin: 0px 10px;
		font-size: 20px;
		cursor:pointer;
		color: #409EFF;
	}
	
	#visitorapplydetails{
		height: 410px;
		overflow:auto
	}
	
	#visitorapplydetails>div{
		display: flex;
		margin: 20px 20px;
		padding: 10px;
		border: 1px solid #EAEEFB;
	}
	
	.Photo{
		width: 120px;
		height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #F5F7FA;
	}
	
	.applydetails>div{
		margin: 15px;
	}
</style>
