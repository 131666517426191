<template>
	<div class="Monitoring">
		<!-- 左侧 -->
		<div class="left boxshadow" v-loading="PageLoad.Leftload">
			<!-- 左侧头部 -->
			<div class="left_top">
				<!-- 搜索框 -->
					<div class="search">
						<i class="el-icon-search"></i>
						<el-input maxlength="32" v-model="searchValue" :placeholder="$t('Employee.SearchTips')"></el-input>
						<el-button size="mini" class="el-icon-search" @click="Search"></el-button>
					</div>
				<div>
					<el-checkbox v-model="checked" :indeterminate="isIndeterminate" @change="handleCheckAllChange">{{$t('Monitoring.selectAll')}}</el-checkbox>
					<el-button type="primary" size="small" style="margin-left: 15px;" :disabled="!userGroupDetails.RoleControllers" @click="LongRangeControl()" icon="el-icon-reading">{{$t('Monitoring.OpenTheDoorRemotely')}}</el-button>
				</div>
				<div class="flex-items-center">
					<el-tooltip class="item" effect="dark" v-if="WebSocektStatus==0" :content="$t('Monitoring.NotConnected')" placement="left">
						<span style="color: #F56C6C;font-size: 21px;" ><i class="el-icon-warning"></i></span>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" v-else-if="WebSocektStatus==1" :content="$t('Monitoring.Connected')" placement="left">
						<span style="color: #409EFF;font-size: 21px;" ><i class="el-icon-link"></i></span>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" v-else-if="WebSocektStatus==2" :content="$t('Monitoring.ConnectingProgress')" placement="left">
						<span style="color: #8C939D;font-size: 21px;"><i class="el-icon-loading"></i></span>
					</el-tooltip>
					<div style="margin-left: 15px;color: #8C939D;">{{$t('Monitoring.Quantity')}}：{{EquipmentList.length}}</div>
				</div>
			</div>
			<!-- 左侧底部 -->
			<div class="left_bottom">
				<el-checkbox-group v-model="checkList"  @change="handleCheckedCitiesChange" style="display: flex;flex-wrap:wrap">
					<el-card v-for="items in EquipmentList" :shadow="items.controllersId == EquipmentSuspension ? 'always':'hover'" :key="items.controllersId" class="equipment">
						<div style="margin-top: -10px;margin-bottom: 8px;display: flex;justify-content: space-between;">
							<div style="margin-left: -5px;">
								<div :id="'tamperAlarm'+items.controllersId" class="ld" style="display: none;">
									<i style="color: #F56C6C;font-size: 23px;" class="el-icon-message-solid"></i>
								</div>
							</div>
							<div style="margin-right: -5px">
								<i v-if="Online(items.online)" style="color: #409EFF;font-size: 23px;" class="el-icon-monitor"></i>
								<i  v-else style="color: red;font-size: 23px;" class="el-icon-warning-outline"></i>
							</div>
						</div>
						<div style="width: 100%;display: flex;justify-content: center;"><img width="70" height="100" :id="'doorMagnetic'+items.controllersId" style="margin: 0px auto;" :src="Equipmentimg(items.controllerSubType)" /></div>
						<div style="width: 100%;display: flex;justify-content: center;margin-top: 8px;"><el-checkbox :label="items.controllersId">{{items.controllerName}}</el-checkbox></div>
					</el-card>
				</el-checkbox-group>
			</div>
			<div class="left_bottom_bottom">
				<div><i style="color: #409EFF;font-size: 17px;" class="el-icon-monitor"></i>&nbsp;{{$t('Monitoring.Online')}}</div>
				<div><i style="color: red;font-size: 17px;" class="el-icon-warning-outline"></i>&nbsp;{{$t('Monitoring.Offline')}}</div>
				<div><i style="color: #F56C6C;font-size: 17px;" class="el-icon-message-solid"></i>&nbsp;{{$t('Monitoring.Alarm')}}</div>
			</div>
		</div>
		<!-- 右侧 -->
		<div class="right flex-center boxshadow">
			<div class="right_value" v-if="CreditCardData.length>0" v-loading="PageLoad.Rightload">
				<el-timeline class="right_timeline">
					<el-timeline-item v-for="(items, i) in CreditCardData" :key="i" :timestamp="items.Time+' ('+Property(items.Property)+ IsShareFlag(items.ShareFlag) +') ' " placement="top" size="large" :color="Colors(items.Property)">
						<el-card shadow="hover">
							<div style="display:flex;align-items: center;" @mouseover="Suspension(true,items.ControllersId)" @mouseleave="Suspension(false,items.ControllersId)">
								<div>
									<img width="120" height="170" v-if="items.Photo!=''" :src="Employeesimg(items.Photo,items.Type)" />
									<div v-else class="NotImage">{{$t('Monitoring.NoPhoto')}}</div>
								</div>
								<ul class="details">
									<li style="margin-top: 0px;">
										<div>
											{{$t('Monitoring.Name')}}
											<div v-if="items.Name!='' && items.Name != undefined && items.ApplyId!=0 && items.Sex == 0">{{items.Name}}<span style="color: #E6A23C;"> {{$t('Monitoring.Visitor')}}</span></div>
											<div v-else-if="items.Name!='' && items.Name != undefined && items.Sex == 1">{{items.Name}}<span style="color: #409EFF;"> {{$t('Monitoring.Sex1')}}</span></div>
											<div v-else-if="items.Name!='' && items.Name != undefined && items.Sex == 2">{{items.Name}}<span style="color: #F56C6C;"> {{$t('Monitoring.Sex2')}}</span></div>
											<div v-else-if="items.Name!='' && items.Name != undefined && items.Sex == 0">{{items.Name}}</div>
										</div>
									</li>
									<li>
										<div v-if="items.ApplyId==0">
											{{$t('Monitoring.Number')}}
											<div>{{items.Number}}</div>
										</div>
										<div v-else>
											{{$t('Monitoring.IdentityCard')}}
											<div>{{items.Number}}</div>
										</div>
									</li>
									<li>
										<div>
											{{$t('Monitoring.Verify')}} 
											<div v-if="items.Type==1 && items.Card!='' && items.Card != undefined">{{items.Card}}</div>
											<div v-if="items.Type==2">{{$t('Monitoring.FaceRecognition')}}</div>
											<div v-if="items.Type==3">{{$t('Monitoring.Button')}}</div>
										</div>
									</li>
									<li>
										<div>
											{{$t('Monitoring.Department')}}  
											<div v-if="items.DepartmentName!='' && items.DepartmentName!=0 && items.DepartmentName!=undefined">{{items.DepartmentName}}</div>
										</div>
									</li>
									<li>
										<div>
											{{$t('Monitoring.Controller')}}
											<div>{{items.ControllerName}}</div>
										</div>
									</li>
									<li v-if="items.Temperature!=null && items.Temperature!=''">
										<div>
											{{$t('Monitoring.Temperature')}}
										<div>{{items.Temperature}}</div>
										</div>
									</li>
								</ul>
							</div>
						</el-card>
					</el-timeline-item>
				</el-timeline>
			</div>
			<div v-else class="cardnull">{{$t('Monitoring.NoMonitoringData')}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				//权限组
				userGroupDetails: {
					RoleControllers: false //设备操作权
				},
				// 页面加载
				PageLoad:{
   					Leftload: false, //左侧加载状态
					Rightload: false, //右侧加载状态
				},
				WebSocektStatus:0, //soket 状态
				checked:false,//全选
				isIndeterminate:false,
				checkList:[],//选择的设备
				CreditCardData:[] ,//刷卡数据
				
				EquipmentLoad:false,//设备加载状态
				QueryEquipment:{ //设备查询参数
					ControllersAreaCode:'',//区域编码
					Count:999999,//分页条数
					Pages:1,//分页页数
					TypeValue:'0',//按条件查询下拉选择
					Value: ''//按条件查询值
				},
				EquipmentSuspension:0,//悬浮的设备ID
				EquipmentList:[] ,//设备
				SystemTime:'', //系统时间
				searchValue:''//搜索框的值
			}
		},
		mounted() {
			//获取操作权
			this.Role();
			//查询设备
			this.Controllers();
		},
		methods: {

			//按搜索框内容查询
			Search(){
				let value = this.searchValue
				if('' == value){
					this.QueryEquipment.TypeValue='0'
					this.QueryEquipment.Value=''

				}else{
					this.QueryEquipment.TypeValue='1'
					this.QueryEquipment.Value=value
				}
				this.Controllers()
			},
			
			//是否为分享码
			IsShareFlag(shareFlag){
				if(shareFlag=='0'){
					shareFlag = ''
				}
				else{
					shareFlag = '-' + this.$t('Monitoring.ShareFlag')
				}
				return shareFlag
			},

			//获取操作权
			Role() {
				//获取权限组
				var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
				for (var i = 0; i < userGroupDetails.length; i++) {
					if (userGroupDetails[i].roleName == "Controllers") { //设备操作权
						this.userGroupDetails.RoleControllers = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
				}
			},
			
			//查询设备
			async Controllers(){
				try
				{
					this.PageLoad.Leftload = true;
					var {data: res} = await this.$http.post('Controllers',this.QueryEquipment);
					if (res.resultCode == 1) //成功
					{
						var Data = JSON.parse(res.body);
						this.SystemTime = Data.DataTiem;
						this.EquipmentList = Data.Controllers;
					}
					this.PageLoad.Leftload = false;
					
					//查询部门
					this.Department();
				}
				catch (err)
				{
					this.PageLoad.Leftload = false;
					console.log(err)
				}
			},

			//查询部门
			async Department() {
				try 
				{
					this.PageLoad.Rightload = true;
					var {data: res} = await this.$http.post('DepartmentsArray')
					if (res.resultCode == 1)//成功
					{
						this.DepartmentList = JSON.parse(res.body);
					}
					this.PageLoad.Rightload = false;

					//获取连接码并连接监控
					this.TheConnectionCode();
				} 
				catch (err)
				{
					this.PageLoad.Rightload = false;
					console.log(err)
				}
			},

			//获取连接码并连接监控
			async TheConnectionCode(){
				try
				{
					var Controllers = "";
					for(var i=0;i<this.EquipmentList.length;i++)
					{
						Controllers=Controllers+this.EquipmentList[i].controllersId+",";
					}
					if(Controllers!="")
					{
						var {data: res} = await this.$http.post('coding',{"Controllers":Controllers});
						if (res.resultCode == 1) //成功
						{
							this.WebSocektStatus = 2;
							let url = this.$EquipmentServerUrl+'Socekt/'+res.body;
							// 创建websocket连接
							this.websock = new WebSocket(url);
							// 监听打开
							this.websock.onopen = this.websockOpen;
							// 监听关闭
							this.websock.onclose = this.websockClose;
							//监听异常
							this.websock.onerror = this.websockError;
							//监听服务器发送的消息
							this.websock.onmessage = this.websockMessage;
						}
					}
				}
				catch (err)
				{
					console.log(err);
				}
			},
			
			websockOpen()
			{
				this.WebSocektStatus = 1;
			    console.log('监听打开')
			},
			websockClose()
			{
				this.WebSocektStatus = 0;
				console.log('监听关闭')
			},
			websockError()
			{
				this.WebSocektStatus = 0;
				console.log('监听异常')
			},
			//监听服务器发送的消息
			websockMessage(e)
			{
				var body = JSON.parse(e.data);
				if(body.Type=="1")
				{
					var bodyData = JSON.parse(body.Body);
					var ControllerId = bodyData.ControllersId;
					for(var i=0;i<this.EquipmentList.length;i++)
					{
						if(ControllerId==this.EquipmentList[i].controllersId)
						{
							bodyData.ControllerName = this.EquipmentList[i].controllerName;
							break;
						}
					}
					var DepartmentId = bodyData.DepartmentId;
					for(var i=0;i<this.DepartmentList.length;i++)
					{
						if(DepartmentId==this.DepartmentList[i].departmentId)
						{
							bodyData.DepartmentName = this.DepartmentList[i].departmentName;
							break;
						}
					}
					if(this.CreditCardData.length<10)
					{
						this.CreditCardData.unshift(bodyData);
					}
					else
					{
						this.CreditCardData.pop();
						this.CreditCardData.unshift(bodyData);
					}
				}
				else if(body.Type=="2")
				{
					var Data = body.Body;
					var UID = Data.uId;//设备ID
					var doorMagnetic = Data.doorMagnetic;//门磁
					console.log(doorMagnetic);
					if(doorMagnetic==1)//开
					{
						document.getElementById('doorMagnetic'+UID).src= require('../assets/images/Equipment/K2.gif');
					}
					else//关
					{
						document.getElementById('doorMagnetic'+UID).src= require('../assets/images/Equipment/G2.gif');
						var thi = this;
						setTimeout(function(){
							for(var i=0;i<thi.EquipmentList.length;i++)
							{
								if(UID==thi.EquipmentList[i].controllersId)
								{
									document.getElementById('doorMagnetic'+UID).src = thi.Equipmentimg(thi.EquipmentList[i].controllerSubType);
									break;
								}
							}
						},1500);
					}
				}
				else if(body.Type=="3")
				{
					var Data = body.Body;
					var UID = Data.uId;//设备ID
					var tamperAlarm = Data.tamperAlarm;//防擦报警
					if(tamperAlarm==0)//没触发
					{
						document.getElementById('tamperAlarm'+UID).style.display="none"
					}
					else//触发
					{
						document.getElementById('tamperAlarm'+UID).style.display = "";
					}
				}
			},

			//远程开门
			async LongRangeControl()
			{
			    var {data: res} = await this.$http.post('LongRangeControl',{"Controllers":this.checkList});
				if(this.FilteringOperation(res))
				{
					if (res.resultCode == 1) //成功
					{
						this.$message({message: this.$t('Monitoring.CommandSent'),type: 'success',offset:10});
					}
				}
			},
			
			//卡片悬浮关联设备
			Suspension(State,controllersId){
				if(State)
				{
					this.EquipmentSuspension = controllersId;
				}
				else
				{
					this.EquipmentSuspension = 0;
				}
			},

			//人员照片
			Employeesimg(photo,Type) {
				try 
				{
					if(photo!=undefined && photo!='')
					{
						if(Type==1)
						{
							return this.$image+"/Employees/"+photo;
						}
						else
						{
							return this.$image+"/TakePhotos/"+photo;
						}
					}
					else{
						return ''
					}
				}
				catch (e) 
				{
					return ''
				}
			},
			
			//设备图片
			Equipmentimg(photo) {
				try {
					return require('../assets/images/Equipment/' + photo+'.png')
				} catch (e) {
					return ''
				}
			},
			
			//设备是否在线
			Online(Data){
				if(Data!="" && Data!=undefined)
				{
					try
					{
						var SystemTime = this.SystemTime;
						var startTime = new Date(Date.parse(Data));
						var date = new Date(Date.parse(SystemTime));
						var min = date.getMinutes();
						date.setMinutes(min - 1);
						if(startTime>=date)
						{
							return true;
						}
						else
						{
							return false;
						}
					}
					catch(e)
					{
						return false;
					}
				}
				else
				{
					return false;
				}
			},
			
			//全选
			handleCheckAllChange(val){
				var cityOptions=[];
				for(var i=0;i<this.EquipmentList.length;i++)
				{
					cityOptions[cityOptions.length] = this.EquipmentList[i].controllersId;
				}
				this.checkList = val ? cityOptions : [];
				this.isIndeterminate = false;
			},
			
			//选择设备
			handleCheckedCitiesChange(value){
				if(value.length==this.EquipmentList.length){
					this.checked = true;
				}
				else
				{
					this.checked = false;
				}
			},
			
			//返回结果过滤
			FilteringOperation(res) {
				var State = true;
				if (res.stateCode == 0) {
					State = false;
					this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
				} else if (res.stateCode == 2) {
					State = false;
					this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
				} else if (res.stateCode == 3) {
					State = false;
					this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
				} 
				return State;
			},
			
			//实时刷卡颜色
			Colors(Property){
				if(Property==1)
				{
					return '#0bbd87';
				}
				else if(Property==2 || Property==11 || Property==12)
				{
					return '#E6A23C';
				}
				else
				{
					return '#ff0000';
				}
			},
			
			Property(Type){
				if (Type == 1)
				{
					return this.$t('Monitoring.CardStatus2');
				}
				else if (Type == 2)
				{
					return this.$t('Monitoring.CardStatus3');
				}
				else if (Type == 11)
				{
					return this.$t('Monitoring.CardStatus4');
				}
				else if (Type == 12)
				{
					return this.$t('Monitoring.CardStatus5');
				}
				else 
				{
				  return this.$t('Monitoring.CardStatus1');
				}
			}
		}
	}
</script>

<style scoped>

	.Monitoring{
		display: flex;
		height: 100%;
	}

	/* 左侧 */
	.left{
		background-color: #FFFFFF;
  		flex-grow: 1;
		width: 0px;
		display: flex;
		flex-direction: column;
		padding: 15px;
	}

	.left_top{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.left_bottom{
		flex-grow: 1;
		height: 0px;
		overflow: auto;
	}

	.left_bottom_bottom{
		display: flex;
		justify-content: end;
		align-items: center;
		height: 30px;
		font-size: 14px;
		color: #606266;
	}

	.left_bottom_bottom>div{
		margin: 0px 10px;
		display: flex;
		align-items: center;
	}

	/* 右侧 */
	.right{
		width: 445px;
		height: 100%;
		margin-left: 20px;
		background-color: #FFFFFF;
	}

	.right_value{
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.right_timeline{
		width: 93%;
		margin: 15px 0px;
		margin-left: -25px;
	}

	/* 监控详情布局 */
	.details>li {
		margin-top: 18px;
		font-weight: 700;
	}
	.details>li>div {
		display: flex;
		align-items: center;
	}

	.details>li>div>div {
		margin-left: 6px;
		color: #606266;
		font-weight: 500;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/* 无照片 */
	.NotImage {
		width: 120px;
		height: 170px;
		background-color: #F5F7FA;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #889BAC;
	}
	
	/* 设备 */
	.equipment{
		font-size: 13px;
		width: 170px;
		height: 210px;
		margin: 20px;
	}
	
	/* 设备状态 */
	.equipmentState{
		margin: 0px auto;
		margin-top: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	
	.ld {
		transform-origin: 50% 0;
		animation: yaolingdang 1s infinite ease;
	}


	.search{
		display: flex;
		flex-direction: row;
		/* align-items: center;
		justify-content: center; */
		width: 70%;
		height: auto;
		margin-top: -3px;
		font-size: 25px;
		margin-left:10px;
		margin-right:10px;
		color: #909399;
	}
	.el-icon-search{
		display: flex;
		margin: 0px 10px;
		align-items: center;
		justify-content: center;
	}
	
	@keyframes yaolingdang {
		5%,25%,45% {
			transform: rotate(8deg);
		}
		0%,10%,30%,50% {
			transform: rotate(-8deg);
		}
		15%,35%,55% {
			transform: rotate(4deg);
		}
		20%,40%,60% {
			transform: rotate(-4deg);
		}
		65%,100% {
			transform: rotate(0deg);
		}
	}
	
</style>
