<template>
	<div class="VisitirArea">
		<!-- 内容 -->
		<div class="content">
			<!-- 左侧 -->
			<div class="content_left boxshadow" v-loading="PageLoad.Leftload">
				<div class="left_value">
					<!-- 功能栏 -->
					<div class="left_value_input">
						<el-input :placeholder="$t('VisitirArea.SearchVisitirArea')" size="medium" v-model="filterText" style="width: 66%;"></el-input>
						<el-button type="primary" icon="el-icon-circle-plus-outline" size="medium" style="margin-left:15px" :disabled="!userGroupDetails.RoleHostMode"
						 @click="addtemplateStaet">{{$t('VisitirArea.Add')}}</el-button>
					</div>

					<!-- 部门菜单 -->
					<el-tree class="left_value_tree" :highlight-current="true" :data="visitorareaList" :filter-node-method="filterNode" ref="tree" node-key="visitorAreaId"
					default-expand-all :expand-on-click-node="false" icon-class="el-icon-s-promotion">
						<span class="custom-tree-node left_tree" slot-scope="{data}" @click="optional(data)">
							<el-popover placement="left" v-if="data.remark!='' && data.remark!=undefined" style="width: 100%;" :title="$t('VisitirArea.Remark')" width="200" trigger="hover" :content="data.remark">
								<div style="width: 100%" slot="reference" >{{ data.visitorAreaName }}</div>
							</el-popover>
							<div style="width: 100%;" v-else slot="reference">{{ data.visitorAreaName }}</div>
							<span>
								<el-popover placement="right" trigger="hover" :open-delay="300">
								<el-button type="info" size="mini" :disabled="!userGroupDetails.RoleHostMode" @click="updtemplateStaet(data)">
									{{$t('VisitirArea.Update')}}
								</el-button>
								<el-button type="danger" size="mini" :disabled="!userGroupDetails.RoleHostMode" @click="DeleteTemolates(data)">
									{{$t('VisitirArea.Delete')}}
								</el-button>
								<i class="el-icon-more left_tree_icon" slot="reference"></i>
							</el-popover>
							</span>
						</span>
					</el-tree>
				</div>
			</div>
			<!-- 右侧 -->
			<div class="content_right" v-if="visitorareaform.VisitorAreaId!=0" v-loading="PageLoad.Rightload">
				<!-- 区域审核人员 -->
				<div class="content_right_approver boxshadow">
					<div class="approver_title">{{$t('VisitirArea.AreaApprover')}}</div>
					<el-alert :title="$t('VisitirArea.ApproverHint')" type="warning" show-icon :closable="false" ></el-alert>
					<div class="approver_option">
						<el-select style="width: 100%;" v-model="areaApproverList" :multiple-limit="5" clearable multiple filterable remote  reserve-keyword  :remote-method="remoteMethod" :loading="approverLoading" :placeholder="$t('VisitirArea.SelectHint')">
							<el-option v-for="item in employeeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
						<el-button type="primary" :disabled="!userGroupDetails.RoleHostMode" style="margin-left:20px" @click="setApprover()">{{$t('VisitirArea.Save')}}</el-button>
					</div>
				</div>
				<!-- 区域设备 -->
				<div class="controller_card boxshadow">
					<!-- 标题 -->
					<div class="controller_title">{{$t('VisitirArea.ControllerList')}}</div>
					<!-- 搜索条件 -->
					<div class="content_right_top flex-items-center">
						<el-input prefix-icon="el-icon-search" v-model="controllerName" @input="searchController" :placeholder="$t('VisitirArea.InputControllerName')"></el-input>
						<el-button type="primary" :disabled="!userGroupDetails.RoleHostMode" style="margin-left:20px" @click="AddAreaController()">{{$t('VisitirArea.Management')}}</el-button>
					</div>
					<!-- 底部 -->
					<div class="content_right_bottom flex-center">
						<el-table :data="searchControllerList" :header-cell-style="{backgroundColor:'#EBEEF5',color:'#606266'}" v-loading="TableLoad" height="100%">
							<el-table-column  prop="controllerId" align="center" :label="$t('VisitirArea.ControllerId')"></el-table-column>
							<el-table-column :label="$t('VisitirArea.ControllerType')" align="center">
								<template slot-scope="scope">
									<div style="height: 90px;display: flex;align-items: center;justify-content: center;">
										<img style="width: 50px;" :src="Equipmentimg(scope.row.controllerSubType)" fit="cover"/>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="controllerName" align="center" :label="$t('VisitirArea.ControllerName')"></el-table-column>
							<el-table-column prop="location" align="center" :label="$t('VisitirArea.ControllerLocation')"></el-table-column>
							<el-table-column align="center" :label="$t('VisitirArea.Orientation')">
								<template slot-scope="scope">
									<span v-if="scope .row.workType==0">{{ $t('VisitirArea.Direction1')}}</span>
									<span v-else-if="scope .row.workType==1">{{$t('VisitirArea.Direction2')}}</span>
									<span v-else-if="scope .row.workType==2">{{$t('VisitirArea.Direction3')}}</span>
								</template></el-table-column>
							<el-table-column :label="$t('VisitirArea.Operation')" width="180" align="center">
								<template slot-scope="scope">
									<el-button type="primary" size="mini" icon="el-icon-edit" @click="showUpdateFloorDialog(scope.row.controllerId)"></el-button>
									<el-button type="danger" :disabled="!userGroupDetails.RoleHostMode" size="mini" icon="el-icon-delete" @click="Deletevisitorareacontroller(scope.row.controllerId,scope.row.visitorAreaName)"></el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
			<div v-else class="HolidayTemplateList flex-center boxshadow">
				<div>
					<!-- 67C23A -->
					<div style="font-size: 100px;" class="el-icon-school"></div>
					<div style="margin-top: 20px;">{{$t('VisitirArea.SelectVisitirArea')}}</div>
				</div>
			</div>
		</div>

		<!-- 区域弹框 -->
		<el-dialog :title="templateTitle" :visible.sync="templateVisible" width="30%" :close-on-click-modal="false">
			<el-form ref="templateformRef" label-width="100px" class="demo-ruleForm" :model="visitorareaform" :rules="VisitorAreaFormRules" label-position="left">
				<el-form-item :label="$t('VisitirArea.AreaName')" prop="VisitorAreaName">
					<el-input maxlength="32" v-model="visitorareaform.VisitorAreaName" :placeholder="$t('VisitirArea.PleaseInput')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('VisitirArea.Remark')">
					<el-input maxlength="32" v-model="visitorareaform.Remark" type="textarea"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="templateVisible = false">{{$t('VisitirArea.Cancel')}}</el-button>
				<el-button type="primary" v-if="visitorareaform.VisitorAreaId==0" :loading="ButtonLoad.AddUpdateArea" @click="Addvisitorarea">{{ButtonsName}}</el-button>
				<el-button type="primary" v-if="visitorareaform.VisitorAreaId!=0" :loading="ButtonLoad.AddUpdateArea" @click="UpdTemplates">{{ButtonsName}}</el-button>
			</span>
		</el-dialog>
		
		<!-- 设备弹框 -->
		<el-dialog :title="$t('VisitirArea.ManagementController')" :visible.sync="AddAreaControllerState" width="68%" :close-on-click-modal="false">
			<el-alert :title="$t('VisitirArea.AddControllerSpecification')" show-icon type="info" :closable="false"></el-alert>
			<div class="controller_dialog">
				<div class="controller_area">
					<el-input class="queryAreaName" v-model="queryAreaName" :placeholder="$t('VisitirArea.InputAreaName')"></el-input>
					<el-tree :data="controllerAreaList" :filter-node-method="filterNodeArea" ref="Area" default-expand-all :expand-on-click-node="false" icon-class="el-icon-s-promotion">
						<span class="custom-tree-node" slot-scope="{ data }" style="height: 100%;" @click="getControllers(data.controllersAreaCode)">
							<div style="width: 100%;">{{ data.regionName }}</div>
						</span>
					</el-tree>
				</div>
				<div class="controller_list">
					<el-input v-model="queryControllerName" @input="searchControllerName()" :placeholder="$t('VisitirArea.InputControllerName')"></el-input>
					<div class="controller_list_details">
						<div class="controller_details">
							<el-checkbox v-model="isCheckAllController" @change="checkAllController">{{$t('VisitirArea.SelectAllController')}}</el-checkbox>
						</div>
						<div v-for="(item,index) in searchControllerNameList" :key="index+'1'">
							<div class="controller_details">
								<el-checkbox v-model="item.checked" @change="checkAllFloor(index,$event)">{{item.controllerId+" - "+item.controllerName+" - "+item.location}}</el-checkbox>
							</div>
							<div class="floor_item" v-if="item.floor.length>0">
								<div v-for="(floorItem,floorIndex) in item.floor"  :key="floorIndex+'2'">
									<el-checkbox style="display: flex;align-items: center;" :disabled="floorItem.status==0" v-model="floorItem.checked" @change="checkFloor(index,floorIndex,$event)" border>
										<div class="floor_item_checked">{{ floorItem.label=="" || floorItem.label==undefined?floorItem.index+" "+$t('VisitirArea.floor'):floorItem.label }}</div>
									</el-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="AddAreaControllerState = false">{{$t('VisitirArea.Cancel')}}</el-button>
				<el-button type="primary" :loading="ButtonLoad.AddUpdateArea" @click="Addvisitorareacontroller">{{$t('VisitirArea.Confirm')}}</el-button>
			</span>
		</el-dialog>

		<!-- 修改设备楼层 -->
		<el-dialog :title="$t('VisitirArea.ManagementController')" :visible.sync="showUpdateFloorDialogStatus" width="55%" :close-on-click-modal="false">
			<el-alert :title="$t('VisitirArea.AddControllerSpecification')" show-icon type="info" :closable="false"></el-alert>
			<div class="controller_dialog" style="height: 500px;">
				<div class="controller_list_details">
					<div class="floor_item">
						<div>
							<el-checkbox style="display: flex;align-items: center;" v-model="checkControllerAll" @change="checkControllerAllFloor($event)" border>
								<div class="floor_item_checked">{{$t('VisitirArea.selectAll')}}</div>
							</el-checkbox>
						</div>
						<div v-for="(floorItem,floorIndex) in controllerFloorList"  :key="floorIndex+'2'">
							<el-checkbox style="display: flex;align-items: center;" :disabled="floorItem.status==0" v-model="floorItem.checked" @change="checkControllerFloor()" border>
								<div class="floor_item_checked">{{ floorItem.label==""  || floorItem.label==undefined?floorItem.index+" "+$t('VisitirArea.floor'):floorItem.label }}</div>
							</el-checkbox>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showUpdateFloorDialogStatus = false">{{$t('VisitirArea.Cancel')}}</el-button>
				<el-button type="primary" :loading="ButtonLoad.AddUpdateArea" @click="setControllerFloor">{{$t('VisitirArea.Confirm')}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			//校验名称
			var VisitorName_verify = (rule, value, callback) => {
			if (!value)
				return callback(new Error(this.$t("VisitirArea.InputAreaName")));
			callback();
			};
			return {
				//权限组
				userGroupDetails: {
					RoleHostMode: false
				},
				// 页面加载
				PageLoad:{
   					Leftload: false, //左侧加载状态
					Rightload: false, //右侧加载状态
				},
				// 按钮加载状态
				ButtonLoad:{
					//新增修改区域
 					AddUpdateArea: false,
				},
				ButtonsName: '', //文本
				TemplatesId: 0,
				filterText: '', //搜索过滤
				visitorareaList:[],//访客区域
				defaultProps: { //模板格式
					children: 'visitorAreaId',
					label: 'visitorAreaName'
				},
				templateTitle: "", //弹框标题
				Leftloading: false, //是否显示加载状态
				templateVisible: false, // 新增修改假期模板弹框显示状态
				VisitorAreaName:"",
				Remark:"",
				VisitorAreaFormRules: { //添加修改模板表单验证
					VisitorAreaName: [{
						required: true,
						validator: VisitorName_verify,
            			trigger: "change",
					}]
				},
				//区域表单数据
				visitorareaform:{
					VisitorAreaName:"",
					Remark:"",
					VisitorAreaId:0,
					approver:[]
				},
				//区域审核人员加载状态
				approverLoading:false,
				//员工列表
				employeeList:[],
				//区域审核人员列表
				areaApproverList:[],
				//区域审核列表详情
				areaApproverListDetails:[],
				//表格加载状态
				TableLoad:false,
				//搜索条件-设备名称
				controllerName:"",
				//区域设备列表
				areaControllerList:[],
				//搜索的区域设备
				searchControllerList:[],
				//设备弹框显示状态
				AddAreaControllerState:false,
				//查询所有设备加载状态
				AddAreaControllerload:false,
				//区域名称查询条件
				queryAreaName:"",
				//是否全设备
				isCheckAllController:false,
				//设备区域列表
				controllerAreaList:[],
				//区域设备列表
				ControllerList:[],
				//设备名称查询条件
				queryControllerName:"",
				//查询的设备的列表
				searchControllerNameList:[],
				//显示修改楼层弹框
				showUpdateFloorDialogStatus:false,
				//指定的设备Id
				appointController:0,
				//设备楼层
				controllerFloorList:[],
				//指定设备的楼层编码
				floorCode:"",
				//选择指定设备全选楼层
				checkControllerAll:false,
			};
		},
		watch: {
			//区域查询过滤使用
			filterText(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.tree.filter(val);
			},
			//设备区域查询过滤使用
			queryAreaName(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.Area.filter(val);
			}
		},
		mounted() {
			const that = this;
			window.onresize = () => {
				return (() => {
					that.screenHeight = window.innerHeight
				})()
			}
		},
		//加载事件
		created() {

			//获取操作权
			this.Role();

			//查询假期模板
			this.visitorarea();

		},
		methods: {
			//获取操作权
			Role() {
				//获取权限组
				var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
				for (var i = 0; i < userGroupDetails.length; i++) {
					if (userGroupDetails[i].roleName == "HostMode") { //配置操作权
						this.userGroupDetails.RoleHostMode = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
				}
			},
			
			//设备图片
			Equipmentimg(photo) {
				try {
					return require('../assets/images/Equipment/' + photo+'.png')
				} catch (e) {
					return ''
				}
			},

			//查询访客区域
			async visitorarea() {
				try 
				{
					this.PageLoad.Leftload = true;
					var {data : res} = await this.$http.post('visitorArea/queryVisitorArea');
					this.Leftloading = false; //隐藏加载动画
					if (res.resultCode == 1) 
					{
						var data = JSON.parse(res.body);
						var visitorareaList = [];
						for(var i=0;i<data.length;i++){
							visitorareaList[i] = {
								"remark":data[i].remark,
								"visitorAreaId":data[i].areaId,
								"visitorAreaName":data[i].areaName,
								"approver":data[i].approver
							}
						}
						this.visitorareaList = visitorareaList;
					}
				}
				catch (err) 
				{
					console.log(err)
				}
				this.PageLoad.Leftload = false;
			},

			//访客区域过滤
			filterNode(value, data) {
				if (!value) return true;
				return data.visitorAreaName.indexOf(value) !== -1;
			},

			//显示新增模板弹框
			addtemplateStaet() {
				this.ButtonsName = this.$t('VisitirArea.Confirm');
				this.templateTitle = this.$t('VisitirArea.AddVisitirArea');
				this.visitorareaform.VisitorAreaId = 0;
				this.visitorareaform.VisitorAreaName = "";
				this.visitorareaform.Remark = "";
				this.visitorareaform.approver = [];
				this.templateVisible = true;
			},

			//新增区域
			Addvisitorarea() {
				this.$refs.templateformRef.validate(async valid => {
					if (!valid) return;
					this.ButtonsName = this.$t('VisitirArea.InSubmit');
					this.ButtonLoad.AddUpdateArea = true;
					var area = this.visitorareaform;
					var data = {"areaName":area.VisitorAreaName,"remark":area.Remark};
					var {data:res} = await this.$http.post('visitorArea/addVisitorArea', data)
					this.ButtonsName = this.$t('VisitirArea.Confirm');
					if (this.FilteringOperation(res)) 
					{
						if (res.resultCode == 1)
						{
							this.$message({message: this.$t('VisitirArea.AddSucceed'),type: 'success',offset:10});
							this.visitorareaform.VisitorAreaName = '';
							this.visitorareaform.Remark = '';
							this.visitorarea();
							this.templateVisible = false;
						} 
					}
					this.ButtonLoad.AddUpdateArea = false;
				})
			},

			//显示修改区域弹框
			updtemplateStaet(data) {
				this.ButtonsName = this.$t('VisitirArea.Confirm');
				this.templateTitle = this.$t('VisitirArea.UpdateArea');
				this.templateVisible = true;
				this.visitorareaform.VisitorAreaId=data.visitorAreaId;
				this.visitorareaform.VisitorAreaName=data.visitorAreaName;
				this.visitorareaform.Remark=data.remark;
				this.visitorareaform.approver=data.approver;
				this.areaApproverList = data.approver;
			},

			//修改区域名称
			UpdTemplates() {
				this.$refs.templateformRef.validate(async valid => { //验证
					if (!valid) return;
					this.ButtonsName = this.$t('VisitirArea.InSubmit');
					this.ButtonLoad.AddUpdateArea = true;
					var area = this.visitorareaform;
					var areaApproverList = this.areaApproverList;
					areaApproverList = areaApproverList!=undefined && areaApproverList.length>0?"["+areaApproverList.toString()+"]":"";
					var data = {"areaName":area.VisitorAreaName,"remark":area.Remark,"areaId":area.VisitorAreaId,"approver":areaApproverList};
					var {data: res} = await this.$http.post('visitorArea/updateVisitorArea', data)
					this.ButtonsName = this.$t('VisitirArea.Confirm');
					if (this.FilteringOperation(res)) 
					{
						if(res.resultCode == 1)//修改成功 
						{ 
							this.$message({message: this.$t('VisitirArea.UpdateSucceed'),type: 'success',offset:10});
							this.visitorarea();
							this.templateVisible = false;
						}
					}
					this.ButtonLoad.AddUpdateArea = false;
				})
			},

			//删除区域
			DeleteTemolates(data) {
				this.$confirm(this.$t('VisitirArea.WhetherDelete'), this.$t('VisitirArea.Hint'), {type: 'warning'}).then(async () => {
					//删除模板
					var {data: res} = await this.$http.post('visitorArea/deleteVisitorArea ', {"areaId": data.visitorAreaId,"areaName":data.visitorAreaName});
					if (this.FilteringOperation(res)) 
					{
						if(res.resultCode == 1) //删除成功
						{ 
							this.$message({message: this.$t('VisitirArea.DeleteSucceed'),type: 'success',offset:10});
							this.visitorareaform.VisitorAreaId=0;
							this.visitorarea();
						}
					}
				}).catch(() => {
					//取消
				});
			},

			//选择区域
			optional(data) {
				//区域Id
				this.visitorareaform.VisitorAreaId = data.visitorAreaId;
				//区域名称
				this.visitorareaform.VisitorAreaName = data.visitorAreaName;
				//区域备注
				this.visitorareaform.Remark = data.remark;
				//区域审核人员Id
				this.visitorareaform.approver = data.approver;
				//查询审核人员
				this.getApproverList();
				//查询区域设备
				this.VisitorAreaController();
			},

			//查询区域审核人员
			async getApproverList(){
				try 
				{
					this.PageLoad.Rightload = true;
					var VisitorAreaId = this.visitorareaform.VisitorAreaId;
					var {data : res} = await this.$http.post('visitorArea/queryVisitorAreaApprover',{"areaId":VisitorAreaId});
					if (res.resultCode == 1) 
					{
						var data = JSON.parse(res.body);
						var areaApproverList = [];
						var areaApproverListDetails = [];
						for(var i = 0;i<data.length;i++)
						{
							areaApproverList[i] = data[i].employeeId;
							areaApproverListDetails[i] = {label:data[i].departmentName+" - "+data[i].employeeName+" - "+data[i].telephone,value:data[i].employeeId};
						}
						this.areaApproverList = areaApproverList;
						this.areaApproverListDetails = areaApproverListDetails;
						this.employeeList = areaApproverListDetails;
					}
				}
				catch (err) 
				{
					console.log(err)
				}
				this.PageLoad.Rightload = false;
			},

			// 搜索员工
			async remoteMethod(query) {
				if (query !== '') {
					var employeeList = []
					this.approverLoading = true;
					var areaApproverListDetails = this.areaApproverListDetails;
					var {data: res} = await this.$http.post('employee/queryEmpByNameOrPhone', {"nameOrPhone":query})
					if (this.FilteringOperation(res) && res.resultCode == 1) 
					{
						var data = JSON.parse(res.body);
						for(var i=0;i<data.length;i++){
							var status = false;
							for(var k=0;k<areaApproverListDetails.length;k++){
								if(data[i].employeeId==areaApproverListDetails[k].value){
									status = true;
								}
							}
							if(!status){
								employeeList[employeeList.length] = {label:data[i].departmentName+" - "+data[i].empName+" - "+data[i].phone,value:data[i].employeeId}
							}
						}
						for(var i=0;i<areaApproverListDetails.length;i++){
							employeeList.unshift(areaApproverListDetails[i]);
						}
						this.employeeList = employeeList;
					}
					this.approverLoading = false;
				} else {
					this.employeeList = [];
				}
			},

			//修改区域审核人员
			async setApprover(){
				try 
				{
					var area = this.visitorareaform;
					var areaApproverList = this.areaApproverList;
					areaApproverList = areaApproverList.length>0?"["+areaApproverList.toString()+"]":"";
					var data = {"areaName":area.VisitorAreaName,"remark":area.Remark,"areaId":area.VisitorAreaId,"approver":areaApproverList};
					var {data: res} = await this.$http.post('visitorArea/updateVisitorArea', data)
					this.ButtonsName = this.$t('VisitirArea.Confirm');
					if (this.FilteringOperation(res)) 
					{
						if(res.resultCode == 1)//修改成功 
						{ 
							this.$message({message: this.$t('VisitirArea.SaveSucceed'),type: 'success',offset:10});
						}
					}
				}
				catch (err) 
				{
					console.log(err)
				}
			},
			
			//查询区域设备
			async VisitorAreaController(){
				try 
				{
					this.PageLoad.Rightload = true;
					var VisitorAreaId = this.visitorareaform.VisitorAreaId;
					var {data : res} = await this.$http.post('visitorAreaController/queryVisitorAreaController  ',{"areaId":VisitorAreaId,"controllerName":this.value});
					if (res.resultCode == 1) 
					{
						this.areaControllerList = JSON.parse(res.body);
						//搜索区域设备
						this.searchController();
					}
				}
				catch (err) 
				{
					console.log(err)
				}
				this.PageLoad.Rightload = false;
			},

			//搜索区域设备
			searchController(){
				var searchControllerList = [];
				var controllerName = this.controllerName;
				if(controllerName!=""){
					var areaControllerList = this.areaControllerList;
					for(var i=0;i<areaControllerList.length;i++){
						if (areaControllerList[i].controllerName.indexOf(controllerName) !== -1) {
							searchControllerList[searchControllerList.length] = areaControllerList[i];
						}
					}
					this.searchControllerList = searchControllerList;
				}else{
					this.searchControllerList = this.areaControllerList;
				}
			},

			
			//添加区域设备
			AddAreaController(){
				//显示设备管理弹框
				this.AddAreaControllerState = true;
				//设备列表
				var ControllerList = this.areaControllerList;
				//已选择的设备Id
				var controllerValue = [];
				for(var i=0;i<ControllerList.length;i++)
				{
					controllerValue[i] = ControllerList[i].controllerId;
				}
				this.ControllerValue = controllerValue;
				//查询设备区域
				this.getControllerArea();
				//查询所有设备
				this.getControllers("");
			},

			//查询设备区域
			async getControllerArea() {
				try 
				{
					var {data: res} = await this.$http.post('ControllersArea')
					if (res.resultCode == 1) 
					{
						this.controllerAreaList = JSON.parse(res.body);
					}
				} 
				catch (err) 
				{
					console.log(err)
				}
			},

			//区域过滤
			filterNodeArea(value, data) {
				if (!value) return true;
				return data.regionName.indexOf(value) !== -1;
			},
			
			//查询区域设备
			async getControllers(areaCode){
				try
				{
					this.AddAreaControllerload = true;
					var areaId = this.visitorareaform.VisitorAreaId;
					var {data: res} = await this.$http.post('queryEquipmentInfoByAreaCode',{areaId:areaId,areaCode:areaCode});
					if (res.resultCode == 1){
						var Data = JSON.parse(res.body);
						var List = [];
						for(var i=0;i<Data.length;i++){
							//楼层编码
							var floorCode = Data[i].floorCode;
							floorCode = floorCode == undefined?"":floorCode;
							//判断楼层是否选中
							var floorCodeList = this.floorCodeInList(floorCode);
							var isContain = floorCodeList.some(item => item == Data[i].index);
							var floorList = Data[i].floorList;
							for(var k=0;k<floorList.length;k++){
								var isContain = floorCodeList.some(item => item == floorList[k].index);
								floorList[k].checked = isContain;
							}
							//是否选中设备
							var checked = Data[i].chooseControllerId;
							checked = checked==undefined?false:true;
							List[i] = {
								"controllerId":Data[i].controllerId,
								"controllerName":Data[i].controllerName,
								"location":Data[i].location,
								"floor":floorList,
								"checked":checked,
								"floorCode":floorCode,
								"taskType":Data[i].taskType,
							};

						}
						this.ControllerList = List;
						//搜索设备
						this.searchControllerName();
					}
					this.AddAreaControllerload = false;
				}
				catch (err)
				{
					this.AddAreaControllerload = false;
					console.log(err)
				}
			},

			//搜索设备
			searchControllerName(){
				var searchControllerNameList = [];
				var controllerList = this.ControllerList;
				var controllerName = this.queryControllerName;
				for(var i=0;i<controllerList.length;i++){
					if (controllerList[i].controllerName.indexOf(controllerName) !== -1) {
						searchControllerNameList[searchControllerNameList.length] = controllerList[i];
					}
				}
				this.searchControllerNameList = searchControllerNameList;
			},

			//全选设备
			checkAllController(event){
				var controllerList = this.ControllerList;
				for(var i=0;i<controllerList.length;i++){
					var checkFloor = [];
					controllerList[i].checked = event;
					var floorList = controllerList[i].floor;
					for(var k=0;k<floorList.length;k++){
						if(floorList[k].status==0) {
							floorList[k].checked = false;
							continue
						};
						floorList[k].checked = event;
						if(event){
							checkFloor[checkFloor.length] = floorList[k].index;
						}
					}
					controllerList[i].floorCode = this.listInfloorCode(checkFloor);
				}
			},

			//楼层编码转数组
			floorCodeInList(floorCode){
				var newFloorCode = "";
				for(var i=0;i<floorCode.length-1;i+=2){
					var code = floorCode.substring(i,i+2);
					code = parseInt(code,16).toString(2);
					var count = (8-code.length);
					for(var k=0;k<count;k++)
					{
						code = "0" + code;
					}
					newFloorCode = code+newFloorCode;
				}
				//翻转顺序
				newFloorCode = newFloorCode.split("").reverse().join("");
				var OriginalList = newFloorCode.split('');
				var floorCodeList = [];
				var index = -8;
				for(var i=0;i<128;i++)
				{
					if(index==0) index=1;
					if(OriginalList[i]==1)
					{
						floorCodeList[floorCodeList.length] = index;
					}
					index++;
				}
				return floorCodeList;
			},
			
			//全选/取消全选楼层
			checkAllFloor(index,event){
				var checkFloor = [];
				var floor = this.ControllerList[index].floor;
				for(var i=0;i<floor.length;i++){
					floor[i].checked = floor[i].status == 1?event:false;
					if(event){
						checkFloor[checkFloor.length] = floor[i].index;
					}
				}
				this.ControllerList[index].checked = event;
				this.ControllerList[index].floorCode = this.listInfloorCode(checkFloor);
			},

			//选择/取消楼层
			checkFloor(index,floorIndex,event){
				var checkFloor = [];
				var checked = false;
				var floor = this.ControllerList[index].floor;
				for(var i=0;i<floor.length;i++){
					if(floor[i].checked){
						checked = true;
						checkFloor[checkFloor.length] = floor[i].index;
					}
				}
				this.ControllerList[index].checked = checked;
				this.ControllerList[index].floorCode = this.listInfloorCode(checkFloor);
			},

			//楼层数组转换楼层code
			listInfloorCode(floorList){
				var floorCode = "";
				for(var i=-8;i<121;i++){
					if(i!=0){
						var Status = false;
						for(var k=0;k<floorList.length;k++){
							if(i==floorList[k]){
								floorCode = floorCode+"1";
								Status = true;
								break;
							}
						}
						if(!Status) floorCode = floorCode+"0";
					}
				}
				var floorCode16 = "";
				floorCode = floorCode.split("").reverse().join("");
				for(var i=0;i<floorCode.length/8;i++){
					var Code = floorCode.substring(i*8,(i+1)*8);
					var Code10 = parseInt(Code,2);//转10进制
					var Code16 = Code10.toString(16);//转16进制
					if(Code16.length<2) Code16 = "0"+Code16;
					floorCode16 = Code16+floorCode16;
				}
				return floorCode16;
			 },

			//保存选中的设备
			async Addvisitorareacontroller(){
				try
				{
					//勾选的设备
					var controllerList = this.ControllerList;
					var areaId = this.visitorareaform.VisitorAreaId;
					var parameter = {areaId:areaId,list:[]};
					var details = []
					for(var i=0;i<controllerList.length;i++)
					{
						if(controllerList[i].checked && controllerList[i].taskType==1 && controllerList[i].floorCode==""){
							this.$message({message: this.$t('VisitirArea.AddControllerSpecification'),type: 'warning',offset:10});
							return;
						}else if(controllerList[i].checked)
						{
							details[details.length] = {controllerId:controllerList[i].controllerId,floorCode:controllerList[i].floorCode};
						}
					}
					parameter.list = details;
					this.ButtonLoad.AddUpdateArea = true;
					var {data: res} = await this.$http.post('visitorAreaController/addVisitorAreaController', parameter)
					if (this.FilteringOperation(res)) 
					{
						this.AddAreaControllerState = false;
						this.$message({message: this.$t('VisitirArea.AddSucceed'),type: 'success',offset:10});
						this.VisitorAreaController();
					}
				}
				catch (err)
				{
					console.log(err)
				}
				this.ButtonLoad.AddUpdateArea = false;
			},

			//显示设备楼层弹框
			async showUpdateFloorDialog(controllerId){
				var areaId = this.visitorareaform.VisitorAreaId;
				var {data: res} = await this.$http.post('controllerFloor/querySingleControllerFloor', {areaId:areaId,controllerId:controllerId})
				if (res.resultCode == 1){
					var Data = JSON.parse(res.body);
					var floorCode = Data.floorCode;
					floorCode = floorCode==undefined?"":floorCode;
					var floorCodeList = this.floorCodeInList(floorCode);
					var controllerFloorList = Data.list;
					var checkControllerAll = controllerFloorList.length>0?true:false;
					for(var i=0;i<controllerFloorList.length;i++){
						var isFloor = floorCodeList.some(item => item == controllerFloorList[i].index);
						controllerFloorList[i].checked = isFloor;
						if(!isFloor && controllerFloorList[i].status==1){
							checkControllerAll = false;
						}
					}
					this.floorCode = floorCode;
					this.appointController = controllerId;
					this.controllerFloorList = controllerFloorList;
					this.checkControllerAll = checkControllerAll;
					this.showUpdateFloorDialogStatus = true;
				}
			},

			//全选/取消全选指定设备楼层
			checkControllerAllFloor(event){
				var checkFloor = [];
				var controllerFloorList = this.controllerFloorList;
				for(var i=0;i<controllerFloorList.length;i++){
					controllerFloorList[i].checked = controllerFloorList[i].status == 1?event:false;
					if(controllerFloorList[i].checked){
						checkFloor[checkFloor.length] = controllerFloorList[i].index;
					}
				}
				this.floorCode = this.listInfloorCode(checkFloor);
			},

			//选择/取消楼层
			checkControllerFloor(){
				var checked = true;
				var checkFloor = [];
				var controllerFloorList = this.controllerFloorList;
				for(var i=0;i<controllerFloorList.length;i++){
					if(!controllerFloorList[i].checked && controllerFloorList[i].status==1){
						checked = false;
					}else if(controllerFloorList[i].checked && controllerFloorList[i].status==1){
						checkFloor[checkFloor.length] = controllerFloorList[i].index;
					}
				}
				this.checkControllerAll = checked;
				this.floorCode = this.listInfloorCode(checkFloor);
			},

			//修改楼层
			async setControllerFloor(){
				var floorCode = this.floorCode;
				var controllerId = this.appointController;
				var areaId = this.visitorareaform.VisitorAreaId;
				var {data: res} = await this.$http.post('visitorAreaController/updateSingleVisitorAreaController', {areaId:areaId,controllerId:controllerId,floorCode:floorCode})
				if (this.FilteringOperation(res)){
					this.showUpdateFloorDialogStatus = false;
					this.$message({message: this.$t('VisitirArea.AddSucceed'),type: 'success',offset:10});
				}
			},
			
			//删除设备
			async Deletevisitorareacontroller(e){
				this.$confirm(this.$t('VisitirArea.WhetherDelete'), this.$t('VisitirArea.Hint'), {type: 'warning'}).then(async () => {
					try
					{
						var Controller = e;
						var {data: res} = await this.$http.post('visitorAreaController/deleteVisitorAreaController', {"controllerId":Controller,"areaId":this.visitorareaform.VisitorAreaId})
						if (this.FilteringOperation(res)) 
						{
							this.dialogVisible = false;
							this.$message({message: this.$t('VisitirArea.DeleteSucceed'),type: 'success',offset:10});
							this.VisitorAreaController();
						}
					}
					catch (err)
					{
						console.log(err)
					}
				}).catch(() => {
					//取消
				});
			},
			
			//请求结果过滤
			FilteringOperation(res) {
				var State = true;
				if (res.stateCode == 0) {
					State = false;
					this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
				} else if (res.stateCode == 2) {
					State = false;
					this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
				} else if (res.stateCode == 3) {
					State = false;
					this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
				}
				return State;
			},
		}
	};
</script>

<style scoped>

	.VisitirArea{
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	/* 内容 */
	.content{
		display: flex;
		height: 100%;
	}
	
	/* 左侧 */
	.content_left{
		background-color: #FFFFFF;
		width: 290px;
		height: 100%;
		margin-right: 20px;
	}

	.left_value {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.left_value_input {
		width:90%;
		margin:15px auto;
		display: flex;
		margin-bottom: 10px;
	}

	.left_value_tree{
		width:90%;
		flex-grow: 1;
		margin: 0px auto;
		margin-bottom: 15px;
		overflow: auto;
	}

	.left_tree{
		display: flex;
		align-items:center;
		justify-content: space-between;
		width: 100%;
		font-size: 15px;
	}

	/* 右侧 */
	.content_right{
		flex-grow: 1;
		width: 0px;
		display: flex;
		flex-direction: column;
	}

	/* 区域审核人员 */
	.content_right_approver{
		margin-bottom: 20px;
		padding: 20px 20px;
		background-color: #FFFFFF;
	}
	.approver_title{
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 20px;
	}
	.approver_option{
		display: flex;
		width: 100%;
		padding-top: 20px;
	}
	/* 区域设备 */
	.controller_card{
		flex-grow: 1;
		padding: 20px;
		display: flex;
		flex-direction: column;
		background-color: #FFFFFF;
	}
	/* 设备标题 */
	.controller_title{
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 20px;
	}
	/* 搜索条件 */
	.content_right_top{
		/* margin-top: 20px; */
		display: flex;
	}

	/* 右侧-底部 */
	.content_right_bottom{
		flex-grow: 1;
		height: 0px;
		width: 100%;
		margin-top: 20px;
	}
	
	.HolidayTemplateList {
		flex-grow: 1;
		flex-direction: column;
		color: #909399;
		background-color: #FFFFFF;
	}

	.HolidayTemplateList>div {
		text-align: center;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}

	/* 设备更改弹框 */
	.controller_dialog{
		margin-top: 20px;
		height: 660px;
		display: flex;
	}
	/* 左侧设备区域 */
	.controller_area{
		width: 250px;
		margin-right: 20px;
		padding: 20px;
		padding-bottom: 0px;
		border-radius: 5px;
		border:1px solid #eeeeee;
	}
	.queryAreaName{
		margin-bottom: 10px;
	}
	/* 右侧设备数据 */
	.controller_list{
		width: 0px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		padding: 20px;
		padding-bottom: 0px;
		border-radius: 5px;
		border:1px solid #eeeeee;
	}
	.controller_list_details{
		overflow: auto;
	}
	.controller_list_details>div{
		padding: 0px 10px;
		border-bottom:1px dashed #eeeeee;
	}
	.controller_list_details>div:last-child {
		border: 0px;
	}
	.controller_details_right:hover{
		cursor: pointer;
	}
	/* 设备信息 */
	.controller_details{
		height: 55px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.controller_details_right{
		height: 55px;
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: end;
	}
	.controller_details_right>div{
		margin-right: 15px;
	}
	/* 楼层信息 */
	.floor_item{
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 8px;
	}
	.floor_item>div{
		margin-right: 15px;
		margin-bottom: 15px;
	}
	.floor_item_checked{
		width: 100px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>

<style>
</style>