<template>
	<div class="Automatic">
		<!-- 左侧 -->
		<div class="content_left boxshadow" v-loading="PageLoad.Leftload">
			<div class="content_left_value">
				<!-- 功能栏 -->
				<div class="left_value_input">
					<el-input :placeholder="$t('Automatic.SearchTemplate')" size="medium" v-model="filterText" style="width:66%;"></el-input>
					<el-button :disabled="!userGroupDetails.RoleAutomatic" icon="el-icon-circle-plus-outline" style="margin-left:15px" size="medium" type="primary" @click="addtemplateStaet">{{$t('Automatic.Add')}}</el-button>
				</div>

				<!-- 树形 -->
				<el-tree class="left_value_tree" :highlight-current="true" :data="AutomaticList" :filter-node-method="filterNode" ref="tree" node-key="AreaId"
				default-expand-all :expand-on-click-node="false" icon-class="el-icon-s-promotion">
					<span class="custom-tree-node left_tree" slot-scope="{data}">
						<el-popover placement="left" v-if="data.code!=''" style="width: 100%;" :title="$t('Automatic.Remark')" width="200" trigger="hover" :content="data.code">
							<div style="width: 100%" slot="reference" @click="optional(data)">{{ data.templateName }}</div>
						</el-popover>
						<div style="width: 100%;" v-else slot="reference" @click="optional(data)">{{ data.templateName }}</div>
						<span v-if="userGroupDetails.RoleAutomatic && data.companyId!=0">
							<el-popover placement="right" trigger="hover" :open-delay="300">
							<el-button type="info" size="mini" @click="updtemplateStaet(data)">
								{{$t('Automatic.Update')}}
							</el-button>
							<el-button type="danger" size="mini" @click="DeleteTemolates(data)">
								{{$t('Automatic.Delete')}}
							</el-button>
							<i class="el-icon-more left_tree_icon" slot="reference"></i>
						</el-popover>
						</span>
					</span>
				</el-tree>
			</div>
		</div>
		<!-- 右侧 -->
		<div class="content_right" v-if="TemplatesId!=0">
			<!-- 头部 -->
			<div class="content_right_top flex-items-center">
				<div class="boxshadow">
					<el-select size="medium" clearable  v-model="TimeTableValue" :placeholder="$t('Automatic.SelectTimeTemplate')">
						<el-option
						v-for="item in TimeTableList"
						:key="item.templatesId"
						:label="item.templateName"
						:value="item.templatesId">
						</el-option>
					</el-select>
					<el-button type="primary" style="margin-left: 10px;" :disabled="!userGroupDetails.RoleAutomaticDetails" 
					icon="el-icon-circle-check" :loading="ButtonState" @click="Save()" size="medium">{{$t('Automatic.Save')}}</el-button>
				</div>
			</div>
			<!-- 底部 -->
			<div class="content_right_bottom">
				<div class="boxshadow flex-center" v-loading="PageLoad.Rightload1">
					<div>
						<div class="clearfix flex-items-center">
							<el-checkbox v-model="DepartmentValue" @change="CheckDepartment()">{{$t('Automatic.DepartmentList')}}</el-checkbox>
							<el-tooltip class="item" effect="dark" :content="$t('Automatic.Refresh')" placement="top">
								<el-button style="float: right;" @click="DepartmentRefresh()" icon="el-icon-refresh-right" size="mini"></el-button>
							</el-tooltip>
						</div>
						<div class="split"></div>
						<div class="contrnt_tree">
							<el-tree v-loading="DepartmentLoad" :data="DepartmentList" ref="TreeDepartment" :props="DepartmentProps" node-key="departmentId" show-checkbox 
							:default-checked-keys="SelectTheDepartment" default-expand-all icon-class="el-icon-s-promotion">
								<span class="custom-tree-node" slot-scope="{ data }">
									<div style="width: 100%;">{{ data.departmentName }}</div>
								</span>
							</el-tree>
						</div>
					</div>
				</div>
				<div class="boxshadow flex-center" v-loading="PageLoad.Rightload2">
					<div>
						<div class="clearfix flex-items-center">
							<el-checkbox v-model="AreaValue" @change="CheckArea()">{{$t('Automatic.AreaList')}}</el-checkbox>
							<el-tooltip class="item" effect="dark" :content="$t('Automatic.Refresh')" placement="top">
								<el-button style="float: right;" @click="AreaRefresh()" icon="el-icon-refresh-right" size="mini"></el-button>
							</el-tooltip>
						</div>
						<div class="split"></div>
						<div class="contrnt_tree">
							<el-tree :data="AreaList" ref="TreeArea" :props="DepartmentProps" node-key="controllersAreaId" show-checkbox 
							:default-checked-keys="SelectTheArea" default-expand-all icon-class="el-icon-s-promotion">
								<div class="custom-tree-node"  slot-scope="{ data }">
									<div style="width: 100%;">
										{{ data.regionName }}
									</div>
								</div>
							</el-tree>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="content_right2 flex-center boxshadow">
			<div>
				<i class="el-icon-thumb" style="font-size: 100px;"></i>
				<div style="text-align: center;font-size: 17px;">{{$t('Automatic.AutomaticAuthorization')}}</div>
			</div>
		</div>
		
		<!-- 模板弹框 -->
		<el-dialog :title="templateTitle" :visible.sync="templateVisible" width="30%" :close-on-click-modal="false">
			<el-form ref="templateformRef" label-width="100px" class="demo-ruleForm" :model="templateForm" :rules="templateFormRules"
			 label-position="left">
				<el-form-item :label="$t('Automatic.TemplateName')" prop="TemplateName">
					<el-input v-model="templateForm.TemplateName" :placeholder="$t('Automatic.InputTemplateName')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Automatic.Remark')">
					<el-input v-model="templateForm.Code" type="textarea"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="templateVisible = false">{{$t('Automatic.Cancel')}}</el-button>
				<el-button type="primary" v-if="templateForm.TemplatesId==0" :loading="ButtonState" @click="AddTemplates">{{ButtonsName}}</el-button>
				<el-button type="primary" v-if="templateForm.TemplatesId!=0" :loading="ButtonState" @click="UpdTemplates">{{ButtonsName}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>

import 'driver.js/dist/driver.min.css'
import Driver from 'driver.js'

	export default {
			data() {
				//校验部门名称
			var Verify = (rule, value, callback) => {
				if (!value) return callback(new Error(this.$t('Automatic.PleaseInput')));
				callback();
			};
				return{
					//权限组
					userGroupDetails: {
						RoleAutomatic: false, //自动注册模板操作权
						RoleAutomaticDetails: false //自动注册模板详情操作权
					},

					// 页面加载
					PageLoad:{
						Leftload: false, //左侧加载状态
						Rightload1: false, //右侧加载状态
						Rightload2: false, //右侧加载状态
					},
					
					//左侧自动注册模板
					AutomaticLoad:false,//左侧加载状态
					AutomaticList:[],//自动注册模板
					filterText: '', //搜索过滤
					defaultProps: { //模板格式
						children: 'templatesId',
						label: 'templateName'
					},
					
					//新增自动注册模板弹框
					templateVisible:false,//新增模板弹框--弹框状态
					ButtonsName: '', //新增模板弹框--按钮文本
					ButtonState:false,//新增模板弹框--按钮状态
					templateTitle:'',//新增模板弹框--标题
					templateForm: { // 添加修改模板表单参数
						TemplateType:4,
						TemplatesId: 0,
						TemplateName: "",
						Code: ""
					},
					templateFormRules: { //添加修改模板表单验证
						TemplateName: [{
							required: true,
							validator: Verify,
							trigger: 'blur'
						}]
					},
					
					Title:'',//模板标题
					ButtonState:false,//保存按钮状态
					TemplatesId:0,//选中的模板ID
					Check:false,//按条件授权选中状态
					TimeTableList:[],//时间表
					TimeTableValue:'',//时间表选择
					DepartmentValue:false,//全选--部门列表
					DepartmentLoad:false, //部门列表加载状态
					DepartmentProps: { //部门列表参数
						children: 'children',
						label: 'departmentName'
					},
					DepartmentList: [], 
					SelectTheDepartment:[],//选中的部门
					AreaValue:false,//全选--区域列表
					DepartmentProps: { //区域列表参数
						children: 'children',
						label: 'regionName'
					},
					AreaLoad:false,//区域列表加载状态
					AreaList:[],//区域列表
					SelectTheArea:[], // 选中的区域
					EquipmentList:[] //设备列表
				}
			},
			watch: {
				filterText(val) {
					this.$refs.tree.filter(val);
				}
			},
			mounted() {
				//高度
				const that = this;
				window.onresize = () => {
					return (() => {
						that.screenHeight = window.innerHeight
					})()
				}

				let cookieAutomaticGuide = this.getCookie()
			 	if(cookieAutomaticGuide === undefined){
				setTimeout(()=>{this.automatic_guide()},1000)
				}
			},
			created() {
				this.Role();//获取操作权
				this.Automatic();//查询自动注册模板
				this.TimeTemplates();//查询时间模板
				this.Department();//查询部门
				this.Area();//查询区域
			},
			methods: {

			//常开常闭引导
			automatic_guide () {
			let driver = new Driver({
				className: 'scoped-class', // 包裹driver.js弹窗的类名 className to wrap driver.js popover
				animate: true, // 高亮元素改变时是否显示动画 Animate while changing highlighted element
				opacity: 0.75, // 背景透明度(0 表示只有弹窗并且没有遮罩) Background opacity (0 means only popovers and without overlay)
				padding: 10, //  元素与边缘的距离 Distance of element from around the edges
				allowClose: false, // 是否允许点击遮罩时关闭 Whether clicking on overlay should close or not
				overlayClickNext: false, // 是否允许点击遮罩时移到到下一步 Should it move to next step on overlay click
				doneBtnText: '完成', // 最终按钮上的文本 Text on the final button
				closeBtnText: '关闭', // 当前步骤关闭按钮上的文本 Text on the close button for this step
				nextBtnText: '下一步', // 当前步骤下一步按钮上的文本 Next button text for this step
				prevBtnText: '上一步', // 当前步骤上一步按钮上的文本 Previous button text for this step
				showButtons: true, // 是否在底部显示控制按钮 Do not show control buttons in footer
				keyboardControl: true, // 是否允许通告键盘控制(escape关闭，箭头键用于移动)Allow controlling through keyboard (escape to close, arrow keys to move)
				scrollIntoViewOptions: {}, //  `scrollIntoView()` 方法的选项 We use `scrollIntoView()` when possible, pass here the options for it if you want any
			})
			// Define the steps for introduction
			let steps = [
				{
				element: '.left_value_input',
				popover: {
					title: this.$t('Automatic.AutomaticTitle1'),
					description: this.$t('Automatic.AutomaticContent1'),
					position: 'right'
				}
				},
				{
				element: '.left_value_tree',
				popover: {
					title: this.$t('Automatic.AutomaticTitle2'),
					description: this.$t('Automatic.AutomaticContent2'),
					position: 'right'
				}
				},
				{
				element: '.content_right2',
				popover: {
					title: this.$t('Automatic.AutomaticTitle3'),
					description: this.$t('Automatic.AutomaticContent3'),
					position: 'left'
				}
				},
			]
			driver.defineSteps(steps)
			// Start the introduction
			driver.start()
			// 保存
			 this.setCookie()
			},

			
			setCookie(){
				//设置10年后过期
				let expires = new Date();
				expires.setTime(expires.getTime()+10*365*24*60*60*1000);
				document.cookie = "automaticGuide="+escape('1')+";expires="+expires.toGMTString();
			},
			getCookie(){
				//获取cookie值
				let str = document.cookie;
				//将值切割成数组
				let arr = str.split(";");
				let automaticGuide;
				//遍历数组
				for(var i=0;i<arr.length;i++){
					var value = arr[i].split("=");
					//避免输入中含有空格，消除文本框空格
          			value[0] = value[0].replace(/\s+/g, "");
					if(value[0] == 'automaticGuide'){
						automaticGuide = value[1];
					}
				}
				console.log("automaticGuide:" + automaticGuide)
				return automaticGuide;
			},
				
				//获取操作权
				Role() {
					var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))//获取权限组
					for (var i = 0; i < userGroupDetails.length; i++) {
						if (userGroupDetails[i].roleName == "Automatic") { //自动注册模板操作权
							this.userGroupDetails.RoleAutomatic = userGroupDetails[i].roleOperateValue == "1" ? true : false;
						}
						if (userGroupDetails[i].roleName == "AutomaticDetails") { //自动注册模板详情操作权
							this.userGroupDetails.RoleAutomaticDetails = userGroupDetails[i].roleOperateValue == "1" ? true : false;
						}
					}
				},
				
				//查询自动注册模板
				async Automatic(){
					try
					{
						this.PageLoad.Leftload = true;
						var {data : res} = await this.$http.post('Automatic');
						if (res.resultCode == 1)//成功
						{ 
							this.AutomaticList = JSON.parse(res.body);
						}
						this.PageLoad.Leftload = false;
					} 
					catch (err)
					{
						this.PageLoad.Leftload = false;
						console.log(err)
					}
					
				},
				
				//自动注册模板查询过滤
				filterNode(value, data) {
					if (!value) return true;
					return data.templateName.indexOf(value) !== -1;
				},
				
				//显示新增模板弹框
				addtemplateStaet() {
					this.ButtonsName = this.$t('Automatic.Confirm');
					this.templateTitle = this.$t('Automatic.AddAuthorizationTemplate');
					this.templateVisible = true;
					this.templateForm = {
						TemplateType:4,
						TemplatesId: 0,
						TemplateName: "",
						Code: ""
					}
				},
				
				//新增自动注册模板
				AddTemplates() {
					this.$refs.templateformRef.validate(async valid => { //验证
						if (!valid) return;
						//新增自动注册模板
						this.ButtonsName = this.$t('Automatic.InSubmit');
						this.buttonState = true;
						var {data: res} = await this.$http.post('AddAutomaticTemplates', this.templateForm)
						this.ButtonsName = this.$t('Automatic.Confirm');
						this.buttonState = false;
						if (this.FilteringOperation(res)) {
							if (res.resultCode == 1) { //新增成功
								this.$message({offset:10,message: this.$t('Automatic.AddSucceed'),type: 'success'});
								this.templateForm.TemplateName = '';
								this.Automatic();
								this.templateVisible = false;
							} else { //新增失败
								this.$message({offset:10,message: this.$t('Automatic.AddFailure'),type: 'error'});
							}
						}
					})
				},
				
				//显示修改模板弹框
				updtemplateStaet(data) {
					this.ButtonsName = this.$t('Automatic.Confirm');
					this.templateTitle =  this.$t('Automatic.UpdateAuthorizationTemplate');
					this.templateVisible = true;
					this.templateForm.TemplateName = data.templateName;
					this.templateForm.Code = data.code;
					this.templateForm.TemplatesId = data.templatesId;
				},
				
				//修改假期模板
				UpdTemplates() {
					this.$refs.templateformRef.validate(async valid => { //验证
						if (!valid) return;
						this.ButtonsName = this.$t('Automatic.InSubmit');
						this.buttonState = true;
						var {data: res} = await this.$http.post('UpdTemplates', this.templateForm)
						this.ButtonsName = this.$t('Automatic.Confirm');
						this.buttonState = false;
						if (this.FilteringOperation(res)) //过滤
						{
							if (res.resultCode == 1) //修改成功
							{ 
								this.$message({offset:10,message: this.$t('Automatic.UpdateSucceed'),type: 'success'});
								if(this.templateForm.TemplatesId==this.TemplatesId)
								{
									this.Title = this.templateForm.TemplateName;
								}
								this.templateForm.TemplateName = '';
								this.templateVisible = false;
								this.Automatic();
							}
							else //修改失败
							{ 
								this.$message({offset:10,message: this.$t('Automatic.UpdateFailure'),type: 'error'});
							}
						}
					})
				},
				
				//删除模板
				DeleteTemolates(data) {
					this.$confirm( this.$t('Automatic.WhetherDelete'), this.$t('Automatic.Hint'), {type: 'warning'}).then(async () => {
						//删除模板
						var {data: res} = await this.$http.post('DeleteAutomaticTemplates', {"TemplatesId": data.templatesId});
						if (this.FilteringOperation(res)) //过滤
						{
							if (res.resultCode == 1) //删除成功
							{
								this.$message({offset:10,message: this.$t('Automatic.DeleteSucceed'),type: 'success'});
								if(data.templatesId==this.TemplatesId)
								{
									this.TemplatesId = 0;
								}
								this.Automatic();
							} 
							else //删除失败
							{ 
								this.$message({offset:10,message:this.$t('Automatic.DeleteFailure'),type: 'error'});
							}
						}
					}).catch(() => {
						//取消
					});
				},
			
				//选择模板
				optional(data) {
					this.TemplatesId = data.templatesId;//模板Id
					this.Title = data.templateName;//模板名称
					this.SelectTheDepartment = [];//选中的部门
					this.Check = data.onlyByCondition == 0 ? false:true;
					var TimeTableValue = '';
					for(var i=0;i<this.TimeTableList.length;i++)
					{
						if(data.scheduleId==this.TimeTableList[i]["templatesId"])
						{
							TimeTableValue = data.scheduleId;
						}
					}
					this.TimeTableValue = TimeTableValue;
					this.PitchOnDepartment();//查询选中的部门
					this.PitchOnArea();//查询选中的区域
				},
				
				//查询时间模板
				async TimeTemplates() {
					try 
					{
						var {data: res} = await this.$http.post('TimeTemplates')
						if (res.resultCode == 1)//成功
						{
							this.TimeTableList = JSON.parse(res.body);
						}
					} 
					catch (err) 
					{
						console.log(err)
					}
					
				},
				
				//部门刷新
				DepartmentRefresh(){
					this.DepartmentValue = false;
					this.Department();
					this.PitchOnDepartment();
				},
				
				//部门全选
				CheckDepartment(){
					if(this.DepartmentValue)
					{
						var Date = [];
						for(var i=0;i<this.DepartmentList.length;i++)
						{
							Date[i] = this.DepartmentList[i]["departmentId"];
						}
						this.$refs.TreeDepartment.setCheckedKeys(Date);
					}
					else
					{
						this.$refs.TreeDepartment.setCheckedKeys([]);
					}
				},
				
				//查询部门
				async Department() {
					try 
					{
						var {data: res} = await this.$http.post('Departments')
						if (res.resultCode == 1)//成功
						{ 
							var DepartmentList = JSON.parse(res.body);
							this.DepartmentList = DepartmentList;
						}
					} 
					catch (err)
					{
						console.log(err)
					}
				},
				
				//查询选中的部门
				async PitchOnDepartment() {
					try 
					{
						this.PageLoad.Rightload1 = true;
						var {data: res} = await this.$http.post('AutomaticDepartments',{"TemplatesId": this.TemplatesId})
						if (res.resultCode == 1)//成功
						{
							this.$refs.TreeDepartment.setCheckedKeys([]);
							var SelectTheDepartment = [];
							var Data = JSON.parse(res.body);
							for(var i=0;i<Data.length;i++)
							{
								SelectTheDepartment[i] = Data[i].departmentId;
							}
							this.SelectTheDepartment = SelectTheDepartment;
						}
						this.PageLoad.Rightload1 = false;
					} 
					catch (err)
					{
						this.PageLoad.Rightload1 = false;
						console.log(err)
					}
				},
				
				//获取是否存在子部门
				DepartmentNotTrr(e,Department){
					for(var i=0;i<Department.length;i++)
					{
						if(Department[i].departmentId == e.departmentId)
						{
							if(e.children.length>0)
							{
								console.log(Department[i],true)
							}
							else
							{
								this.SelectTheDepartment[this.SelectTheDepartment.length] = Department[i].departmentId;
							}
						}
					}
					for(var i=0;i<e.children.length;i++)
					{
						var department = e.children[i];
						this.DepartmentNotTrr(department,Department);
					}
				},
				
				//区域刷新
				AreaRefresh(){
					this.AreaValue = false;
					this.Area();
					this.PitchOnArea();
				},
				
				//查询区域
				async Area() {
					try {
						//请求服务端获取区域
						var {data: res} = await this.$http.post('ControllersArea')
						if (res.resultCode == 1) 
						{
							this.AreaList = JSON.parse(res.body);
						}
					} 
					catch (err) 
					{
						console.log(err)
					}
					
				},
				
				//查询选中的区域
				async PitchOnArea() {
					try 
					{
						this.PageLoad.Rightload2 = true;
						var {data:res} = await this.$http.post('AutomaticArea',{"TemplatesId": this.TemplatesId})
						if (res.resultCode == 1)//成功
						{ 	
							this.$refs.TreeArea.setCheckedKeys([]);
							var SelectTheArea = [];
							var Data = JSON.parse(res.body);
							for(var i=0;i<Data.length;i++)
							{
								SelectTheArea[i] = Data[i]["controllersAreaId"];
							}
							this.SelectTheArea = SelectTheArea;
						}
						this.PageLoad.Rightload2 = false;
					} 
					catch (err)
					{
						this.PageLoad.Rightload2 = false;
						console.log(err)
					}
				},
				
				//区域全选
				CheckArea(){
					if(this.AreaValue)
					{
						var Date = [];
						for(var i=0;i<this.AreaList.length;i++)
						{
							Date[i] = this.AreaList[i]["controllersAreaId"];
						}
						this.$refs.TreeArea.setCheckedKeys(Date);
					}
					else
					{
						this.$refs.TreeArea.setCheckedKeys([]);
					}
				},
				
				//保存
				async Save(){
					var Department = this.$refs.TreeDepartment.getCheckedKeys();
					var Area =  this.$refs.TreeArea.getCheckedKeys();
					var OnlyByCondition  = this.Check ? 1:0;
					var ScheduleId = this.TimeTableValue == ""? 0:this.TimeTableValue;
					this.ButtonState = true;
					var body = {"TemplatesId":this.TemplatesId,"TimeTemplate":this.TimeTableValue,"DepartmentId":Department,"Area":Area,"OnlyByCondition":OnlyByCondition,"ScheduleId":ScheduleId}
					var {data:res} = await this.$http.post('Save',body);
					this.ButtonState = false;
					if(this.FilteringOperation(res))
					{
						if(res.resultCode == 1)
						{
							this.$message({offset:10,message:this.$t('Automatic.SaveSucceed'),type: 'success'});
							this.Automatic();
						}
						else
						{
							this.$message({offset:10,message: this.$t('Automatic.SaveFailure'),type: 'error'});
						}
					}
				},
				
				//操作过滤
				FilteringOperation(res){
					var State = true;
					if (res.stateCode == 0) {
						State = false;
						this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
					} else if (res.stateCode == 2) {
						State = false;
						this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
					} else if (res.stateCode == 3) {
						State = false;
						this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
					} 
					return State;
				}
			}
		}
</script>

<style scoped>

	.Automatic{
		display: flex;
		width: 100%;
		height: 100%;
	}

	/* 左侧 */
	.content_left{
		background-color: #FFFFFF;
		width: 290px;
		height: 100%;
		margin-right: 20px;
	}

	.content_left_value {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.left_value_input {
		width:90%;
		margin:15px auto;
		display: flex;
		margin-bottom: 10px;
	}

	.left_value_tree{
		width:90%;
		flex-grow: 1;
		margin: 0px auto;
		margin-bottom: 15px;
		overflow: auto;
	}

	.left_tree{
		display: flex;
		align-items:center;
		justify-content: space-between;
		width: 100%;
		font-size: 15px;
	}

	.left_tree_icon{
		padding: 0px 5px;
	}

	.content_right{
		flex-grow: 1;
		flex-direction: column;
		display: flex;
	}

	/* 右侧-头部 */
	.content_right_top{
		width: 100%;
		display: flex;
		margin-bottom: 20px;
	}

	.content_right_top>div{
		width: 100%;
		padding: 15px 16px;
		display: flex;
		justify-content: space-between;
		background-color: #FFFFFF;
	
	}

	/* 右侧-底部 */
	.content_right_bottom{
		flex-grow: 1;
		height: 0px;
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.content_right_bottom>div{
		width: 49.1%;
		height: 100%;
		background-color: #FFFFFF;
	}

	.content_right_bottom>div>div{
		width: 95%;
		height: 95%;
		display: flex;
		flex-direction: column;
	}

	.clearfix{
		height: 30px;
		justify-content: space-between;
	}

	.split{
		margin-top: 10px;
		height: 10px;
		border-top:1px solid #EEE;
	}

	.contrnt_tree{
		overflow: auto;
		height: 0px;
		flex-grow: 1;
	}

	.content_right2{
		flex-grow: 1;
		display: flex;
		background-color: #FFFFFF;
		flex-direction: column;
		color: #909399;
	}

	.content_right2>div{
		text-align: center;
	}


	.from {
		width: 100%;
		display: flex;
	}
	
	.HolidayTemplate {
		width: 15.7%;
		padding-right: 15px;
		border-right: 1px solid #eeeeee;
	}
	
	.HolidayTemplate_input {
		display: flex;
		margin-bottom: 10px;
	}
	
	.HolidayTemplate_input button {
		margin-left: 15px;
	}
	
	.HolidayTemplateList {
		width: 84.4%;
		margin-left: 10px;
	}
	
	.el-date-editor.el-input,
	.el-date-editor.el-input__inner {
		width: 100%;
	}
	
	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}
</style>
