<template>
	<div class="User">
		<!-- 头部 -->
		<div class="Top boxshadow" v-loading="Tableload">
			<div class="top_search flex-items-center">
				<i class="el-icon-search"></i>
				<el-input size="medium" v-model="UserData.Value" :placeholder="$t('User.InputUserName')"></el-input>
				<el-button size="mini" slot="append" @click="User()" icon="el-icon-search"></el-button>
				<el-button size="medium" type="primary" @click="AddUserDialog()" :disabled="!userGroupDetails.RoleUser"  style="margin-left: 20px;" icon="el-icon-circle-plus-outline">{{$t('User.AddUser')}}</el-button>
				<el-button size="medium" type="danger" style="margin-left: 20px;" :disabled="!userGroupDetails.RoleUser" slot="reference" icon="el-icon-remove-outline" @click="DeleteUser">{{$t('User.BatchesDelete')}}</el-button>
			</div>
			<div>
				<el-button size="medium" class="el-icon-refresh-right" @click="User()"> {{$t('User.Refresh')}}</el-button>
			</div>
		</div>
		<!-- 中部内容 -->
		<div class="centre flex-center boxshadow">
			<div>
				<div class="content_bottom_title">{{$t('User.UserList')}}</div>
				<el-table @select="GetFormCheck"  @selection-change="GetFormCheck" :data="UserList" height="100%">
					<el-table-column type="selection" align="center"></el-table-column>
					<el-table-column :label="$t('User.HeadPortrait')" align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.userPortrait=='' || scope.row.userPortrait == undefined" style="border-radius:7px;width: 70px;height: 70px;background-color: #F5F7FA;color:#CAC4CC;display: flex;align-items: center;justify-content: center;margin: 0px auto;">{{$t('User.NoheadPortrait')}}</div>
							<img v-else width="70" height="70" style="border-radius:7px" :src="img(scope.row.userPortrait)" />
						</template>
					</el-table-column>
					<el-table-column prop="userName" :label="$t('User.UserName')" align="center"></el-table-column>
					<el-table-column prop="Password" :label="$t('User.Password')" align="center">
						<template>
							<div style="margin-top: 10px;">******</div>
						</template>
					</el-table-column>
					<el-table-column prop="telephone" :label="$t('User.Telephone')" align="center">
						<template slot-scope="scope">
							<el-tag type="success" v-if="scope.row.telephone != ''">{{scope.row.telephone}}</el-tag>
							<el-tag type="danger" v-else>无</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="userGroupName" :label="$t('User.Role')" align="center">
						<template slot-scope="scope">
							<span>{{UserGroupNameTransform(scope.row.userGroupName)}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="departmentName" :label="$t('User.Department')" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.departmentName!='' && scope.row.departmentName!=undefined">{{scope.row.departmentName}}</span>
							<el-tag type="info" v-else>{{$t('User.NotSelected')}}</el-tag>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="openid" label="绑定微信" align="center">
						<template slot-scope="scope">
							<el-tag v-if="scope.row.openid!='' && scope.row.openid!=undefined">已绑定</el-tag>
							<el-tag type="info" v-else>未绑定</el-tag>
						</template>
					</el-table-column> -->
					<el-table-column prop="openid" :label="$t('User.Status')" align="center">
						<template slot-scope="scope">
							<el-tag type="success" v-if="scope.row.usersState != 0">{{$t('User.Normal')}}</el-tag>
							<el-tag type="danger" v-else>{{$t('User.Disable')}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="date" :label="$t('User.Operation')" fixed="right"  width="190" align="center">
						<template slot-scope="scope">
							<div class="table_button">
								<div class="table_button">
									<el-button :disabled="scope.row.userGroupId==1 ? true:false" @click="PermissionToView(scope.row)" type="success" size="small" icon="el-icon-zoom-in">{{$t('User.ToView')}}</el-button>	
								</div>
								<div class="table_button">
									<el-button  @click="UpdUserDialog(scope.row)" type="primary" size="small" icon="el-icon-edit">{{$t('User.Update')}}</el-button>
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="content_bottom_page flex-items-center">
					<el-pagination style="margin-top: 10px;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:page-sizes="[20, 50, 100]" :page-size="UserData.Count" :current-page="UserData.Pages" layout="total, sizes, prev, pager, next, jumper"
					:total="UserListSize">
					</el-pagination>
				</div>
			</div>
		</div>

		<!-- 添加用户 -->
		<el-dialog :title="DialogTitle" :top="AddDialogState == 1 ? '5.5%':'1.5%'" :visible.sync="UserVisible" :width="AddDialogState == 1 ? '40%':'60%'" :close-on-click-modal="false">
			<div v-loading="fromDataLoad">
				<div v-if="AddDialogState == 1" style="display: flex;justify-content: space-between;width: 100%">
					<el-form ref="UserForm" :model="fromData" :rules="fromDataRules" label-position="right" label-width="100px">
						<el-form-item :label="$t('User.UserName')" prop="UserName">
							<el-input maxlength="32" clearable v-model="fromData.UserName" :placeholder="$t('User.PleaseInput')"></el-input>
						</el-form-item>
						<el-form-item :label="$t('User.Password')" prop="UserPassword">
							<el-input maxlength="32" clearable v-model="fromData.UserPassword" show-password :placeholder="$t('User.PleaseInput')"></el-input>
						</el-form-item>
						<el-form-item v-if="fromData.UserRoleValue!=1 && userGroupDetails.RoleUser" :label="'*'+$t('User.Department')">
							<select-tree v-model="fromData.DepartmentId" :options="DepartmentList" :props="DepartmentProps" />
						</el-form-item>
						<el-form-item v-if="!CompanyAdministrator" :label="$t('User.Role')" prop="UserRoleValue" :style="{'margin-top': fromData.UserRoleValue!=1 ? '-15px':'0px'}">
							<el-select v-model="fromData.UserRoleValue" @change="UserRoleSelect"  :placeholder="$t('User.Select')">
								<el-option v-for="item in RoleList" :key="item.userGroupId" :label="UserGroupNameTransform(item.userGroupName)" :value="item.userGroupId">
								 <el-popover placement="right" width="200" trigger="hover" :content="NotesTransform(item.userGroupName)">
									<div slot="reference" style="width:100%">{{UserGroupNameTransform(item.userGroupName)}}</div>
								 </el-popover>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="$t('User.Telephone')" prop="telephone">
							<el-input maxlength="11" clearable v-model="fromData.telephone" :placeholder="$t('User.InputTelephone')"/>
						</el-form-item>
						<el-form-item v-if="!CompanyAdministrator" :label="$t('User.Status')">
							<el-switch v-model="fromData.UserStatue" inactive-color="#ff4949" :active-text="$t('User.Enable')" :inactive-text="$t('User.Disable')"></el-switch>
						</el-form-item>
					</el-form>
					<div style="margin-right: 25px;">
						<el-upload class="avatar-uploader" action="" :http-request="upload" :show-file-list="false" 
						 :before-upload="beforeAvatarUpload" list-type="picture" name="picture">
							<img v-if="imageUrl!=''" :src="imageUrl" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<el-button icon="el-icon-delete" plain size="mini" @click="DeleteUserImg()" style="margin-top:10px;width: 180px;">{{$t('User.DeleteHeadPortrait')}}</el-button>
					</div>
				</div>
				<div v-else>
					<div class="chunk">
						<div>
							<div style="margin-bottom: 10px;m">{{$t('User.Area')}}</div>
							<el-card shadow="hover">
								<div class="tree_input">
									<el-input maxlength="32" :placeholder="$t('User.SearchArea')" v-model="AreaText"></el-input>
								</div>
								<el-tree :default-checked-keys="AreaChecks" @check="AreaCheck()" v-loading="AreaTreeload" :data="AreaList" :filter-node-method="AreafilterNode" ref="Areatree" node-key="controllersAreaId"
								 default-expand-all show-checkbox :expand-on-click-node="false" icon-class="el-icon-s-promotion" style="height:235px;overflow:auto;">
									<span class="custom-tree-node" slot-scope="{ data }">
										<div style="width: 100%;">{{ data.regionName }}</div>
									</span>
								</el-tree>
							</el-card>
						</div>
						<div>
							<div style="margin-bottom: 10px;">{{$t('User.Controller')}}</div>
							<el-card shadow="hover">
								<div class="tree_input">
									<el-input maxlength="32" :placeholder="$t('User.SearchController')" v-model="EquipmentText"></el-input>
								</div>
								<el-tree :default-checked-keys="EquipmentChecks" @check="EquipmentCheck()" v-loading="EquipmentTreeload" :data="EquipmentList" :filter-node-method="EquipmentfilterNode" ref="Equipmenttree" node-key="controllersId"
								 default-expand-all show-checkbox :expand-on-click-node="false" icon-class="el-icon-s-promotion" style="height:235px;overflow:auto;">
									<span class="custom-tree-node" slot-scope="{ data }">
										<div style="width: 100%;">{{ data.controllersId }} - {{ data.controllerName }} ( {{ data.regionName}} - {{ data.location}} ) </div>
									</span>
								</el-tree>
							</el-card>
						</div>
					</div>
					<div class="chunk">
						<div>
							<div style="margin-bottom: 10px;margin-top: 10px;">{{$t('User.Department')}}</div>
							<el-card shadow="hover">
								<div class="tree_input">
									<el-input maxlength="32" :placeholder="$t('User.SearchDepartment')" v-model="DepartmentText"></el-input>
								</div>
								<el-tree :default-checked-keys="DepartmentChecks" v-loading="DepartmentTreeload" :data="DepartmentList" :filter-node-method="DepartmentfilterNode" ref="Departmentree" node-key="departmentId"
								 default-expand-all show-checkbox :expand-on-click-node="false" icon-class="el-icon-s-promotion" style="height:235px;overflow:auto;">
									<span class="custom-tree-node" slot-scope="{ data }">
										<div style="width: 100%;">{{ data.departmentName }}</div>
									</span>
								</el-tree>
							</el-card>
						</div>
						<div>
							<!-- <div style="margin-bottom: 10px;">访问用户</div>
						<el-card shadow="hover" >
							<div class="Department_input">
								<el-input placeholder="搜索部门" v-model="AreaText"></el-input>
							</div>
							<el-tree v-loading="AreaTreeload" :data="AreaList" :filter-node-method="AreafilterNode" ref="Usertree"
							 default-expand-all show-checkbox :expand-on-click-node="false" icon-class="el-icon-s-promotion" style="height:240px;overflow:auto;">
								<span class="custom-tree-node" slot-scope="{ node, data }">
									<div @click="choose(data.departmentCode,data.departmentName,data.strDepartmentId)" style="width: 100%;">{{ data.departmentName }}</div>
								</span>
							</el-tree>
						</el-card> -->
						</div>
					</div>

				</div>
			</div>
			<span v-if="ButtonDisplayStatus" slot="footer" class="dialog-footer">
				<el-button @click="UserVisible = false">{{$t('User.Cancel')}}</el-button>
				<el-button v-if="AddDialogState!=1" @click="AddDialogState = 1" type="primary">{{$t('User.PreviousStep')}}</el-button>
				<el-button v-if="fromData.UserRoleValue!=1 && AddDialogState==1 && userGroupDetails.RoleUser" @click="NextStep()" type="primary">{{$t('User.NextStep')}}</el-button>
				<el-button v-if="fromData.UserRoleValue==1 && AddDialogState==1" @click="SaveTheUser(1)" type="primary">{{$t('User.Save')}}</el-button>
				<el-button v-if="fromData.UserRoleValue!=1 && AddDialogState==2"  @click="SaveTheUser(2)" type="primary">{{$t('User.Save')}}</el-button>
				<el-button v-if="!userGroupDetails.RoleUser" @click="updateUser()" type="primary">{{$t('User.Save')}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import SelectTree from '../components/SelectTree.vue';
	import 'driver.js/dist/driver.min.css'
	import Driver from 'driver.js'

	export default {
		components: {
			SelectTree,
		},
		data() {
			//校验
			var Verify = (rule, value, callback) => {
				if (!value) return callback(new Error(this.$t('User.PleaseInput')));
				callback();
			};

			//校验
			var Verify2 = (rule, value, callback) => {
				if (!value) return callback(new Error(this.$t('User.Select')));
				callback();
			};
			//校验手机号
			var VerifyTel = (rule,value,callback) => {
				//如果内容为空
				if(value==undefined || value==''){
					callback();
				}
				// 电话号码正则表达式（支持手机号码，3-4位区号，7-8位直播号码，1－4位分机号）
      			const reg = /^(\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$/; 
      			if (!reg.test(value)) {
					return callback(new Error("手机号格式错误"));
	  			}
				callback();
			};
			return {
				//权限组
				userGroupDetails: {
					RoleUser: false, //操作权
				},
				Tableload: false, //加载状态
				options: [{
					value: 1,
					label: this.$t('User.UserName')
				}, {
					value: 2,
					label: this.$t('User.Name')
				}], //条件下拉选择值
				value: '', //下拉选择
				UserData: {
					Count: 20, //分页条数
					Pages: 1, //分页页数
					TypeValue: '1', //按条件查询下拉选择
					Value: '' //按条件查询值
				},
				UserList: [], //人员列表
				UserListSize: 0, //人员数量
				UserVisible: false, //新增弹框状态
				AddDialogState: 1, //步骤

				imageUrl: '', //头像

				// 部门树形格式
				DepartmentProps: {
					parent: 'parentDepartmentId', // 父级唯一标识
					value: 'strDepartmentId', // 唯一标识
					label: 'departmentName', // 标签显示
					children: 'children', // 子级
				},
				DepartmentText: '', //部门查询过滤值
				DepartmentTreeload: false, //部门加载状态
				DepartmentList: [], //部门集合
				DepartmenChecks:[],//默认选中的值

				AreaText: '', //区域查询过滤值
				AreaTreeload: false, //区域加载状态
				AreaList: [], //区域集合
				AreaChecks:[],//默认选中的值

				EquipmentText: '', //设备查询过滤值
				EquipmentTreeload: false, //设备加载状态
				EquipmentList: [], //设备集合
				EquipmentChecks:[],//默认选中的值

				RoleList: [], //角色集合
				
				DialogTitle:"",//弹框标题
				VisibleButtonload:false,//弹框按钮状态
				ButtonDisplayStatus:true,//弹框底部按钮块是否显示
				
				//新增表单
				fromData: {
					UsersId:'0',//Id
					UserRoleValue: '', //选择的角色
					DepartmentId: '0', //所属部门
					UserGroupId: '0', //角色Id
					UserName: '', //登录名
					UserPassword: '', //密码
					Userimg: '', //头像名
					Department: '', //访问部门
					UserStatue: true, //状态
					Area: '', //访问区域
					Controller: '',//访问设备
					telephone:''//手机号码
				},
				
				fromDataLoad:false,//from表单加载状态
				CompanyAdministrator:false,//是否为公司管理员
				
				IdData:[],//需删除的ID
				NameData:[],//删除时需记录的名称
				//表单校验规则
				fromDataRules: {
					UserName: [{
							required: true,
							validator: Verify,
							max: 32,
							trigger: 'blur'
						}
					],
					UserPassword: [{
							required: true,
							validator: Verify,
							trigger: 'blur'
						},
					],
					Name: [{
						required: true,
						validator: Verify,
						trigger: 'change'
					}, ],
					UserRoleValue: [{
						required: true,
						validator: Verify2,
						trigger: 'change'
					}],
					telephone: [
						{
							required: false,
							validator:VerifyTel,
							trigger: 'change'
						}
					]
				}
			}
		},
		watch: {

			//部门查询过滤使用
			DepartmentText(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.Departmentree.filter(val);
			},

			//区域查询过滤使用
			AreaText(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.Areatree.filter(val);
			},

			//设备查询过滤使用
			EquipmentText(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.Equipmenttree.filter(val);
			}
		},
		mounted() {

			//获取操作权
			this.Role();

			//查询人员
			this.User();

			//查询部门
			this.Department()
			let cookieUserGuide = this.getCookie()
			if(cookieUserGuide === undefined){
				setTimeout(()=>{this.user_guide()},1000)
			}

		},
		methods: {
			//用户引导
			user_guide () {
			let driver = new Driver({
				className: 'scoped-class', // 包裹driver.js弹窗的类名 className to wrap driver.js popover
				animate: true, // 高亮元素改变时是否显示动画 Animate while changing highlighted element
				opacity: 0.75, // 背景透明度(0 表示只有弹窗并且没有遮罩) Background opacity (0 means only popovers and without overlay)
				padding: 10, //  元素与边缘的距离 Distance of element from around the edges
				allowClose: false, // 是否允许点击遮罩时关闭 Whether clicking on overlay should close or not
				overlayClickNext: false, // 是否允许点击遮罩时移到到下一步 Should it move to next step on overlay click
				doneBtnText: '完成', // 最终按钮上的文本 Text on the final button
				closeBtnText: '关闭', // 当前步骤关闭按钮上的文本 Text on the close button for this step
				nextBtnText: '下一步', // 当前步骤下一步按钮上的文本 Next button text for this step
				prevBtnText: '上一步', // 当前步骤上一步按钮上的文本 Previous button text for this step
				showButtons: true, // 是否在底部显示控制按钮 Do not show control buttons in footer
				keyboardControl: true, // 是否允许通告键盘控制(escape关闭，箭头键用于移动)Allow controlling through keyboard (escape to close, arrow keys to move)
				scrollIntoViewOptions: {}, //  `scrollIntoView()` 方法的选项 We use `scrollIntoView()` when possible, pass here the options for it if you want any
			})
			// Define the steps for introduction
			let steps = [
				{
				element: '.Top',
				popover: {
					title: this.$t('User.UserGuideTitle1'),
					description: this.$t('User.UserGuideContent1'),
					position: 'bottom'
				}
				},
				{
				element: '.flex-center',
				popover: {
					title: this.$t('User.UserGuideTitle2'),
					description: this.$t('User.UserGuideContent2'),
					position: 'top'
				}
				},
			]
			driver.defineSteps(steps)
			// Start the introduction
			driver.start()
			// 保存
			 this.setCookie()
			},

			
			setCookie(){
				//设置10年后过期
				let expires = new Date();
				expires.setTime(expires.getTime()+10*365*24*60*60*1000);
				document.cookie = "userGuide="+escape('1')+";expires="+expires.toGMTString();
			},
			getCookie(){
				//获取cookie值
				let str = document.cookie;
				//将值切割成数组
				let arr = str.split(";");
				let userGuide;
				//遍历数组
				for(var i=0;i<arr.length;i++){
					var value = arr[i].split("=");
					//避免输入中含有空格，消除文本框空格
          			value[0] = value[0].replace(/\s+/g, "");
					if(value[0] == 'userGuide'){
						userGuide = value[1];
					}
				}
				console.log("userGuide:" + userGuide)
				return userGuide;
			},

			//获取操作权
			Role() {
				//获取权限组
				var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
				for (var i = 0; i < userGroupDetails.length; i++) {
					if (userGroupDetails[i].roleName == "User") { //操作权
						this.userGroupDetails.RoleUser = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
				}
			},

			//照片
			img(photo) {
				try 
				{
					return this.$image+"/User/" + photo;
				} 
				catch (e)
				{
					return ''
				}
			},

			//查询人员
			async User() {
				try 
				{
					this.Tableload = true;
					var {data: res} = await this.$http.post('User', this.UserData)
					if (res.resultCode == 1) {
						this.UserList = JSON.parse(res.body);
						if (this.UserList.length > 0) 
						{
							this.UserListSize = this.UserList[0].size;
						} 
						else
						{
							this.UserListSize = 0;
						}
					}
					this.Tableload = false;
				} 
				catch (err) 
				{
					this.Tableload = false;
					console.log(err)
				}
			},

			//分页条数
			handleSizeChange(val) {
				this.UserData.Count = val;
				this.User();
			},

			//分页页数
			handleCurrentChange(val) {
				this.UserData.Pages = val;
				this.User();
			},

			//人员条件下拉选择
			UserSelect() {
				this.UserData.Value = "";
				this.UserData.TypeValue = this.value;
				if (this.value == "") {
					this.User();
				}
			},

			//新增用户弹框
			AddUserDialog() {
				this.ButtonDisplayStatus = true;
				this.CompanyAdministrator = false;
				this.fromDataLoad = true;
				this.DialogTitle = this.$t('User.AddUser');
				this.AddDialogState = 1;
				this.fromData = {UsersId:'0',UserRoleValue: '',telephone:'',DepartmentId: '0',UserGroupId: '0',UserName: '',UserPassword: '',Userimg: '',Department: '',UserStatue: true,Area: '',Controller: ''}
				this.UserGroup(); //查询角色权限组
				this.UserVisible = true;
				this.fromDataLoad = false;
			},

			//选择角色
			UserRoleSelect() {
				console.log("选择角色：" + this.fromData.UserRoleValue)
				this.fromData.UserGroupId = this.fromData.UserRoleValue;
			},

			//上传照片
			async upload(f) {
				let param = new FormData(); //创建From对象
				param.append('picture', f.file)
				var {data: res} = await this.$http.post('UserImg', param)
				if (this.FilteringOperation(res)) { //请求结果过滤
					if (res.resultCode == 1) //照片上传成功
					{
						this.fromData.Userimg = res.body;
						this.imageUrl = this.$image+"/User/" + res.body;
					}
					else 
					{
						this.$message({type: 'error',message: this.$t('User.UploadingFailed'),offset:10});
					}
				}
			},

			//照片校验
			beforeAvatarUpload(file) {
				const isPNG = file.type === 'image/png';
				const isGIF = file.type === 'image/gif';
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 5;

				if (!isJPG && !isGIF && !isPNG) {
					this.$message.error(this.$t('User.PhotoRequire1'));
				}

				if (!isLt2M) {
					this.$message.error(this.$t('User.PhotoRequire2'));
				}

				return isLt2M && (isGIF || isJPG || isPNG);
			},

			//删除照片
			DeleteUserImg() {
				this.fromData.Userimg = '';
				this.imageUrl = '';
			},
			
			//查询角色权限组
			async UserGroup() {
				try 
				{
					var {data: res} = await this.$http.post('UserGroup')
					if (res.resultCode == 1) //成功
					{
						this.RoleList = JSON.parse(res.body);
					}
				} 
				catch (err) 
				{
					this.DepartmentTreeload = false;
					console.log(err)
				}
			},

			//新增用户(下一步)
			NextStep() {
				this.$refs.UserForm.validate(async valid => {
					if (!valid) return;
					if(this.fromData.UserGroupId!=1 && this.fromData.DepartmentId==0){
						return this.$message({type: 'warning',message: this.$t('User.SelectDepartment'),offset:10});
					}
					var {data: res} = await this.$http.post('VerifyAccountPassword', {UserName:this.fromData.UserName,UsersId:this.fromData.UsersId});
					if (this.FilteringOperation(res)) {
						if (res.resultCode == 1) {
							this.AddDialogState = 2;
							this.Area(); //查询区域
							this.Controllers();//查询设备
						} else {
							return this.$message.error({message:this.$t('User.UserNameExists'),offset:10});
						}
					}
				});
			},

			//查询部门
			async Department() {
				try 
				{
					this.DepartmentTreeload = true;
					var {data: res} = await this.$http.post('Departments')
					if (res.resultCode == 1) //成功
					{
						this.DepartmentList = JSON.parse(res.body);
					}
					this.DepartmentTreeload = false;
				} 
				catch (err) 
				{
					this.DepartmentTreeload = false;
					console.log(err)
				}
			},

			//查询区域
			async Area() {
				try 
				{
					this.AreaTreeload = true;
					var {data: res} = await this.$http.post('ControllersArea')
					if (res.resultCode == 1) {
						this.AreaList = JSON.parse(res.body);
					}
					this.AreaTreeload = false;
				} 
				catch (err) 
				{
					this.AreaTreeload = false;
					console.log(err)
				}
			},
			
			//查询设备
			async Controllers() {
				try {
					this.EquipmentTreeload = true;
					var {data: res} = await this.$http.post('UserControllers')
					if (res.resultCode == 1) {
						this.EquipmentList = JSON.parse(res.body);
					}
					this.EquipmentTreeload = false;
				} 
				catch (err) 
				{
					this.EquipmentTreeload = false;
					console.log(err)
				}
			},

			//部门过滤
			DepartmentfilterNode(value, data) {
				if (!value) return true;
				return data.departmentName.indexOf(value) !== -1;
			},

			//区域过滤
			AreafilterNode(value, data) {
				if (!value) return true;
				return data.regionName.indexOf(value) !== -1;
			},

			//设备过滤
			EquipmentfilterNode(value, data) {
				if (!value) return true;
				return data.controllerName.indexOf(value) !== -1;
			},
			
			//区域勾选
			AreaCheck(){
				var AreaCheckList = this.$refs.Areatree.getCheckedKeys();
				var Equipment = [];
				for(var i=0;i<this.EquipmentList.length;i++)
				{
					for(var k=0;k<AreaCheckList.length;k++)
					{
						if(this.EquipmentList[i].controllersAreaId==AreaCheckList[k])
						{
							Equipment[Equipment.length] = this.EquipmentList[i].controllersId;
						}
					}
				}
				this.$refs.Equipmenttree.setCheckedKeys(Equipment);
			},
			
			//设备勾选
			EquipmentCheck(){
				var EquipmentCheckList = this.$refs.Equipmenttree.getCheckedNodes();
				var Area = [];
				for(var i=0;i<EquipmentCheckList.length;i++)
				{
					Area[Area.length] = EquipmentCheckList[i].controllersAreaId; 
				}
				this.$refs.Areatree.setCheckedKeys(Area);
			},
			
			
			//保存用户
			async SaveTheUser(Type){
				if(Type==1)//最高用户
				{
					this.$refs.UserForm.validate(async valid => {
						if (!valid) return;
						var {data: res} = await this.$http.post('VerifyAccountPassword', {UserName:this.fromData.UserName,UsersId:this.fromData.UsersId});
						if (this.FilteringOperation(res)) {
							if (res.resultCode == 1) 
							{
								var {data: res} = await this.$http.post('AddUdpUser', this.fromData);
								if (this.FilteringOperation(res)) {
									if (res.resultCode == 1) 
									{
										this.$refs.UserForm.resetFields();
										this.UserVisible = false;
										if(this.fromData.UsersId=='0')
										{
											this.$message({type: 'success',message: this.$t('User.AddSucceed'),offset:10});
										}
										else
										{
											this.$message({type: 'success',message: this.$t('User.UpdateSucceed'),offset:10});
										}
										this.User();
									} 
									else if(res.resultCode == 2)
									{
										this.$message({type: 'error',message: this.$t('User.UserUpperLimit'),offset:10});
									}
									else
									{
										if(this.fromData.UsersId=='0')
										{
											this.$message({type: 'error',message: this.$t('User.AddFailure'),offset:10});
										}
										else
										{
											this.$message({type: 'error',message: this.$t('User.UpdateFailure'),offset:10});
										}
									}
								}
							}
							else 
							{
								return this.$message.error({message: this.$t('User.UserNameExists'),offset:10});
							}
						}
					});
				}
				else
				{
					this.GetSavedValue();
					var {data: res} = await this.$http.post('AddUdpUser', this.fromData);
					if (this.FilteringOperation(res)) {
						if (res.resultCode == 1) 
						{
							this.UserVisible = false;
							if(this.fromData.UsersId=='0')
							{
								this.$message({type: 'success',message: this.$t('User.AddSucceed'),offset:10});
							}
							else
							{
								this.$message({type: 'success',message: this.$t('User.UpdateSucceed'),offset:10});
							}
							this.User();
						} 
						else if(res.resultCode == 2)
						{
							this.$message({type: 'error',message: this.$t('User.UserUpperLimit'),offset:10});
						}
						else
						{
							if(this.fromData.UsersId=='0')
							{
								this.$message({type: 'error',message: this.$t('User.AddFailure'),offset:10});
							}
							else
							{
								this.$message({type: 'error',message: this.$t('User.UpdateFailure'),offset:10});
							}
						}
					}
				}
			},

			//修改用户弹框
			async updateUser(){
				var {data: res} = await this.$http.post('updateUser',this.fromData);
				if (this.FilteringOperation(res)) {
						if (res.resultCode == 1) 
						{
							this.UserVisible = false;
							if(this.fromData.UsersId=='0')
							{
								this.$message({type: 'success',message: this.$t('User.AddSucceed'),offset:10});
							}
							else
							{
								this.$message({type: 'success',message: this.$t('User.UpdateSucceed'),offset:10});
							}
							this.User();
						} 
						else if(res.resultCode == 2)
						{
							this.$message({type: 'error',message: this.$t('User.UserUpperLimit'),offset:10});
						}
						else
						{
							if(this.fromData.UsersId=='0')
							{
								this.$message({type: 'error',message: this.$t('User.AddFailure'),offset:10});
							}
							else
							{
								this.$message({type: 'error',message: this.$t('User.UpdateFailure'),offset:10});
							}
						}
					}
			},
			
			//修改用户弹框
			async UpdUserDialog(Data) {
				if(Data.usersState==2)
				{
					this.CompanyAdministrator = true;
				}
				else
				{
					this.CompanyAdministrator = false;
				}
				this.ButtonDisplayStatus = true;
				this.fromDataLoad = true;
				this.imageUrl = Data.userPortrait!=""?this.$image+"/User/" + Data.userPortrait:"";
				var {data: res} = await this.$http.post('UserAreaControllerDepartment',{UsersId:Data.usersId});
				if(res.resultCode == 1){
					var body = JSON.parse(res.body);
					this.AreaChecks = body.Area;
					this.EquipmentChecks = body.Controller;
					this.DepartmentChecks = body.Department;
					this.fromData = {UsersId:Data.usersId,UserRoleValue:Data.userGroupId,DepartmentId:Data.departmentId.toString(),UserGroupId: Data.userGroupId,UserName: Data.userName,UserPassword: Data.userPassword,Userimg: Data.userPortrait,Department: '',UserStatue: Data.usersState ==1 ? true:false,Area: '',Controller: '',telephone:Data.telephone}
					this.AddDialogState = 1;
					this.DialogTitle = this.$t('User.UpdateUser');
					this.UserGroup(); //查询角色权限组
					this.UserVisible = true;
					this.fromDataLoad = false;
					if(body.UpdateUserGroupId==1)
					{
						this.CompanyAdministrator = true;
					}
				}
			},
			
			//查看权限
			async PermissionToView(Data){
				this.DialogTitle = this.$t('User.ViewPermissions');
				this.Area(); //查询区域
				this.Controllers();//查询设备
				this.ButtonDisplayStatus = false;
				var {data: res} = await this.$http.post('UserAreaControllerDepartment',{UsersId:Data.usersId});
				if(res.resultCode == 1){
					var body = JSON.parse(res.body);
					this.AreaChecks = body.Area;
					this.EquipmentChecks = body.Controller;
					this.DepartmentChecks = body.Department;
				}
				this.AddDialogState = 2;
				this.UserVisible = true;
			},
			
			//获取保存值
			GetSavedValue(){
				var AreaCheck = this.$refs.Areatree.getCheckedKeys();
				var Area = "";
				for(var i=0;i<AreaCheck.length;i++)
				{
					Area = Area + AreaCheck[i] + ",";
				}
				this.fromData.Area = Area;
				
				var EquipmentCheck = this.$refs.Equipmenttree.getCheckedKeys();
				var Equipment = "";
				for(var i=0;i<EquipmentCheck.length;i++)
				{
					Equipment = Equipment + EquipmentCheck[i] + ",";
				}
				this.fromData.Controller = Equipment;
				
				var DepartmenCheck = this.$refs.Departmentree.getCheckedKeys();
				var Departmen = "";
				for(var i=0;i<DepartmenCheck.length;i++)
				{
					Departmen = Departmen + DepartmenCheck[i] + ",";
				}
				this.fromData.Department = Departmen;
			},
			
			//获取表格选中的结果
			GetFormCheck(val)
			{
				this.IdData = [];
				this.NameData = [];
				for (var i = 0; i < val.length; i++) {
					if(val[i].usersState != 2)
					{
						this.IdData[i] = val[i].usersId;
						this.NameData[i] = val[i].userName;
					}
					else
					{
						this.$message({message: this.$t('User.Notice'),offset:10});
					}
				}
			},
			//删除用户
			async DeleteUser(){
				console.log("前端数据："+this.NameData)
				if(this.IdData.length>0)
				{
					this.$confirm(this.$t('User.WhetherDelete'), this.$t('User.Hint'), {type: 'warning'}).then(async () => {
						try
						{
							var {data: res} = await this.$http.post('DeleteUser',{UsersId:this.IdData,"UserName":this.NameData});
							if (this.FilteringOperation(res)) 
							{
								if(res.resultCode == 1)
								{
									this.$message({type: 'success',message: this.$t('User.DeleteSucceed'),offset:10});
									if((this.UserList.length-this.IdData.length)==0 && this.UserData.Pages>1)
									{
										this.UserData.Pages = this.UserData.Pages-1;
									}
									this.User();
								}
								else
								{
									this.$message({type: 'error',message: this.$t('User.DeleteFailure'),offset:10});
								}
							}
						}
						catch (err)
						{
							console.log(err)
						}
					}).catch(() => {
						//取消
					});
				}
				else
				{
					this.$message({type: 'warning',message: this.$t('User.Select'),offset:10});
				}
			},

			UserGroupNameTransform(lable){
				if(lable=="1") return this.$t('User.Role1')
				else if(lable=="2") return this.$t('User.Role2')
				else if(lable=="3") return this.$t('User.Role3')
			},

			NotesTransform(lable){
				if(lable=="1") return this.$t('User.Notes1')
				else if(lable=="2") return this.$t('User.Notes2')
				else if(lable=="3") return this.$t('User.Notes3')
			},

			//操作过滤
			FilteringOperation(res) {
				var State = true;
				if (res.stateCode == 0) {
					State = false;
					this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
				} else if (res.stateCode == 2) {
					State = false;
					this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
				} else if (res.stateCode == 3) {
					State = false;
					this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
				}
				return State;
			}
		}

	}
</script>

<style scoped>

	.User{
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	/* 头部 */
	.Top{
		padding: 15px 16px;
		background-color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.top_search>i{
		font-size: 25px;
		margin-left:10px;
		margin-right:10px;
		color: #909399;
	}

	.top_search>>>.el-input__inner {
    	border: 0;
		font-size: 15px;
  	}

	/* 中部 */
	.centre{
		flex-grow: 1;
		height: 0px;
		width: 100%;
		background-color: #FFFFFF;
	}

	.centre>div{
		display: flex;
		flex-direction: column;
		width: 96%;
		height: 95%;
	}

	.content_bottom_title{
		font-weight: 600;
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 10px;
	}

	.content_bottom_page{
		margin-top: 7px;
	}

	.table_button{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;	
	}
	.table_button>div{
		margin: 5px;
	}





	.avatar-uploader-icon {
		border: 1px dashed #d9d9d9;
		border-radius: 5px;
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		border-radius: 5px;
		display: block;
		border: 1px solid #d9d9d9;
	}


	.chunk {
		display: flex;
		width: 100%;
		justify-content: space-evenly;
	}

	.chunk>div {
		width: 45%;
	}

	.tree_input {
		margin-bottom: 5px;
	}
</style>
