<template>
	<div class="frombut">
		<el-card  v-loading="load" class="box-card boxshadow" shadow="never">
			<el-form ref="form" label-width="100px" label-position="left" :model="Company" :rules="CompanyFormRules">
				<el-form-item :label="$t('Company.Logo')">
					<el-upload class="avatar-uploader" action="" :http-request="upload" :show-file-list="false"
					 :before-upload="beforeAvatarUpload" list-type="picture" name="picture">
						<img v-if="imageUrl!=''" :src="imageUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item :label="$t('Company.CompanyName')" prop="companyName">
					<el-input  type="text" show-word-limit v-model="Company.companyName"  maxlength="30"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Company.CompanyProfile')" prop="companyName">
				    <el-input type="textarea" show-word-limit rows="7" v-model="Company.companyNote"  maxlength="200"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Company.NumberAuthorizedAccounts')">
				    {{Company.authorizationAccount}}
				</el-form-item>
				<el-form-item :label="$t('Company.TermAuthorization')">
				    {{Company.authorizationStartTime}} - {{Company.authorizationEndTime}}
				</el-form-item>
				<el-form-item :label="$t('Company.PersonNum')">
				    {{Company.personNum}} 
				</el-form-item>
				<el-form-item :label="$t('Company.EquipmentNum')">
				    {{Company.equipmentNum}} 
				</el-form-item>
				<el-form-item :label="$t('Company.Visitor')">
				    {{Company.visitorStartTime}} - {{Company.visitorEndTime}}
				</el-form-item>
				<el-form-item :label="$t('Company.Lift')">
				    {{Company.liftStartTime}} - {{Company.liftEndTime}}
				</el-form-item>
				<el-button type="primary" :disabled="!userGroupDetails.RoleCorporateInformation" @click="Update()">{{$t('Company.SaveInformation')}}</el-button>
			</el-form>
		</el-card>
	</div>
</template>

<script>
	export default {
			data() {
				var Verify = (rule, value, callback) => {
					if (!value) return callback(new Error(this.$t('Company.PleaseInput')));
					callback();
				};
				return {
					//权限组
					userGroupDetails: {
						RoleCorporateInformation: false
					},
					imageUrl:'',//公司logo
					Company:{},//公司信息
					load:false, //加载状态
					//表单验证规则
					CompanyFormRules: {
						companyName: [{
							required: true,
							validator: Verify,
							trigger: 'blur'
						}],
						companyNote: [{
							required: true,
							validator: Verify,
							trigger: 'blur'
						}]
					}
					}
			},
			mounted() {
				//高度
				const that = this;
				window.onresize = () => {
					return (() => {
						that.screenHeight = window.innerHeight
					})()
				}
			},
			created() {
				
				//获取操作权
				this.Role();
				
				//获取公司信息
				this.User()
			},
			methods: {
				
				//获取操作权
				Role() {
					//获取权限组
					var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
					for (var i = 0; i < userGroupDetails.length; i++) {
						if (userGroupDetails[i].roleName == "CorporateInformation") { //操作权
							this.userGroupDetails.RoleCorporateInformation = userGroupDetails[i].roleOperateValue == "1" ? true : false;
						}
					}
				},
				
				//查询公司信息
				async User() {
					try 
					{
						this.load = true;
						var {data: res} = await this.$http.post('Company')
						if (res.resultCode == 1) {
							this.Company = JSON.parse(res.body);//公司信息
							this.imageUrl = this.Company.companyLogo!="" && this.Company.companyLogo!=undefined ? this.$image+"/Logo/" + this.Company.companyLogo:"";//logo
						}
						this.load = false;
					} 
					catch (err) 
					{
						this.load = false;
						console.log(err)
					}
				},
				
				//上传照片
				async upload(f) {
					let param = new FormData(); //创建From对象
					param.append('picture', f.file)
					
					var {data: res} = await this.$http.post('LOGOImg', param);
					if (this.FilteringOperation(res)) { //请求结果过滤
						if (res.resultCode == 1) //照片上传成功
						{
							this.Company.companyLogo = res.body;
							this.imageUrl = this.$image+"/Logo/" + res.body;
						}
						else 
						{
							this.$message({type: 'error',message: this.$t('Company.UploadingFailed'),offset:10});
						}
					}
				},
				
				//照片校验
				beforeAvatarUpload(file) {
					const isJPG = file.type === 'image/jpeg';
					const isPNG = file.type === 'image/png';
					const isLt2M = file.size / 1024 / 1024 < 5;
					if (!isJPG && !isPNG) {
						this.$message.error({message:this.$t('Company.PhotoRequire1'),offset:10});
					}
					if (!isLt2M) {
						this.$message.error(this.$t('Company.PhotoRequire2'));
					}
					return (isJPG || isPNG) && isLt2M;
				},
				
				//删除照片
				DeleteUserImg() {
					this.Company.companyLogo = '';
					this.imageUrl = '';
				},
				
				//修改公司信息
				Update() {
					this.$refs.form.validate(async valid => { //验证
						if (!valid) return;
						if(this.Company.companyName == "")
						{
							return this.$message({message: this.$t('Company.Message1'),type: 'error',offset:10});
						}
						if(this.Company.companyNote == "")
						{
							return this.$message({message: this.$t('Company.Message2'),type: 'error',offset:10});
						}
						var {data: res} = await this.$http.post('UpdateCompany', this.Company);
						if (this.FilteringOperation(res)){ 
							if (res.resultCode == 1)
							{
								this.$message({type: 'success',message: this.$t('Company.SaveSucceed'),offset:10});
							}
							else 
							{
								this.$message({type: 'error',message: this.$t('Company.SaveFailure'),offset:10});
							}
						}
					})
				},
				
				//操作过滤
				FilteringOperation(res) {
					var State = true;
					if (res.stateCode == 0) {
						State = false;
						this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
					} else if (res.stateCode == 2) {
						State = false;
						this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
					} else if (res.stateCode == 3) {
						State = false;
						this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
					} 
					return State;
				}
			}
		}
</script>

<style scoped>

	.avatar-uploader-icon {
		border: 1px dashed #d9d9d9;
		border-radius: 5px;
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		border-radius: 5px;
		display: block;
		border: 1px solid #d9d9d9;
	}

</style>
