<template>
	<div class="Controller">
		<!-- 左侧 -->
		<div class="content_left boxshadow" v-loading="Treeload">
			<div class="content_left_value">
				<!-- 功能栏 -->
				<div class="left_value_input">
					<el-input  maxlength="32" :placeholder="$t('Controller.SearchArea')" size="medium" v-model="filterText" style="width:66%;"></el-input>
					<el-button :disabled="!userGroupDetails.RoleArea" icon="el-icon-circle-plus-outline" style="margin-left:15px" size="medium" type="primary" @click="AddAreaDialog('')">{{$t('Controller.Add')}}</el-button>
				</div>
				<!-- 树形 -->
				<el-tree class="left_value_tree" :highlight-current="true" :data="AreaList" :filter-node-method="filterNode" ref="tree" node-key="AreaId"
				default-expand-all :expand-on-click-node="false" icon-class="el-icon-s-promotion">
					<span class="custom-tree-node left_tree" slot-scope="{data}" @click="choose(data)">
						<div>{{data.regionName}}</div>
						<el-popover placement="right" trigger="hover" :open-delay="300">
							<el-button type="primary" :disabled="!userGroupDetails.RoleArea" size="mini" @click="AddAreaDialog(data.controllersAreaId)">{{$t('Controller.Add')}}</el-button>
							<el-button type="info" :disabled="!userGroupDetails.RoleArea" size="mini" @click="UpdateAreaDialog(data.controllersAreaId)">{{$t('Controller.Update')}}</el-button>
							<el-button type="danger" :disabled="!userGroupDetails.RoleArea" size="mini" @click="DeleteArea(data.controllersAreaId,data.regionName)">{{$t('Controller.Delete')}}</el-button>
							<i class="el-icon-more left_tree_icon" slot="reference"></i>
						</el-popover>
					</span>
				</el-tree>
			</div>
		</div>
		<!-- 右侧 -->
			<div class="content_right" v-loading="Tableload">
				<!-- 头部 -->
				<div class="content_right_top flex-items-center">
					<!--条件 -->
					<div class="right_top_card1 flex-items-center boxshadow">
						<!-- 搜索框 -->
						<div v-if="TypeValue !='3'" class="right_top_search flex-items-center">
							<i class="el-icon-search"></i>
							<el-input maxlength="32" size="medium" v-model="QueryEquipment.Value" :placeholder="$t('Controller.SearchTips')"></el-input>
						</div>
						<div v-else class="right_top_Date">
							<el-select size="medium" v-model="QueryEquipment.Value" @change="Controllers()" clearable slot="prepend" :placeholder="$t('Controller.SelectControllerType')">
								<el-option :label="$t('Controller.ControllerType1')" value="1"></el-option>
								<el-option :label="$t('Controller.ControllerType2')" value="2"></el-option>
							</el-select>
						</div>
						<!-- 条件类型 -->
						<div class="right_top_search_type flex-items-center">
							<el-button slot="append" size="mini" class="el-icon-search"  @click="Find()"></el-button>
							<i></i>
							<el-select size="medium" style="width:150px" v-model="TypeValue" slot="prepend" @change="TypeValueChange()">
								<el-option :label="$t('Controller.ControllerName')" value="1"></el-option>
								<el-option :label="$t('Controller.ControllerLocation')" value="2"></el-option>
								<el-option :label="$t('Controller.ControllerType')" value="3"></el-option>
								<el-option :label="$t('Controller.SN')" value="4"></el-option>
							</el-select>
						</div>
					</div>
					<!--操作1 -->
					<div class="right_top_card2 flex-center boxshadow">
						<el-button size="medium" type="primary" icon="el-icon-circle-plus-outline" @click="AddEquipmentDialog()"
						:disabled="!userGroupDetails.RoleControllers">{{$t('Controller.AddController')}}</el-button>
						<el-button size="medium" type="danger" style="margin-left: 20px;" slot="reference" icon="el-icon-remove-outline" :disabled="!userGroupDetails.RoleControllers" @click="DelControllers('')">{{$t('Employee.BatchesDelete')}}</el-button>
					</div>
					<!--操作2 -->
					<div class="right_top_card3 flex-center boxshadow">
						<el-button size="small" class="el-icon-refresh-right" @click="Controllers()"> {{$t('Controller.Refresh')}}</el-button>
					</div>
				</div>
				<!-- 底部 -->
				<div class="content_right_bottom flex-center boxshadow">
					<div>
						<div class="content_right_bottom_title">{{$t('Controller.ControllerList')}}</div>
						<el-table :data="EquipmentList" v-loading="Tableload" height="100%"  @selection-change="handleSelectionChange">
							<el-table-column type="selection" align="center"></el-table-column>
							<el-table-column :label="$t('Controller.ControllerId')" prop="controllersId" align="center"></el-table-column>
							<el-table-column :label="$t('Controller.ControllerType')" align="center">
								<template slot-scope="scope">
									<div style="height: 95px;display: flex;align-items: center;justify-content: center;">
										<img style="width: 50px;" :src="Equipmentimg(scope.row.controllerSubType)" fit="cover"/>
									</div>
								</template>
							</el-table-column>
							<el-table-column :label="$t('Controller.ControllerDetails')" align="center">
								<template slot-scope="scope">
									<el-popover placement="left" trigger="click">
										<div style="display: flex;align-items: center;">
											<!-- 以太网 wifi二维码 -->
											<div v-if="scope.row.controllerType==1 && userGroupDetails.RoleControllers" style="margin-right: 10px;">
												<qrcode v-if="scope.row.enableDHCP == 1" :value="scope.row.qrValue" :options="{ width: 300 , errorCorrectionLevel: 'L'}"></qrcode>
												<qrcode v-else :value="scope.row.qrValue" :options="{ width: 500, errorCorrectionLevel: 'L'}"></qrcode>
											</div>
											<div>
												<div class="TableP"><div>{{$t('Controller.ControllerType')}}</div>{{ ControllerSubType(scope.row.controllerType)+' - '+scope.row.controllerSubType}}</div>
												<div class="TableP"><div>{{$t('Controller.ControllerName')}}</div>{{ scope.row.controllerName}}</div>
												<div class="TableP"><div>{{$t('Controller.ControllerLocation')}}</div>{{ scope.row.location}}</div>
												<div v-if="scope.row.controllerType==1">
													<div class="TableP">
														<div>{{$t('Controller.ObtainIPAutomatically')}}</div><el-tag size="small" v-if="scope.row.enableDHCP == 1">{{$t('Controller.Enable')}}</el-tag><el-tag size="small" v-else type="info">{{$t('Controller.NotEnabled')}}</el-tag>
													</div>
													<div class="TableP" v-if="scope.row.enableDHCP != 1"><div>{{$t('Controller.IP')}}</div>{{ scope.row.iP}}</div>
													<div class="TableP" v-if="scope.row.enableDHCP != 1"><div>{{$t('Controller.SubnetMask')}}</div>{{ scope.row.mASK}}</div>
													<div class="TableP" v-if="scope.row.enableDHCP != 1"><div>{{$t('Controller.Gateway')}}</div>{{ scope.row.gateWay}}</div>
													<div class="TableP" v-if="scope.row.enableDHCP != 1"><div>{{$t('Controller.DNS1')}}</div>{{ scope.row.dNS}}</div>
													<div class="TableP" v-if="scope.row.enableDHCP != 1"><div>{{$t('Controller.DNS2')}}</div>{{ scope.row.dNS2}}</div>
													<div class="TableP">
														<div>{{$t('Controller.CustomService')}}</div>
														<span v-if="scope.row.serverIP!='' && scope.row.serverIP !=undefined">{{scope.row.serverIP}}</span>
														<el-tag size="small" v-else type="info">{{$t('Controller.NotEnabled')}}</el-tag>
													</div>
													<div class="TableP">
														<div>{{$t('Controller.VerificationMode')}}</div>
														<el-tag size="small" v-if="scope.row.validateMode == 0">{{$t('Controller.VerificationMode1')}}</el-tag>
														<el-tag size="small" v-if="scope.row.validateMode == 1" type="warning">{{$t('Controller.VerificationMode3')}}</el-tag>
														<el-tag size="small" v-if="scope.row.validateMode == 2" type="warning">{{$t('Controller.VerificationMode2')}}</el-tag>
													</div>
													<div class="TableP">
														<div>{{$t('Controller.ModeDetails')}}</div>
														<el-tag size="small" v-if="scope.row.offlineValidate == 0">{{$t('Controller.Mode1')}}</el-tag>
														<el-tag size="small" v-if="scope.row.offlineValidate == 1">{{$t('Controller.Mode2')}}</el-tag>
														<el-tag size="small" v-if="scope.row.offlineValidate == 3">{{$t('Controller.Mode3')}}</el-tag>
														<el-tag size="small" v-if="scope.row.offlineValidate == 4">{{$t('Controller.Mode4')}}</el-tag>
														<el-tag size="small" v-if="scope.row.offlineValidate == 5">{{$t('Controller.Mode5')}}</el-tag>
														<!-- <el-tag size="small" v-if="scope.row.offlineValidate == 6">{{$t('Controller.Mode6')}}</el-tag> -->
													</div>
												</div>
											</div>
										</div>
										<div v-if="scope.row.controllerType==1"  slot="reference" style="color: #409EFF;cursor:pointer;">
											<i class="el-icon-connection" style="font-size: 15px;"></i> {{ scope.row.controllerName}}
											<div>
												<span v-if="scope.row.networkModel==0">({{$t('Controller.Ethernet')}} +</span>
												<span v-else-if="scope.row.networkModel==1">({{$t('Controller.WIFI')}} +</span>
												<!-- <span v-else-if="scope.row.networkModel==2">({{$t('Controller.Adaptive')}} +</span> -->
												<span v-else-if="scope.row.networkModel==3">({{$t('Controller.FourG')}} +</span>
												<span v-if="scope.row.cM==0"> {{$t('Controller.HTTP')}})</span>
												<span v-else-if="scope.row.cM==1"> {{$t('Controller.MQTT')}})</span>
											</div>
										</div>
										<div v-if="scope.row.controllerType==2" slot="reference" style="color: #409EFF;cursor:pointer;">
											{{ scope.row.controllerName}}
											<div>({{$t('Controller.MQTT')}})</div>
										</div>
									</el-popover>
								</template>
							</el-table-column>
							<el-table-column :label="$t('Controller.TaskType')" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.taskType==0">{{$t('Controller.TaskType0')}}</span>
									<span v-if="scope.row.taskType==1">{{$t('Controller.TaskType1')}}</span>
								</template>
							</el-table-column>
							<el-table-column :label="$t('Controller.ControllerLocation')" align="center">
								<template slot-scope="scope">
									<div>{{scope.row.regionName}}</div>
									<div>{{scope.row.location}}</div>
								</template>
							</el-table-column>
							<el-table-column :label="$t('Controller.IP')" prop="iP" align="center">
								<template slot-scope="scope">
									<div v-if="scope.row.enableDHCP==0">{{scope.row.iP}}</div>
									<el-tag type="info" v-else>{{$t('Controller.ObtainIPAutomatically')}}</el-tag>
								</template>
							</el-table-column>
							<el-table-column :label="$t('Controller.InformationSync')" align="center">
								<template slot-scope="scope">
									<el-tag v-if="scope.row.information == 1 && scope.row.controllerType==1" type="success">{{$t('Controller.Synced')}}</el-tag>
									<el-tag v-else-if="scope.row.information == 0 && scope.row.controllerType==1" type="warning">{{$t('Controller.NotSynced')}}</el-tag>
									<el-tag v-if="scope.row.controllerType==2" type="danger">{{$t('Controller.NotSupport')}}</el-tag>
								</template>
							</el-table-column>
							<el-table-column :label="$t('Controller.OnlineStatus')" align="center">
								<template slot-scope="scope">
									<i v-if="Online(scope.row.online)" style="font-size: 30px;color: #409EFF;" class="el-icon-monitor"></i>
									<i v-else style="font-size: 30px;color: #F56C6C" class="el-icon-warning"></i>
								</template>
							</el-table-column>
							
							<el-table-column :label="$t('Controller.Operation')" width="175px" align="center">
								<template slot-scope="scope">
									<div class="table_button">
										<el-tooltip class="item" effect="dark" :content="$t('Controller.SyncInformation')" placement="top" :enterable="false">
											<el-button :disabled="!userGroupDetails.RoleControllers || scope.row.controllerType!=1" type="warning" size="mini" icon="el-icon-refresh" @click="SynchronousDeviceInformation(scope.row.controllersId)"></el-button>
										</el-tooltip>
										<el-tooltip class="item" effect="dark" :content="$t('Controller.Update')" placement="top" :enterable="false">
											<el-button :disabled="!userGroupDetails.RoleControllers" type="primary" size="mini" icon="el-icon-edit" @click="UpdEquipmentDialog(scope.row)"></el-button>
										</el-tooltip>
										<el-tooltip class="item" effect="dark" :content="$t('Controller.Delete')" placement="top" :enterable="false">
											<el-button :disabled="!userGroupDetails.RoleControllers" type="danger" size="mini" icon="el-icon-delete" @click="DelControllers(scope.row.controllersId,scope.row.controllerName)"></el-button>
										</el-tooltip>
									</div>
									<div class="table_button">
										<el-tooltip class="item" effect="dark" :content="$t('Controller.RebotDevice')" placement="top" :enterable="false">
											<el-button :disabled="!userGroupDetails.RoleControllers || scope.row.controllerType!=1" type="success" 
											size="mini" icon="el-icon-s-opportunity" @click="rebot(scope.row.controllersId)"></el-button>
										</el-tooltip>
										<el-tooltip class="item" effect="dark" :content="$t('Controller.CleanDeviceEmployee')" placement="top" :enterable="false">
											<el-button :disabled="!userGroupDetails.RoleControllers || scope.row.controllerType!=1" type="info" 
											size="mini" icon="el-icon-delete-solid" @click="cleanDeviceEmployeeTip(scope.row.controllersId)"></el-button>
										</el-tooltip>
										<el-tooltip class="item" effect="dark" :content="$t('Controller.cleanBrushCard')" placement="top" :enterable="false">
											<el-button :disabled="!userGroupDetails.RoleControllers || scope.row.controllerType!=1" type="warning" 
											size="mini" icon="el-icon-delete-solid" @click="cleanBrushCardRecordTip(scope.row.controllersId)"></el-button>
										</el-tooltip>
									</div>
								</template>
							</el-table-column>
						</el-table>
						<div class="content_right_bottom_page flex-items-center">
							<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[20, 50, 100]"
							:page-size="QueryEquipment.Count" :current-page="QueryEquipment.Page" layout="total, sizes, prev, pager, next, jumper" :total="EquipmentListSize">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>

		<!-- 新增修改区域弹框 -->
		<el-dialog :title="AreaDialog.title" :visible.sync="AreaDialog.dialogVisible" width="30%" :close-on-click-modal="false">
			<el-form ref="AreaformRef" label-width="80px" :model="AreaForm" :rules="AreaFormRules">
				<el-form-item :label="$t('Controller.ParentArea')" v-if="AreaDialog.listState">
					<select-tree :placeholder="$t('SelectTree.Select')" v-model="AreaForm.AreaId" :options="AreaList" :props="AreaProps" />
				</el-form-item>
				<el-form-item :label="$t('Controller.AreaName')" prop="AreaName">
					<el-input maxlength="32" v-model="AreaForm.AreaName" :placeholder="$t('Controller.PleaseInput')"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="AreaDialog.dialogVisible = false">{{$t('Controller.Cancel')}}</el-button>
				<el-button type="primary" v-if="AreaDialog.operationType==1" :loading="AreaDialog.buttonState" @click="AddArea">{{AreaDialog.buttonName}}</el-button>
				<el-button type="primary" v-else :loading="AreaDialog.buttonState" @click="UpdateAreaName">{{AreaDialog.buttonName}}</el-button>
			</span>
		</el-dialog>
		
		<!-- 新增修改设备弹框 -->
		<el-dialog :title="DialogTitle" :visible.sync="EquipmentVisible"  width="60%" top="70px" :close-on-click-modal="false">
			<div v-if="active==1">
				<el-alert :title="$t('Controller.AddPage1Message')" type="info" show-icon :closable="false"></el-alert>
				<div class="controller_dialog_top">
					<div class="controller_value">
						<div class="controller_type">
							<div :class="taskType==0?'check_controller_type1':'check_controller_type2'" @click="taskType=0">{{$t('Controller.TaskType0')}}</div>
							<div :class="taskType==1?'check_controller_type1':'check_controller_type2'" @click="taskType=1">{{$t('Controller.TaskType1')}}</div>
						</div>
						<div class="controller_item_title">{{$t('Controller.QRCodeController')}}</div>
						<div>
							<div class="controller_item" v-for="(item,index) in controllerTypelist[taskType].controller" :key="index+'1'" v-if="item.controllerType==1">
								<div class="EquipmentType_img" @click="ClickRadio(item.controllerType+','+item.controllerSubType+','+item.cm+','+taskType)">
									<img :src="Equipmentimg(item.controllerSubType)" />
								</div>
								<div class="EquipmentType_radio">
									<el-radio v-model="radio" @change="Radio" :label="item.controllerType+','+item.controllerSubType+','+item.cm+','+taskType">{{item.controllerSubType}}</el-radio>
								</div>
							</div>
						</div>
						<div class="controller_item_title" v-if="taskType==0">{{$t('Controller.FaceController')}}</div>
						<div>
							<div class="controller_item" v-for="(item,index) in controllerTypelist[taskType].controller" :key="index+'2'" v-if="item.controllerType==2">
								<div class="EquipmentType_img" @click="ClickRadio(item.controllerType+','+item.controllerSubType+','+item.cm+','+taskType)">
									<img :src="Equipmentimg(item.controllerSubType)" />
								</div>
								<div class="EquipmentType_radio">
									<el-radio v-model="radio" @change="Radio" :label="item.controllerType+','+item.controllerSubType+','+item.cm+','+taskType">{{item.controllerSubType}}</el-radio>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="controller_dialog_bottom">
					<div></div>
					<div>
						<el-button v-if="taskType==0 || taskType==2" :disabled="radio == '' ? true:false" type="primary" @click="active=3">{{$t('Controller.NextStep')}}</el-button>
						<el-button v-if="taskType==1" :disabled="radio=='' ? true:false" type="primary" @click="getControllerFloor">{{$t('Controller.NextStep')}}</el-button>
					</div>
				</div>
			</div>
			<div v-else-if="active==2">
				<el-alert :title="$t('Controller.AddPage2Message')" type="info" show-icon :closable="false"></el-alert>
				<div class="controller_dialog_top">
					<div class="generated_floor">
						<div>{{$t("Controller.ElevatorFloor")}}</div>
						<div><el-input v-model="least" :placeholder="$t('Controller.Lowest')"><template slot="append">{{$t('Controller.Lowest')}}</template></el-input></div>
						<div>-</div>
						<div><el-input v-model="max" :placeholder="$t('Controller.HighestDescription')"><template slot="append">{{$t('Controller.Highest')}}</template></el-input></div>
						<div><el-button type="primary" @click="generatedFloor">{{$t('Controller.SetFloor')}}</el-button></div>
					</div>
					<div class="generated_floor_value">
						<el-table :data="floorList" height="590px" :header-cell-style="{backgroundColor:'#EBEEF5',color:'#606266'}">
              				<el-table-column align="center" prop="date" :label="$t('LiftArea.Building')">
                				<template slot-scope="scope">{{ scope.row.index }}&nbsp;{{ $t("LiftArea.Floor") }}</template>
              				</el-table-column>
              				<el-table-column align="center" prop="name" :label="$t('LiftArea.Name')">
                				<template slot-scope="scope">
                  					<div v-if="scope.row.type == undefined || scope.row.type == 0">
                    					<span v-if=" scope.row.label == '' || scope.row.label == undefined">{{ scope.row.index }}&nbsp;{{$t("LiftArea.Floor")}}</span>
                    					<span v-else>{{ scope.row.label }}</span>
                    					<el-button size="mini" type="text" style="margin-left: 5px" class="el-icon-edit UpdateFloorName" @click="showUpdateFloorName(scope.$index)"></el-button>
                  					</div>
                  					<div v-else class="flex-center">
										<el-input class="FloorCount_input" maxlength="32" style="width: 150px; margin-right: 10px" size="mini" v-model="scope.row.label"></el-input>
										<el-button slot="append" size="mini" @click="scope.row.type=0" >{{ $t("LiftArea.Save") }}</el-button>
                  					</div>
                				</template>
              				</el-table-column>
              				<el-table-column align="center" prop="address" :label="$t('LiftArea.Status')">
               					<template slot-scope="scope">
                  					<div class="Table-column">
										<el-switch style="display: block"
										v-model="scope.row.status"
										active-color="#13ce66"
										inactive-color="#ff4949"
										:active-text="$t('LiftArea.On')"
										:inactive-text="$t('LiftArea.Off')"
										>
										</el-switch>
                  					</div>
                				</template>
              				</el-table-column>
            			</el-table>
					</div>
				</div>
				<div class="controller_dialog_bottom">
					<div></div>
					<div>
						<el-button @click="active=1">{{$t('Controller.PreviousStep')}}</el-button>
						<el-button type="primary" @click="judgmentFloor">{{$t('Controller.NextStep')}}</el-button>
					</div>
				</div>
			</div>
			<div v-else-if="active==3">
				<el-alert :title="$t('Controller.AddPage3Message')" type="info" show-icon :closable="false"></el-alert>
				<div class="controller_dialog_top">
					<el-form ref="EquipmentformRef" label-position="right" style="margin-right: 10px;" :model="EquipmentForm" :rules="EquipmentFormRules" label-width="125px">
						<el-form-item :label="'* '+$t('Controller.Area')" prop="ControllersAreaId">
							<select-tree :placeholder="$t('SelectTree.Select')" v-model="EquipmentForm.ControllersAreaId" :options="AreaList" :props="EquipmentProps" />
						</el-form-item>
						<el-form-item :label="$t('Controller.ControllerName')" prop="ControllerName">
							<el-input :placeholder="$t('Controller.PleaseInput')" v-model="EquipmentForm.ControllerName" maxlength="32" show-word-limit></el-input>
						</el-form-item>
						<el-form-item :label="$t('Controller.ControllerLocation')" prop="Location">
							<el-input :placeholder="$t('Controller.PleaseInput')" v-model="EquipmentForm.Location" maxlength="32" show-word-limit></el-input>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentForm.NetworkModel != 3" :label="$t('Controller.ObtainIPAutomatically')">
							<el-switch v-model="EquipmentForm.DHCP"></el-switch>
						</el-form-item>
						<el-form-item v-if="!EquipmentForm.DHCP && EquipmentForm.ControllerType==1 && EquipmentForm.NetworkModel != 3" :label="$t('Controller.IP')" prop="IP">
							<el-input :placeholder="$t('Controller.PleaseInput')" v-model="EquipmentForm.IP" ></el-input>
						</el-form-item>
						<el-form-item v-if="!EquipmentForm.DHCP && EquipmentForm.ControllerType==1 && EquipmentForm.NetworkModel != 3" :label="$t('Controller.SubnetMask')" prop="MASK">
							<el-input :placeholder="$t('Controller.PleaseInput')" v-model="EquipmentForm.MASK"></el-input>
						</el-form-item>
						<el-form-item v-if="!EquipmentForm.DHCP && EquipmentForm.ControllerType==1 && EquipmentForm.NetworkModel != 3" :label="$t('Controller.Gateway')" prop="GateWay">
							<el-input :placeholder="$t('Controller.PleaseInput')" v-model="EquipmentForm.GateWay"></el-input>
						</el-form-item>
						<el-form-item v-if="!EquipmentForm.DHCP && EquipmentForm.ControllerType==1 && EquipmentForm.NetworkModel != 3" :label="$t('Controller.DNS1')" prop="DNS">
							<el-input  :placeholder="$t('Controller.PleaseInput')" v-model="EquipmentForm.DNS"></el-input>
						</el-form-item>
						<el-form-item v-if="!EquipmentForm.DHCP && EquipmentForm.ControllerType==1 && EquipmentForm.NetworkModel != 3" :label="$t('Controller.DNS2')" prop="DNS2">
							<el-input :placeholder="$t('Controller.PleaseInput')" v-model="EquipmentForm.DNS2"></el-input>
						</el-form-item>
						<el-form-item  :label="$t('Controller.AdvancedConfiguration')">
							<el-switch v-model="EquipmentAdvancedConfig"></el-switch>&nbsp;&nbsp;({{$t('Controller.AdvancedConfigurationHint')}})
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig" :label="$t('Controller.DoorOpenDelay')" prop="Delayed">
							<el-input maxlength="32" :placeholder="$t('Controller.PleaseInput')+$t('Controller.millisecond')" v-model="EquipmentForm.Delayed"></el-input>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig" :label="$t('Controller.ControllerPassword')" prop="SystemPassword">
							<el-input maxlength="32" :placeholder="$t('Controller.PleaseInput')" show-password v-model="EquipmentForm.SystemPassword"></el-input>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig" :label="$t('Controller.ServiceAddress')">
							<el-input maxlength="100" :placeholder="$t('Controller.CustomServiceAddress')" v-model="EquipmentForm.ServerUrl"></el-input>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig" :label="$t('Controller.SN')" prop="SN">
							<el-input :placeholder="$t('Controller.SN')" v-model="EquipmentForm.SN"></el-input>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig" :label="$t('Controller.OpenClose')">
							<el-select v-model="EquipmentForm.TemplateId" clearable :placeholder="$t('Controller.OpenClose')">
							    <el-option v-for="item in openCloseList" :key="item.templatesId" :label="item.templateName" :value="item.templatesId"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig" :label="$t('Controller.NetworkingMode')">
							<el-select v-model="EquipmentForm.NetworkModel"  :placeholder="$t('Controller.NetworkingMode')">
							    <el-option :label="$t('Controller.EthernetConnect')" value="0"></el-option>
							    <el-option v-if="EquipmentForm.ControllerSubType==='CR02EN' || EquipmentForm.ControllerSubType==='CR05EU' || 
								EquipmentForm.ControllerSubType==='CR06E'|| EquipmentForm.ControllerSubType==='CR06EU'||
								EquipmentForm.ControllerSubType==='CR07EU' || EquipmentForm.ControllerSubType==='CR08EU' || 
								EquipmentForm.ControllerSubType==='CR09EU' || EquipmentForm.ControllerSubType==='CR09EUL'" :label="$t('Controller.WIFIConnect')" value="1"></el-option>
								<!-- <el-option :label="$t('Controller.AdaptiveWay')" value="2"></el-option> -->
								<el-option v-if="EquipmentForm.ControllerSubType==='CR09EU' || EquipmentForm.ControllerSubType==='CR09EUL'" :label="$t('Controller.FourG')" value="3"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig && (EquipmentForm.NetworkModel==1 || EquipmentForm.NetworkModel==2)" :label="$t('Controller.WIFIName')" prop="WIFIName">
							<el-input maxlength="32" :placeholder="$t('Controller.PleaseInput')" v-model="EquipmentForm.WIFIName"></el-input>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig && (EquipmentForm.NetworkModel==1 || EquipmentForm.NetworkModel==2)" :label="$t('Controller.WIFIPassword')" prop="WIFIPassWord">
							<el-input maxlength="32" :placeholder="$t('Controller.PleaseInput')" show-password v-model="EquipmentForm.WIFIPassWord"></el-input>
						</el-form-item>
						<el-form-item v-if="EquipmentAdvancedConfig" :label="$t('Controller.Direction')">
							<el-select v-model="EquipmentForm.WorkType" :placeholder="$t('Controller.Direction')">
							    <el-option :label="$t('Controller.Direction1')" value="0"></el-option>
							    <el-option :label="$t('Controller.Direction2')" value="1"></el-option>
								<el-option :label="$t('Controller.Direction3')" value="2"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig" :label="$t('Controller.CommunicationMode')">
							<el-select v-model="EquipmentForm.CM" :placeholder="$t('Controller.Select')">
							      <el-option label="HTTP" value="0"></el-option>
							      <el-option label="MQTT" value="1"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig && EquipmentForm.CM==1" :label="$t('Controller.MQTTUserName')" prop="MqttUserName">
							<el-input maxlength="32" :placeholder="$t('Controller.PleaseInput')" v-model="EquipmentForm.MqttUserName"></el-input>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig && EquipmentForm.CM==1" :label="$t('Controller.MQTTPassWord')" prop="MqttPassWord">
							<el-input maxlength="32" :placeholder="$t('Controller.PleaseInput')" show-password v-model="EquipmentForm.MqttPassWord"></el-input>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig" :label="$t('Controller.VerificationMode')">
							<el-radio v-model="EquipmentForm.ValidateMode" @change="changeMode" label="0" border size="medium">{{$t('Controller.VerificationMode1')}}</el-radio>
							<el-radio v-model="EquipmentForm.ValidateMode" @change="changeMode" label="2" border size="medium">{{$t('Controller.VerificationMode2')}}</el-radio>
							<el-radio v-model="EquipmentForm.ValidateMode" @change="changeMode" label="1" border size="medium">{{$t('Controller.VerificationMode3')}}</el-radio>
						</el-form-item>
						<el-form-item v-if="EquipmentForm.ControllerType==1 && EquipmentAdvancedConfig" :label="$t('Controller.ModeDetails')">
							<el-radio v-model="EquipmentForm.OfflineValidate" label="0">{{$t('Controller.Mode1')}}</el-radio>
							<el-radio v-model="EquipmentForm.OfflineValidate" label="1">{{$t('Controller.Mode2')}}</el-radio>
							<!-- 在线状态下不显示离线规则验证 -->
							<el-radio v-if="EquipmentForm.ValidateMode==1" v-model="EquipmentForm.OfflineValidate" label="3">{{$t('Controller.Mode3')}}</el-radio>
							<el-radio v-model="EquipmentForm.OfflineValidate" label="4">{{$t('Controller.Mode4')}}</el-radio>
							<el-radio v-model="EquipmentForm.OfflineValidate" label="5">{{$t('Controller.Mode5')}}</el-radio>
							<!-- 在线状态下不显示离线规则验证 -->
						</el-form-item>
					</el-form>
				</div>
				<div class="controller_dialog_bottom">
					<div></div>
					<div>
						<el-button v-if="EquipmentForm.taskType==0 || EquipmentForm.taskType==2" @click="active=1">{{$t('Controller.PreviousStep')}}</el-button>
						<el-button v-if="EquipmentForm.taskType==1" @click="active=2">{{$t('Controller.PreviousStep')}}</el-button>
						<el-button type="primary" @click="AddUpdEquipment">{{$t('Controller.Save')}}</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 添加设备和修改设备成功后的提示弹框 -->
		<el-dialog :title="$t('Controller.Tip')" :visible.sync="EquipmentSuccessVisible" width="60%" top="70px" :close-on-click-modal="false">
			<!-- 配置码码，居中显示 -->
			<div class="center">
				<qrcode :value="this.qrValue" :options="{ width: 300 , errorCorrectionLevel: 'L'}"></qrcode>
			</div>
			<div class="center_desc">{{$t('Controller.Description')}}</div>
			    <span slot="footer" class="dialog-footer">
			      <el-button type="primary" @click="CloseEquipmentSuccess()">{{this.$t('Controller.Sure')}}</el-button>
			    </span>
		</el-dialog>
	</div>
</template>

<script>
	import SelectTree from '../components/SelectTree.vue';
	import 'driver.js/dist/driver.min.css'
	import Driver from 'driver.js'

	export default {
		name: 'about',
		components: {
			SelectTree,
		},
		data() {
			//校验名称
			var AreaName_verify = (rule, value, callback) => {
			if (!value)
				return callback(new Error(this.$t('Controller.PleaseInput')));
			callback();
			};
			//校验IP
			var CheckIP = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error(this.$t('Controller.NotNull')));
				}
				var re=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;//正则表达式   
				if(re.test(value))   
				{   
				    if(RegExp.$1<256 && RegExp.$2<256 && RegExp.$3<256 && RegExp.$4<256)
					{
						//正确
					}
					else
					{
						return callback(new Error(this.$t('Controller.IncorrectFormat')));
					}
				}
				else
				{
					return callback(new Error(this.$t('Controller.IncorrectFormat')));
				}
			};
			
			var CheckMASK = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error(this.$t('Controller.NotNull')));
				}
				var re=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;//正则表达式
				if(re.test(value))   
				{   
				    if(RegExp.$1<256 && RegExp.$2<256 && RegExp.$3<256 && RegExp.$4<256)
					{
						//正确
					}
					else
					{
						return callback(new Error(this.$t('Controller.IncorrectFormat')));
					}
				}
				else
				{
					return callback(new Error(this.$t('Controller.IncorrectFormat')));
				}
			};
			var CheckGateWay = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error(this.$t('Controller.NotNull')));
				}
				var re=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;//正则表达式   
				if(re.test(value))   
				{   
				    if(RegExp.$1<256 && RegExp.$2<256 && RegExp.$3<256 && RegExp.$4<256)
					{
						//正确
					}
					else
					{
						return callback(new Error(this.$t('Controller.IncorrectFormat')));
					}
				}
				else
				{
					return callback(new Error(this.$t('Controller.IncorrectFormat')));
				}
			};
			var CheckDNS = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error(this.$t('Controller.NotNull')));
				}
				var re=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;//正则表达式
				if(re.test(value))   
				{   
				    if(RegExp.$1<256 && RegExp.$2<256 && RegExp.$3<256 && RegExp.$4<256)
					{
						//正确
					}
					else
					{
						return callback(new Error(this.$t('Controller.IncorrectFormat')));
					}
				}
				else
				{
					return callback(new Error(this.$t('Controller.IncorrectFormat')));
				}
			};
			var CheckServerUrl = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error(this.$t('Controller.NotNull')));
				}
			};
			var CheckSN = async (rule,value,callback) =>{
				//如果值不为空，则必须为纯数字
				if(value){
					// var re = /^[0-9]*$/; //正则表达式
					// if(!re.test(value)){
					// 	return callback(new Error('设备序列号只能输入纯数字'));
					// }
					//文本框输入值与修改值不同才需要请求后台校验
					if(value != this.judgeSn){
						var {data: res} = await this.$http.post('JudgeEquipmentSN', {"SN":value})
						if (res.resultCode == 2) {
							return callback(new Error(this.$t('Controller.CheckFailure')));
						}
						if (res.resultCode == 3) {
							return callback(new Error(this.$t('Controller.SNExist')));
						}
					}
				}
			};

			var ControllerName_verify = (rule, value, callback) => {
				if (!value)
				return callback(new Error(this.$t('Controller.PleaseInput')));
				callback();
			};
			var Verify = (rule, value, callback) => {
				if (!value)
				return callback(new Error(this.$t('Controller.Select')));
				callback();
			};
			return {
				//二维码内容
				qrValue:'',
				//权限组
				userGroupDetails: {
					RoleArea: false, //区域操作权
					RoleControllers: false //设备操作权
				},

				//区域信息
				ControllersAreaCode:'',//选中的区域编码
				ControllersAreaId:'',//选中的区域Id
				suspensionStyle: {}, //区域列表样式
				Treeload: false, //树形Loading加载状态
				filterText: '', //区域搜索
				AreaList: [], //区域列表
				
				//区域操作弹框
				AreaDialog: {
					click: 'AddArea',
					listState: true, //是否显示下拉菜单
					title: this.$t('Controller.AddArea'), //标题
					dialogVisible: false, //显示状态
					buttonName: this.$t('Controller.Submit'), //提交按钮名称
					buttonState: false, //按钮状态
					operationType: 1 //1-新增，2-修改
				},

				// 数据默认字段
				AreaProps: {
					parent: 'parentControllersAreaId', // 父级唯一标识
					value: 'controllersAreaId', // 唯一标识
					label: 'regionName', // 标签显示
					children: 'children', // 子级
				},

				//区域表单数据绑定对象
				AreaForm: {
					AreaId: '',
					AreaName: '',
					Type: 1
				},
				
				//表单验证规则
				AreaFormRules: {
					AreaId: [{
						required: true,
						validator: Verify,
						trigger: 'blur'
					}],
					AreaName: [{
							required: true,
							validator: AreaName_verify,
							trigger: "change",
						}
					]
				},


				//设备信息
				Tableload: false, //表格Loading加载状态
				TableName: '', //表格标题
				TypeValue: '1',
				QueryEquipment:{
					ControllersAreaCode:'',//区域编码
					Count:20,//分页条数
					Pages:1,//分页页数
					TypeValue:'0',//按条件查询下拉选择
					Value: ''//按条件查询值
				},
				IdData: [], //选中的设备ID
				SystemTime:'',//服务端时间
				EquipmentListSize:0,
				EquipmentList: [],//设备
				// 数据默认字段
				EquipmentProps: {
					parent: 'parentControllersAreaId', // 父级唯一标识
					value: 'controllersAreaId', // 唯一标识
					label: 'regionName', // 标签显示
					children: 'children' // 子级
				},
				EquipmentVisible: false, //新增修改设备弹框状态
				EquipmentSuccessVisible:false,//新增修改设备成功后的提示弹框状态
				//设备工作类型
				taskType:0,
				//设备类型
				controllerTypelist:[
					//门禁设备
					{taskType:0,controller:[
						//设备类型、设备子类型，设备协议类型
						{controllerType:1,controllerSubType:"CR02E",cm:1},
						{controllerType:1,controllerSubType:"CR02EU",cm:1},
						{controllerType:1,controllerSubType:"CR02EN",cm:1},
						{controllerType:1,controllerSubType:"RD02EU",cm:1},
						{controllerType:1,controllerSubType:"CR03E",cm:1},
						{controllerType:1,controllerSubType:"CR05EU",cm:1},
						{controllerType:1,controllerSubType:"CR06E",cm:2},
						{controllerType:1,controllerSubType:"CR06EU",cm:2},
						{controllerType:1,controllerSubType:"CR07EU",cm:2},
						{controllerType:1,controllerSubType:"CR08EU",cm:2},
						{controllerType:1,controllerSubType:"CR09EU",cm:2},
						{controllerType:1,controllerSubType:"CR09EUL",cm:2},
						{controllerType:2,controllerSubType:"F5",cm:3},
						{controllerType:2,controllerSubType:"F6",cm:3},
						{controllerType:2,controllerSubType:"F8",cm:3},
						{controllerType:2,controllerSubType:"F108",cm:3},
					]},
					//梯控设备
					{taskType:1,controller:[
						//设备类型、设备子类型，设备协议类型
						{controllerType:1,controllerSubType:"CR02EN",cm:2},
						{controllerType:1,controllerSubType:"RD02EU",cm:2},
						{controllerType:1,controllerSubType:"CR05EU",cm:2},
						{controllerType:1,controllerSubType:"CR06E",cm:2},
						{controllerType:1,controllerSubType:"CR06EU",cm:2},
						{controllerType:1,controllerSubType:"CR07EU",cm:2},
						{controllerType:1,controllerSubType:"CR08EU",cm:2},
						{controllerType:1,controllerSubType:"CR09EU",cm:2},
						{controllerType:1,controllerSubType:"CR09EUL",cm:2},
					]},
					//单机门禁
					{taskType:2,controller:[
						//设备类型、设备子类型，设备协议类型
						{controllerType:1,controllerSubType:"CR06",cm:3},
					]}
				],
				active:1,//步骤条
				radio:'' ,//设备类型
				buttomState:false,//按钮状态
				DialogTitle:this.$t('Controller.AddController'),//弹框标题
				AreaValueState:false,//区域选中状态
				EquipmentAdvancedConfig:false,//设备高级配置显示状态
				EquipmentForm: {
					ControllersId:0,
					ControllerType:'',//设备类型
					ControllerSubType:'',//设备子类型
					Agreement:1,//协议
					taskType:"0",//工作类别
					ControllersAreaId:'',//区域
					ControllerName:'',//设备名称
					Location:'',//设备位置
					SystemPassword:'666666',//设备密码
					Delayed:'3000',//控制延时
					WIFIName:'',//WiFi名称
					WIFIPassWord:'',//wifi密码
					NetworkModel:'0',//联网模式
					CM:'0',//通讯协议
					MqttUserName:'admin',
					MqttPassWord:'admin',
					DHCP:true,
					IP:'',//设备IP
					MASK:'',//子网掩码
					GateWay:'',//网关
					DNS:'',//首先DNS
					DNS2:'',//备用DNS
					ServerUrl:'',//服务器地址
					ValidateMode:'0',//验证模式
					OfflineValidate:'5',//模式详情
					WorkType:'0',//设备方向
					SN:'',//设备序列号
					TemplateId:""
				},
				judgeSn:'',//新增修改时记录原值
				
				//设备表单数据验证
				EquipmentFormRules:{
					ControllerName: [
						{required: true,validator: ControllerName_verify,trigger: 'blur'},
					],
					Delayed:[
						{required: true,validator: ControllerName_verify,trigger: 'blur'},
					],
					SystemPassword: [
						{required: true,validator: ControllerName_verify,trigger: 'blur'},
					],
					WIFIName:[{required: true,validator: ControllerName_verify,trigger: 'blur'}],
					WIFIPassWord:[{required: true,validator: ControllerName_verify,trigger: 'blur'}],
					MqttUserName: [{required: true,validator: ControllerName_verify,trigger: 'blur'}],
					MqttPassWord: [{required: true,validator: ControllerName_verify,trigger: 'blur'}],
					IP:[{required: true,validator: CheckIP,trigger: 'blur'}],
					MASK:[{required: true,validator: CheckMASK,trigger: 'blur'}],
					GateWay:[{required: true,validator: CheckGateWay,trigger: 'blur'}],
					DNS:[{required: true,validator: CheckDNS,trigger: 'blur'}],
					DNS2:[{required: true,validator: CheckDNS,trigger: 'blur'}],
					ServerUrl: [{required: true,validator: CheckServerUrl,trigger: 'blur'}],
					SN: [{required: false,validator: CheckSN,trigger: 'blur'}]
				},
				openCloseList:[],
				//楼层
				floorList:[],
				//最低层
				least:"",
				//最高层
				max:""
			};
		},
		watch: {
			//区域查询过滤使用
			filterText(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.tree.filter(val);
			}
		},
		mounted() {
			
			//获取操作权
			this.Role();

			//查询区域
			this.Area();
			
			//查询设备
			this.Controllers();

			//查询常开常闭模板
			this.Template();
			let cookieControllerGuide = this.getCookie()
			if(cookieControllerGuide === undefined){
				setTimeout(()=>{this.controller_guide()},1000)
			}
		},
		methods: {

			//设备引导
			controller_guide () {
			let driver = new Driver({
				className: 'scoped-class', // 包裹driver.js弹窗的类名 className to wrap driver.js popover
				animate: true, // 高亮元素改变时是否显示动画 Animate while changing highlighted element
				opacity: 0.75, // 背景透明度(0 表示只有弹窗并且没有遮罩) Background opacity (0 means only popovers and without overlay)
				padding: 10, //  元素与边缘的距离 Distance of element from around the edges
				allowClose: false, // 是否允许点击遮罩时关闭 Whether clicking on overlay should close or not
				overlayClickNext: false, // 是否允许点击遮罩时移到到下一步 Should it move to next step on overlay click
				doneBtnText: '完成', // 最终按钮上的文本 Text on the final button
				closeBtnText: '关闭', // 当前步骤关闭按钮上的文本 Text on the close button for this step
				nextBtnText: '下一步', // 当前步骤下一步按钮上的文本 Next button text for this step
				prevBtnText: '上一步', // 当前步骤上一步按钮上的文本 Previous button text for this step
				showButtons: true, // 是否在底部显示控制按钮 Do not show control buttons in footer
				keyboardControl: true, // 是否允许通告键盘控制(escape关闭，箭头键用于移动)Allow controlling through keyboard (escape to close, arrow keys to move)
				scrollIntoViewOptions: {}, //  `scrollIntoView()` 方法的选项 We use `scrollIntoView()` when possible, pass here the options for it if you want any
			})
			// Define the steps for introduction
			let steps = [
				{
				element: '.left_value_input',
				popover: {
					title: this.$t('Controller.ControllerGuideTitle1'),
					description: this.$t('Controller.ControllerGuideContent1'),
					position: 'right'
				}
				},
				{
				element: '.left_value_tree',
				popover: {
					title: this.$t('Controller.ControllerGuideTitle2'),
					description: this.$t('Controller.ControllerGuideContent2'),
					position: 'right'
				}
				},
				{
				element: '.content_right_top',
				popover: {
					title: this.$t('Controller.ControllerGuideTitle3'),
					description: this.$t('Controller.ControllerGuideContent3'),
					position: 'bottom'
				}
				},
				{
				element: '.content_right_bottom',
				popover: {
					title: this.$t('Controller.ControllerGuideTitle4'),
					description: this.$t('Controller.ControllerGuideContent4'),
					position: 'left'
				}
				}
			]
			driver.defineSteps(steps)
			// Start the introduction
			driver.start()
			// 保存
			this.setCookie()
			},

			setCookie(){
				//设置10年后过期
				let expires = new Date();
				expires.setTime(expires.getTime()+10*365*24*60*60*1000);
				document.cookie = "controllerGuide="+escape('1')+";expires="+expires.toGMTString();
			},
			getCookie(){
				//获取cookie值
				let str = document.cookie;
				//将值切割成数组
				let arr = str.split(";");
				let controllerGuide;
				//遍历数组
				for(var i=0;i<arr.length;i++){
					var value = arr[i].split("=");
					//避免输入中含有空格，消除文本框空格
          			value[0] = value[0].replace(/\s+/g, "");
					if(value[0] == 'controllerGuide'){
						controllerGuide = value[1];
					}
				}
				return controllerGuide;
			},

			//设备图片
			Equipmentimg(photo) {
				try {
					return require('../assets/images/Equipment/' + photo+'.png')
				} catch (e) {
					return ''
				}
			},

			//获取操作权
			Role() {
				//获取权限组
				var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
				for (var i = 0; i < userGroupDetails.length; i++) {
					if (userGroupDetails[i].roleName == "ControllersArea") { //区域操作权
						this.userGroupDetails.RoleArea = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
					if (userGroupDetails[i].roleName == "Controllers") { //设备操作权
						this.userGroupDetails.RoleControllers = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
				}
			},
			//切换离线在线时触发
			changeMode(){
				this.EquipmentForm.OfflineValidate = '5'
			},

			//查询区域
			async Area() {
				try 
				{
					this.Treeload = true;
					var {data: res} = await this.$http.post('ControllersArea')
					if (res.resultCode == 1) 
					{
						this.AreaList = JSON.parse(res.body);
					}
					this.Treeload = false;
				} 
				catch (err) 
				{
					this.Treeload = false;
					console.log(err)
				}
			},

			//区域过滤
			filterNode(value, data) {
				if (!value) return true;
				return data.regionName.indexOf(value) !== -1;
			},

			//修改区域操作弹框 （新增操作操作）
			AddAreaDialog(Id) {
				this.AreaDialog.listState = true; //显示下拉菜单
				this.AreaDialog.dialogVisible = true; //显示区域操作弹框
				this.AreaDialog.title = this.$t('Controller.AddArea') //标题
				this.AreaDialog.buttonName = this.$t('Controller.Submit');
				this.AreaDialog.operationType = 1; //显示新增按钮
				this.AreaForm.AreaId = Id; //区域Id
				this.AreaForm.AreaName = ''; //区域名称
			},

			//新增区域
			AddArea() {
				this.$refs.AreaformRef.validate(async valid => { //验证
					if (!valid) return;

					this.AreaDialog.buttonState = true; //按钮状态为加载中
					if (this.AreaForm.AreaId == "") {
						this.AreaForm.AreaId = '0';
					}
					this.AreaForm.Type = 1; //操作为新增
					var {data: res} = await this.$http.post('AddUpdControllersArea', this.AreaForm) //新增区域
					this.AreaDialog.buttonState = false; //按钮状态为加载中
					if (this.FilteringOperation(res)) { //请求结果过滤
						if (res.resultCode == 1) //成功
						{
							this.AreaDialog.dialogVisible = false;
							this.$message({offset:10,message:this.$t('Controller.AddSucceed'),type: 'success'});
							this.AreaForm.AreaName = '';
							this.Area();
						} 
						else if (res.resultCode == 2) //出现错误
						{
							this.$message({offset:10,message:this.$t('Controller.AddFailure'),type: 'error'});

						} 
						else if (res.resultCode == 4) //请求参数不正确
						{
							this.$message({offset:10,message:this.$t('Controller.IncorrectParameter'),type: 'warning'});

						} 
						else if (res.resultCode == 5) //区域名称已存在
						{
							this.$message({offset:10,message:this.$t('Controller.AreaExists'),type: 'warning'});
						}
					}
				})
			},

			//修改区域操作弹框 （修改操作）
			UpdateAreaDialog(Id) {
				this.AreaDialog.listState = false; //不显示下拉菜单
				this.AreaDialog.dialogVisible = true; //显示区域操作弹框
				this.AreaDialog.title = this.$t('Controller.UpdateArea') //标题
				this.AreaDialog.buttonName = this.$t('Controller.Submit');
				this.AreaDialog.operationType = 2; //显示修改按钮
				this.AreaForm.AreaId = Id; //区域Id
			},

			//修改区域名称
			UpdateAreaName() {
				this.$refs.AreaformRef.validate(async valid => { //验证
					if (!valid) return;

					this.AreaDialog.buttonState = true; //按钮状态为加载中
					this.AreaDialog.buttonName =  this.$t('Controller.InSubmit'); //按钮状态为加载中

					if (this.AreaForm.AreaId == "") {
						this.AreaForm.AreaId = '0';
					}

					this.AreaForm.Type = 2; //操作为新增

					//修改区域名称
					var {data: res} = await this.$http.post('AddUpdControllersArea', this.AreaForm)

					this.AreaDialog.buttonState = false; //按钮状态为加载中
					this.AreaDialog.buttonName = this.$t('Controller.Submit'); //按钮状态为加载中
					if (this.FilteringOperation(res)) { //请求结果过滤
						if (res.resultCode == 1) //成功
						{
							this.AreaDialog.dialogVisible = false;
							this.$message({offset:10,message:this.$t('Controller.UpdateSucceed'),type: 'success'});
							this.AreaForm.AreaName = '';
							this.Area();
						} 
						else if (res.resultCode == 2) //出现错误
						{
							this.$message({offset:10,message:this.$t('Controller.UpdateFailure'),type: 'error'});

						} 
						else if (res.resultCode == 4) //请求参数不正确
						{
							this.$message({offset:10,message:this.$t('Controller.IncorrectParameter'),type: 'warning'});

						} 
						else if (res.resultCode == 5) //区域名称已存在
						{
							this.$message({offset:10,message:this.$t('Controller.AreaExists'),type: 'warning'});
						}
					}
				})
			},

			//删除区域
			async DeleteArea(Id,regionName) {
				if (Id != "") {
					//删除区域
					var {data: res} = await this.$http.post('DelControllersArea', {"AreaId": "" + Id + "","RegionName":regionName})
					if (this.FilteringOperation(res)) { //请求结果过滤
						if (res.resultCode == 1) //成功
						{
							this.$message({offset:10,message:this.$t('Controller.DeleteSucceed'),type: 'success'});
							this.Area();
						} 
						else if (res.resultCode == 2) //出现错误
						{
							this.$message({offset:10,message:this.$t('Controller.DeleteFailure'),type: 'error'});

						} 
						else if (res.resultCode == 4) //区域下存在设备
						{
							this.$message({offset:10,message:this.$t('Controller.ExistController'),type: 'error'});
						}
					}
				}
			},

			//选择区域
			choose(Data) {
				this.ControllersAreaId = Data.controllersAreaId;
				this.ControllersAreaCode = Data.controllersAreaCode;
				this.QueryEquipment.ControllersAreaCode = Data.controllersAreaCode;
				this.TableName = Data.regionName;
				this.Controllers();
			},
			
			//查询设备
			async Controllers(){
				try
				{
					this.Tableload = true;
					var {data: res} = await this.$http.post('Controllers',this.QueryEquipment);
					if (res.resultCode == 1) //成功
					{
						var Data = JSON.parse(res.body);
						this.SystemTime = Data.DataTiem;
						this.EquipmentList = Data.Controllers;
						if(this.EquipmentList.length>0)
						{
							this.EquipmentListSize = this.EquipmentList[0].size;
						}
						else
						{
							this.EquipmentListSize = 0;
						}
					}
					this.Tableload = false;
				}
				catch (err)
				{
					this.Tableload = false;
					console.log(err)
				}
			},
			
			//分页条数
			handleSizeChange(val) {
				this.QueryEquipment.Count = val;
				this.Controllers();
			},
			
			//分页页数
			handleCurrentChange(val) {
				this.QueryEquipment.Pages = val;
				this.Controllers();
			},
			
			//按条件查找
			Find(){
				if(this.TypeValue!='')
				{
					this.QueryEquipment.TypeValue = this.TypeValue
				}
				this.QueryEquipment.Pages = 1;
				this.Controllers();
			},
			
			//所有设备
			AllEquipment(){
				this.TableName = '';
				this.QueryEquipment.ControllersAreaCode = "";
				this.Controllers();
			},
			
			//条件选择
			TypeValueChange(){
				this.QueryEquipment.Value=''
				this.QueryEquipment.TypeValue = this.TypeValue
				if(this.TypeValue == '')
				{
					this.Controllers();
				}
			},
			
			//设备类型
			ControllerSubType(Type){
				if(Type==1)
				{
					return this.$t('Controller.ControllerType1');
				}
				else if(Type==2)
				{
					return this.$t('Controller.ControllerType2');
				}
			},
			
			//设备是否在线
			Online(Data){
				if(Data!="" && Data!=undefined)
				{
					try
					{
						var SystemTime = this.SystemTime;
						var startTime = new Date(Date.parse(Data));
						var date = new Date(Date.parse(SystemTime));
						var min = date.getMinutes();
						date.setMinutes(min - 1);
						if(startTime>=date)
						{
							return true;
						}
						else
						{
							return false;
						}
					}
					catch(e)
					{
						return false;
					}
				}
				else
				{
					return false;
				}
			},
			
			//新增设备时显示的弹框
			AddEquipmentDialog(){
				this.EquipmentAdvancedConfig = false;
				this.radio = '';
				this.floorList = [];
				this.EquipmentForm.Type = 1;
				var EquipmentForm = {
					ControllersId:0,
					ControllerType:'',
					ControllerSubType:'',
					Agreement:0,
					taskType:"0",//工作类别
					ControllersAreaId:''+this.ControllersAreaId+'',
					ControllerName:'',
					Location:'',
					SystemPassword:'666666',
					Delayed:'3000',//控制延时
					WIFIName:'',//WiFi名称
					WIFIPassWord:'',//wifi密码
					NetworkModel:'0',//联网模式
					CM:'0',//通讯协议
					MqttUserName:'admin',
					MqttPassWord:'admin',
					DHCP:true,
					IP:'',
					MASK:'',
					GateWay:'',
					DNS:'',
					DNS2:'',
					ServerUrl:'',
					ValidateMode:'0',
					OfflineValidate:'5',
					WorkType:'0',//设备方向
					SN:'',//设备序列号
					TemplateId:""
				}
				this.EquipmentForm = EquipmentForm;
				this.active = 1;
				this.DialogTitle = this.$t('Controller.AddController');
				this.EquipmentVisible = true;
				this.judgeSn = '';
			},
			
			//修改设备时显示的弹框
			UpdEquipmentDialog(Data){
				this.EquipmentAdvancedConfig = false;
				this.EquipmentForm.ControllersAreaId = this.ControllersAreaId;
				this.floorList = [];
				this.radio = Data.controllerType+","+Data.controllerSubType+","+Data.agreement+","+Data.taskType;
				this.taskType = Data.taskType==undefined || Data.taskType==''?0:Data.taskType;
				var EquipmentForm = {
					ControllersId:Data.controllersId,
					ControllerType:Data.controllerType,
					ControllerSubType:Data.controllerSubType,
					Agreement:Data.agreement,
					taskType:Data.taskType,
					ControllersAreaId:""+Data.controllersAreaId+"",
					ControllerName:Data.controllerName,
					Location:Data.location,
					SystemPassword:Data.systemPassword != undefined ? Data.systemPassword:'666666',
					Delayed:Data.delayed,
					NetworkModel:""+Data.networkModel+"",
					WIFIName:Data.wIFIName,
					WIFIPassWord:Data.wIFIPassWord,
					CM:Data.cM.toString(),
					MqttUserName:Data.mqttUserName,
					MqttPassWord:Data.mqttPassWord,
					DHCP:Data.enableDHCP == 1 ? true:false,
					IP:Data.iP,
					MASK:Data.mASK,
					GateWay:Data.gateWay,
					DNS:Data.dNS,
					DNS2:Data.dNS2,
					ServerUrl:Data.serverIP,
					ValidateMode:""+Data.validateMode+"",
					OfflineValidate:""+Data.offlineValidate+"",
					WorkType:""+Data.workType+"",
					SN:""+Data.sn+"",
					TemplateId:""+Data.templateId!=0?Data.templateId:""+"",
				}
				this.active = 1;
				this.EquipmentForm = EquipmentForm;
				this.DialogTitle = this.$t('Controller.UpdateController');
				this.EquipmentVisible = true;
				this.judgeSn = ""+Data.sn+"";
			},
			
			//打开新增增修改设备成功后的提示弹框
			OpenEquipmentSuccess(){
				this.EquipmentSuccessVisible = true;
			},
			
			//关闭新增增修改设备成功后的提示弹框
			CloseEquipmentSuccess(){
				this.EquipmentSuccessVisible = false;
			},
			
			//选设备类型
			Radio(){
				var radio = this.radio.split(',');
				this.EquipmentForm.ControllerType = radio[0];
				this.EquipmentForm.ControllerSubType = radio[1];
				this.EquipmentForm.Agreement = radio[2];
				this.EquipmentForm.taskType = radio[3].toString();
			},
			
			//选设备类型
			ClickRadio(Data){
				var radio = Data.split(',');
				this.radio = Data;
				this.EquipmentForm.ControllerType = radio[0];
				this.EquipmentForm.ControllerSubType = radio[1];
				this.EquipmentForm.Agreement = radio[2];
				this.EquipmentForm.taskType = radio[3].toString();
			},
			
			//下一步/上一步
			NextLastStep(Type){
				if(Type==1)
				{
					if(this.EquipmentForm.ControllersId==0)
					{
						//新增时加载最后一次配置信息
						this.LoadOnce();
					}
					else
					{
						this.active = 2;
					}
				}
				else
				{
					this.active = 1;
				}
			},
			
			//加载上一次配置
			async LoadOnce(){
				try
				{
					var {data: res} = await this.$http.post('SpecifiedControllers',{"ControllerSubType":this.EquipmentForm.ControllerSubType});
					if (res.resultCode == 1) //成功
					{
						var Data = JSON.parse(res.body);
						if(Data!=null)
						{
							this.EquipmentForm.SystemPassword = Data.systemPassword;//设备密码
							this.EquipmentForm.Delayed = Data.delayed;//控制延时
							this.EquipmentForm.NetworkModel = Data.networkModel.toString();//联网模式
							this.EquipmentForm.WIFIName = Data.wIFIName;//WIFI名称
							this.EquipmentForm.WIFIPassWord = Data.wIFIPassWord;//WIFI密码
							this.EquipmentForm.CM = Data.cM.toString();//通讯模式
							this.EquipmentForm.MqttUserName = Data.mqttUserName;//MQTT用户名
							this.EquipmentForm.MqttPassWord = Data.MqttPassWord;//MQTT密码
							this.EquipmentForm.DHCP = Data.enableDHCP == 1 ? true:false;//DHCP
							var IP = Data.iP.split(".");//IP
							this.EquipmentForm.IP = IP.length>=4?(IP[0]+"."+IP[1]+"."+IP[2]):"";//IP
							this.EquipmentForm.MASK = Data.mASK;//MASK
							this.EquipmentForm.GateWay = Data.gateWay;//GateWay
							this.EquipmentForm.DNS = Data.dNS;//dNS
							this.EquipmentForm.DNS2 = Data.dNS2;//dNS2
							this.EquipmentForm.ServerUrl = Data.serverIP;//自定义服务地址
							this.EquipmentForm.ValidateMode = Data.validateMode.toString();//验证模式
							this.EquipmentForm.OfflineValidate = Data.offlineValidate.toString();//模式详情
							this.EquipmentForm.WorkType = Data.workType.toString();//设备方向
						}
						this.active = 2;
					}
				}
				catch (err)
				{
					console.log(err)
				}
			},
			
			//新增/修改设备
			AddUpdEquipment(){
				//如果为4G模式，切换为DHCP
				if(this.EquipmentForm.NetworkModel == 3){
					this.EquipmentForm.DHCP = true;
				}
				this.$refs.EquipmentformRef.validate(async valid => { //验证
					if (!valid) return;
					if (this.EquipmentForm.ControllersAreaId=='' || this.EquipmentForm.ControllersAreaId=='0')
					{
						this.AreaValueState=true;
						return;
					}
					this.buttomState = true;
					this.EquipmentForm.floorList = this.floorList;
					this.EquipmentForm.TemplateId = this.EquipmentForm.TemplateId==''?0:this.EquipmentForm.TemplateId;
					var {data: res} = await this.$http.post('AddUpdControllers', this.EquipmentForm)
					this.buttomState = false;
					if(this.FilteringOperation(res))
					{
						if(this.EquipmentForm.ControllersId==0)
						{
							if (res.resultCode == 1)
							{
								this.Controllers();
								this.EquipmentVisible = false;
								this.$refs.EquipmentformRef.resetFields();
								this.$message({offset:10,message: this.$t('Controller.AddSucceed'),type: 'success'});
								this.active = 1;
							}
						}
						else
						{
							if (res.resultCode == 1)
							{
								this.Controllers();
								this.EquipmentVisible = false;
								this.$refs.EquipmentformRef.resetFields();
								this.$message({offset:10,message: this.$t('Controller.UpdateSucceed'),type: 'success'});
								this.active = 1;
							}
						}
						if(res.resultCode == 500){
							this.$message({offset:10,message: this.$t('Controller.EquipmentNumError'),type: 'error'});
						}
					}
				})
			},
    		//生成楼层
			generatedFloor() {
				var least = this.least; //最小楼层
				var max = this.max; //最大楼层
				var regPos = /^-?\d+(?:\.\d+)?$/; 
				//判断是否是数字。
				if (!regPos.test(least) || !regPos.test(max)){
					return this.$message({ message: this.$t("LiftArea.Message1"), type: "error", offset: 10});
				}
				//判断最低最高层
				if (least < -7 || least > 120 || max < -7 || max > 120){
					return this.$message({ message: this.$t("LiftArea.Message2"), type: "error", offset: 10});
				}
				least = Number(this.least);
				max = Number(this.max) + 1;
				var FloorData = [];
				var TableData = this.floorList;
				var Tableleast = 120;
				var Tablemax = -8;
				for (var i = 0; i < TableData.length; i++) {
					if (TableData[i].index < Tableleast){
						Tableleast = TableData[i].index;
					}
					if (TableData[i].index > Tablemax){
						Tablemax = TableData[i].index;
					}
				}
				for (var i = least; i < max; i++) {
					if (i != 0) {
						var value = { index: i, label: "",type:0,status: true};
						for (var k = 0; k < TableData.length; k++) {
							if (i == TableData[k].index) {
								value = {index: i,label: TableData[k].label,type:0,status: TableData[k].status};
								break;
							}
						}
						FloorData[FloorData.length] = value;
					}
				}
				this.floorList = FloorData;
			},

			//判断楼层
			judgmentFloor(){
				var floorList = this.floorList;
				if(floorList.length==0){
					this.$message({message: this.$t("Controller.AddFloorDescription"),type: "error",offset: 10});
					return;
				}
				this.active=3;
			},

			//保存楼层数据
			async SaveLiftFloor() {
				var floorList = this.floorList;
				if(floorList.length==0){
					this.$message({message: this.$t("Controller.AddFloorDescription"),type: "error",offset: 10});
					return;
				}
				var controllerId = this.EquipmentForm.ControllersId;
				var { data: res } = await this.$http.post("controllerFloor/saveControllerFloor ", {floorList: floorList,controllerId: controllerId});
				if (res.resultCode == 1) {
					this.EquipmentVisible = false;
					this.$message({message: this.$t("LiftArea.CreatingSuccessful"),type: "success",offset: 10,});
				} else {
					this.$message({message: this.$t("LiftArea.CreateFailure"),type: "error",offset: 10});
				}
			},

			//获取楼层
			async getControllerFloor() {
				var controllerId = this.EquipmentForm.ControllersId;
				if(controllerId!=0){
					var { data: res } = await this.$http.post("controllerFloor/queryControllerFloorByControllerId", {controllerId: controllerId});
					if (res.resultCode == 1) {
						var least = 120;
						var max = -8;
						var floorList = JSON.parse(res.body);
						console.log(floorList)
						for (var i = 0; i < floorList.length; i++) {
							if (floorList[i].index < least){
								least = floorList[i].index;
							}
							if (floorList[i].index > max) {
								max = floorList[i].index;
							}
							floorList[i].status = floorList[i].status == 0 ? false : true;
							floorList[i].type = 0;
						}
					}
					this.least = floorList.length > 0 ? least.toString() : ""; //最小楼层
					this.max = floorList.length > 0 ? max.toString() : ""; //最大楼层
					this.floorList = floorList;
				}
				this.active = 2;
			},

			//显示修改楼层名称弹框
			showUpdateFloorName(index) {
				this.floorList[index].type = 1;
			},

			//修改楼层名称
			async UpdateLiftFloor(LiftFloor, index) {
			try {
				LiftFloor = {
					LiftFloorId: LiftFloor.liftFloorId,
					FloorName: LiftFloor.floorName,
					Status: LiftFloor.status ? 1 : 0,
				};
				var { data: res } = await this.$http.post("UpdateLiftFloor", LiftFloor);
				if (res.resultCode == 1) {
				//成功
				this.$message({
					message: this.$t("LiftArea.UpdateSucceed"),
					type: "success",
					offset: 10,
				});
				this.tableData[index].type = 0;
				} else {
				this.$message({
					message: this.$t("LiftArea.UpdateFailure"),
					type: "error",
					offset: 10,
				});
				}
				this.QueryFloorLoad = false;
			} catch (err) {
				this.QueryFloorLoad = false;
				console.log(err);
			}
			},
			
			//保存选中结果
			handleSelectionChange(val) {
				this.IdData = [];
				this.ControllerNameData = [];
				for (var i = 0; i < val.length; i++) {
					this.IdData[i] = val[i].controllersId;
					this.ControllerNameData[i] = val[i].controllerName;
				}
			},
			
			//删除设备
			DelControllers(ControllersId,ControllerName) {
				if(ControllersId=="" && this.IdData.length==0) return this.$message({offset:10,message:this.$t('Controller.Select'),type: 'warning'});
				this.$confirm(this.$t('Controller.WhetherDelete'),this.$t('Controller.Hint'), {type: 'warning'}).then(async () => {
					this.DeleteControllers(ControllersId,ControllerName);
				}).catch(() => {
					//取消
				});
			},
			
			//删除设备
			async DeleteControllers(ControllersId,ControllerName){
				var Controllers = [];
				var ControllerNames = [];
				if(ControllersId!='')
				{
					Controllers[0] = ControllersId;
					ControllerNames[0] = ControllerName;
				}
				else
				{
					Controllers = this.IdData;
					ControllerNames = this.ControllerNameData;
				}
				var {data: res} = await this.$http.post('DeleteControllers', {"Controllers":Controllers,"ControllerNames":ControllerNames});
				if(this.FilteringOperation(res))
				{
					if (res.resultCode == 1) //成功
					{
						this.$message({offset:10,message:this.$t('Controller.DeleteSucceed'),type: 'success'});
						if((this.EquipmentList.length-Controllers.length)==0 && this.QueryEquipment.Pages>1)
						{
							this.QueryEquipment.Pages = this.QueryEquipment.Pages-1;
						}
						this.IdData = [];
						this.Controllers();
					}
				}
			},
			
			//同步设备信息
			async SynchronousDeviceInformation(ControllersId){
				var {data: res} = await this.$http.post('SynchronousDeviceInformation', {"ControllersId":ControllersId});
				if(this.FilteringOperation(res))
				{
					if (res.resultCode == 1) //成功
					{
						this.$message({offset:10,message: this.$t('Controller.SuccessfulOperation'),type: 'success'});
						this.Controllers();
					}
					else
					{
						this.$message({message: this.$t('Controller.OperationFailed'),type: 'error',offset:10});
					}
				}
			},
			//重启设备
			async rebot(ControllersId){
				var {data: res} = await this.$http.post('RebotDevice', {"ControllersId":ControllersId});
				if(this.FilteringOperation(res))
				{
					if (res.resultCode == 1) //成功
					{
						this.$message({offset:10,message: this.$t('Controller.SuccessfulOperation'),type: 'success'});
						this.Controllers();
					}
					else
					{
						this.$message({message: this.$t('Controller.OperationFailed'),type: 'error',offset:10});
					}
				}
			},
			//清空设备白名单提示
			cleanDeviceEmployeeTip(ControllersId){
				this.$confirm(this.$t('Controller.cleanDeviceEmployeeTip'),this.$t('Controller.Hint'), {type: 'warning'}).then(async () => {
					this.cleanDeviceEmployee(ControllersId);
				}).catch(() => {
					//取消
				});
			},
			//清空白名单
			async cleanDeviceEmployee(ControllersId){
				var {data: res} = await this.$http.post('CleanDeviceEmployee', {"ControllersId":ControllersId});
				if(this.FilteringOperation(res))
				{
					if (res.resultCode == 1) //成功
					{
						this.$message({offset:10,message: this.$t('Controller.SuccessfulOperation'),type: 'success'});
						this.Controllers();
					}
					else
					{
						this.$message({message: this.$t('Controller.OperationFailed'),type: 'error',offset:10});
					}
				}
			},
			//清空设备刷卡记录提示
			cleanBrushCardRecordTip(ControllersId){
				this.$confirm(this.$t('Controller.cleanBrushCardRecordTip'),this.$t('Controller.Hint'), {type: 'warning'}).then(async () => {
					this.cleanBrushCardRecord(ControllersId);
				}).catch(() => {
					//取消
				});
			},
			//清空设备刷卡记录
			async cleanBrushCardRecord(ControllersId){
				var {data: res} = await this.$http.post('CleanBrushCardRecord', {"ControllersId":ControllersId});
				if(this.FilteringOperation(res))
				{
					if (res.resultCode == 1) //成功
					{
						this.$message({offset:10,message: this.$t('Controller.SuccessfulOperation'),type: 'success'});
						this.Controllers();
					}
					else
					{
						this.$message({message: this.$t('Controller.OperationFailed'),type: 'error',offset:10});
					}
				}
			},

			//查询模板
			async Template() {
				try 
				{
					var { data: res } = await this.$http.post("openClosedTemplates");
					if (res.resultCode == 1) {
					this.openCloseList = JSON.parse(res.body);
					}
				} 
				catch (err) 
				{
					console.log(err);
				}
			},
			
			//返回结果过滤
			FilteringOperation(res) {
				var State = true;
				if (res.stateCode == 0) {
					State = false;
					this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
				} else if (res.stateCode == 2) {
					State = false;
					this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
				} else if (res.stateCode == 3) {
					State = false;
					this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
				} 
				return State;
			}
		}
	};
</script>

<style scoped>

	.Controller{
		display: flex;
		height: 100%;
	}

	/* 左侧 */
	.content_left{
		background-color: #FFFFFF;
		width: 290px;
		height: 100%;
		margin-right: 20px;
	}

	.content_left_value {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.left_value_input {
		width:90%;
		margin:15px auto;
		display: flex;
		margin-bottom: 10px;
	}

	.left_value_tree{
		width:90%;
		flex-grow: 1;
		margin: 0px auto;
		margin-bottom: 15px;
		overflow: auto;
	}

	.left_tree{
		display: flex;
		align-items:center;
		justify-content: space-between;
		width: 100%;
		font-size: 15px;
	}

	.left_tree_icon{
		padding: 0px 5px;
	}

	/* 右侧 */
	.content_right{
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	/* 右侧-头部 */
	.content_right_top{
		width: 100%;
		display: flex;
		margin-bottom: 20px;
	}

	.right_top_card1{
		flex-grow: 1;
		padding: 15px 16px;
		background-color: #FFFFFF;
		justify-content: space-between;
	}

	.right_top_search>i{
		font-size: 25px;
		margin-left:10px;
		margin-right:10px;
		color: #909399;
	}

	.right_top_Date>>>i{
		font-size: 25px;
		color: #909399;
	}

	.right_top_search>>>.el-input__inner {
    	border: 0;
		font-size: 15px;
  	}

	.right_top_search_type{
		margin-right: 15px;
	}

	.right_top_search_type>i{
		margin-left: 20px;
		height: 20px;
		display: flex;
		border-left:1px solid #c7c7c7;
	}

	.right_top_search_type>>>.el-input__inner{
		border:0;
	}

	.right_top_card2{
		margin: 0px 20px;
		padding: 15px 16px;
		background-color: #FFFFFF;
	}

	.right_top_card3{
		padding: 15px 16px;
		background-color: #FFFFFF;
	}


	/* 右侧-底部 */
	.content_right_bottom{
		flex-grow: 1;
		height: 0px;
		width: 100%;
		background-color: #FFFFFF;
	}

	.content_right_bottom>div{
		display: flex;
		flex-direction: column;
		width: 96%;
		height: 95%;
	}

	.content_right_bottom_title{
		font-weight: 600;
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 10px;
	}

	.content_right_bottom_page{
		margin-top: 12px;
	}
	
	.table_button{
		margin: 7px 0px;
	}



	.el-table {
		margin-top: 10px;
	}

	/* 回到顶部 */
	.Tbacktop {
		height: 100%;
		width: 100%;
		background-color: #FFFFFF;
		border-radius: 50%;
		box-shadow: 0 0 6px rgba(0, 0, 0, .12);
		text-align: center;
		line-height: 40px;
		color: #1989fa;
	}

	.EquipmentList {
		margin-left: 15px;
		width: 84%;
		overflow: auto;
	}

	.Operation {
		width: 60%;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}

	.el-pagination {
		margin-top: 15px;
	}

	.from {
		width: 100%;
		display: flex;
	}

	.Area {
		width: 255px;
		padding-right: 15px;
		border-right: 1px solid #eeeeee;
	}

	.Area_input {
		display: flex;
		margin-bottom: 10px;
	}
	
	/* 新增修改设备弹框 */
	.controller_dialog_top{
		height:650px;
		padding-top: 20px;
		overflow:auto
	}
	/* 设备工作类别菜单 */
	.controller_type{
		margin-top:10px;
		display: grid;
		grid-gap: 35px;
		grid-template-columns: repeat(auto-fill, 180px);
  		justify-content: center;
	}
	.controller_type>div{
		width:180px;
		border-radius: 30px;
		padding: 15px 0px;
		/* margin:0px 20px; */
		display:flex;
		align-items:center;
		justify-content:center;
	}
	.check_controller_type1{
		background-color:#409EFF;
		color:#FFFFFF
	}
	.check_controller_type2{
		background-color:#eeeeee;
		color:#606266
	}
	.controller_type>div:hover{
		cursor: pointer;
	}
	/* 选择的设备 */
	.controller_value{
		padding:0px 20px;
	}
	.controller_item_title{
		margin: 20px 0px;
		text-align: center;
		font-size: 17px;
		font-weight: 700;
	}
	.controller_value>div{
		overflow:hidden;
		display: grid;
		grid-gap: 35px;
		grid-template-columns: repeat(auto-fill, 180px);
  		justify-content: center;
	}
	.controller_item{
		width: 180px;
		height: 210px;
		padding-top: 10px;
		cursor:pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius:10px;
		/* background-color:#f7f7f7 */
	}
	.controller_dialog_bottom{
		height:80px;
		display:flex;
		align-items:center;
		justify-content:space-between;
	}
	/* 选择设备 */
	.EquipmentOption{
		width: 98%;
		margin: 0px auto;
		margin-bottom: 10px;
	}
	.EquipmentType{
		margin: 20px;
	}
	.EquipmentType_img{
		width: 130px;
		cursor:pointer;
		height: 140px;
		display: flex;
		justify-content: center;
	}
	.EquipmentType_img>img{
		height: 100%;
	}
	.EquipmentType_radio{
		padding-top:10px;
		width: 100%;
		text-align: center;
	}
	/* 生成楼层信息 */
	.generated_floor{
		display:flex;
		margin-bottom:20px;
	}
	.generated_floor>div{
		display:flex;
		align-items:center;
	}
	.generated_floor>div:nth-child(1){
		margin:0px 20px;
	}
	.generated_floor>div:nth-child(2){
		flex-grow:1;
	}
	.generated_floor>div:nth-child(3){
		margin:0px 20px;
	}
	.generated_floor>div:nth-child(4){
		flex-grow:1;
	}
	.generated_floor>div:nth-child(5){
		margin-left: 20px;
	}
	.generated_floor_value{
		flex-grow:1;
	}
	.Table-column {
	height: 29.2px;
	display: flex;
	align-items: center;
	justify-content: center;
	}
	/* 表格属性 */
	.TableP{
		display: flex;
		align-items: center;
		margin-right: 10px;
	}

	.TableP div{
		margin-top: 10px;
		margin-bottom: 10px;
		width: 120px;
	}
	/* 全局弹性属性 */
	.center{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.center_desc{
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 28px;
	}
</style>
