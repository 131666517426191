import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import './plugins/element.js'
import * as echarts from 'echarts'
import locale from 'element-ui/lib/locale'
import ElementEN from 'element-ui/lib/locale/lang/en'
import ElementCN from 'element-ui/lib/locale/lang/zh-CN'
import ElementTW from 'element-ui/lib/locale/lang/zh-TW'
import './assets/css/Global.css'
import { message } from './config/ResetMessage';

Vue.config.productionTip = false

//多语言切换
Vue.use(VueI18n)
const i18n = new VueI18n({
   //从localStorage里获取用户中英文选择，没有则默认中文
  locale: localStorage.getItem('languages') || 'J',
  messages: {
    //简体中文
    'J': Object.assign(language_J,ElementCN),
    //繁体中文
    'F': Object.assign(language_F,ElementTW),
    //英文
    'E': Object.assign(language_E,ElementEN),
  }
})
locale.i18n((key, value) => i18n.t(key, value))

//axios请求的根路径
var ServerUrl = window.global_config.ServerUrl
Vue.prototype.$ServerUrl = ServerUrl
axios.defaults.baseURL = ServerUrl
axios.defaults.withCredentials = true
Vue.prototype.$http = axios

//图片地址
Vue.prototype.$image = window.global_config.ImageUrl

//echarts图表统计UI
Vue.prototype.$echarts = echarts

//消息提示
Vue.prototype.$message = message;

//设备服务地址
Vue.prototype.$EquipmentServerUrl = window.global_config.EquipmentServerUrl

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
