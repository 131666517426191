import Vue from 'vue'
import VueRouter from 'vue-router'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import Login from '../views/Login.vue'
import Index from '../components/Index.vue'
import Home from '../views/Home.vue'
import Employee from '../views/Employee.vue'
import BulkImport from '../views/BulkImport.vue'
import LiftArea from '../views/LiftArea.vue'
import LiftAreaAuthority from '../views/LiftAreaAuthority.vue'
import LiftFloorBlacklist from '../views/LiftFloorBlacklist.vue'
import Visitor from '../views/Visitor.vue'
import VisitirArea from '../views/VisitirArea.vue'
import VisitorConfig from '../views/VisitorConfig.vue'
import ControllerDate from '../views/ControllerDate.vue'
import ControllerTime from '../views/ControllerTime.vue'
import CardRecords from '../views/CardRecords.vue'
import Controller from '../views/Controller.vue'
import Permission from '../views/Permission.vue'
import Automatic from '../views/Automatic.vue'
import Monitoring from '../views/Monitoring.vue'
import User from '../views/User.vue'
import Company from '../views/Company.vue'
import OtherSettings from '../views/OtherSettings.vue'
import LogRecord from '../views/LogRecord.vue'
import OpenClosed from '../views/OpenClosed.vue'
import ManuallyBackupData from '../views/ManuallyBackupData.vue'
import EditAdminPwd from '../views/EditAdminPwd.vue'

Vue.use(VueRouter)
Vue.component(VueQrcode.name, VueQrcode);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Index',
    name: 'Index',
    component: Index,
    redirect: '/Home',
    children:[
      //首页
      {path: '/Home',name: 'Home',component: Home},
      //组织架构
      {path: '/Employee', name: 'Employee',component: Employee},
      //批量操作
      {path: '/BulkImport',name: 'BulkImport',component: BulkImport},
      //电梯组
      {path: '/LiftArea',name: 'LiftArea',component: LiftArea},
       //梯控权限
      {path: '/LiftAreaAuthority',name: 'LiftAreaAuthority',component: LiftAreaAuthority},
      //黑名单
      {path: '/LiftFloorBlacklist',name: 'LiftFloorBlacklist',component: LiftFloorBlacklist},
      //访客申请
      {path: '/Visitor',name: 'Visitor',component: Visitor},
      //访客区域
      {path: '/VisitirArea',name: 'VisitirArea',component: VisitirArea},
      //访客配置
      {path: '/VisitorConfig',name: 'VisitorConfig',component: VisitorConfig},
      //假期表
      {path: '/ControllerDate',name: 'ControllerDate',component: ControllerDate},
      //时间表
      {path: '/ControllerTime',name: 'ControllerTime',component: ControllerTime},
      //刷卡记录
      {path: '/CardRecords',name: 'CardRecords',component: CardRecords},
      //设备管理
      {path: '/Controller',name: 'Controller',component: Controller},
      //权限管理
      {path: '/Permission',name: 'Permission',component: Permission},
      //自动授权
      {path: '/Automatic',name: 'Automatic',component: Automatic},
      //数据监控
      {path: '/Monitoring',name: 'Monitoring',component: Monitoring},
      //登录用户
      {path: '/User',name: 'User',component: User},
      //日志记录
      {path: '/LogRecord',name: 'LogRecord',component: LogRecord},
      //公司信息
      {path: '/Company',name: 'Company',component: Company},
      //其他配置
      {path: '/OtherSettings',name: 'OtherSettings',component: OtherSettings},
      //常开常闭
      {path: '/OpenClosed',name: 'OpenClosed',component: OpenClosed},
      //手动备份数据库
      {path: '/ManuallyBackupData',name: 'ManuallyBackupData',component: ManuallyBackupData},
      //系统首次登录管理员需修改密码
      {path: '/EditAdminPwd',name:'EditAdminPwd',component:EditAdminPwd},
    ]
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{
	if(to.path === '/Login') return next()
	//获取保存的信息
	const tokenStr = window.sessionStorage.getItem('language')
	if(!tokenStr) return next(Login)
	next()
})

export default router
