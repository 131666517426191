<template>
  <div class="employee">
    <!-- 内容 -->
    <div class="content">
      <!-- 左侧 -->
      <div class="content_left boxshadow" v-loading="PageLoad.Leftload">
        <div class="Department">
          <!-- 功能栏 -->
          <div class="Department_input">
            <el-input
              :placeholder="$t('Employee.SearchDepartment')"
              size="medium"
              maxlength="32"
              v-model="filterText"
              style="width: 155px"
            ></el-input>
            <el-button
              :disabled="!userGroupDetails.RoleDepartment"
              icon="el-icon-circle-plus-outline"
              style="margin-left: 15px"
              size="medium"
              type="primary"
              @click="AddDepartmentDialog('')"
              >{{ $t("Employee.Add") }}</el-button
            >
          </div>

          <!-- 部门菜单 -->
          <el-tree
            class="Department_tree"
            :highlight-current="true"
            :data="DepartmentList"
            :filter-node-method="filterNode"
            ref="tree"
            node-key="departmentId"
            default-expand-all
            :expand-on-click-node="false"
            icon-class="el-icon-s-promotion"
          >
            <span
              class="custom-tree-node left_tree"
              slot-scope="{ data }"
              @click="
                choose(
                  data.departmentCode,
                  data.departmentName,
                  data.strDepartmentId
                )
              "
            >
              <div>{{ data.departmentName }}</div>
              <el-popover placement="right" trigger="hover" :open-delay="300">
                <el-button
                  type="primary"
                  :disabled="!userGroupDetails.RoleDepartment"
                  size="mini"
                  @click="AddDepartmentDialog(data.strDepartmentId)"
                  >{{ $t("Employee.Add") }}</el-button
                >
                <el-button
                  type="info"
                  :disabled="!userGroupDetails.RoleDepartment"
                  size="mini"
                  @click="
                    UpdateDepartmentDialog(
                      data.strDepartmentId,
                      data.departmentName
                    )
                  "
                  >{{ $t("Employee.Update") }}</el-button
                >
                <el-button
                  type="danger"
                  :disabled="!userGroupDetails.RoleDepartment"
                  :loading="ButtonLoad.deleteDepartment"
                  size="mini"
                  @click="
                    DeleteDepartment(data.strDepartmentId, data.departmentName)
                  "
                  >{{ $t("Employee.Delete") }}</el-button
                >
                <i class="el-icon-more left_tree_icon" slot="reference"></i>
              </el-popover>
            </span>
          </el-tree>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="content_right" v-loading="PageLoad.Rightload">
        <!-- 头部 -->
        <div class="content_right_top flex-items-center">
          <!--条件 -->
          <div class="right_top_card1 flex-items-center boxshadow">
            <!-- 搜索框 -->
            <div
              v-if="paging.Type != '4'"
              class="right_top_search flex-items-center"
            >
              <i class="el-icon-search"></i>
              <el-input
                maxlength="32"
                v-model="paging.Value"
                :placeholder="$t('Employee.SearchTips')"
              ></el-input>
            </div>
            <div
              v-if="paging.Type == '4'"
              class="right_top_search right_top_Date"
            >
              <el-date-picker
                size="medium"
                v-model="paging.Value"
                type="datetimerange"
                align="center"
                value-format="yyyy-MM-dd HH:mm:ss"
                :start-placeholder="$t('Employee.StartDate')"
                :end-placeholder="$t('Employee.EndDate')"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </div>

            <!-- 条件类型 -->
            <div class="right_top_search_type flex-items-center">
              <el-button
                size="mini"
                class="el-icon-search"
                @click="Search"
              ></el-button>
              <i></i>
              <el-select
                v-model="paging.Type"
                @change="paging.Value = ''"
                size="medium"
                slot="prepend"
                :placeholder="$t('Employee.Conditions')"
              >
                <el-option
                  :label="$t('Employee.SearchCriteria1')"
                  value="1"
                ></el-option>
                <el-option
                  :label="$t('Employee.SearchCriteria2')"
                  value="2"
                ></el-option>
                <el-option
                  :label="$t('Employee.SearchCriteria3')"
                  value="3"
                ></el-option>
                <el-option
                  :label="$t('Employee.SearchCriteria4')"
                  value="4"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!--操作1 -->
          <div class="right_top_card2 flex-center boxshadow">
            <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="addEmployeesDialog"
              :disabled="!userGroupDetails.RoleEmployees"
              size="medium"
              >{{ $t("Employee.AddEmployee") }}</el-button
            >
            <el-button
              size="medium"
              type="danger"
              style="margin-left: 20px"
              slot="reference"
              icon="el-icon-delete"
              :disabled="!userGroupDetails.RoleEmployees"
              @click="DelBatEmployees"
              >{{ $t("Employee.BatchesDelete") }}</el-button
            >
          </div>
          <!--操作2 -->
          <div class="right_top_card3 flex-center boxshadow">
            <el-button
              size="medium"
              class="el-icon-refresh-right"
              @click="Employees()"
              style="margin-right: 15px"
            >
              {{ $t("Employee.Refresh") }}</el-button
            >
            <el-popover trigger="click" placement="bottom">
              <el-button
                size="small"
                @click="EmployeesExecl(1)"
                type="primary"
                >{{ $t("Employee.Export2") }}</el-button
              >
              <el-button
                size="small"
                @click="EmployeesExecl(2)"
                type="success"
                >{{ $t("Employee.Export3") }}</el-button
              >
              <el-button size="small" @click="EmployeesExecl(3)" type="info">{{
                $t("Employee.Export4")
              }}</el-button>
              <el-button
                size="medium"
                class="el-icon-receiving"
                slot="reference"
              >
                {{ $t("Employee.Export1") }}</el-button
              >
            </el-popover>
          </div>
        </div>
        <!-- 底部 -->
        <div class="content_right_bottom flex-center boxshadow">
          <div>
            <div class="content_right_bottom_title">
              {{ $t("Employee.TableTitle") }}
            </div>
            <el-table
              :data="EmployeesList"
              @selection-change="handleSelectionChange"
              height="100%"
            >
              <el-table-column
                type="selection"
                align="center"
                prop="employeeId"
              ></el-table-column>
              <el-table-column
                :label="$t('Employee.Number')"
                prop="number"
                align="center"
              ></el-table-column>
              <el-table-column
                :label="$t('Employee.Department')"
                prop="departmentName"
                align="center"
              ></el-table-column>
              <el-table-column
                :label="$t('Employee.NameInfor')"
                prop="name"
                align="center"
              >
                <template slot-scope="scope">
                  <el-popover
                    trigger="click"
                    placement="left"
                    :open-delay="500"
                  >
                    <p>{{ $t("Employee.Name") }}: {{ scope.row.name }}</p>
                    <p>
                      <span>
                        <div v-if="scope.row.photo == ''">
                          {{ $t("Employee.NoPhoto") }}
                        </div>
                        <div v-else class="Employeesimg">
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 100%;
                              max-height: 100%;
                            "
                            :src="Employeesimg(scope.row.photo)"
                          />
                        </div>
                      </span>
                    </p>
                    <p>
                      {{ $t("Employee.Sex") }}:
                      <el-tag
                        :type="scope.row.sex == 1 ? 'primary' : 'danger'"
                        disable-transitions
                        >{{
                          scope.row.sex == 1
                            ? $t("Employee.Sex1")
                            : $t("Employee.Sex2")
                        }}</el-tag
                      >
                    </p>
                    <p>{{ $t("Employee.Phone") }}: {{ scope.row.telephone }}</p>
                    <p>
                      {{ $t("Employee.IdentityCard") }}:
                      <span v-if="scope.row.identityCard != ''">{{
                        scope.row.identityCard
                      }}</span>
                      <span v-else>{{ $t("Employee.NotFilled") }}</span>
                    </p>
                    <p>
                      {{ $t("Employee.Location") }}:
                      <span v-if="scope.row.address != ''">{{
                        scope.row.address
                      }}</span>
                      <span v-else>{{ $t("Employee.NotFilled") }}</span>
                    </p>
                    <p>
                      {{ $t("Employee.PeriodOfValidity") }}:
                      <span v-if="scope.row.startTime != undefined"
                        >{{ scope.row.startTime }} -
                        {{ scope.row.endTime }}</span
                      >
                      <span v-else>{{ $t("Employee.NotSet") }}</span>
                    </p>
                    <div slot="reference" class="name-wrapper">
                      <el-button
                        type="primary"
                        plain
                        size="small"
                        v-if="scope.row.sex == 1"
                        >{{ scope.row.name }}</el-button
                      >
                      <el-button
                        type="danger"
                        plain
                        size="small"
                        v-if="scope.row.sex == 2"
                        >{{ scope.row.name }}</el-button
                      >
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Employee.Card1')"
                prop="card"
                align="center"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.q != '' && scope.row.q != null">
                    {{ scope.row.q }}
                  </div>
                  <i v-else class="el-icon-remove-outline"></i>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Employee.Card2')"
                prop="card"
                align="center"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.c == "" || scope.row.c == null
                      ? $t("Employee.No")
                      : scope.row.c
                  }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Employee.Card3')"
                prop="card"
                align="center"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.k == "" || scope.row.k == null
                      ? $t("Employee.No")
                      : scope.row.k
                  }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Employee.AddDate')"
                prop="joinDate"
                width="160"
                align="center"
              ></el-table-column>
              <el-table-column
                :label="$t('Employee.Operation')"
                width="180"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('Employee.Update')"
                    placement="top"
                    :enterable="false"
                  >
                    <el-button
                      type="primary"
                      :disabled="!userGroupDetails.RoleEmployees"
                      size="mini"
                      icon="el-icon-edit"
                      @click="UpdEmployeesDialog(scope.row)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('Employee.Delete')"
                    placement="top"
                    :enterable="false"
                  >
                    <el-button
                      type="danger"
                      :disabled="!userGroupDetails.RoleEmployees"
                      size="mini"
                      icon="el-icon-delete"
                      @click="
                        DelEmployees(scope.row.employeeId, scope.row.number)
                      "
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('Employee.ViewingController')"
                    placement="top"
                    :enterable="false"
                  >
                    <el-button
                      type="warning"
                      size="mini"
                      icon="el-icon-mobile-phone"
                      @click="SelEmployeesJurisdiction(scope.row.employeeId)"
                    ></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div class="content_right_bottom_page flex-items-center">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[20, 50, 100]"
                :page-size="paging.Count"
                :current-page="Page"
                layout="total, sizes, prev, pager, next, jumper"
                :total="EmployeesListSize"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增修改部门弹框 -->
    <el-dialog
      :title="DepartmentDialog.title"
      :visible.sync="DepartmentDialog.dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form
        ref="DepartmentformRef"
        label-width="80px"
        :model="DepartmentForm"
        :rules="DepartmentFormRules"
      >
        <el-form-item
          :label="$t('Employee.SuperiorDepartment')"
          v-if="DepartmentDialog.listState"
        >
          <select-tree
            v-model="DepartmentForm.DepartmentId"
            :placeholder="$t('SelectTree.Select')"
            :options="DepartmentList"
            :props="DepartmentProps"
          />
        </el-form-item>
        <el-form-item
          :label="$t('Employee.DepartmentName')"
          prop="DepartmentName"
        >
          <el-input
            maxlength="32"
            v-model="DepartmentForm.DepartmentName"
            :placeholder="$t('Employee.InputDepartmentName')"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DepartmentDialog.dialogVisible = false">{{
          $t("Employee.Cancel")
        }}</el-button>
        <el-button
          type="primary"
          v-if="DepartmentDialog.operationType == 1"
          :loading="ButtonLoad.AddUpdateDepartment"
          @click="AddDepartment"
          >{{ DepartmentDialog.buttonName }}</el-button
        >
        <el-button
          type="primary"
          v-else
          :loading="ButtonLoad.AddUpdateDepartment"
          @click="UpdateDepartmentName"
          >{{ DepartmentDialog.buttonName }}</el-button
        >
      </span>
    </el-dialog>

    <!-- 新增修改人员弹框 -->
    <el-dialog
      :title="EmployeesDialog.title"
      :visible.sync="EmployeesDialog.dialogVisible"
      width="55%"
      top="70px"
      :close-on-click-modal="false"
    >
      <div class="Employees_form">
        <div class="Employees_form_left">
          <div>
            <el-upload
              class="avatar-uploader"
              action=""
              :http-request="upload"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
              name="picture"
            >
              <img v-if="imageUrl != ''" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-button
              v-if="imageUrl != ''"
              icon="el-icon-delete"
              plain
              size="mini"
              style="width: 180px"
              @click="DelEmployeesImage"
              >{{ $t("Employee.DeletePhoto") }}</el-button
            >
          </div>
          <div class="Employees_form_left_title">
            <span>{{ $t("Employee.Attention") }}：</span>
            <p>
              1.{{ $t("Employee.Matter1") }}<span style="color: red">*</span
              >{{ $t("Employee.Matter2") }}
            </p>
            <p>2.{{ $t("Employee.Matter3") }}</p>
            <p>3.{{ $t("Employee.Matter4") }}</p>
            <p>4.{{ $t("Employee.Matter5") }}</p>
          </div>
        </div>
        <div class="Employees_form_right">
          <el-form
            ref="EmployeesformRef"
            label-width="100px"
            :model="EmployeesForm"
            :rules="EmployeesFormRules"
            class="demo-ruleForm"
            label-position="left"
          >
            <el-form-item
              :label="$t('Employee.Department')"
              style="margin-bottom: 5px"
            >
              <select-tree
                v-model="EmployeesForm.DepartmentId"
                :options="DepartmentList"
                :props="DepartmentProps"
              />
            </el-form-item>
            <el-form-item :label="$t('Employee.Number')" prop="Number">
              <el-input
                maxlength="32"
                v-model="EmployeesForm.Number"
                :placeholder="$t('Employee.PleaseInput')"
              >
                <el-button
                  slot="append"
                  icon="el-icon-refresh-right"
                  @click="Generate"
                  >{{ $t("Employee.Generate") }}</el-button
                >
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('Employee.Name')" prop="Name">
              <el-input
                maxlength="32"
                v-model="EmployeesForm.Name"
                :placeholder="$t('Employee.PleaseInput')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('Employee.Sex')" prop="Sex">
              <el-radio v-model="EmployeesForm.Sex" label="1">{{
                $t("Employee.Sex1")
              }}</el-radio>
              <el-radio v-model="EmployeesForm.Sex" label="2">{{
                $t("Employee.Sex2")
              }}</el-radio>
            </el-form-item>
            <el-form-item :label="$t('Employee.Card1')" prop="Q">
              <el-input
                maxlength="32"
                v-model="EmployeesForm.Q"
                :placeholder="$t('Employee.PleaseInput')"
              >
                <template slot="append"
                  >{{ $t("Employee.Card") }}({{
                    $t("Employee.Phone")
                  }})</template
                >
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('Employee.Card2')" prop="C">
              <el-input
                maxlength="32"
                v-model="EmployeesForm.C"
                :placeholder="$t('Employee.PleaseInput')"
              >
                <template slot="append">{{ $t("Employee.Card") }}</template>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('Employee.Card3')" prop="K">
              <el-input
                maxlength="32"
                v-model="EmployeesForm.K"
                :placeholder="$t('Employee.PleaseInput')"
              >
                <template slot="append">{{ $t("Employee.Card") }}</template>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('Employee.IdentityCard')">
              <el-input
                maxlength="32"
                v-model="EmployeesForm.IdentityCard"
                :placeholder="$t('Employee.PleaseInput')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('Employee.Location')">
              <el-input
                maxlength="128"
                v-model="EmployeesForm.Address"
                :placeholder="$t('Employee.PleaseInput')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('Employee.PeriodOfValidity')">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="EmployeesForm.DateTime"
                style="width: 100%"
                type="datetimerange"
                :range-separator="$t('Employee.To')"
                :start-placeholder="$t('Employee.StartDate')"
                :end-placeholder="$t('Employee.EndDate')"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('Employee.IsShareCode')">
              <el-switch v-model="EmployeesForm.IsShareCode"></el-switch>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="EmployeesDialog.dialogVisible = false">{{
          $t("Employee.Cancel")
        }}</el-button>
        <el-button
          type="primary"
          v-if="EmployeesForm.EmployeeId == 0"
          :loading="ButtonLoad.AddUpdateDepartment"
          @click="AddEmployees"
          >{{ $t("Employee.Submit") }}</el-button
        >
        <el-button
          type="primary"
          v-if="EmployeesForm.EmployeeId != 0"
          :loading="ButtonLoad.AddUpdateDepartment"
          @click="UpdEmployees"
          >{{ $t("Employee.Submit") }}</el-button
        >
      </span>
    </el-dialog>

    <!-- 查看已授权设备 -->
    <el-drawer
      :visible.sync="EmployeesControllersDialog.dialogVisible"
      :with-header="false"
      :modal="false"
      direction="rtl"
      size="50%"
    >
      <el-table
        v-loading="EmployeesControllersDialog.load"
        :data="ControllersList"
        height="92%"
      >
        <el-table-column :label="$t('Employee.AuthorizeController')">
          <el-table-column
            property="controllersId"
            :label="$t('Employee.ControllerId')"
            align="center"
          ></el-table-column>
          <el-table-column
            property="RegionName"
            :label="$t('Employee.ControllerType')"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  ControllerSubType(scope.row.controllerType) +
                  ' - ' +
                  scope.row.controllerSubType
                "
                placement="left"
              >
                <div
                  style="
                    height: 115px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <img
                    style="width: 60px"
                    :src="Controllersimg(scope.row.controllerSubType)"
                    fit="cover"
                  />
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            property="controllerName"
            :label="$t('Employee.ControllerName')"
            align="center"
          ></el-table-column>
          <el-table-column
            property="regionName"
            :label="$t('Employee.Area')"
            align="center"
          ></el-table-column>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('Employee.Refresh')"
              placement="left"
            >
              <el-button
                @click="EmployeesJurisdiction()"
                class="el-icon-refresh-left"
                size="mini"
              ></el-button>
            </el-tooltip>
          </template>
          <el-table-column
            property="location"
            :label="$t('Employee.ControllerLocation')"
            align="center"
          ></el-table-column>
        </el-table-column>
      </el-table>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 70px;
        "
      >
        <el-button
          type="primary"
          @click="Previous"
          :disabled="JurisdictionPages == 1 ? true : false"
          >{{ $t("Employee.PreviousPage") }}</el-button
        >
        <el-button
          type="primary"
          style="margin-left: 30px"
          @click="Down"
          :disabled="ControllersList.length < 20 ? true : false"
          >{{ $t("Employee.NextPage") }}</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import SelectTree from "./../components/SelectTree.vue";
import "driver.js/dist/driver.min.css";
import Driver from "driver.js";

export default {
  name: "employess",
  components: {
    SelectTree,
  },
  data() {
    //校验部门名称
    var DepartmentName_verify = (rule, value, callback) => {
      if (!value)
        return callback(new Error(this.$t("Employee.InputDepartmentName")));
      callback();
    };

    //校验工号
    var checkNumber = async (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("Employee.NumberNotEmpty")));
      }

      var { data: res } = await this.$http.post("Addverify", {
        Str: this.EmployeesForm.Number,
        Type: "Number",
        EmployeeId: this.EmployeesForm.EmployeeId,
      });

      if (res.State == 2) {
        return callback(new Error(this.$t("Employee.NumberExist")));
      }
    };

    //校验姓名
    var Name_verify = (rule, value, callback) => {
      if (!value)
        return callback(new Error(this.$t("Employee.InputEmployeeName")));
      callback();
    };

    //校验二维码
    var checkQ = async (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("Employee.QrCodeNotEmpty")));
      }

      //校验格式
      var myreg = /^[0-9]*$/;
      if (!myreg.test(value)) {
        return callback(new Error(this.$t("Employee.IncorrectFormat")));
      }

      //清空0开头
      this.EmployeesForm.Q = value.replace(/\b(0+)/gi, "");

      var { data: res } = await this.$http.post("Addverify", {
        Str: this.EmployeesForm.Q,
        Type: "Q",
        EmployeeId: this.EmployeesForm.EmployeeId,
      });

      if (res.State == 2) {
        return callback(new Error(this.$t("Employee.QrCodeExist")));
      }
    };

    //校验IC卡
    var checkC = async (rule, value, callback) => {
      if (value) {
        //校验格式
        var myreg = /^[0-9]*$/;
        if (!myreg.test(value)) {
          return callback(new Error(this.$t("Employee.IncorrectFormat")));
        }

        //清空0开头
        this.EmployeesForm.C = value.replace(/\b(0+)/gi, "");

        var { data: res } = await this.$http.post("Addverify", {
          Str: this.EmployeesForm.C,
          Type: "C",
          EmployeeId: this.EmployeesForm.EmployeeId,
        });
        if (res.State == 2) {
          return callback(new Error(this.$t("Employee.ICCardExist")));
        }
      }
    };

    //校验密码
    var checkK = async (rule, value, callback) => {
      if (value) {
        //校验格式
        var myreg = /^[0-9]*$/;
        if (!myreg.test(value)) {
          return callback(new Error(this.$t("Employee.IncorrectFormat")));
        }

        var { data: res } = await this.$http.post("Addverify", {
          Str: this.EmployeesForm.K,
          Type: "K",
          EmployeeId: this.EmployeesForm.EmployeeId,
        });
        if (res.State == 2) {
          return callback(new Error(this.$t("Employee.PasswordExist")));
        }
      }
    };

    return {
      //权限组
      userGroupDetails: {
        RoleDepartment: false, //部门操作权
        RoleEmployees: false, //人员操作权
      },
      // 页面加载
      PageLoad: {
        Leftload: false, //左侧加载状态
        Rightload: false, //右侧加载状态
      },
      // 按钮加载状态
      ButtonLoad: {
        //新增修改部门
        AddUpdateDepartment: false,
        //删除部门
        deleteDepartment: false,
        //新增修改人员
        AddUpdateEmployee: false,
        //批量删除人员
        DeleteEmployee: false,
      },
      //部门信息
      suspensionStyle: {}, //部门列表样式
      suspensionX: 0,
      suspensionY: 0,
      suspensionW: 0,
      filterText: "", //部门搜索
      DepartmentList: [], //部门列表
      defaultProps: {
        //部门列表参数
        children: "children",
        label: "departmentName",
      },
      //部门操作弹框
      DepartmentDialog: {
        click: "AddDepartment",
        listState: true, //是否显示下拉菜单
        title: this.$t("Employee.AddDepartment"), //标题
        dialogVisible: false, //显示状态
        buttonName: this.$t("Employee.Submit"), //提交按钮名称
        operationType: 1, //1-新增，2-修改
      },

      // 数据默认字段
      DepartmentProps: {
        parent: "parentDepartmentId", // 父级唯一标识
        value: "strDepartmentId", // 唯一标识
        label: "departmentName", // 标签显示
        children: "children", // 子级
      },

      //部门表单数据绑定对象
      DepartmentForm: {
        DepartmentId: "",
        DepartmentName: "",
        Type: 1,
      },
      //表单验证规则
      DepartmentFormRules: {
        DepartmentName: [
          {
            required: true,
            validator: DepartmentName_verify,
            trigger: "change",
          },
        ],
      },

      //人员表格信息
      IdData: [], //人员Id
      NumberData: [], //工号（用于日志记录）
      TableName: "", //表格标题
      imageUrl: "", //人员照片
      value: "", //按条件查询下拉选择
      Page: 1, //页数
      //人员操作弹框
      EmployeesDialog: {
        title: this.$t("Employee.AddEmployee"), //标题
        dialogVisible: false, //显示状态
        buttonState: false, //按钮状态
      },
      EmployeesList: [],
      EmployeesListSize: 0,
      //部门表单数据绑定对象
      EmployeesForm: {
        EmployeeId: 0, //人员Id
        DepartmentId: "", //部门id
        Number: "", //工号
        Name: "", //姓名
        Sex: "1", //性别
        Q: "", //Q
        C: "", //C
        K: "", //K
        Telephone: "", //电话
        IdentityCard: "", //身份证号
        Photo: "", //照片
        Address: "", //住址
        DateTime: [], //日期
        IsShareCode: true //默认开启分享码
      },
      //表单验证规则
      EmployeesFormRules: {
        Number: [
          {
            required: true,
            validator: checkNumber,
            trigger: "blur",
          },
        ],
        Name: [
          {
            required: true,
            validator: Name_verify,
            trigger: "change",
          },
        ],
        Sex: [
          {
            required: true,
            trigger: "change",
          },
        ],
        Q: [
          {
            required: true,
            validator: checkQ,
            trigger: "blur",
          },
        ],
        C: [
          {
            validator: checkC,
            trigger: "blur",
          },
        ],
        K: [
          {
            validator: checkK,
            trigger: "blur",
          },
        ],
      },
      paging: {
        DepartmentCode: "",
        EmployeeId: 0,
        Count: 20,
        Pages: 1,
        Type: "1",
        Value: "",
      },
      EmployeesControllersDialog: {
        dialogVisible: false, //显示状态
        load: true,
      },
      ControllersList: [], //已授权设备
      JurisdictionPages: 1, //人员设备权限分页
      JurisdictionEmployeeId: 0, //人员设备权限人员ID
    };
  },
  watch: {
    //部门查询过滤使用
    filterText(val) {
      //每当输入的数据有变化就触发原生的过滤节点这个函数
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    let cookieEmployeeGuide = this.getCookie();
    if (cookieEmployeeGuide === undefined) {
      setTimeout(() => {
        this.employee_guide();
      }, 1000);
    }
  },
  //加载事件
  created() {
    //获取操作权
    this.Role();

    //查询部门
    this.Department();

    //查询人员
    this.Employees();
  },
  methods: {
    //人员引导
    employee_guide() {
      let driver = new Driver({
        className: "scoped-class", // 包裹driver.js弹窗的类名 className to wrap driver.js popover
        animate: true, // 高亮元素改变时是否显示动画 Animate while changing highlighted element
        opacity: 0.75, // 背景透明度(0 表示只有弹窗并且没有遮罩) Background opacity (0 means only popovers and without overlay)
        padding: 10, //  元素与边缘的距离 Distance of element from around the edges
        allowClose: false, // 是否允许点击遮罩时关闭 Whether clicking on overlay should close or not
        overlayClickNext: false, // 是否允许点击遮罩时移到到下一步 Should it move to next step on overlay click
        doneBtnText: "完成", // 最终按钮上的文本 Text on the final button
        closeBtnText: "关闭", // 当前步骤关闭按钮上的文本 Text on the close button for this step
        nextBtnText: "下一步", // 当前步骤下一步按钮上的文本 Next button text for this step
        prevBtnText: "上一步", // 当前步骤上一步按钮上的文本 Previous button text for this step
        showButtons: true, // 是否在底部显示控制按钮 Do not show control buttons in footer
        keyboardControl: true, // 是否允许通告键盘控制(escape关闭，箭头键用于移动)Allow controlling through keyboard (escape to close, arrow keys to move)
        scrollIntoViewOptions: {}, //  `scrollIntoView()` 方法的选项 We use `scrollIntoView()` when possible, pass here the options for it if you want any
      });
      // Define the steps for introduction
      let steps = [
        {
          element: ".Department_input",
          popover: {
            title: this.$t("Employee.EmployeeGuideTitle1"),
            description: this.$t("Employee.EmployeeGuideContent1"),
            position: "right",
          },
        },
        {
          element: ".Department_tree",
          popover: {
            title: this.$t("Employee.EmployeeGuideTitle2"),
            description: this.$t("Employee.EmployeeGuideContent2"),
            position: "right",
          },
        },
        {
          element: ".content_right_top",
          popover: {
            title: this.$t("Employee.EmployeeGuideTitle3"),
            description: this.$t("Employee.EmployeeGuideContent3"),
            position: "bottom",
          },
        },
        {
          element: ".content_right_bottom",
          popover: {
            title: this.$t("Employee.EmployeeGuideTitle4"),
            description: this.$t("Employee.EmployeeGuideContent4"),
            position: "left",
          },
        },
      ];
      driver.defineSteps(steps);
      // Start the introduction
      driver.start();
      // 保存
      this.setCookie();
    },

    setCookie() {
      //设置10年后过期
      let expires = new Date();
      expires.setTime(expires.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);
      document.cookie =
        "employeeGuide=" + escape("1") + ";expires=" + expires.toGMTString();
    },
    getCookie() {
      //获取cookie值
      let str = document.cookie;
      //将值切割成数组
      let arr = str.split(";");
      let employeeGuide;
      //遍历数组
      for (var i = 0; i < arr.length; i++) {
        var value = arr[i].split("=");
        //避免输入中含有空格，消除文本框空格
        value[0] = value[0].replace(/\s+/g, "");
        if (value[0] == "employeeGuide") {
          employeeGuide = value[1];
        }
      }
      console.log("employeeGuide:" + employeeGuide);
      return employeeGuide;
    },

    //获取操作权
    Role() {
      //获操作取权限组
      var userGroupDetails = JSON.parse(
        window.sessionStorage.getItem("userGroupDetails")
      );
      for (var i = 0; i < userGroupDetails.length; i++) {
        if (userGroupDetails[i].roleName == "Departments") {
          //部门操作权
          this.userGroupDetails.RoleDepartment =
            userGroupDetails[i].roleOperateValue == "1" ? true : false;
        }
        if (userGroupDetails[i].roleName == "Employees") {
          //人员操作权
          this.userGroupDetails.RoleEmployees =
            userGroupDetails[i].roleOperateValue == "1" ? true : false;
        }
      }
    },

    //设备类型
    ControllerSubType(Type) {
      if (Type == 1) {
        return this.$t("Employee.QrCodeController");
      } else if (Type == 2) {
        return this.$t("Employee.FaceController");
      }
    },

    //人员照片
    Employeesimg(photo) {
      try {
        var timestamp = new Date().valueOf();
        return this.$image + "/Employees/" + photo + "?DataTime=" + timestamp;
      } catch (e) {
        return "";
      }
    },

    //设备照片
    Controllersimg(photo) {
      try {
        return require("@/assets/images/Equipment/" + photo + ".png");
      } catch (e) {
        return "";
      }
    },

    //查询部门
    async Department() {
      try {
        this.PageLoad.Leftload = true;
        var { data: res } = await this.$http.post("Departments");
        if (res.resultCode == 1) {
          //成功
          this.DepartmentList = JSON.parse(res.body);
        }
        this.PageLoad.Leftload = false;
      } catch (err) {
        this.PageLoad.Leftload = false;
        console.log(err);
      }
    },

    //部门过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.departmentName.indexOf(value) !== -1;
    },

    //部门操作弹框 （新增操作操作）
    AddDepartmentDialog(Id) {
      this.DepartmentDialog.buttonName = this.$t("Employee.Submit");
      this.DepartmentDialog.listState = Id == "" ? true : false; //显示下拉菜单
      this.DepartmentDialog.dialogVisible = true; //显示部门操作弹框
      this.DepartmentDialog.title = this.$t("Employee.AddDepartment"); //标题
      this.DepartmentDialog.operationType = 1; //显示新增按钮
      this.DepartmentForm.DepartmentId = Id; //部门Id
      this.DepartmentForm.DepartmentName = ""; //部门名称
    },

    //新增部门
    AddDepartment() {
      this.$refs.DepartmentformRef.validate(async (valid) => {
        //验证
        if (!valid) return;
        this.ButtonLoad.AddUpdateDepartment = true;

        if (this.DepartmentForm.DepartmentId == "") {
          this.DepartmentForm.DepartmentId = "0";
        }

        this.DepartmentForm.Type = 1; //操作为新增
        var { data: res } = await this.$http.post(
          "AddUpdDepartment",
          this.DepartmentForm
        );
        this.DepartmentDialog.buttonName = this.$t("Employee.Submit");

        if (this.FilteringOperation(res)) {
          //请求结果过滤
          if (res.resultCode == 1) {
            //成功
            this.$message({
              message: this.$t("Employee.AddSucceed"),
              type: "success",
              offset: 10,
            });
            this.DepartmentDialog.dialogVisible = false; //隐藏部门操作弹框
            this.DepartmentForm.DepartmentName = "";
            this.Department();
          } else if (res.resultCode == 2) {
            //出现错误
            this.$message({
              message: this.$t("Employee.AddFailure"),
              type: "error",
              offset: 10,
            });
          } else if (res.resultCode == 4) {
            //请求参数不正确
            this.$message({
              message: this.$t("Employee.IncorrectParameter"),
              type: "warning",
              offset: 10,
            });
          } else if (res.resultCode == 5) {
            //部门名称已存在
            this.$message({
              message: this.$t("Employee.DepartmentExist"),
              type: "warning",
              offset: 10,
            });
          }
        }
        this.ButtonLoad.AddUpdateDepartment = false;
      });
    },

    //修改部门操作弹框 （修改操作）
    UpdateDepartmentDialog(Id, Name) {
      this.DepartmentDialog.listState = false; //不显示下拉菜单
      this.DepartmentDialog.dialogVisible = true; //显示部门操作弹框
      this.DepartmentDialog.title = this.$t("Employee.UpdateDepartment"); //标题
      this.DepartmentDialog.operationType = 2; //显示修改按钮
      this.DepartmentForm.DepartmentId = Id; //部门Id
      this.DepartmentForm.DepartmentName = Name;
    },

    //修改部门名称
    UpdateDepartmentName() {
      this.$refs.DepartmentformRef.validate(async (valid) => {
        //验证
        if (!valid) return;
        this.ButtonLoad.AddUpdateDepartment = true;

        this.DepartmentDialog.buttonName = this.$t("Employee.InSubmit"); //按钮状态为加载中

        if (this.DepartmentForm.DepartmentId == "") {
          this.DepartmentForm.DepartmentId = "0";
        }

        this.DepartmentForm.Type = 2; //操作为新增

        //修改部门名称
        var { data: res } = await this.$http.post(
          "AddUpdDepartment",
          this.DepartmentForm
        );
        this.DepartmentDialog.buttonName = this.$t("Employee.Submit"); //按钮状态为加载中
        if (this.FilteringOperation(res)) {
          //请求结果过滤
          if (res.resultCode == 1) {
            //成功
            this.DepartmentDialog.dialogVisible = false; //显示部门操作弹框
            this.$message({
              message: this.$t("Employee.UpdateSucceed"),
              type: "success",
              offset: 10,
            });
            this.TableName = this.DepartmentForm.DepartmentName;
            this.DepartmentForm.DepartmentName = "";
            this.Department();
          } else if (res.resultCode == 2) {
            //出现错误
            this.$message({
              message: this.$t("Employee.UpdateFailure"),
              type: "error",
              offset: 10,
            });
          } else if (res.resultCode == 4) {
            //请求参数不正确
            this.$message({
              message: this.$t("Employee.IncorrectParameter"),
              type: "warning",
              offset: 10,
            });
          } else if (res.resultCode == 5) {
            //部门名称已存在
            this.$message({
              message: this.$t("Employee.DepartmentExist"),
              type: "warning",
              offset: 10,
            });
          }
        }
        this.ButtonLoad.AddUpdateDepartment = false;
      });
    },

    //删除部门(多传一个部门名称是为了日志记录)
    async DeleteDepartment(Id, departmentName) {
      if (Id != "") {
        //删除部门
        this.ButtonLoad.deleteDepartment = true;
        var { data: res } = await this.$http.post("DeleteDepartment", {
          DepartmentId: "" + Id + "",
          DepartmentName: departmentName,
        });
        if (this.FilteringOperation(res)) {
          //请求结果过滤
          if (res.resultCode == 1) {
            //成功
            this.$message({
              message: this.$t("Employee.DeleteSucceed"),
              type: "success",
              offset: 10,
            });
            this.Department();
            if (this.EmployeesForm.DepartmentId == Id) {
              this.TableName = "";
              this.paging.Pages = 1;
              this.paging.DepartmentCode = "";
              this.paging.Type = "1";
              this.Employees();
            }
          } else if (res.resultCode == 2) {
            //出现错误
            this.$message({
              message: this.$t("Employee.DeleteFailure"),
              type: "error",
              offset: 10,
            });
          } else if (res.resultCode == 4) {
            //部门下存在人员
            this.$message({
              message: this.$t("Employee.DepartmentExistEmployee"),
              type: "error",
              offset: 10,
            });
          } else {
            this.$message({
              message: this.$t("Employee.DeleteFailure"),
              type: "error",
              offset: 10,
            });
            this.Department();
          }
        }
        this.ButtonLoad.deleteDepartment = false;
      }
    },

    //选择部门
    choose(Code, Name, Id) {
      this.Page = 1;
      this.paging.Pages = 1;
      this.TableName = Name;
      this.EmployeesForm.DepartmentId = Id;
      this.paging.DepartmentCode = Code;
      this.Employees();
    },

    //所有人员部门
    AllDepartments() {
      this.Page = 1;
      this.TableName = "";
      this.paging.DepartmentCode = "";
      this.paging.Pages = 1;
      this.Employees();
    },

    //按条件查询
    Search() {
      this.Page = 1;
      this.paging.Pages = 1;
      this.Employees();
    },

    //查询人员
    async Employees() {
      try {
        var Type = 0;
        this.PageLoad.Rightload = true;
        if (this.paging.Type != "") {
          Type = this.paging.Type;
        }
        if (this.paging.Value == null) this.paging.Value = "";
        var { data: res } = await this.$http.post("SelectEmployees", {
          DepartmentCode: "" + this.paging.DepartmentCode + "",
          Count: this.paging.Count,
          Pages: this.paging.Pages,
          Type: Type,
          Value: "" + this.paging.Value + "",
          EmployeeId: this.paging.EmployeeId,
        });
        if (res.resultCode == 1) {
          this.EmployeesList = JSON.parse(res.body);
          if (this.EmployeesList.length > 0) {
            this.EmployeesListSize = this.EmployeesList[0].size;
          } else {
            this.EmployeesListSize = 0;
          }
        }
        this.PageLoad.Rightload = false;
      } catch (err) {
        this.PageLoad.Rightload = false;
        console.log(err);
      }
    },

    // 照片404
    imgError() {
      console.log(111);
    },

    //导出人员
    async EmployeesExecl(ExportType) {
      try {
        var Type = 0;
        if (this.paging.Type != "") {
          Type = this.paging.Type;
        }
        var Data =
          this.$t("Employee.Department") +
          "," +
          this.$t("Employee.Number") +
          "," +
          this.$t("Employee.Name") +
          "," +
          this.$t("Employee.Sex") +
          "(1-" +
          this.$t("Employee.Sex1") +
          " 2-" +
          this.$t("Employee.Sex2") +
          ")," +
          this.$t("Employee.Card1") +
          "," +
          this.$t("Employee.Card2") +
          "," +
          this.$t("Employee.Card3") +
          "," +
          this.$t("Employee.IdentityCard") +
          "," +
          this.$t("Employee.Location") +
          "," +
          this.$t("Employee.StartDate") +
          "," +
          this.$t("Employee.EndDate") +
          "," +
          this.$t("Employee.AddDate");
        var parameter =
          this.paging.DepartmentCode +
          "," +
          this.paging.Count +
          "," +
          this.paging.Pages +
          "," +
          Type +
          "," +
          this.paging.Value +
          "," +
          ExportType;
        var url =
          this.$http.defaults.baseURL +
          "/" +
          "EmployeesExecl?parameter=" +
          parameter +
          "&head=" +
          Data;
        window.location.href = url;
      } catch (err) {
        console.log(err);
      }
    },

    //分页条数
    handleSizeChange(val) {
      this.paging.Count = val;
      this.Employees();
    },

    //分页页数
    handleCurrentChange(val) {
      this.paging.Pages = val;
      this.Page = val;
      this.Employees();
    },

    //上传照片
    async upload(f) {
      let param = new FormData(); //创建From对象
      param.append("picture", f.file);
      var { data: res } = await this.$http.post("upload", param);
      if (this.FilteringOperation(res)) {
        //请求结果过滤
        if (res.resultCode == 1) {
          //照片上传成功
          var body = JSON.parse(res.body);
          this.EmployeesForm.Photo = body.Name;
          this.imageUrl =
            this.$image + "/Employees/" + body.CompanyId + "/" + body.Name;
        } else if (res.resultCode == 2) {
          this.$message({
            type: "error",
            message: this.$t("Employee.PhotoUploadFailure"),
            offset: 10,
          });
        }
      }
    },

    //照片校验
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!isJPG) {
        this.$message.error(this.$t("Employee.PhotoRequire1"));
      }
      if (!isLt2M) {
        this.$message.error(this.$t("Employee.PhotoRequire2"));
      }
      return isJPG && isLt2M;
    },

    //卡号生成
    Generate() {
      var time = new Date();
      var getFullYear = time.getFullYear().toString().substring(2, 4); //年（后两位）
      var getMonth = time.getMonth() + 1; //月份
      getMonth = getMonth > 10 ? getMonth.toString() : "0" + getMonth;
      var getDate = time.getDate(); //日
      getDate = getDate > 10 ? getDate.toString() : "0" + getDate;
      var getHours = time.getHours(); //时
      getHours = getHours > 10 ? getHours.toString() : "0" + getHours;
      var getMinutes = time.getMinutes(); //分
      getMinutes = getMinutes > 10 ? getMinutes.toString() : "0" + getMinutes;
      var getSeconds = time.getSeconds(); //秒
      getSeconds = getSeconds > 10 ? getSeconds.toString() : "0" + getSeconds;
      var getMilliseconds = time.getMilliseconds().toString().substr(0, 1); //毫秒
      this.EmployeesForm.Number =
        getFullYear +
        getMonth +
        getDate +
        getHours +
        getMinutes +
        getSeconds +
        getMilliseconds;
    },

    //新增人员弹框
    addEmployeesDialog() {
      if (this.DepartmentList.length > 0) {
        if (this.EmployeesForm.DepartmentId == "") {
          this.EmployeesForm.DepartmentId =
            this.DepartmentList[0].strDepartmentId;
        }

        this.EmployeesForm.Number = "";
        this.EmployeesDialog.dialogVisible = true;
        this.EmployeesDialog.title = this.$t("Employee.AddEmployee");

        this.imageUrl = "";
        this.EmployeesForm.EmployeeId = 0;
        this.EmployeesForm.Number = "";
        this.EmployeesForm.Name = "";
        this.EmployeesForm.Q = "";
        this.EmployeesForm.C = "";
        this.EmployeesForm.K = "";
        this.EmployeesForm.Telephone = "";
        this.EmployeesForm.IdentityCard = "";
        this.EmployeesForm.Photo = "";
        this.EmployeesForm.Address = "";
        this.EmployeesForm.DateTime = [];
        this.EmployeesForm.IsShareCode = true;
      } else {
        this.$message({
          message: this.$t("Employee.NotDepartment"),
          type: "error",
          offset: 10,
        });
      }
    },

    //新增人员
    AddEmployees() {
      this.$refs.EmployeesformRef.validate(async (valid) => {
        //验证
        if (!valid) return;

        if (this.EmployeesForm.DepartmentId == 0) {
          this.$message({
            message: this.$t("Employee.SelectDepartment"),
            type: "warning",
            offset: 10,
          });
          return;
        }
        this.ButtonLoad.AddUpdateDepartment = true;
        this.EmployeesForm.EmployeeId = 0;
        var { data: res } = await this.$http.post(
          "AddUpdEmployee",
          this.EmployeesForm
        );
        if (this.FilteringOperation(res)) {
          if (res.resultCode == 0) {
            //新增失败
            this.$message({
              message: this.$t("Employee.AddFailure"),
              type: "error",
              offset: 10,
            });
          } else if (res.resultCode == 1) {
            //新增成功
            this.$message({
              message: this.$t("Employee.AddSucceed"),
              type: "success",
              offset: 10,
            });
            this.Employees();
            this.imageUrl = "";
            this.EmployeesForm.Number = "";
            this.EmployeesForm.Name = "";
            this.EmployeesForm.Q = "";
            this.EmployeesForm.C = "";
            this.EmployeesForm.K = "";
            this.EmployeesForm.Telephone = "";
            this.EmployeesForm.IdentityCard = "";
            this.EmployeesForm.Photo = "";
            this.EmployeesForm.Address = "";
            this.EmployeesForm.DateTime = [];
            this.EmployeesForm.IsShareCode = true;
            this.EmployeesDialog.dialogVisible = false;
          } else if (res.resultCode == 2) {
            //卡号存在
            this.$message({
              message: this.$t("Employee.CardExist"),
              type: "warning",
              offset: 10,
            });
          } else if (res.resultCode == 3) {
            //工号存在
            this.$message({
              message: this.$t("Employee.NumberExist"),
              type: "warning",
              offset: 10,
            });
          }
        }
        this.ButtonLoad.AddUpdateDepartment = false;
      });
    },

    //修改人员弹框
    UpdEmployeesDialog(list) {
      this.EmployeesDialog.dialogVisible = true;
      this.EmployeesDialog.title = this.$t("Employee.UpdateEmployee");
      this.EmployeesForm.DepartmentId = list.departmentId + ""; //部门Id
      this.EmployeesForm.EmployeeId = list.employeeId;
      this.EmployeesForm.Number = list.number; //工号
      this.EmployeesForm.Name = list.name; //姓名
      this.imageUrl =
        list.photo != "" ? this.$image + "/Employees/" + list.photo : "";
      this.EmployeesForm.Sex = list.sex + "";
      this.EmployeesForm.Q = list.q; //Q
      this.EmployeesForm.C = list.c; //C
      this.EmployeesForm.K = list.k; //K
      this.EmployeesForm.Telephone = list.telephone; //电话
      this.EmployeesForm.IdentityCard = list.identityCard; //身份证号
      this.EmployeesForm.Address = list.address; //住址
      if (list.startTime != undefined && list.endTime != undefined) {
        this.EmployeesForm.DateTime = [list.startTime, list.endTime]; //日期
      } else {
        this.EmployeesForm.DateTime = []; //日期
      }
      this.EmployeesForm.IsShareCode = list.isShareCode == 1 ? true : false;//分享码
      var photo = list.photo.split("/");
      this.EmployeesForm.Photo =
      list.photo != "" ? photo[photo.length - 1] : ""; //照片
    },

    //修改人员信息
    UpdEmployees() {
      this.$refs.EmployeesformRef.validate(async (valid) => {
        //验证
        if (!valid) return;
        this.ButtonLoad.AddUpdateDepartment = true;
        var { data: res } = await this.$http.post(
          "AddUpdEmployee",
          this.EmployeesForm
        );
        if (this.FilteringOperation(res)) {
          if (res.resultCode == 0) {
            //修改失败
            this.$message({
              message: this.$t("Employee.UpdateFailure"),
              type: "error",
              offset: 10,
            });
          } else if (res.resultCode == 1) {
            //修改成功
            this.$message({
              message: this.$t("Employee.UpdateSucceed"),
              type: "success",
              offset: 10,
            });
            this.Employees();
            this.EmployeesDialog.dialogVisible = false;
          } else if (res.resultCode == 2) {
            //卡号存在
            this.$message({
              message: this.$t("Employee.CardExist"),
              type: "warning",
              offset: 10,
            });
          } else if (res.resultCode == 3) {
            //工号存在
            this.$message({
              message: this.$t("Employee.NumberExist"),
              type: "warning",
              offset: 10,
            });
          }
        }
        this.ButtonLoad.AddUpdateDepartment = false;
      });
    },

    //删除照片
    DelEmployeesImage() {
      this.EmployeesForm.Photo = "";
      this.imageUrl = "";
    },

    //删除人员
    DelEmployees(employeeId, number) {
      this.$confirm(
        this.$t("Employee.WhetherDelete"),
        this.$t("Employee.Hint"),
        { type: "warning" }
      )
        .then(async () => {
          //删除人员
          var { data: res } = await this.$http.post("DelEmployees", {
            Employees: [employeeId],
            Number: [number],
          });
          if (this.FilteringOperation(res)) {
            if (res.resultCode == 0) {
              //删除失败
              this.$message({
                message: this.$t("Employee.DeleteFailure"),
                type: "error",
                offset: 10,
              });
            } else if (res.resultCode == 1) {
              //删除成功
              this.$message({
                message: this.$t("Employee.DeleteSucceed"),
                type: "success",
                offset: 10,
              });
              if (this.EmployeesList.length - 1 == 0 && this.paging.Pages > 1) {
                this.paging.Pages = this.paging.Pages - 1;
              }
              this.Employees();
            }
          }
        })
        .catch(() => {
          //取消
        });
    },

    //保存选中结果
    handleSelectionChange(val) {
      this.IdData = [];
      this.NumberData = [];
      for (var i = 0; i < val.length; i++) {
        this.IdData[i] = val[i].employeeId;
        this.NumberData[i] = val[i].number;
      }
    },

    //批量删除人员
    async DelBatEmployees() {
      if (this.IdData.length > 0) {
        this.$confirm(
          this.$t("Employee.WhetherDelete"),
          this.$t("Employee.Hint"),
          { type: "warning" }
        )
          .then(async () => {
            try {
              //删除人员
              this.ButtonLoad.DeleteEmployee = true;
              var { data: res } = await this.$http.post("DelEmployees", {
                Employees: this.IdData,
                Number: this.NumberData,
              });
              if (this.FilteringOperation(res)) {
                if (res.resultCode == 0) {
                  //删除失败
                  this.$message({
                    message: this.$t("Employee.DeleteFailure"),
                    type: "error",
                    offset: 10,
                  });
                } else if (res.resultCode == 1) {
                  //删除成功
                  this.$message({
                    message: this.$t("Employee.DeleteSucceed"),
                    type: "success",
                    offset: 10,
                  });
                  if (
                    this.EmployeesList.length - this.IdData.length == 0 &&
                    this.paging.Pages > 1
                  ) {
                    this.paging.Pages = this.paging.Pages - 1;
                  }
                  this.IdData = [];
                  this.NumberData = [];
                  this.Employees();
                }
              }
              this.ButtonLoad.DeleteEmployee = false;
            } catch (err) {
              console.log(err);
            }
          })
          .catch(() => {
            //取消
          });
      } else {
        this.$message({
          message: this.$t("Employee.SelectDleteEmployee"),
          type: "warning",
          offset: 10,
        });
      }
    },

    //查看人员设备权限
    SelEmployeesJurisdiction(EmployeeId) {
      this.EmployeesControllersDialog.dialogVisible = true;
      this.JurisdictionPages = 1;
      this.JurisdictionEmployeeId = EmployeeId;
      this.EmployeesJurisdiction();
    },

    //查询人员设备权限分页（上一页）
    Previous() {
      this.JurisdictionPages = this.JurisdictionPages - 1;
      this.EmployeesJurisdiction();
    },

    //查询人员设备权限分页（下一页）
    Down() {
      this.JurisdictionPages = this.JurisdictionPages + 1;
      this.EmployeesJurisdiction();
    },

    //查询人员设备权限
    async EmployeesJurisdiction() {
      try {
        this.EmployeesControllersDialog.load = true;
        var { data: res } = await this.$http.post("EmployeesJurisdiction", {
          Pages: this.JurisdictionPages,
          EmployeeId: this.JurisdictionEmployeeId,
        });
        if (res.resultCode == 1) {
          this.ControllersList = JSON.parse(res.body);
        }
        this.EmployeesControllersDialog.load = false;
      } catch (err) {
        this.EmployeesControllersDialog.load = false;
        console.log(err);
      }
    },

    //操作过滤
    FilteringOperation(res) {
      var State = true;
      if (res.stateCode == 0) {
        State = false;
        this.$message({
          message: this.$t("Employee.Session"),
          type: "warning",
          offset: 10,
        });
      } else if (res.stateCode == 2) {
        State = false;
        this.$message({
          message: this.$t("Employee.Failure"),
          type: "error",
          offset: 10,
        });
      } else if (res.stateCode == 3) {
        State = false;
        this.$message({
          message: this.$t("Employee.AccessDenied"),
          type: "error",
          offset: 10,
        });
      }
      //限制人员数达到限制会提示
      else if (res.stateCode == 500) {
        State = false;
        this.$message({
          message: this.$t("BulkImport.PersonNumError"),
          type: "error",
          offset: 10,
        });
      }
      return State;
    },
  },
};
</script>
<style scoped>
.employee {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* 内容 */
.content {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

/* 左侧 */
.content_left {
  background-color: #ffffff;
  width: 290px;
  height: 100%;
  margin-right: 20px;
}

.Department {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Department_input {
  width: 90%;
  margin: 15px auto;
  display: flex;
  margin-bottom: 10px;
}

.Department_tree {
  width: 90%;
  flex-grow: 1;
  margin: 0px auto;
  margin-bottom: 15px;
  overflow: auto;
}

.left_tree {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}

.left_tree_icon {
  padding: 5px 10px;
}

.left_tree_icon:hover {
  background: #d9ecff;
}

/* 右侧 */
.content_right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* 右侧-头部 */
.content_right_top {
  width: 100%;
  height: 70px;
  display: flex;
  margin-bottom: 20px;
}

.right_top_card1 {
  flex-grow: 1;
  height: 100%;
  background-color: #ffffff;
  justify-content: space-between;
}

.right_top_search > i {
  margin-top: -3px;
  font-size: 25px;
  margin-left: 25px;
  margin-right: 10px;
  color: #909399;
}

.right_top_Date {
  margin-left: 21px;
}

.right_top_Date >>> i {
  font-size: 25px;
  color: #909399;
}

.right_top_search >>> .el-input__inner {
  border: 0;
  font-size: 15px;
}

.right_top_search_type {
  margin-right: 15px;
}

.right_top_search_type > i {
  margin-left: 20px;
  height: 20px;
  display: flex;
  border-left: 1px solid #c7c7c7;
}

.right_top_search_type >>> .el-input__inner {
  border: 0;
}

.right_top_card2 {
  margin: 0px 20px;
  width: 300px;
  height: 100%;
  background-color: #ffffff;
}

.right_top_card3 {
  width: 250px;
  height: 100%;
  background-color: #ffffff;
}

/* 右侧-底部 */
.content_right_bottom {
  flex-grow: 1;
  width: 100%;
  height: 0px;
  background-color: #ffffff;
}

.content_right_bottom > div {
  display: flex;
  flex-direction: column;
  width: 96%;
  height: 95%;
}

.content_right_bottom_title {
  font-weight: 600;
  color: #6f6f6f;
  font-size: 14.5px;
  margin-bottom: 10px;
}

.content_right_bottom_page {
  margin-top: 12px;
}

.Employeesimg {
  width: 340px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 人员操作弹框 */
.Employees_form {
  display: flex;
}

.Employees_form_left {
  width: 40%;
}

.Employees_form_left_title {
  width: 90%;
  margin-top: 30px;
}

.Employees_form_left_title P {
  line-height: 15px;
  color: #8a8a8a;
}

.Employees_form_right {
  width: 60%;
}

.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  border-radius: 5px;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  border-radius: 5px;
  display: block;
  border: 1px solid #d9d9d9;
}

::v-deep.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  border-radius: 3px;
}
</style>	

