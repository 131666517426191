<template>
	<div class="ShuttleBox">
		<el-card shadow="never" style="width: 40%;" body-style="padding:0px">
			<div class="ShuttleBox_Data_Top">
				<div><el-checkbox v-model="CheckAll1" @change="ChangeCheckAll1">{{checkboxName1}}</el-checkbox></div>
				<div>{{CheckModel1.length}}/{{Data1.length}}</div>
			</div>
			<div class="ShuttleBox_Data_SearchBox">
				<el-input v-model="SearchBox1" size="small" :placeholder="placeholder" clearable></el-input>
			</div>
			<div class="ShuttleBox_Data">
				<el-checkbox-group v-model="CheckModel1" @change="ISCheckAll1">
					<div v-for="(item,index) in Data1" :key="item.key">
						<div  v-if="Search(item,SearchBox1)" :class="index==0?'ShuttleBox_Data_row1':'ShuttleBox_Data_row2'">
							<el-checkbox :label="item.key" :key="item.key">{{item.label}}</el-checkbox>
						</div>
					</div>
				</el-checkbox-group>
			</div>
		</el-card>
		
		<div class="ShuttleBox_Button">
			<div><el-button type="primary" :loading="ShuttleBox_Button1" @click="Addjurisdiction">{{ButtomName1}} <i class="el-icon-d-arrow-right"></i></el-button></div>
			<div><el-button type="primary" :loading="ShuttleBox_Button1" @click="Removejurisdiction"><i class="el-icon-d-arrow-left"></i> {{ButtomName2}}</el-button></div>
		</div>
		
		<el-card shadow="never" style="width: 40%;" body-style="padding:0px">
			<div class="ShuttleBox_Data_Top">
				<div><el-checkbox v-model="CheckAll2" @change="ChangeCheckAll2">{{checkboxName2}}</el-checkbox></div>
				<div>{{CheckModel2.length}}/{{Data2.length}}</div>
			</div>
			<div class="ShuttleBox_Data_SearchBox">
				<el-input v-model="SearchBox2" size="small" :placeholder="placeholder" clearable></el-input>
			</div>
			<div class="ShuttleBox_Data">
				<el-checkbox-group v-model="CheckModel2" @change="ISCheckAll2">
					<div v-for="(item,index) in Data2" :key="item.key">
						<div v-if="Search(item,SearchBox2)" :class="index==0?'ShuttleBox_Data_row1':'ShuttleBox_Data_row2'">
							<el-checkbox :label="item.key" :key="item.key">{{item.label}}</el-checkbox>
						</div>
					</div>
				</el-checkbox-group>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default {
		//参数
		data() {
			return {
				//未选择全选状态
				CheckAll1:false,
				//已选择全选状态
				CheckAll2:false,
				//未选择勾选
				CheckModel1:[],
				//已选择勾选
				CheckModel2:[],
				//添加权限弹框加载状态
				ShuttleBox_Button1:false,
				//移除权限弹框加载状态
				ShuttleBox_Button2:false,
				//未选择搜索框
				SearchBox1:"",
				//已选择搜索框
				SearchBox2:"",
				//已选择数据
				Data2:[]
			};
		},
		//接收传值
		props:[
			"Data1",//未选择数据
			"Data3",//已选择数据
			"checkboxName1",//标题名称1
			"checkboxName2",//标题名称2
			"ButtomName1",//按钮1名称
			"ButtomName2",//按钮2名称
			"placeholder"
		],
		//监听数据变化
		watch:{
			//加载默认勾选
			Data3(val){
				if(this.Data3!=undefined)
				{
					this.Data2 = this.Data3;
					this.Addjurisdiction();
				}
			}
		},
		//自定义事件
		methods:{
			//全选未选择触发的事件
			ChangeCheckAll1(e){
				if(e)
				{
					var Check = [];
					for(var i=0;i<this.Data1.length;i++)
					{
						if(this.SearchBox1!='')
						{
							var Status = this.Search(this.Data1[i],this.SearchBox1);
							if(Status)
							{
								Check[Check.length] = this.Data1[i].key;
							}
						}
						else
						{
							Check[Check.length] = this.Data1[i].key;
						}
					}
					this.CheckModel1 = Check;
				}
				else
				{
					this.CheckModel1 = [];
				}
			},
			//是否勾选全选按钮
			ISCheckAll1(e){
				this.CheckAll1 = e.length == this.Data1.length?true:false
			},
			//搜索
			Search(item,SearchBox){
				return item.pinyin.indexOf(SearchBox) > -1;
			},
			//过滤重复数据
			FilterSelected(item){
				var Status = false;
				for(var i=0;i<this.Data2.length;i++)
				{
					if(item == this.Data2[i].key)
					{
						Status = true;
						break;
					}
				}
				return Status;
			},
			//添加权限
			Addjurisdiction(){
				this.ShuttleBox_Button1 = true;
				for(var i=0;i<this.Data1.length;i++)
				{
					for(var k=0;k<this.CheckModel1.length;k++)
					{
						if(this.Data1[i].key == this.CheckModel1[k])
						{
							if(!this.FilterSelected(this.Data1[i].key))
							{
								this.Data2[this.Data2.length] = this.Data1[i];
							}
						}
					}
				}
				this.CheckModel1 = [] 
				this.CheckAll1 = false;
				this.ShuttleBox_Button1 = false;
				var model = [];
				for(var i=0;i<this.Data2.length;i++)
				{
					model[model.length] = this.Data2[i].key;
				}
				this.$emit('model', model);//传值给父vue
			},
			
			//全选已选择触发的事件
			ChangeCheckAll2(e){
				if(e)
				{
					var Check = [];
					for(var i=0;i<this.Data2.length;i++)
					{
						if(this.SearchBox2!='')
						{
							var Status = this.Search(this.Data2[i],this.SearchBox2);
							if(Status)
							{
								Check[Check.length] = this.Data2[i].key;
							}
						}
						else
						{
							Check[Check.length] = this.Data2[i].key;
						}
					}
					this.CheckModel2 = Check;
				}
				else
				{
					this.CheckModel2 = [];
				}
			},
			//是否勾选全选按钮
			ISCheckAll2(e){
				this.CheckAll2 = e.length == this.Data2.length?true:false
			},
			//删除权限
			Removejurisdiction(){
				var Data2 = [];
				for(var i=0;i<this.Data2.length;i++)
				{
					var Status = false;
					for(var k=0;k<this.CheckModel2.length;k++)
					{
						if(this.Data2[i].key == this.CheckModel2[k])
						{
							Status = true;
							break;
						}
					}
					if(!Status)
					{
						Data2[Data2.length] = this.Data2[i];
					}
				}
				this.Data2 = Data2;
				this.CheckModel2 = []
				this.CheckAll2 = false;
				var model = [];
				for(var i=0;i<Data2.length;i++)
				{
					model[model.length] = Data2[i].key;
				}
				this.$emit('model', model);//传值给父vue
			}
		}
	};
</script>

<style  scoped>
	/* 自定义穿梭框 */
	.ShuttleBox{
		display:flex;
		align-items: center;
		justify-content: space-between;
	}
	.ShuttleBox_Data_Top{
		padding: 0px 10px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #EBEEF5;
	}
	.ShuttleBox_Data_SearchBox{
		margin: 10px;
	}
	
	.ShuttleBox_Data{
		overflow:auto;
		height: 231px;
	}
	.ShuttleBox_Data_row1{
		margin: 0px 10px;
	}
	.ShuttleBox_Data_row2{
		margin: 10px 10px;
	}
	.ShuttleBox_Button>div{
		text-align: center;
		margin: 10px;
	}
</style>
