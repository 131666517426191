<template>
	<div class="card" v-loading="loading">
		<!-- 小程序申请码 -->
		<div class="mini_app_icon boxshadow">
			<div class="app_icon_title">{{$t('VisitorConfig.ApplyQRCode')}}</div>
			<el-alert :title="$t('VisitorConfig.ApplyQRCodeInstruction')" show-icon type="info" :closable="false"></el-alert>
			<el-button class="download_app_icon" @click="downloadMiniQR">{{$t('VisitorConfig.DownloadApplyQRCode')}}</el-button>
		</div>
		<!-- 访客申请配置 -->
		<div class="apply_config boxshadow" shadow="never">
			<div class="apply_config_title">{{$t('VisitorConfig.VisitorApplyForm')}}</div>
			<div class="apply_config_item">
				<div class="apply_config_left">
					<div class="input_value">
						<!-- 默认输入配置 -->
						<div v-for="(item) in defaultApplyInputConfigList" :key="item.index+'1'">
							<el-checkbox v-model="item.required" :disabled="true" true-label="1" false-label="0" :label="$t('VisitorConfig.Required')" border></el-checkbox>
							<el-select style="margin: 0px 15px" :disabled="true" v-model="item.labelType" :placeholder="$t('VisitorConfig.SelectiveType')">
								<el-option :label="$t('VisitorConfig.SelectiveType1')" value="1"></el-option>
								<el-option :label="$t('VisitorConfig.SelectiveType2')" value="2"></el-option>
							</el-select>
							<el-input style="width: 290px;" :disabled="true" maxlength="15" v-model="item.label" :placeholder="$t('VisitorConfig.InputTitle')"></el-input>
							<el-popover placement="right" trigger="click">
								<div class="option">
									<el-tag v-for="(option) in item.optionValue" class="label" closable>{{option}}</el-tag>
								</div>
								<el-button style="margin:0px 15px;" :disabled="true" slot="reference">{{$t('VisitorConfig.OptionValue')}}</el-button>
							</el-popover>
							<el-button :disabled="true" icon="el-icon-delete"></el-button>
						</div>
						<!-- 可自定义输入配置 -->
						<div v-for="(item,index) in applyInputConfigList" :key="item.index+'2'">
							<el-checkbox v-model="item.required" true-label="1" false-label="0" :label="$t('VisitorConfig.Required')" border></el-checkbox>
							<el-select style="margin: 0px 15px"  v-model="item.labelType" :placeholder="$t('VisitorConfig.SelectiveType')">
								<el-option :label="$t('VisitorConfig.SelectiveType1')" value="1"></el-option>
								<el-option :label="$t('VisitorConfig.SelectiveType2')" value="2"></el-option>
							</el-select>
							<el-input style="width: 290px;" maxlength="15" v-model="item.label" :placeholder="$t('VisitorConfig.InputTitle')"></el-input>
							<el-popover placement="right" trigger="click">
								<div class="option">
									<el-tag v-for="(option,optionIndex) in item.optionValue" class="label" @close="DeleteInputOptionConfig(index,optionIndex)" closable>{{option}}</el-tag>
									<el-input style="margin:0px 10px;width: 170px;" :placeholder="$t('VisitorConfig.InputOptionValue')" size="small" v-model="addInputOption" class="input-with-select">
										<el-button slot="append" @click="addInputOptionConfig(index)" icon="el-icon-plus"></el-button>
									</el-input>
								</div>
								<el-button style="margin:0px 15px;" :disabled="item.labelType==1" slot="reference">{{$t('VisitorConfig.OptionValue')}}</el-button>
							</el-popover>
							<el-button icon="el-icon-delete" @click="deleteInputConfigItem(item.index)"></el-button>
						</div>
						<el-button style="width: 100%;" @click="addInputConfigItem">{{$t('VisitorConfig.NewEntry')}}</el-button>
					</div>
					<div class="imag_value">
						<div class="image_index">
							<div><el-checkbox v-model="facePhotoEnabled">{{$t('VisitorConfig.Enabled')}}</el-checkbox></div>
							<div><i class="el-icon-user"></i></div>
							<div><span style="color:#F56C6C">*</span> {{$t('VisitorConfig.VisitorPhoto')}}</div>
						</div>
						<div class="image_index" v-for="(item) in applyImageConfigList" :key="item.index">
							<div>
								<el-checkbox v-model="item.required" true-label="1" false-label="0">{{$t('VisitorConfig.Required')}}</el-checkbox>
								<i class="el-icon-delete delete_image" @click="deleteImageConfigItem(item.index)"></i>
							</div>
							<div><i class="el-icon-picture-outline"></i></div>
							<div><input type="text" class="image_name"  maxlength="15" v-model="item.label" :placeholder="$t('VisitorConfig.InputTitle')"/></div>
						</div>
						<div class="image_index add_image" @click="addImageConfigItem">
							<i class="el-icon-plus"></i>
						</div>
					</div>
				</div>
				<div class="apply_config_right">
					<el-alert :title="$t('VisitorConfig.ApplyConfigInstruction')" show-icon type="info" :closable="false"></el-alert>
					<div class="explain">
						<div class="explain_title">{{$t('VisitorConfig.ApplyConfigTitle1')}}</div>
						<div class="explain_details">
							<div>{{$t('VisitorConfig.ApplyConfigTitle1Details1')}}</div>
						</div>
						<div class="explain_title">{{$t('VisitorConfig.ApplyConfigTitle2')}}</div>
						<div class="explain_details">
							<div>{{$t('VisitorConfig.ApplyConfigTitle2Details1')}}</div>
							<div>{{$t('VisitorConfig.ApplyConfigTitle2Details2')}}</div>
							<div>{{$t('VisitorConfig.ApplyConfigTitle2Details3')}}</div>
							<div>{{$t('VisitorConfig.ApplyConfigTitle2Details4')}}</div>
							<div>{{$t('VisitorConfig.ApplyConfigTitle2Details5')}}</div>
							<div>{{$t('VisitorConfig.ApplyConfigTitle2Details6')}} <i class='el-icon-delete'></i> {{ $t('VisitorConfig.DeleteButton') }}</div>
						</div>
						<div class="explain_title">{{$t('VisitorConfig.ApplyConfigTitle3')}}</div>
						<div class="explain_details">
							<div>{{$t('VisitorConfig.ApplyConfigTitle3Details1')}}</div>
							<div>{{$t('VisitorConfig.ApplyConfigTitle3Details2')}}</div>
							<div>{{$t('VisitorConfig.ApplyConfigTitle3Details3')}}</div>
							<div>{{$t('VisitorConfig.ApplyConfigTitle3Details4')}} <i class='el-icon-delete'></i> {{ $t('VisitorConfig.DeleteButton') }}</div>
						</div>
						<div class="explain_title">{{$t('VisitorConfig.ApplyConfigTitle4')}}</div>
						<div class="explain_details">
							<div>{{$t('VisitorConfig.ApplyConfigTitle4Details1')}}</div>
							<div>{{$t('VisitorConfig.ApplyConfigTitle4Details2')}}</div>
						</div>
					</div>
				</div>
			</div>
			<el-button type="primary" class="save_apply_config" @click="saveApplyConfig">{{$t('VisitorConfig.SaveConfig')}}</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				//权限组
				userGroupDetails: {
					RoleHostMode: false //配置操作权
				},
				//加载状态
				loading:false,
				//默认输入配置
				defaultApplyInputConfigList:[
					{index:1,required:"1",label:this.$t('VisitorConfig.VisitorName'),labelType:"1",optionValue:[]},
					{index:2,required:"1",label:this.$t('VisitorConfig.VisitorPhone'),labelType:"1",optionValue:[]},
				],
				//访客输入配置
				applyInputConfigList:[],
				//访客图片配置
				applyImageConfigList:[],
				//人脸照片是否启用
				facePhotoEnabled:false,
				//新增的选项
				addInputOption:""
			};
		},
		//加载事件
		created() {
			//获取操作权
			this.Role();
			//获取访客申请配置
			this.getApplyConfig();
		},
		methods: {
			//获取操作权
			Role() {
				//获取权限组
				var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
				for (var i = 0; i < userGroupDetails.length; i++) {
					if (userGroupDetails[i].roleName == "HostMode") { //配置操作权
						this.userGroupDetails.RoleHostMode = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
				}
			},
			//下载小程序二维码
			async downloadMiniQR(){
				try {
					const response = await this.$http.get('wxCode/getMiniCode', {responseType: 'blob'});
					const imageBlob = new Blob([response.data], { type: 'image/jpeg' });
					const downloadLink = document.createElement('a');
					downloadLink.href = URL.createObjectURL(imageBlob);
					downloadLink.download = 'MiniApp.jpg';
					downloadLink.click();
				} catch (error) {
					this.$message({message: this.$t('VisitorConfig.getQRCodeFail'),type: 'error',offset:10});
				}
			},
			//获取访客申请配置
			async getApplyConfig(){
				this.loading = true;
				var {data: res} = await this.$http.post('visitorApplyConfig/queryVisitorApplyConfig')
				if (res.resultCode == 1)
				{
					var data = JSON.parse(res.body);
					//去掉companyId字段
					data.forEach(obj => delete obj.companyId);
					//访客输入配置
					var applyInputConfigList = []; 
					//访客图片配置
					var applyImageConfigList = [];
					for(var i=0;i<data.length;i++){
						data[i].required = data[i].required.toString();
						data[i].labelType = data[i].labelType.toString();
						data[i].optionValue = data[i].optionValue==undefined?[]:JSON.parse(data[i].optionValue);
						if(data[i].labelType=="3" && data[i].optionValue[0]=="0"){
							applyImageConfigList[applyImageConfigList.length] = data[i];
						}
						else if(data[i].labelType=="3" && data[i].optionValue[0]=="1")
						{
							this.facePhotoEnabled = true;
						}
						else if(data[i].labelType!="3"){
							applyInputConfigList[applyInputConfigList.length] = data[i];
						}
					}
					applyImageConfigList.sort((a, b) => a.index - b.index);
					this.applyImageConfigList = applyImageConfigList;
					applyInputConfigList.sort((a, b) => a.index - b.index);
					this.applyInputConfigList = applyInputConfigList;
					this.loading = false;
				}
			},
			//新增填写配置
			addInputConfigItem(){
				var applyInputConfigList = this.applyInputConfigList;
				var index = applyInputConfigList.map(obj => obj.index);
				var max = Math.max(...index)+1;
				var newItem = {index:max,required:"0",label:"",labelType:"1",optionValue:[]}
				applyInputConfigList.push(newItem);
			},
			//删除填写配置
			deleteInputConfigItem(index){
				var applyInputConfigList = this.applyInputConfigList;
				index = applyInputConfigList.findIndex(obj => obj.index === index);
				applyInputConfigList.splice(index,1);
			},
			//新增选项配置
			addInputOptionConfig(index){
				var addInputOption = this.addInputOption;
				if(addInputOption=="") return;
				var applyInputConfigList = this.applyInputConfigList;
				var optionValue = applyInputConfigList[index].optionValue;
				optionValue.push(addInputOption);
				this.addInputOption = "";
			},
			//删除选项配置
			DeleteInputOptionConfig(index,optionIndex){
				var applyInputConfigList = this.applyInputConfigList;
				var optionValue = applyInputConfigList[index].optionValue;
				optionValue.splice(optionIndex,1);
			},
			//新增图片配置
			addImageConfigItem(){
				var applyImageConfigList = this.applyImageConfigList;
				var index = applyImageConfigList.map(obj => obj.index);
				var max = Math.max(...index)+1;
				var newItem = {index:max,required:"0",label:"",labelType:"3",optionValue:[0]}
				applyImageConfigList.push(newItem);
			},
			//删除图片配置
			deleteImageConfigItem(index){
				var applyImageConfigList = this.applyImageConfigList;
				index = applyImageConfigList.findIndex(obj => obj.index === index);
				applyImageConfigList.splice(index,1);
			},
			//保存配置
			async saveApplyConfig(){
				try
				{
					var applyConfigList = [];
					var applyInputConfigList = JSON.parse(JSON.stringify(this.applyInputConfigList));
					for(var i=0;i<applyInputConfigList.length;i++){
						if(applyInputConfigList[i].labelType=="1" && applyInputConfigList[i].label==""){
							this.$message({message: this.$t('VisitorConfig.HintInput'),type: 'warning',offset:10});
							return;
						}else if(applyInputConfigList[i].labelType=="2" && (applyInputConfigList[i].label=="" || applyInputConfigList[i].optionValue.length==0)){
							this.$message({message: this.$t('VisitorConfig.HintInput'),type: 'warning',offset:10});
							return;
						}
						applyInputConfigList[i].index = applyConfigList.length+1;
						applyConfigList[applyConfigList.length] = applyInputConfigList[i];
					}
					var facePhotoEnabled = this.facePhotoEnabled;
					if(facePhotoEnabled){
						var index = applyConfigList.length+1;
						applyConfigList[applyConfigList.length] = {index:index,required:"1",label:this.$t('VisitorConfig.VisitorPhoto'),labelType:"3",optionValue:["1"]};
					}
					var applyImageConfigList = JSON.parse(JSON.stringify(this.applyImageConfigList));
					for(var i=0;i<applyImageConfigList.length;i++){
						if(applyImageConfigList[i].label==""){
							this.$message({message: this.$t('VisitorConfig.HintInput'),type: 'warning',offset:10});
							return;
						}
						applyImageConfigList[i].index = applyConfigList.length+1;
						applyConfigList[applyConfigList.length] = applyImageConfigList[i];
					}
					applyConfigList = {"details":applyConfigList};
					var {data: res} = await this.$http.post('visitorApplyConfig/updateVisitorApplyConfig', applyConfigList)
					if (this.FilteringOperation(res)) 
					{
						this.$message({message: this.$t('VisitorConfig.SaveSucceed'),type: 'success',offset:10});
					}
				}
				catch (err)
				{
					this.addhostbyselectState = false;
					console.log(err)
				}
			},
			
			//操作过滤
			FilteringOperation(res){
				var State = true;
				if (res.stateCode == 0) {
					State = false;
					this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
				} else if (res.stateCode == 2) {
					State = false;
					this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
				} else if (res.stateCode == 3) {
					State = false;
					this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
				}
				return State;
			}
		}
	};
</script>

<style scoped>

	/* 小程序申请码 */
	.mini_app_icon{
		padding: 20px;
		overflow:hidden;
		margin-bottom: 20px;
		background-color: #fff;
	}
	/* 小程序标题 */
	.app_icon_title{
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 20px;
	}
	/* 下载图标 */
	.download_app_icon{
		margin-top: 20px;
	}
	/* 访客申请配置 */
	.apply_config{
		padding: 20px;
		background-color: #ffffff;
	}
	/* 访客申请配置标题 */
	.apply_config_title{
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 20px;
	}
	.apply_config_item{
		display: flex;
	}
	/* 左侧配置项 */
	.apply_config_left{
		width: 560px;
		border-radius: 5px;
		border:1px solid #eee;
		padding: 20px 20px 0px 20px;
	}
	.input_value{
		margin-bottom: 20px;
	}
	.input_value>div{
		display: flex;
		align-items: center;
		padding-bottom: 20px;
	}
	/* 配置选项 */
	.option{
		display: flex;
	}
	.label{
		margin: 0px 5px;
	}
	/* 申请配置-图片 */
	.imag_value{
		display: grid;
		grid-gap: 25px;
		grid-template-columns: repeat(auto-fill, 170px);
		margin-bottom: 20px;
	}
	.image_index{
		width: 170px;
		height: 170px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #f3f3f3;
	}
	.image_index>div:nth-child(1){
		width: 85%;
		margin: 10px auto;
		display: flex;
		justify-content: space-between;
	}
	.image_index>div:nth-child(2){
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 43px;
		color: #949494;
	}
	.image_index>div:nth-child(3){
		width: 100%;
		height: 42px;
		color: #fff;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #02020265;
	}
	.delete_image{
		color: #909399;
	}
	.delete_image:hover{
		color: #F56C6C;
		cursor: pointer;
	}
	.add_image{
		color: #909399;
		font-size: 40px;
	}
	.add_image:hover{
		cursor: pointer;
	}
	.image_name{
		width: 80%;
		color: #fff;
		border:0px;
		height: 100%;
		background-color: transparent;
	}
	.image_index input[type="text"] {
		outline: none;
	}
	.image_index input::placeholder{
		color: #fff;
	}
	/* 右侧说明项 */
	.apply_config_right{
		flex-grow: 1;
		margin-left: 50px;
	}
	.explain{
		overflow: hidden;
		font-size: 14px;
		padding: 0px 20px;
		padding-bottom: 20px;
		margin-top: 15px;
		border-radius: 5px;
		background-color: #F4F4F5;
	}
	.explain_title{
		margin-top:20px;
		color: #303133;
	}
	.explain_details{
		margin:5px 0px;
		color: #909399;
	}
	/* 保存配置 */
	.save_apply_config{
		margin-top: 20px;
	}
</style>