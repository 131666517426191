<template>
<el-form
    ref="userForm"
    :model="userForm"
    style="max-width: 460px"
    :rules="rules"
  >
    <el-form-item :label="$t('EditAdminPwd.Pwd')" prop="pwd">
      <el-input placeholder="Please input" type="password" v-model="userForm.pwd" maxlength="15"/>
    </el-form-item>
    <el-form-item :label="$t('EditAdminPwd.ConfirmPwd')" prop="confirmPwd">
      <el-input  placeholder="Please input" type="password" v-model="userForm.confirmPwd" maxlength="15"/>
    </el-form-item>
      <el-button type="primary"  @click="submitForm()">{{$t('EditAdminPwd.Submit')}}</el-button>
  </el-form>
</template>

<script>
	export default {
		data() {
            //正则表达式满足大小写加数字构成的密码才能通过
            var checkPass = (rule,value,callback) => {
                //内容不能为空
                if(value === "" || value === undefined){
                    callback(new Error(this.$t('EditAdminPwd.ErrorTip1')))
                }
                //数字和字母正则表达式
                var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/)
                if(!reg.test(value)){
                     callback(new Error(this.$t('EditAdminPwd.ErrorTip2')))
                }
                callback()
            }
            //两次输入的密码一致才可以通过
            var validatePass = (rule,value,callback)=> {
                //内容不能为空
                if(value === "" || value === undefined){
                    callback(new Error(this.$t('EditAdminPwd.ErrorTip1')))
                }
                //两次密码输入一致才能通过
                if(value !== this.userForm.pwd){
                    callback(new Error(this.$t('EditAdminPwd.ErrorTip3')))
                }
                callback()
            }
			return {
                userForm:{
                    userId:1,
                    pwd:"",
                    confirmPwd:""
                },
                rules:{
                    pwd: [
                        {require:true,trigger:"blur"},
                        { min:6, max:15, message:this.$t('EditAdminPwd.ErrorTip4'), trigger:'blur'},
                        { validator: checkPass, trigger: 'blur' }
                    ],
                    confirmPwd: 
                    [{require:true,trigger:"blur"},
                    { min:6, max:15, message:this.$t('EditAdminPwd.ErrorTip4'), trigger:'blur'},
                    { validator: validatePass, trigger: 'blur' }
                    ]
                },
			}
            
		},
		methods: {

            //提交密码修改
            async submitForm(){
                this.$refs.userForm.validate(async (valid)=>{
                if (!valid) return;
                    //请求后台接口
                    var {data: res} = await this.$http.post('editPasswordForAdminFirstLogin',this.userForm);
                    //成功
                    if (res.resultCode == 1) {
                        this.$message({
                            type: "success",
                            duration: 1500,
                            message: this.$t('EditAdminPwd.EditSuccess')
                        });
                        //跳转首页
                        this.$router.push("/index")
                    }
                    //失败
                    else{
                        this.$message({
                            type: "error",
                            duration: 1500,
                            message: this.$t('EditAdminPwd.EditFail')
                        });
                        //跳转首页
                        this.$router.push("/index")
                    }
                })
                
            }
            
		}
	}
</script>
<style scoped>

</style>