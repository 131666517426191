<template>
  <div class="LogRecord" v-loading="Tableload">
    <div class="top flex-items-center boxshadow">
        <div v-if="FormData.Type==1" class="Card1 flex-items-center">
            <i class="el-icon-search"></i>
            <el-input size="medium" v-model="FormData.Value" :placeholder="$t('LogRecord.PleaseInput')"></el-input>
        </div>
        <div v-else-if="FormData.Type==2">
             <el-select size="medium" v-model="FormData.Value">
                <el-option :label="$t('LogRecord.Modules1')" value="1"></el-option>
                <el-option :label="$t('LogRecord.Modules2')" value="2"></el-option>
                <el-option :label="$t('LogRecord.Modules3')" value="3"></el-option>
                <el-option :label="$t('LogRecord.Modules4')" value="4"></el-option>
                <el-option :label="$t('LogRecord.Modules5')" value="5"></el-option>
                <el-option :label="$t('LogRecord.Modules6')" value="6"></el-option>
                <el-option :label="$t('LogRecord.Modules7')" value="7"></el-option>
                <el-option :label="$t('LogRecord.Modules8')" value="8"></el-option>
                <el-option :label="$t('LogRecord.Modules9')" value="9"></el-option>
                <el-option :label="$t('LogRecord.Modules10')" value="10"></el-option>
                <el-option :label="$t('LogRecord.Modules11')" value="11"></el-option>
                <el-option :label="$t('LogRecord.Modules12')" value="12"></el-option>
                <el-option :label="$t('LogRecord.Modules13')" value="13"></el-option>
                <el-option :label="$t('LogRecord.Modules14')" value="14"></el-option>
                <el-option :label="$t('LogRecord.Modules15')" value="15"></el-option>
                <el-option :label="$t('LogRecord.Modules16')" value="16"></el-option>
            </el-select>
        </div>
        <div v-else>
             <el-select size="medium" v-model="FormData.Value">
                <el-option :label="$t('LogRecord.Actions1')" value="1"></el-option>
                <el-option :label="$t('LogRecord.Actions2')" value="2"></el-option>
                <el-option :label="$t('LogRecord.Actions3')" value="3"></el-option>
                <el-option :label="$t('LogRecord.Actions4')" value="4"></el-option>
                <el-option :label="$t('LogRecord.Actions5')" value="5"></el-option>
                <el-option :label="$t('LogRecord.Actions6')" value="6"></el-option>
                <el-option :label="$t('LogRecord.Actions7')" value="7"></el-option>
                <el-option :label="$t('LogRecord.Actions8')" value="8"></el-option>
            </el-select>
        </div>
        <div class="Card2 flex-items-center">
            <div class="Card2_button"><el-button size="mini" class="el-icon-search" @click="QueryLogRecord"></el-button></div>
            <el-select size="medium" v-model="FormData.Type" @change="FormData.Value=''">
                <el-option :label="$t('LogRecord.Select1')" value="1"></el-option>
                <el-option :label="$t('LogRecord.Select2')" value="2"></el-option>
                <el-option :label="$t('LogRecord.Select3')" value="3"></el-option>
            </el-select>
        </div>
    </div>
    <div class="bottom boxshadow">
        <div class="bottom_title">{{$t('LogRecord.LogRecord')}}</div>
        <el-table :data="tableData" height="100%" style="width: 100%">
            <el-table-column prop="userName" align="center" :label="$t('LogRecord.Lable1')"></el-table-column>
            <el-table-column prop="loginIp" align="center" :label="$t('LogRecord.Lable2')"></el-table-column>
            <el-table-column prop="modules" align="center" :label="$t('LogRecord.Lable3')">
                <template slot-scope="scope">
					<div>{{ModulesTransform(scope.row.modules)}}</div>	
				</template>
            </el-table-column>
            <el-table-column prop="eventTime" align="center" :label="$t('LogRecord.Lable4')"></el-table-column>
            <el-table-column prop="actions" align="center" :label="$t('LogRecord.Lable5')">
                <template slot-scope="scope">
					<div>{{ActionsTransform(scope.row.actions)}}</div>	
				</template>
            </el-table-column>
            <el-table-column prop="detail" align="center" width="500" :label="$t('LogRecord.Lable6')">
                <template slot-scope="scope">
					<div class="tabnle_detail">{{DetailTransform(scope.row.detail)}}</div>
				</template>
            </el-table-column>
        </el-table>
        <div class="bottom_page flex-items-center">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:page-sizes="[20, 50, 100]" :page-size="FormData.Count" :current-page="FormData.Pages" layout="total, sizes, prev, pager, next, jumper"
			:total="DataSize">
			</el-pagination>
		</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        FormData:{
            Value:"",
            Count:20,
            Pages:1,
            Type:"1"
        },
        Tableload:false,
        tableData: [],
        DataSize:0,
    }
  },
  //创建实例时执行
  created() {
   
  },
  //页面加载后执行
  mounted: function () {
    //查询日志记录
    this.QueryLogRecord();
  },
  //所有方法
  methods: {
    //分页条数
	handleSizeChange(val) {
		this.FormData.Count = val;
		this.QueryLogRecord();
	},

	//分页页数
	handleCurrentChange(val) {
		this.FormData.Pages = val;
		this.QueryLogRecord();
	},

    //查询日志记录
    async QueryLogRecord(){
        try 
		{
			this.Tableload = true;
			var {data: res} = await this.$http.post('QueryLogRecord', this.FormData)
			if (res.resultCode == 1) {
				this.tableData = JSON.parse(res.body);
                this.DataSize = this.tableData.length > 0?this.tableData[0].size:0;
			}
			this.Tableload = false;
		} 
		catch (err) 
		{
			this.Tableload = false;
			console.log(err)
		}
    },

    // 模块转换
    ModulesTransform(lable){
       if(lable==1) return this.$t('LogRecord.Modules1')
       else if(lable==2) return this.$t('LogRecord.Modules2')
       else if(lable==3) return this.$t('LogRecord.Modules3')
       else if(lable==4) return this.$t('LogRecord.Modules4')
       else if(lable==5) return this.$t('LogRecord.Modules5')
       else if(lable==6) return this.$t('LogRecord.Modules6')
       else if(lable==7) return this.$t('LogRecord.Modules7')
       else if(lable==8) return this.$t('LogRecord.Modules8')
       else if(lable==9) return this.$t('LogRecord.Modules9')
       else if(lable==10) return this.$t('LogRecord.Modules10')
       else if(lable==11) return this.$t('LogRecord.Modules11')
       else if(lable==12) return this.$t('LogRecord.Modules12')
       else if(lable==13) return this.$t('LogRecord.Modules13')
       else if(lable==14) return this.$t('LogRecord.Modules14')
       else if(lable==15) return this.$t('LogRecord.Modules15')
       else if(lable==16) return this.$t('LogRecord.Modules16')
       else if(lable==17) return this.$t('LogRecord.Modules17')
    },

    // 操作转换
    ActionsTransform(lable){
       if(lable==1) return this.$t('LogRecord.Actions1')
       else if(lable==2) return this.$t('LogRecord.Actions2')
       else if(lable==3) return this.$t('LogRecord.Actions3')
       else if(lable==4) return this.$t('LogRecord.Actions4')
       else if(lable==5) return this.$t('LogRecord.Actions5')
       else if(lable==6) return this.$t('LogRecord.Actions6')
       else if(lable==7) return this.$t('LogRecord.Actions7')
       else if(lable==8) return this.$t('LogRecord.Actions8')
    },

    //详情转换
    DetailTransform(lable){
        lable = lable.replace("userName",this.$t('LogRecord.Detail1'))
        lable = lable.replace("addDep",this.$t('LogRecord.Detail2'))
        lable = lable.replace("editDep",this.$t('LogRecord.Detail3'))
        lable = lable.replace("delDep",this.$t('LogRecord.Detail4'))
        lable = lable.replace("addEmp",this.$t('LogRecord.Detail5'))
        lable = lable.replace("editEmp",this.$t('LogRecord.Detail6'))
        lable = lable.replace("editEmpAfter",this.$t('LogRecord.Detail7'))
        lable = lable.replace("delEmp",this.$t('LogRecord.Detail8'))
        lable = lable.replace("addBatchEmp",this.$t('LogRecord.Detail9'))
        lable = lable.replace("number",this.$t('LogRecord.Detail10'))
        lable = lable.replace("addHolidayTemp",this.$t('LogRecord.Detail11'))
        lable = lable.replace("tempName",this.$t('LogRecord.Detail12'))
        lable = lable.replace("editTmp",this.$t('LogRecord.Detail13'))
        lable = lable.replace("delHolidayTemp",this.$t('LogRecord.Detail14'))
        lable = lable.replace("addHolidayDetail",this.$t('LogRecord.Detail15'))
        lable = lable.replace("editHolidayDetail",this.$t('LogRecord.Detail16'))
        lable = lable.replace("delHolidayDetail ",this.$t('LogRecord.Detail17'))
        lable = lable.replace("holDetailId",this.$t('LogRecord.Detail18'))
        lable = lable.replace("addTimeTemp",this.$t('LogRecord.Detail19'))
        lable = lable.replace("editTimeTmp",this.$t('LogRecord.Detail20'))
        lable = lable.replace("timeTmpDetailId",this.$t('LogRecord.Detail21'))
        lable = lable.replace("delTimeTmp",this.$t('LogRecord.Detail22'))
        lable = lable.replace("addArea",this.$t('LogRecord.Detail23'))
        lable = lable.replace("areaName",this.$t('LogRecord.Detail24'))
        lable = lable.replace("editArea",this.$t('LogRecord.Detail25'))
        lable = lable.replace("delArea",this.$t('LogRecord.Detail26'))
        lable = lable.replace("addEquipment",this.$t('LogRecord.Detail27'))
        lable = lable.replace("equipmentSubType",this.$t('LogRecord.Detail28'))
        lable = lable.replace("equipmentName",this.$t('LogRecord.Detail29'))
        lable = lable.replace("editEquipment",this.$t('LogRecord.Detail30'))
        lable = lable.replace("equipmentId",this.$t('LogRecord.Detail31'))
        lable = lable.replace("delEquipment",this.$t('LogRecord.Detail32'))
        lable = lable.replace("addAuthority",this.$t('LogRecord.Detail33'))
        lable = lable.replace("authorityEmpNum",this.$t('LogRecord.Detail34'))
        lable = lable.replace("empId",this.$t('LogRecord.Detail35'))
        lable = lable.replace("delAuthority",this.$t('LogRecord.Detail36'))
        lable = lable.replace("addAutoTmp",this.$t('LogRecord.Detail37'))
        lable = lable.replace("saveTmp",this.$t('LogRecord.Detail38'))
        lable = lable.replace("departmentId",this.$t('LogRecord.Detail39'))
        lable = lable.replace("areaId",this.$t('LogRecord.Detail40'))
        lable = lable.replace("delAutoTmp",this.$t('LogRecord.Detail41'))
        lable = lable.replace("syncEquipment",this.$t('LogRecord.Detail42'))
        lable = lable.replace("addSysUser",this.$t('LogRecord.Detail43'))
        lable = lable.replace("editSysUser",this.$t('LogRecord.Detail44'))
        lable = lable.replace("editUserName",this.$t('LogRecord.Detail45'))
        lable = lable.replace("delSysUser",this.$t('LogRecord.Detail46'))
        lable = lable.replace("editCompanyInfo",this.$t('LogRecord.Detail47'))
        lable = lable.replace("companyPro",this.$t('LogRecord.Detail48'))
        lable = lable.replace("addLiftArea",this.$t('LogRecord.Detail49'))
        lable = lable.replace("updLiftArea",this.$t('LogRecord.Detail50'))
        lable = lable.replace("delLiftArea",this.$t('LogRecord.Detail51'))
        lable = lable.replace("saveLiftFloor",this.$t('LogRecord.Detail52'))
        lable = lable.replace("floorData",this.$t('LogRecord.Detail53'))
        lable = lable.replace("updLiftFloor",this.$t('LogRecord.Detail54'))
        lable = lable.replace("addLiftFloorPer",this.$t('LogRecord.Detail55'))
        lable = lable.replace("floorNum",this.$t('LogRecord.Detail56'))
        lable = lable.replace("updLiftFloorPer",this.$t('LogRecord.Detail57'))
        lable = lable.replace("delLiftFloorPer",this.$t('LogRecord.Detail58'))
        lable = lable.replace("addBlack",this.$t('LogRecord.Detail59'))
        lable = lable.replace("delBlack",this.$t('LogRecord.Detail60'))
        lable = lable.replace("removeBlack",this.$t('LogRecord.Detail61'))
        lable = lable.replace("rejectVisitor",this.$t('LogRecord.Detail64'))
        lable = lable.replace("agreeVisitor",this.$t('LogRecord.Detail65'))
        lable = lable.replace("addVisitorArea",this.$t('LogRecord.Detail66'))
        lable = lable.replace("editVisitorArea",this.$t('LogRecord.Detail67'))
        lable = lable.replace("delVisitorArea",this.$t('LogRecord.Detail68'))
        lable = lable.replace("addVisitorAreaCon",this.$t('LogRecord.Detail69'))
        lable = lable.replace("equipmentNum",this.$t('LogRecord.Detail70'))
        lable = lable.replace("delVisitorAreaCon",this.$t('LogRecord.Detail71'))
        lable = lable.replace("editVisitorSetting",this.$t('LogRecord.Detail72'))
        lable = lable.replace("delVisitorP",this.$t('LogRecord.Detail73'))
        lable = lable.replace("addVisitorP",this.$t('LogRecord.Detail74'))
        lable = lable.replace("editVisitorP",this.$t('LogRecord.Detail75'))
        lable = lable.replace("editAftName",this.$t('LogRecord.Detail76'))
        lable = lable.replace("visitorName",this.$t('LogRecord.Detail77'))
        lable = lable.replace("employeeName",this.$t('LogRecord.Detail78'))
        lable = lable.replace("approveName",this.$t('LogRecord.Detail79'))
        lable = lable.replace("reason",this.$t('LogRecord.Detail80'))
        lable = lable.replace("addCommand",this.$t('LogRecord.Detail81'))
        lable = lable.replace("commandName",this.$t('LogRecord.Detail82'))
        lable = lable.replace("commandStatus",this.$t('LogRecord.Detail83'))
        lable = lable.replace("commandStartEnt",this.$t('LogRecord.Detail84'))
        lable = lable.replace("updateCommand",this.$t('LogRecord.Detail85'))
        lable = lable.replace("deleteCommand",this.$t('LogRecord.Detail86'))
        lable = lable.replace("commandID",this.$t('LogRecord.Detail87'))
		lable = lable.replace("addOpenCloseTemp",this.$t('LogRecord.Detail88'))
		lable = lable.replace("editHolidayTemp",this.$t('LogRecord.Detail89'))
		lable = lable.replace("editDateTemp",this.$t('LogRecord.Detail90'))
		lable = lable.replace("editOpenCloseTemp",this.$t('LogRecord.Detail91'))
		lable = lable.replace("delOpenCloseTemp",this.$t('LogRecord.Detail92'))
        lable = lable.replace("rebotDevice",this.$t('LogRecord.Detail93'))
        lable = lable.replace("cleanDeviceEmployee",this.$t('LogRecord.Detail94'))
        lable = lable.replace("cleanDeviceBrushCardRecord",this.$t('LogRecord.Detail95'))
        return lable;
    }
  }
};
</script>

<style scoped>
    .LogRecord{
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* 头部 */
    .top{
        padding: 15px 16px;
        background-color: #FFFFFF;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }

    .Card1{
        flex-grow: 1;
    }

    .Card1>.el-icon-search{
        font-size: 25px;
		margin-left:10px;
		margin-right:10px;
    }
 
    .Card1>>>.el-input__inner {
    	border: 0;
		font-size: 15px;
  	}

    .Card2_button{
        padding-right: 20px;
        margin-right: 10px;
        margin-left: 20px;
        border-right: 1px solid #c7c7c7;
    }

    .Card2>>>.el-input__inner {
    	border: 0;
  	}

    .bottom{
        padding: 15px 16px;
        height: 0px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
    }

    .bottom_title{
		font-weight: 600;
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 10px;
	}

    .bottom_page{
        padding-top: 15px;
    }

    .tabnle_detail{
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
