<template>
	<div class="Permission">
		<!-- 页面1 -->
		<div class="Card1" v-if="ControllerEmployeesVisible==0">
			<!-- 左侧 -->
			<div class="content_left boxshadow" v-loading="PageLoad.Leftload">
				<div class="content_left_value">
					<!-- 功能栏 -->
					<div class="left_value_input">
						<el-input :placeholder="$t('Permission.SearchArea')" size="medium" v-model="filterText"></el-input>
					</div>
					<!-- 树形 -->
					<el-tree class="left_value_tree" :highlight-current="true" :data="AreaList" :filter-node-method="filterNode" ref="tree" node-key="AreaId"
					default-expand-all :expand-on-click-node="false" icon-class="el-icon-s-promotion">
						<span class="custom-tree-node left_tree" slot-scope="{data}" @click="choose(data)">
							<div>{{data.regionName}}</div>
						</span>
					</el-tree>
				</div>
			</div>
			<!-- 右侧 -->
			<div class="content_right" v-loading="PageLoad.Rightload">
				<!-- 头部 -->
				<div class="content_right_top flex-items-center">
					<!--条件 -->
					<div class="right_top_card1 flex-items-center boxshadow">
						<!-- 搜索框 -->
						<div v-if="TypeValue !='3'" class="right_top_search flex-items-center">
							<i class="el-icon-search"></i>
							<el-input size="medium" v-model="QueryEquipment.Value" :placeholder="$t('Permission.SearchTips')"></el-input>
						</div>
						<div v-else class="right_top_Date">
							<el-select size="medium" v-model="QueryEquipment.Value" @change="Controllers()" clearable slot="prepend" :placeholder="$t('Permission.Select')">
								<el-option :label="$t('Permission.ControllerType1')" value="1"></el-option>
								<el-option :label="$t('Permission.ControllerType2')" value="2"></el-option>
							</el-select>
						</div>
						<!-- 条件类型 -->
						<div class="right_top_search_type flex-items-center">
							<el-button slot="append" size="mini" class="el-icon-search"  @click="Find()"></el-button>
							<i></i>
							<el-select size="medium" style="width:150px" v-model="TypeValue" slot="prepend"  @change="TypeValueChange()">
								<el-option :label="$t('Permission.ControllerName')" value="1"></el-option>
								<el-option :label="$t('Permission.ControllerLocation')" value="2"></el-option>
								<el-option :label="$t('Permission.ControllerType')" value="3"></el-option>
							</el-select>
						</div>
					</div>
					<!--操作1 -->
					<div class="right_top_card2 flex-center boxshadow">
						<el-button size="medium" type="primary" icon="el-icon-circle-plus-outline" @click="AddPermissionVisible()"
						:disabled="!userGroupDetails.RoleControllers">{{$t('Permission.AddPermission1')}}</el-button>
					</div>
					<!--操作2 -->
					<div class="right_top_card3 flex-center boxshadow">
						<el-button size="small" class="el-icon-refresh-right" @click="Controllers()"> {{$t('Permission.Refresh')}}</el-button>
					</div>
				</div>
				<!-- 底部 -->
				<div class="content_right_bottom flex-center boxshadow">
					<div>
						<div class="content_right_bottom_title">{{$t('Permission.ControllerList')}}</div>
						<el-table :data="EquipmentList" height="100%">
							<el-table-column :label="$t('Permission.ControllerId')" prop="controllersId" align="center"></el-table-column>
							<el-table-column :label="$t('Permission.ControllerType')" align="center">
								<template slot-scope="scope">
									<el-tooltip class="item" effect="dark" :content="ControllerSubType(scope.row.controllerType)+' - '+scope.row.controllerSubType" placement="left">
										<div style="height: 115px;display: flex;align-items: center;justify-content: center;"><img style="width: 60px;" :src="Equipmentimg(scope.row.controllerSubType)" fit="cover"/></div>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column :label="$t('Permission.ControllerName')" prop="controllerName" align="center"></el-table-column>
							<el-table-column :label="$t('Controller.TaskType')" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.taskType==0">{{$t('Controller.TaskType0')}}</span>
									<span v-if="scope.row.taskType==1">{{$t('Controller.TaskType1')}}</span>
								</template>
							</el-table-column>
							<el-table-column :label="$t('Permission.ControllerLocation')" align="center">
								<template slot-scope="scope">
									{{scope.row.regionName}}<br/>
									{{scope.row.location}}
								</template>
							</el-table-column>
							<el-table-column :label="$t('Permission.OnlineStatus')" align="center">
								<template slot-scope="scope">
									<i v-if="Online(scope.row.online)" style="font-size: 30px;color: #409EFF;" class="el-icon-monitor"></i>
									<i v-else style="font-size: 30px;color: #F56C6C" class="el-icon-warning"></i>
								</template>
							</el-table-column>
							
							<el-table-column :label="$t('Permission.SyncedNotSynced')" width="200" align="center">
								<template slot-scope="scope">
									<div>{{scope.row.haveSynchronous}} / {{scope.row.sum}}</div>
									<span style="color: #409EFF;cursor:pointer;"  @click="CheckTheAuthorization(scope.row)">{{$t('Permission.ToView')}}</span>
								</template>
							</el-table-column>
							<el-table-column :label="$t('Permission.EmployeeSynchronization')" align="center">
								<template slot-scope="scope">
									<el-progress :percentage="Percentage(scope.row.haveSynchronous,scope.row.sum)" :width="100"  type="circle"></el-progress>
								</template>
							</el-table-column>
							<el-table-column :label="$t('Permission.Operation')" align="center">
								<template slot-scope="scope">
									<div style="margin-bottom: 10px;">
										<el-tooltip class="item" effect="dark" :content="$t('Permission.SynchronizeChanges')" placement="top" :enterable="false">
										<el-button type="primary" size="mini" class="el-icon-sort" :disabled="!userGroupDetails.RoleControllers" @click="PermissionToChange(scope.row.controllersId,1)"></el-button>
										</el-tooltip>
										<el-tooltip class="item" effect="dark" :content="$t('Permission.Resync')" placement="top" :enterable="false">
											<el-button type="warning" size="mini" class="el-icon-refresh" :disabled="!userGroupDetails.RoleControllers"  @click="PermissionToChange(scope.row.controllersId,2)"></el-button>
										</el-tooltip>
									</div>
									<el-button type="info" size="mini" @click="CheckDeleteAuthorization(scope.row)">{{$t('Permission.Card3Title')}}</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="content_right_bottom_page flex-items-center">
							<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[20, 50, 100]"
							:page-size="QueryEquipment.Count" :current-page="QueryEquipment.Page" layout="total, sizes, prev, pager, next, jumper" :total="EquipmentListSize">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 页面2 -->
		<div class="Card2" v-else-if="ControllerEmployeesVisible == 1" v-loading="PageLoad.Rightload">
			<!-- 头部 -->
			<div class="Card2_top">
				<div class="Card2_top1 boxshadow">
					<el-button size="medium" icon="el-icon-back" @click="ControllerEmployeesVisible = 0">{{$t('Permission.Backtrack')}}</el-button>
				</div>
				<div class="Card2_top2 boxshadow">
					<!-- 搜索框 -->
					<div v-if="AuthorizerTypeValue!=0 && AuthorizerTypeValue!=2" class="right_top_search flex-items-center">
						<i class="el-icon-search"></i>
						<el-input size="medium" v-model="QueryAuthorizer.Value" :disabled="AuthorizerTypeValue=='5'" :placeholder="$t('Permission.SearchTips')"></el-input>
					</div>
					<div v-if="AuthorizerTypeValue==2" class="select_tree flex-items-center">
						<select-tree size="medium" style="height:36px" :placeholder="$t('SelectTree.Select')"  v-model="DepartmentId" :options="DepartmentList" :props="DepartmentProps" />
					</div>
					<!-- 条件类型 -->
					<div class="right_top_search_type flex-items-center">
						<el-button v-if="AuthorizerTypeValue!=2" slot="append" size="mini" class="el-icon-search" @click="FindAuthorizer()"></el-button>
						<el-button v-else slot="append" size="mini" class="el-icon-search" @click="DepartmentChange()"></el-button>
						<i></i>
						<el-select size="medium" style="width:150px" v-model="AuthorizerTypeValue" slot="prepend"  @change="AuthorizerTypeValueChange()">
							<el-option :label="$t('Permission.Number')" value="1"></el-option>
							<el-option :label="$t('Permission.Department')" value="2"></el-option>
							<el-option :label="$t('Permission.Name')" value="3"></el-option>
							<el-option :label="$t('Permission.Card')" value="4"></el-option>
							<el-option :label="$t('Permission.NotSynced')" value="5"></el-option>
						</el-select>
					</div>
				</div>
				<div class="Card2_top3 boxshadow">
					<el-button size="medium" icon="el-icon-circle-plus-outline" :disabled="!userGroupDetails.RoleControllers" @click="AddPermissionVisible()" type="primary">{{$t('Permission.AddPermission1')}}</el-button>
					<el-button size="medium" type="danger" style="margin-left: 20px;" slot="reference" icon="el-icon-remove-outline" :disabled="!userGroupDetails.RoleControllers" @click="DeleteTheAuthorized()">{{$t('Permission.BatchesDelete')}}</el-button>
					<el-button size="medium" icon="el-icon-refresh" :disabled="!userGroupDetails.RoleControllers" @click="SynchronizeSpecified()" style="margin-left: 20px;" type="warning">{{$t('Permission.BatchSync')}}</el-button>
				</div>
				<div class="Card2_top4 boxshadow">
					<el-button icon="el-icon-refresh-right" @click="RefurbishAuthorizer()" size="small">{{$t('Permission.Refresh')}}</el-button>
				</div>
			</div>
			<!-- 底部 -->
			<div class="content_right_bottom flex-center boxshadow">
				<div>
					<div class="content_right_bottom_title">{{$t('Permission.PermissionList')}}</div>
					<el-table @selection-change="handleSelectionChange" :data="AuthorizerList" height="100%">
						<el-table-column type="selection" align="center"></el-table-column>
						<el-table-column prop="number" :label="$t('Permission.Number')" align="center"></el-table-column>
						<el-table-column prop="departmentName" :label="$t('Permission.Department')" align="center"></el-table-column>
						<el-table-column prop="name" :label="$t('Permission.Name')" align="center"></el-table-column>
						<el-table-column v-if="ControllerType!=2" :label="$t('Permission.Schedule')" align="center">
							<template slot-scope="scope">
								<span>{{scope.row.templateName !='' && scope.row.templateName!=undefined ? scope.row.templateName:$t('Permission.Not')}}</span>
							</template>
						</el-table-column>
						<el-table-column v-if="ControllerType!=2" :label="$t('Permission.Card1')" align="center">
							<template slot-scope="scope">
								<el-tag>{{scope.row.q}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column :label="$t('Permission.Card2')" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.c =='' || scope.row.c == undefined" type="info">{{$t('Permission.Not')}}</el-tag>
								<el-tag v-else>{{scope.row.c}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column v-if="ControllerType!=2" :label="$t('Permission.Card3')" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.i =='' || scope.row.i == undefined" type="info">{{$t('Permission.Not')}}</el-tag>
								<el-tag v-else>{{scope.row.i}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column v-if="ControllerType==2" :label="$t('Permission.StatusDetails')" align="center">
							<template slot-scope="scope">
								<el-tooltip v-if="scope.row.photo==''" class="item" effect="dark" :content="$t('Permission.NoPhoto')" placement="left">
									<el-tag type="warning" effect="dark">{{$t('Permission.NoPhoto')}} <i class="el-icon-picture-outline"></i></el-tag>
								</el-tooltip>
								<el-tooltip v-else-if="(scope.row.status == '0' || scope.row.status == '') && scope.row.photoState==0" class="item" effect="dark" :content="$t('Permission.Synchronizing')" placement="left">
									<el-tag effect="dark">{{$t('Permission.Synchronizing')}} <i class="el-icon-loading"></i></el-tag>
								</el-tooltip>
								<el-tooltip v-else-if="scope.row.status == '1'" class="item" effect="dark" :content="$t('Permission.Synced')" placement="left">
									<el-tag  type="success" effect="dark">{{$t('Permission.Synced')}} <i class="el-icon-circle-check"></i></el-tag>
								</el-tooltip>
								<el-tooltip v-else-if="scope.row.photoState == '462' || scope.row.photoState == '475'" class="item" effect="dark" :content="$t('Permission.CardExists')" placement="left">
									<el-tag type="danger" effect="dark">{{$t('Permission.CardExists')}} <i class="el-icon-bank-card"></i></el-tag>
								</el-tooltip>
								<el-tooltip v-else-if="scope.row.photoState == '468'" class="item" effect="dark" :content="$t('Permission.PhotoError1')" placement="left">
									<el-tag type="danger" effect="dark">{{$t('Permission.NoFace')}} <i class="el-icon-s-custom"></i></el-tag>
								</el-tooltip>
								<el-tooltip v-else-if="scope.row.photoState == '478'" class="item" effect="dark" :content="$t('Permission.PhotoError2')" placement="left">
									<el-tag type="danger" effect="dark">{{$t('Permission.FaceExists')}} <i class="el-icon-s-release"></i></el-tag>
								</el-tooltip>
								<el-tooltip v-else class="item" effect="dark" :content="$t('Permission.SyncFailed')" placement="left">
									<el-tag type="danger" effect="dark">{{$t('Permission.SyncFailed')}} <i class="el-icon-warning"></i></el-tag>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column prop="status" :label="$t('Permission.SyncStatus')" align="center">
							<template slot-scope="scope">
								<i v-if="scope.row.status=='0' || scope.row.status==undefined" style="color: #E6A23C;font-size: 28px;" class="el-icon-warning"></i>
								<i v-else-if="scope.row.status=='1'" class="el-icon-success" style="color: #67C23A;font-size: 28px;"></i>
								<i v-else class="el-icon-error" style="color: #F56C6C;font-size: 28px;"></i>
							</template>
						</el-table-column>
						<el-table-column :label="$t('Permission.Operation')" align="center">
							<template slot-scope="scope">	
								<el-button size="mini" type="primary" v-if="taskType==1" :disabled="!userGroupDetails.RoleControllers" @click="ShowStoreyVisible(scope.row)">{{$t('Permission.Update')}}</el-button>
								<el-button size="mini" plain :disabled="!userGroupDetails.RoleControllers" @click="SynchronizeSpecified(scope.row.recordID)">{{$t('Permission.Sync')}}</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="content_right_bottom_page flex-items-center">
						<el-pagination @size-change="AuthorizerNumber" @current-change="AuthorizerPages" :page-sizes="[20, 50, 100]"
						:page-size="QueryAuthorizer.Count" :current-page="QueryAuthorizer.Page" layout="total, sizes, prev, pager, next, jumper" :total="AuthorizerListSize">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- 页面3 -->
		<div class="Card3" v-else v-loading="PageLoad.deleteLoad">
			<!-- 头部 -->
			<div class="Card3_top">
				<div class="Card3_top1 boxshadow">
					<el-button size="medium" icon="el-icon-back" @click="ControllerEmployeesVisible = 0">{{$t('Permission.Backtrack')}}</el-button>
				</div>
				<div class="Card2_top4 boxshadow">
					<el-button icon="el-icon-refresh-right" @click="ReFrushGetDelData()" size="small">{{$t('Permission.Refresh')}}</el-button>
				</div>
			</div>
			<!-- 底部 -->
			<div class="content_right_bottom flex-center boxshadow">
				<div>
					<div class="content_right_bottom_title">{{$t('Permission.Card3Title')}}</div>
					<el-table :data="deleteEmpList" height="100%">
						<el-table-column prop="controllerId" :label="$t('Permission.controllerId')" width="200" align="center" />
						<el-table-column prop="card" :label="$t('Permission.card')" width="580" align="center"/>
						<el-table-column prop="cardType" :label="$t('Permission.cardType')" width="380" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.cardType==1" type="info">{{$t('Permission.Card1')}} <i class="el-icon-menu"></i></el-tag>
								<el-tag v-else-if="scope.row.cardType==2" type="info">{{$t('Permission.Card2')}} <i class="el-icon-bank-card"></i></el-tag>
								<el-tag v-else-if="scope.row.cardType==3" type="info">{{$t('Permission.Card3')}} <i class="el-icon-key"></i></el-tag>
							</template>
						</el-table-column>
						<el-table-column :label="$t('Permission.status')" width="400" align="center">
							<template slot-scope="scope">
								<i v-if="scope.row.status=='0'" style="color: #E6A23C;font-size: 28px;" class="el-icon-warning"></i>
								<i v-else-if="scope.row.status=='1'" class="el-icon-success" style="color: #67C23A;font-size: 28px;"></i>
								<i v-else class="el-icon-error" style="color: #F56C6C;font-size: 28px;"></i>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<div class="content_right_bottom_page flex-items-center">
						<el-pagination 
						@size-change="handelDelListSize"
						@current-change="handelDelCurrentList"
						:page-sizes="[20, 50, 100,200]"
						:page-size="pageSize" :current-page="pageNum" layout="total, sizes, prev, pager, next, jumper"
						:total="TableDataCount"
						/>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 添加权限 -->
		<el-dialog :title="$t('Permission.AddPermission1')" top="2%" destroy-on-close :visible.sync="EditPermissionVisible" width="65%">
		  <div style="display: flex;">
			<div style="width: 23%;margin-right: 2%;">
				<el-card shadow="hover" v-loading="DepartmentTreeload">
					<div class="Area_input">
						<el-input size="small" :placeholder="$t('Permission.SearchDepartment')" v-model="filterTextDepartment"></el-input><el-button @click="Department();ChooseDepartment('');" style="margin-left: 5px;" size="mini">{{$t('Permission.Refresh')}}</el-button>
					</div>
					<el-tree :data="DepartmentList" :filter-node-method="filterNodeDepartment" ref="Department"
					 default-expand-all :expand-on-click-node="false" icon-class="el-icon-s-promotion" style="height:247px;overflow:auto;">
						<span class="custom-tree-node" slot-scope="{ data }" style="height: 100%;" @click="ChooseDepartment(data.departmentCode)" >
							<div style="width: 100%;">{{ data.departmentName }}</div>
						</span>
					</el-tree>
				</el-card>
				<el-card shadow="hover" style="margin-top: 62px;">
					<div class="Area_input">
						<el-input size="small" :placeholder="$t('Permission.SearchArea')" v-model="filterTextArea"></el-input><el-button @click="Equipment('')" style="margin-left: 5px;" size="mini">{{$t('Permission.Refresh')}}</el-button>
					</div>
					<el-tree v-loading="Treeload" :data="AreaList" :filter-node-method="filterNodeArea" ref="Area"
					 default-expand-all :expand-on-click-node="false" icon-class="el-icon-s-promotion" style="height:247px;overflow:auto;">
						<span class="custom-tree-node" slot-scope="{ data }" style="height: 100%;" @click="Equipment(data.controllersAreaCode)">
							<div style="width: 100%;">{{ data.regionName }}</div>
						</span>
					</el-tree>
				</el-card>
			</div>
			<div style="width:78%" >
				<ShuttleBox v-loading="EmployeesTreeload" :Data1="EmployeesData" :checkboxName1="$t('Permission.Unselected')" :checkboxName2="$t('Permission.HasChosen')" :ButtomName1="$t('Permission.AddPermission2')" :ButtomName2="$t('Permission.DeletePermission2')" @model="AddEmployeesValue"></ShuttleBox>
				<div style="display: flex;align-items: center;padding: 15px 0px;">
					<el-pagination background layout="total,prev, pager, next" :page-size="1000" @current-change="EmployeesNumber" :total="EmployeesCount"></el-pagination>
				</div>
				<div class="controller_card" v-loading="EquipmentTreeload">
					<div class="controller_item" v-if="EquipmentData.length>0">
						<div class="item_title"><el-checkbox v-model="checkAllController" @change="changeCheckAllController">{{$t('Permission.selectAllController')}}</el-checkbox></div>
					</div>
					<div class="controller_item" v-for="(item,index) in EquipmentData" :key="item.key">
						<div class="item_title"><el-checkbox v-model="item.check" @change="changeCheckAllFloor(index,$event)">{{item.label}}</el-checkbox></div>
						<div class="lift" v-if="item.floorList.length>0">
							<div v-for="(floorItem,floorIndex) in item.floorList" :key="'floor'+floorIndex">
								<el-checkbox class="lift_item"  v-model="floorItem.check" :disabled="floorItem.status==0" @change="changecheckedFloor(index)" border>
									<div class="floor_item_checked" v-if="floorItem.label!=''">{{floorItem.label}}</div>
									<div class="floor_item_checked" v-else>{{ floorItem.index+" "+$t('Permission.Floor') }}</div>
								</el-checkbox>
							</div>
						</div>
					</div>
				</div>
			</div>
		  </div>
		   <span slot="footer" class="dialog-footer">
			    <el-select style="margin-right: 15px;" clearable v-model="SelectTimeTemplate" :placeholder="$t('Permission.SelectTimeTemplate')">
					<el-option 
					v-for="item in TimeTemplate"
					:key="item.templatesId"
					:label="item.templateName"
					:value="item.templatesId">
					</el-option>
				</el-select>
				<el-button :loading="SaveButton" @click="SaveTheAuthorization()" type="primary">{{$t('Permission.Confirm')}}</el-button>
		    </span>
		</el-dialog>

		<!-- 修改楼层弹框 -->
		<el-dialog :title="$t('Permission.Update')" top="2%" destroy-on-close :visible.sync="EditStoreyVisible" width="56%">
			<div style="height: 650px;">
				<div class="lift">
					<div>
						<el-checkbox :label="0" class="lift_item" @change="employeeCheckedAllFloor($event)" border>
							<div class="floor_item_checked">{{$t('Permission.selectAll')}}</div>
						</el-checkbox>
					</div>
					<div v-for="(floorItem,floorIndex) in controllerFloorList" @change="employeeCheckedFloor($event)" :key="floorIndex">
						<el-checkbox class="lift_item" v-model="floorItem.check" :disabled="floorItem.status==0" border>
							<div class="floor_item_checked" v-if="floorItem.label!=''">{{floorItem.label}}</div>
							<div class="floor_item_checked" v-else>{{ floorItem.index+" "+$t('Permission.Floor') }}</div>
						</el-checkbox>
					</div>
				</div>
		   </div>
		   <span slot="footer" class="dialog-footer">
				<el-button :loading="SaveButton" @click="SaveCheckedFloor()" type="primary">{{$t('Permission.Confirm')}}</el-button>
		    </span>
		</el-dialog>

	</div>
</template>

<script>
	import SelectTree from '../components/SelectTree.vue';
	import ShuttleBox from '../components/ShuttleBox.vue';
	import 'driver.js/dist/driver.min.css'
	import Driver from 'driver.js'

	
	export default {
		name: 'about',
		components: {
			SelectTree,ShuttleBox,
		},
		data() {
			return {
				//权限组
				userGroupDetails: {
					RoleArea: false, //区域操作权
					RoleControllers: false //设备操作权
				},
				// 页面加载
				PageLoad:{
					//左侧加载状态
   					Leftload: false, 
					//右侧加载状态
					Rightload: false, 
					//同步删除加载状态
					deleteLoad: false,
				},
				//同步删除分页数据
				delData:{},
				//同步删除列表分页
				pageNum:1,
				pageSize:20,
				TableDataCount:0,
				//分页需要记录的设备Id
				delPageConId:0,

				//区域信息
				ControllersAreaCode:'',//选中的区域编码
				ControllersAreaId:'',//选中的区域Id
				suspensionStyle: {}, //区域列表样式
				Treeload: false, //树形Loading加载状态
				filterText: '', //区域搜索
				AreaList: [], //区域列表

				//设备信息
				Tableload: false, //表格Loading加载状态
				TableName: '', //表格标题
				TypeValue:'1',
				QueryEquipment:{ //设备查询参数
					ControllersAreaCode:'',//区域编码
					Count:20,//分页条数
					Pages:1,//分页页数
					TypeValue:'0',//按条件查询下拉选择
					Value: ''//按条件查询值
				},
				SystemTime:'',//服务端时间
				EquipmentListSize:0,
				EquipmentList:[],//设备
				deleteEmpList:[],//同步删除表格数据
				// 数据默认字段
				EquipmentProps: {
					parent: 'parentControllersAreaId', // 父级唯一标识
					value: 'controllersAreaId', // 唯一标识
					label: 'regionName', // 标签显示
					children: 'children' // 子级
				},
				ControllerEmployeesVisible:0,
				AuthorizerTypeValue:'1',
				QueryAuthorizer:{
					ControllerId:0,//设备Id
					DepartmentCode:'',//部门编码
					Count:20,//分页条数
					Pages:1,//分页页数
					TypeValue:'0',//按条件查询下拉选择
					Value: ''//按条件查询值
				},
				AuthorizerListSize:0,//指定设备的授权人员数量
				AuthorizerList:[],//授权人员
				ControllerType:0,//设备类型
				
				DepartmentList:[],//部门
				DepartmentProps: {
					parent: 'parentDepartmentId', // 父级唯一标识
					value: 'departmentCode', // 唯一标识
					label: 'departmentName', // 标签显示
					children: 'children', // 子级
				},
				DepartmentTreeload:false,//部门加载状态
				DepartmentId:'', // 部门Id
				filterTextDepartment:'',//部门查询条件
				filterTextArea:'',//区域搜索条件
				EditPermissionVisible:false ,//编辑权限弹框
				EmployeesPages:1,//人员分页页数
				EmployeesCount:0,//人员总数
				DepartmentCode:"",//部门编码
				EmployeesTreeload:false,
				EmployeesData: [],//穿梭框人员列表
				EmployeesValue: [],//穿梭框选中人员列表
				EquipmentTreeload:false,
				EquipmentData:[],//穿梭框设备列表
				//是否全选设备
				checkAllController:false,
				SelectTimeTemplate:'',//选择的时间模板
				TimeTemplate:[],//时间模板
				SaveButton:false,//保存按钮状态
				IdData: "", //选中的人员ID
				RecordData: "", //选中的同步ID
				EditStoreyVisible:false,//编辑楼层弹框
				//设备楼层
				controllerFloorList:[],
				//楼层编码
				controllerFloorCode:"",
				//被操作楼层的人员
				designeeEmployeeId:0,
				CheckFloorList:[],//修改选中的楼层
				ControllerLow:0,//设备最底层
				ControllerHigh:0,//设备最高层
				RecordId:0,//记录Id
				ControllerId:0,//设备Id
				LiftFloorCode:"",//楼层编码
				//设备类别
				taskType:0,
				EmployeesfilterMethod(query, item) {
					return item.pinyin.indexOf(query) > -1;
				},
				EquipmentfilterMethod(query, item) {
					return item.pinyin.indexOf(query) > -1;
				}
			};
		},
		watch: {
			//区域查询过滤使用
			filterText(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.tree.filter(val);
			},
			
			//部门查询过滤使用
			filterTextDepartment(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.Department.filter(val);
			},
			
			//区域查询过滤使用
			filterTextArea(val) {
				//每当输入的数据有变化就触发原生的过滤节点这个函数
				this.$refs.Area.filter(val);
			}
		},
		//离开界面事件
		destroyed () {
			clearTimeout(this.timer) //清除定时器
		},
		mounted() {
			//获取操作权
			this.Role();

			//查询区域
			this.Area();
			
			//查询设备
			this.Controllers(1);

			let cookiePermissioGuide = this.getCookie()
			 if(cookiePermissioGuide === undefined){
				setTimeout(()=>{this.permission_guide()},1000)
			}
			
			//定时器
			this.timer = setInterval( () => {
				this.timerControllers()
			}, 5000)
		},
		methods: {
			//权限引导
			permission_guide () {
			let driver = new Driver({
				className: 'scoped-class', // 包裹driver.js弹窗的类名 className to wrap driver.js popover
				animate: true, // 高亮元素改变时是否显示动画 Animate while changing highlighted element
				opacity: 0.75, // 背景透明度(0 表示只有弹窗并且没有遮罩) Background opacity (0 means only popovers and without overlay)
				padding: 10, //  元素与边缘的距离 Distance of element from around the edges
				allowClose: false, // 是否允许点击遮罩时关闭 Whether clicking on overlay should close or not
				overlayClickNext: false, // 是否允许点击遮罩时移到到下一步 Should it move to next step on overlay click
				doneBtnText: '完成', // 最终按钮上的文本 Text on the final button
				closeBtnText: '关闭', // 当前步骤关闭按钮上的文本 Text on the close button for this step
				nextBtnText: '下一步', // 当前步骤下一步按钮上的文本 Next button text for this step
				prevBtnText: '上一步', // 当前步骤上一步按钮上的文本 Previous button text for this step
				showButtons: true, // 是否在底部显示控制按钮 Do not show control buttons in footer
				keyboardControl: true, // 是否允许通告键盘控制(escape关闭，箭头键用于移动)Allow controlling through keyboard (escape to close, arrow keys to move)
				scrollIntoViewOptions: {}, //  `scrollIntoView()` 方法的选项 We use `scrollIntoView()` when possible, pass here the options for it if you want any
			})
			// Define the steps for introduction
			let steps = [
				{
				element: '.left_value_input',
				popover: {
					title: this.$t('Permission.PermissionGuideTitle1'),
					description: this.$t('Permission.PermissionGuideContent1'),
					position: 'right'
				}
				},
				{
				element: '.left_value_tree',
				popover: {
					title: this.$t('Permission.PermissionGuideTitle2'),
					description: this.$t('Permission.PermissionGuideContent2'),
					position: 'right'
				}
				},
				{
				element: '.content_right_top',
				popover: {
					title: this.$t('Permission.PermissionGuideTitle3'),
					description: this.$t('Permission.PermissionGuideContent3'),
					position: 'bottom'
				}
				},
				{
				element: '.content_right_bottom',
				popover: {
					title: this.$t('Permission.PermissionGuideTitle4'),
					description: this.$t('Permission.PermissionGuideContent4'),
					position: 'left'
				}
				}
			]
			driver.defineSteps(steps)
			// Start the introduction
			driver.start()
			// 保存
			 this.setCookie()
			},

			
			setCookie(){
				//设置10年后过期
				let expires = new Date();
				expires.setTime(expires.getTime()+10*365*24*60*60*1000);
				document.cookie = "permissionGuide="+escape('1')+";expires="+expires.toGMTString();
			},
			getCookie(){
				//获取cookie值
				let str = document.cookie;
				//将值切割成数组
				let arr = str.split(";");
				let permissionGuide;
				//遍历数组
				for(var i=0;i<arr.length;i++){
					var value = arr[i].split("=");
					//避免输入中含有空格，消除文本框空格
          			value[0] = value[0].replace(/\s+/g, "");
					if(value[0] == 'permissionGuide'){
						permissionGuide = value[1];
					}
				}
				return permissionGuide;
			},

			//分页条数
			handelDelListSize(e){
				this.pageSize = e;
				this.getDeleteECData(this.delPageConId);
   			},
    		//分页页数
    		handelDelCurrentList(e){
      			this.pageNum = e;
      			this.getDeleteECData(this.delPageConId);
    		},
			
			//照片
			Equipmentimg(photo) {
				try {
					return require('../assets/images/Equipment/' + photo+'.png')
				} catch (e) {
					return ''
				}
			},

			//获取操作权
			Role() {
				//获取权限组
				var userGroupDetails = JSON.parse(window.sessionStorage.getItem('userGroupDetails'))
				for (var i = 0; i < userGroupDetails.length; i++) {
					if (userGroupDetails[i].roleName == "ControllersArea") { //区域操作权
						this.userGroupDetails.RoleArea = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
					if (userGroupDetails[i].roleName == "Controllers") { //设备操作权
						this.userGroupDetails.RoleControllers = userGroupDetails[i].roleOperateValue == "1" ? true : false;
					}
				}
			},

			//查询区域
			async Area() {
				try 
				{
					this.PageLoad.Leftload = true;
					var {data: res} = await this.$http.post('ControllersArea')
					if (res.resultCode == 1) 
					{
						this.AreaList = JSON.parse(res.body);
					}
					this.PageLoad.Leftload = false;
				} 
				catch (err) 
				{
					this.PageLoad.Leftload = false;
					console.log(err)
				}
			},

			//区域过滤
			filterNode(value, data) {
				if (!value) return true;
				return data.regionName.indexOf(value) !== -1;
			},

			//选择区域
			choose(Data) {
				this.ControllersAreaId = Data.controllersAreaId;
				this.ControllersAreaCode = Data.controllersAreaCode;
				this.QueryEquipment.ControllersAreaCode = Data.controllersAreaCode;
				this.TableName = Data.regionName;
				this.Controllers(1);
			},
			
			//查询设备
			async Controllers(loadstate){
				try
				{	
					if(loadstate==1)
					{
						this.PageLoad.Rightload = true;
					}
					var {data: res} = await this.$http.post('ControllersEmployees',this.QueryEquipment);
					if (res.resultCode == 1) //成功
					{
						var Data = JSON.parse(res.body);
						this.SystemTime = Data.DataTiem;
						this.EquipmentList = Data.Controllers;
						if(this.EquipmentList.length>0)
						{
							this.EquipmentListSize = this.EquipmentList[0].size;
						}
						else
						{
							this.EquipmentListSize = 0;
						}
						this.timerControllers();
					}
					this.PageLoad.Rightload = false;
				}
				catch (err)
				{
					this.PageLoad.Rightload = false;
					console.log(err)
				}
			},
			
			//查询同步信息(定时)
			async timerControllers(){
				try
				{
					if(this.EquipmentList.length>0)
					{
						if(this.ControllerEmployeesVisible==0)
						{
							var ControllerId = [];
							for(var i=0;i<this.EquipmentList.length;i++)
							{
								ControllerId[i] = this.EquipmentList[i].controllersId;
							}
							var {data: res} = await this.$http.post('SynchronizationInformation',{"ControllerId":ControllerId});
							if (res.resultCode == 1) //成功
							{
								var Data = JSON.parse(res.body);
								this.SystemTime = Data.SystemTime;
								for(var i=0;i<this.EquipmentList.length;i++)
								{
									for(var j=0;j<Data.ControllersId.length;j++)
									{
										if(this.EquipmentList[i].controllersId == Data.ControllersId[j].controllerId)
										{
											this.EquipmentList[i].haveSynchronous = Data.ControllersId[j].synchronization;
											this.EquipmentList[i].sum = Data.ControllersId[j].sum;
											this.EquipmentList[i].online = Data.ControllersId[j].syncTime;
										}
									}
								}
							}
						}	
					}
				}
				catch (err)
				{
					console.log(err)
				}
			},
			
			//百分比
			Percentage(num, total) { 
			    if (num == 0 || total == 0){
			        return 0;
			    }
				var ratio = (num / total * 100);
				if(ratio.toString().indexOf(".") != -1 )
				{
					let index = ratio.toString().indexOf('.');
					ratio = Number(ratio.toString().slice(0, index + 3));
				}
			    return ratio;
			},
			
			//分页条数
			handleSizeChange(val) {
				this.QueryEquipment.Count = val;
				this.Controllers();
			},
			
			//分页页数
			handleCurrentChange(val) {
				this.QueryEquipment.Pages = val;
				this.Controllers();
			},
			
			//所有设备
			AllEquipment(){
				this.TableName = "";
				this.QueryEquipment.ControllersAreaCode = "";
				this.Controllers();
			},
			
			//按条件查找
			Find(){
				if(this.TypeValue!='')
				{
					this.QueryEquipment.TypeValue = this.TypeValue
				}
				this.QueryEquipment.Pages = 1;
				this.Controllers();
			},
			
			//条件选择
			TypeValueChange(){
				this.QueryEquipment.Pages = 1;
				this.QueryEquipment.Value=''
				this.QueryEquipment.TypeValue = this.TypeValue
				if(this.TypeValue == '')
				{
					this.Controllers();
				}
			},
			
			//设备类型
			ControllerSubType(Type){
				if(Type==1)
				{
					return this.$t('Permission.ControllerType1');
				}
				else if(Type==2)
				{
					return this.$t('Permission.ControllerType2');
				}
			},
			
			//设备是否在线
			Online(Data){
				if(Data!="" && Data!=undefined)
				{
					try
					{
						var SystemTime = this.SystemTime;
						var startTime = new Date(Date.parse(Data));
						var date = new Date(Date.parse(SystemTime));
						var min = date.getMinutes();
						date.setMinutes(min - 1);
						if(startTime>=date)
						{
							return true;
						}
						else
						{
							return false;
						}
					}
					catch(e)
					{
						return false;
					}
				}
				else
				{
					return false;
				}
			},
			
			//查看授权
			CheckTheAuthorization(Data){
				this.ControllerType = Data.controllerType;
				this.ControllerEmployeesVisible = 1;
				var QueryAuthorizer = {
					ControllerId:Data.controllersId,
					DepartmentCode:this.QueryAuthorizer.DepartmentCode,
					Count:20,
					Pages:1,
					TypeValue:this.QueryAuthorizer.TypeValue,
					Value: this.QueryAuthorizer.Value
				};
				this.taskType = Data.taskType;
				this.ControllerLow = Data.low,
				this.ControllerHigh = Data.high,
				this.ControllerId = Data.controllersId,
				this.QueryAuthorizer = QueryAuthorizer;
				this.AuthorizerList = [];
				this.Authorizer();
			},

			//同步删除查看
			CheckDeleteAuthorization(Data){
				//切换到页面3
				this.ControllerEmployeesVisible = 2;
				//设备ID
				let controllerId = Data.controllersId
				this.delPageConId = controllerId
				
				//查询后台同步删除数据
				this.getDeleteECData(controllerId)
			},

			//后台同步删除数据查询接口
			async getDeleteECData(controllerId){
				//查询后台同步删除数据
				try
				{
					this.PageLoad.deleteLoad = true;
					var {data: res} = await this.$http.post('getDeleteECData',{"pageNum":this.pageNum,"pageSize":this.pageSize,"controllerId":controllerId});
					this.delData = JSON.parse(res.body)
					if (res.resultCode == 1) //成功
					{
						this.TableDataCount = this.delData.total;
						this.deleteEmpList = this.delData.list;
					}
					this.PageLoad.deleteLoad = false;
				}
				catch (err)
				{
					this.PageLoad.deleteLoad = false;
					console.log(err)
				}
			},

			//刷新同步删除查询数据接口
			ReFrushGetDelData(){
				this.getDeleteECData(this.delPageConId)
			},
			
			//按条件查找
			FindAuthorizer(){
				if(this.AuthorizerTypeValue!='')
				{
					this.QueryAuthorizer.TypeValue = this.AuthorizerTypeValue
				}
				this.QueryAuthorizer.Pages = 1;
				this.Authorizer();
			},
			
			//条件选择
			AuthorizerTypeValueChange(){
				this.QueryAuthorizer.Pages = 1;
				this.QueryAuthorizer.DepartmentCode = ''
				this.QueryAuthorizer.Value=''
				this.QueryAuthorizer.TypeValue = this.AuthorizerTypeValue
				if(this.AuthorizerTypeValue == '')
				{
					this.Authorizer();
				}
				else if(this.AuthorizerTypeValue == 2)
				{
					this.Department();
				}
				else if(this.AuthorizerTypeValue == 5)
				{
					this.QueryAuthorizer.Value='未同步'
					this.Authorizer();
				}
			},
			
			//分页条数
			AuthorizerNumber(val) {
				this.QueryAuthorizer.Count = val;
				this.Authorizer();
			},
			
			//分页页数
			AuthorizerPages(val) {
				this.QueryAuthorizer.Pages = val;
				this.Authorizer();
			},
			
			//刷新授权人员
			RefurbishAuthorizer(){
				this.Authorizer();
			},
			
			//查询授权人员
			async Authorizer(){
				try
				{
					this.PageLoad.Rightload = true;
					var {data: res} = await this.$http.post('Authorizer',this.QueryAuthorizer);
					if (res.resultCode == 1) //成功
					{
						this.AuthorizerList = JSON.parse(res.body);
						if(this.AuthorizerList.length>0)
						{
							this.AuthorizerListSize = this.AuthorizerList[0].size;
						}
						else
						{
							this.AuthorizerListSize = 0;
						}
					}
					this.PageLoad.Rightload = false;
				}
				catch (err)
				{
					this.PageLoad.Rightload = false;
					console.log(err)
				}
			},
			
			//查询部门
			async Department() {
				try 
				{
					this.DepartmentTreeload = true;
					var {data: res} = await this.$http.post('Departments')
					if (res.resultCode == 1)//成功
					{ 
						this.DepartmentList = JSON.parse(res.body);
					}
					this.DepartmentTreeload = false;
				} 
				catch (err)
				{
					this.DepartmentTreeload = false;
					console.log(err)
				}
			},
			
			//授权记录详情（部门选择）
			DepartmentChange(){
				this.QueryAuthorizer.DepartmentCode =  this.DepartmentId;
				this.Authorizer();
			},
			
			//添加权限弹框
			AddPermissionVisible(){
				this.EmployeesPages = 1;
				this.EquipmentData = [];
				this.EmployeesValue = [];
				this.SelectTimeTemplate = '';
				this.EditPermissionVisible = true;
				this.Department();//部门
				this.ChooseDepartment('');//查询人员
				this.Equipment('');//设备
				this.TimeTemplates();//时间表
			},
			
			//选择部门
			ChooseDepartment(DepartmentCode){
				this.DepartmentCode = DepartmentCode;
				this.EmployeesPages = 1;
				this.Employees();
			},
			
			//查询人员
			async Employees() {
				try
				{
					this.EmployeesTreeload = true;
					var {data: res} = await this.$http.post('SelectEmployees', {"DepartmentCode": "" + this.DepartmentCode + "","Count":1000,"Pages":this.EmployeesPages,"Type":0,"Value":"","EmployeeId":0})
					if (res.resultCode == 1) {
						var body = JSON.parse(res.body);
						var Employees = [];
						this.EmployeesCount = body.length>0 ? body[0].size:0;
						for(var i = 0;i<body.length;i++)
						{
							Employees[i] = { key: body[i].employeeId,label:body[i].number+" - "+body[i].name,pinyin:body[i].number+","+body[i].name+","+body[i].q+","+body[i].c+","+body[i].k}
						}
						this.EmployeesData = Employees;
					}
					this.EmployeesTreeload = false;
				}
				catch (err)
				{
					this.EmployeesTreeload = false;
					console.log(err)
				}
			},
			
			//人员分页页数
			EmployeesNumber(val) {
				this.EmployeesPages = val;
				this.Employees();
			},
			
			//查询设备
			async Equipment(areaCode){
				try
				{
					this.EquipmentTreeload = true;
					var {data: res} = await this.$http.post('queryEquipmentInfoForPermission',{areaCode:areaCode});
					if (res.resultCode == 1) //成功
					{
						var newControllerList = [];
						var controllerList = JSON.parse(res.body);
						for(var i = 0;i<controllerList.length;i++)
						{
							newControllerList[i] = { 
								check:false,
								floorCode:"",
								key:controllerList[i].controllerId,
								floorList:controllerList[i].floorList,
								label:controllerList[i].controllerId+" - "+controllerList[i].controllerName+" - "+controllerList[i].location
							}
						}
						this.EquipmentData = newControllerList;
					}
					this.EquipmentTreeload = false;
				}
				catch (err)
				{
					this.EquipmentTreeload = false;
					console.log(err)
				}
			},

			//全选设备
			changeCheckAllController(status){
				var controllerList = this.EquipmentData;
				for(var i=0;i<controllerList.length;i++){
					var checkFloor = [];
					controllerList[i].check = status;
					var floorList = controllerList[i].floorList;
					for(var k=0;k<floorList.length;k++){
						if(floorList[k].status==0) {
							floorList[k].check = false;
							continue
						};
						floorList[k].check = status;
						if(status){
							checkFloor[checkFloor.length] = floorList[k].index;
						}
					}
					controllerList[i].floorCode = this.listInfloorCode(checkFloor);
				}
			},

			//全选楼层
			changeCheckAllFloor(index,status){
				var checkFloor = [];
				var floorList = this.EquipmentData[index].floorList;
				for(var i=0;i<floorList.length;i++){
					if(floorList[i].status==0) continue;
					floorList[i].check = status;
					if(status){
						checkFloor[checkFloor.length] = floorList[i].index;
					}
				}
				this.EquipmentData[index].floorCode = this.listInfloorCode(checkFloor);
			},
			
			//选择楼层
			changecheckedFloor(index){
				var checkFloor = [];
				var checkAllController = false;
				var floorList = this.EquipmentData[index].floorList;
				var newFloorList = [];
				for(var i=0;i<floorList.length;i++){
					newFloorList[newFloorList.length] = floorList[i];
					if(newFloorList[i].status==0) continue;
					if(newFloorList[i].check){
						checkAllController = true;
						checkFloor[checkFloor.length] = newFloorList[i].index;
					};
				}
				this.EquipmentData[index].floorList = newFloorList;
				this.EquipmentData[index].check = checkAllController;
				this.EquipmentData[index].floorCode = this.listInfloorCode(checkFloor);
			},
			//楼层数组转换楼层code
			listInfloorCode(floorList){
				var floorCode = "";
				for(var i=-8;i<121;i++){
					if(i!=0){
						var Status = false;
						for(var k=0;k<floorList.length;k++){
							if(i==floorList[k]){
								floorCode = floorCode+"1";
								Status = true;
								break;
							}
						}
						if(!Status) floorCode = floorCode+"0";
					}
				}
				var floorCode16 = "";
				floorCode = floorCode.split("").reverse().join("");
				for(var i=0;i<floorCode.length/8;i++){
					var Code = floorCode.substring(i*8,(i+1)*8);
					var Code10 = parseInt(Code,2);//转10进制
					var Code16 = Code10.toString(16);//转16进制
					if(Code16.length<2) Code16 = "0"+Code16;
					floorCode16 = Code16+floorCode16;
				}
				return floorCode16;
			 },
			
			//部门过滤
			filterNodeDepartment(value, data) {
				if (!value) return true;
				return data.departmentName.indexOf(value) !== -1;
			},
			
			//区域过滤
			filterNodeArea(value, data) {
				if (!value) return true;
				return data.regionName.indexOf(value) !== -1;
			},
			
			//查询时间模板
			async TimeTemplates() {
				try
				{
					var {data: res} = await this.$http.post('TimeTemplates')
					if (res.resultCode==1) 
					{
						this.TimeTemplate = JSON.parse(res.body)
					}
				}
				catch(err)
				{
					console.log(err)
				}
			},
			
			//保存授权
			async SaveTheAuthorization(){
				this.SaveButton = true;
				var TemplatesId = 0;
				var EquipmentValue = [];
				var equipmentData = this.EquipmentData;
				for(var i=0;i<equipmentData.length;i++){
					if(equipmentData[i].check){
						EquipmentValue[EquipmentValue.length]={
							ControllerId:equipmentData[i].key,
							LiftFloorCode:equipmentData[i].floorCode,
						}
					}
				}
				if(this.EmployeesValue.length>0 && EquipmentValue.length>0)
				{
					if(this.SelectTimeTemplate!="")
					{
						TemplatesId = this.SelectTimeTemplate;
					}
					var {data: res} = await this.$http.post('AddPermission',{"TemplatesId":TemplatesId,"Controller":EquipmentValue,"EmployeeId":this.EmployeesValue});
					if(this.FilteringOperation(res))
					{
						if(res.resultCode == 1)
						{
							this.$message({ message:this.$t('Permission.AddSucceed'),type: 'success',offset:10});
							this.EditPermissionVisible = false;
							if(this.ControllerEmployeesVisible == 1)
							this.Authorizer();
							this.Controllers();
							
						}
					}
					this.SaveButton = false;
				}
				else
				{
					this.$message({message: this.$t('Permission.SelectEmployeeController'),type: 'warning',offset:105});
					this.SaveButton = false;
				}
			},

			//显示修改楼层弹框
			async ShowStoreyVisible(e){
				this.RecordId = e.recordID;
				this.EditStoreyVisible = true;
				var employeeId = e.employeeId;
				this.designeeEmployeeId = employeeId;
				var controllerId = this.ControllerId;
				var {data: res} = await this.$http.post('queryEmpLiftPermission ',{"controllerId":controllerId,"employeeId":employeeId});
				if (res.resultCode==1) 

				{
					var floorData = JSON.parse(res.body)
					var floorCode = floorData.floorCode;
					var checkFloorList = this.floorCodeInList(floorCode);
					var floorList = floorData.list;
					for(var i=0;i<floorList.length;i++){
						var status = checkFloorList.some(obj => obj == floorList[i].index);
						floorList[i].check = status;
					}
					this.controllerFloorCode = floorCode;
					this.controllerFloorList = floorList;
				}
			},
			//全选楼层（指定员工）
			employeeCheckedAllFloor(status){
				var checkFloor = [];
				var controllerFloorList = this.controllerFloorList;
				for(var i=0;i<controllerFloorList.length;i++){
					if(controllerFloorList[i].status==0) continue;
					controllerFloorList[i].check = status;
					checkFloor[checkFloor.length] = controllerFloorList[i].index;
				}
				this.controllerFloorCode = this.listInfloorCode(checkFloor);
			},

			//选择楼层（指定员工）
			employeeCheckedFloor(status){
				var checkFloor = [];
				var controllerFloorList = this.controllerFloorList;
				for(var i=0;i<controllerFloorList.length;i++){
					if(controllerFloorList[i].status==0) continue;
					if(controllerFloorList[i].check){
						checkFloor[checkFloor.length] = controllerFloorList[i].index;
					};
				}
				this.controllerFloorCode = this.listInfloorCode(checkFloor);
			},

			///楼层编码转数组
			floorCodeInList(floorCode){
				var newFloorCode = "";
				for(var i=0;i<floorCode.length-1;i+=2){
					var code = floorCode.substring(i,i+2);
					code = parseInt(code,16).toString(2);
					var count = (8-code.length);
					for(var k=0;k<count;k++)
					{
						code = "0" + code;
					}
					newFloorCode = code+newFloorCode;
				}
				//翻转顺序
				newFloorCode = newFloorCode.split("").reverse().join("");
				var OriginalList = newFloorCode.split('');
				var floorCodeList = [];
				var index = -8;
				for(var i=0;i<128;i++)
				{
					if(index==0) index=1;
					if(OriginalList[i]==1)
					{
						floorCodeList[floorCodeList.length] = index;
					}
					index++;
				}
				return floorCodeList;
			},

			//保存人员楼层权限
			async SaveCheckedFloor(){
				var controllerId = this.ControllerId;
				var employeeId = this.designeeEmployeeId;
				var liftFloorCode = this.controllerFloorCode;
				var parameter = {controllerId:controllerId,employeeId:employeeId,liftFloorCode:liftFloorCode}
				var {data: res} = await this.$http.post('updateFloorCodePermission',parameter);
				if(this.FilteringOperation(res))
				{
					if(res.resultCode == 1)
					{
						this.Authorizer();
						this.EditStoreyVisible = false;
						this.$message({ message:this.$t('Permission.UpdateSucceed'),type: 'success',offset:10});
					}
				}
			},
			
			//保存选中的
			handleSelectionChange(val) {
				this.IdData = "";
				var IdData = "";
				for (var i = 0; i < val.length; i++) {
					IdData = IdData+val[i].employeeId+",";
				}
				if(IdData!="")
				{
					IdData = IdData.substr(0, IdData.length - 1);
					this.IdData = IdData;
				}
				
				this.RecordData = [];
				var RecordData = [];
				for (var i = 0; i < val.length; i++) {
					RecordData[i] = val[i].recordID
				}
				this.RecordData = RecordData;
			},
			
			//删除授权
			async DeleteTheAuthorized(){
				if(this.IdData!="")
				{
					console.log(this.IdData)
					var {data: res} = await this.$http.post('DeletePermission',{ControllerId:this.QueryAuthorizer.ControllerId,EmployeeId:this.IdData});
					if(this.FilteringOperation(res))
					{
						if(res.resultCode == 1)
						{
							this.$message({ message: this.$t('Permission.DeleteSucceed'),type: 'success',offset:10});
							var IdData = this.IdData.split(",");
							if((this.AuthorizerList.length-IdData.length)==0 && this.QueryAuthorizer.Pages>1)
							{
								this.QueryAuthorizer.Pages = this.QueryAuthorizer.Pages-1;
							}
							this.Authorizer();
							this.Controllers();
						}
					}
				}
				else
				{
					this.$message({ message: this.$t('Permission.SelectDeleteEmployee'),type: 'warning',offset:10});
				}
			},
			
			//权限变更，同步所有
			async PermissionToChange(ControllerId,Type){
				if(Type==2)
				{
					this.$confirm(this.$t('Permission.SureResync'), this.$t('Permission.Hint'), {type: 'warning'}).then(async () => {
						var {data: res} = await this.$http.post('PermissionToChange',{"ControllerId":ControllerId,"Type":Type});
						if(this.FilteringOperation(res))
						{
							if(res.resultCode == 1)
							{
								this.$message({ message:this.$t('Permission.SuccessfulOperation'),type: 'success',offset:10});
								this.timerControllers();
							}
						}
					}).catch(() => {
						//取消
					});
				}
				else
				{
					var {data: res} = await this.$http.post('PermissionToChange',{"ControllerId":ControllerId,"Type":Type});
					if(this.FilteringOperation(res))
					{
						if(res.resultCode == 1)
						{
							this.$message({ message:this.$t('Permission.SuccessfulOperation'),type: 'success',offset:10});
							this.timerControllers();
						}
					}
				}
				
			},
			
			//同步指定
			async SynchronizeSpecified(RecordID){
				var RecordData = [];
				if(RecordID!="" && RecordID!=undefined)
				{
					RecordData[0] = RecordID;
				}
				else
				{
					RecordData = this.RecordData;
				}
				if(RecordData.length>0)
				{
					var {data: res} = await this.$http.post('SynchronizeSpecified',{"RecordID":RecordData,"ControllerId":this.QueryAuthorizer.ControllerId});
					if(this.FilteringOperation(res))
					{
						if(res.resultCode == 1)
						{
							this.$message({ message:this.$t('Permission.SuccessfulOperation'),type: 'success',offset:10});
							this.timerControllers();
						}
					}
				}
				else if(RecordData.length==0 && (RecordID=="" || RecordID==undefined))
				{
					this.$message({ message:this.$t('Permission.SelectSyncEmployee'),type: 'warning',offset:10});
				}
			},
			//获取选择的人员
			AddEmployeesValue(e){
				this.EmployeesValue = e;
			},
			
			//返回结果过滤
			FilteringOperation(res) {
				var State = true;
				if (res.stateCode == 0) {
					State = false;
					this.$message({message: this.$t('Axios.Session'),type: 'warning',offset:10});
				} else if (res.stateCode == 2) {
					State = false;
					this.$message({message: this.$t('Axios.Failure'),type: 'error',offset:10});
				} else if (res.stateCode == 3) {
					State = false;
					this.$message({message: this.$t('Axios.AccessDenied'),type: 'error',offset:10});
				} 
				return State;
			}
		}
	};
</script>

<style scoped>

	.Permission{
		width: 100%;
		height: 100%;
	}

	/* 页面1 */
	.Card1{
		display: flex;
		height: 100%;
	}

	/* 左侧 */
	.content_left{
		background-color: #FFFFFF;
		width: 290px;
		height: 100%;
		margin-right: 20px;
	}

	.content_left_value {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.left_value_input {
		width:90%;
		margin:15px auto;
		display: flex;
		margin-bottom: 10px;
	}

	.left_value_tree{
		width:90%;
		flex-grow: 1;
		margin: 0px auto;
		margin-bottom: 15px;
		overflow: auto;
	}

	.left_tree{
		display: flex;
		align-items:center;
		justify-content: space-between;
		width: 100%;
		font-size: 15px;
	}

	.left_tree_icon{
		padding: 0px 5px;
	}

	/* 右侧 */
	.content_right{
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	/* 右侧-头部 */
	.content_right_top{
		width: 100%;
		display: flex;
		margin-bottom: 20px;
	}

	.right_top_card1{
		flex-grow: 1;
		padding: 15px 16px;
		background-color: #FFFFFF;
		justify-content: space-between;
	}

	.right_top_search>i{
		font-size: 25px;
		margin-left:10px;
		margin-right:10px;
		color: #909399;
	}

	.right_top_Date>>>i{
		font-size: 25px;
		color: #909399;
	}

	.right_top_search>>>.el-input__inner {
    	border: 0;
		font-size: 15px;
  	}

	.right_top_search_type{
		margin-right: 15px;
	}

	.right_top_search_type>i{
		margin-left: 20px;
		height: 20px;
		display: flex;
		border-left:1px solid #c7c7c7;
	}

	.right_top_search_type>>>.el-input__inner{
		border:0;
	}

	.right_top_card2{
		margin: 0px 20px;
		padding: 15px 16px;
		background-color: #FFFFFF;
	}

	.right_top_card3{
		padding: 15px 16px;
		background-color: #FFFFFF;
	}


	/* 右侧-底部 */
	.content_right_bottom{
		flex-grow: 1;
		height: 0px;
		width: 100%;
		background-color: #FFFFFF;
	}

	.content_right_bottom>div{
		display: flex;
		flex-direction: column;
		width: 96%;
		height: 95%;
	}

	.content_right_bottom_title{
		font-weight: 600;
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 10px;
	}

	.content_right_bottom_page{
		margin-top: 2px;
	}
 
	/* 页面2 */
	.Card2{
		display: flex;
		flex-direction: column;
		height:100%
	}

	/* 头部 */
	.Card2_top{
		display: flex;
		margin-bottom: 20px;
	}

	.Card2_top1{
		display: flex;
		padding: 15px 16px;
		margin-right: 20px;
		background-color: #FFFFFF;
	}

	.Card2_top2{
		flex-grow: 1;
		display: flex;
		justify-content: space-between;
		padding: 15px 16px;
		background-color: #FFFFFF;
	}
	/* 页面3 */
	.Card3{
		display: flex;
		flex-direction: column;
		height:100%
	}

	.Card3_top{
		display: flex;
		margin-bottom: 20px;
	}

	.Card3_top1{	
		display: flex;
		padding: 15px 16px;
		margin-right: 20px;
		background-color: #FFFFFF;
		width: 100%;
	}

	.select_tree>>>input{
		  cursor:pointer;
	}

	.Card2_top3{
		display: flex;
		padding: 15px 16px;
		margin:0px 20px;
		background-color: #FFFFFF;
	}

	.Card2_top4{
		display: flex;
		padding: 15px 16px;
		background-color: #FFFFFF;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}
	.Area_input {
		display: flex;
		margin-bottom: 10px;
	}

	/* 选择设备楼层 */
	.controller_card{
		height: 329px;
		border-radius: 5px;
		overflow:scroll;
		border:1px solid #EBEEF5;
	}
	.controller_item{
		padding: 5px 10px;
		border-bottom: 1px dashed #eeeeee;
	}
	.controller_card>.controller_item:last-child{
		border:0px
	}
	.item_title{
		padding:15px 20px;
	}
	.lift{
		margin: 0px 15px;
		margin-bottom: 5px;
		display: flex;
		flex-wrap: wrap;
	}
	.lift>div{
		margin-right:10px;
		margin-bottom: 10px;
	}
	.lift_item{
		display: flex;
		align-items: center;
	}
	.floor_item_checked{
		width: 100px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
