<template>
  <el-container>
    <!-- 左侧 -->
    <el-aside width="auto">
      <el-menu
        :unique-opened="true"
        :default-active="ativePath"
        class="menu_style"
        background-color="#2B3A4A"
        text-color="#8699AD"
        active-text-color="#FFFFFF"
        :collapse="collapse"
        :router="true"
      >
        <!-- 公司信息 -->
        <div class="logo flex-items-center">
          <i>
            <div
              class="logo_img"
              :style="{ 'background-image': 'url(' + this.logoUrl + ')' }"
            ></div>
          </i>
          <div class="company_name">
            <div class="item" v-if="!collapse">{{ CompanyName }}</div>
          </div>
        </div>

        <!-- 功能菜单 -->
        <!-- 首页数据 -->
        <el-menu-item-group>
          <span slot="title" v-if="!collapse">{{ $t('Index.MenuName') }}</span>
          <el-menu-item index="Home" @click="saveNavState('Home',['Index.Menu1'])">
            <i class="el-icon-s-home"></i>
            <span slot="title">{{ $t('Index.Menu1') }}</span>
          </el-menu-item>
        </el-menu-item-group>

        <!-- 快捷操作 -->
        <el-submenu index="Shortcuts">
          <template slot="title">
            <i class="el-icon-position"></i>
            <span slot="title">{{ $t('Index.Menu2') }}</span>
          </template>
          <!-- 添加设备 -->
          <el-menu-item-group :title="$t('Index.Step1')">
            <el-menu-item index="Controller" @click="saveNavState('Controller',['Index.Menu2','Index.Menu3'])">
              <i class="el-icon-mobile-phone"></i>
              <span slot="title">{{ $t('Index.Menu3') }}</span>
            </el-menu-item>
          </el-menu-item-group>
          <!-- 添加人员 -->
          <el-menu-item-group :title="$t('Index.Step2')">
            <el-menu-item index="Employee" @click="saveNavState('Employee',['Index.Menu2','Index.Menu4'])">
              <i class="el-icon-user"></i>
              <span slot="title">{{ $t('Index.Menu4') }}</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group :title="$t('Index.Step3')">
            <!-- 添加权限 -->
            <el-menu-item index="Permission" @click="saveNavState('Permission',['Index.Menu2','Index.Menu5'])">
              <i class="el-icon-key"></i>
              <span slot="title">{{ $t('Index.Menu5') }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <!-- 人员管理 -->
        <el-submenu index="Employees">
          <template slot="title">
            <i class="el-icon-user-solid"></i>
            <span slot="title">{{ $t('Index.Menu6') }}</span>
          </template>
          <!-- 组织架构 -->
          <el-menu-item index="Employee" @click="saveNavState('Employee',['Index.Menu6','Index.Menu7'])">
            <i class="el-icon-user"></i>
            <span slot="title">{{ $t('Index.Menu7') }}</span>
          </el-menu-item>
          <!-- 批量操作 -->
          <el-menu-item index="BulkImport" @click="saveNavState('BulkImport',['Index.Menu6','Index.Menu8'])">
            <i class="el-icon-s-open"></i>
            <span slot="title">{{ $t('Index.Menu8') }}</span>
          </el-menu-item>
        </el-submenu>

        <!-- 设备管理 -->
        <el-submenu index="Controller">
          <template slot="title">
            <i class="el-icon-mobile-phone"></i>
            <span slot="title">{{ $t('Index.Menu21') }}</span>
          </template>
          <!-- 设备列表 -->
          <el-menu-item index="Controller" @click="saveNavState('Controller',['Index.Menu32'])">
            <i class="el-icon-mobile"></i>
            <span slot="title">{{ $t('Index.Menu32') }}</span>
          </el-menu-item>
          <!-- 常开常闭 -->
          <el-menu-item index="OpenClosed" @click="saveNavState('OpenClosed',['Index.Menu17','Index.Menu31'])">
            <i class="el-icon-wallet"></i>
            <span slot="title">{{ $t('Index.Menu31') }}</span>
          </el-menu-item>
        </el-submenu>

        <!-- 权限管理 -->
        <el-submenu index="Permissions">
          <template slot="title">
            <i class="el-icon-unlock"></i>
            <span slot="title">{{ $t('Index.Menu22') }}</span>
          </template>
          <!-- 权限列表 -->
          <el-menu-item-group :title="$t('Index.Menu23')">
             <el-menu-item index="Permission" @click="saveNavState('Permission',['Index.Menu22','Index.Menu23'])">
              <i class="el-icon-key"></i>
              <span slot="title">{{ $t('Index.Menu23') }}</span>
            </el-menu-item>
          </el-menu-item-group>
          <!-- 自动授权 -->
          <el-menu-item-group :title="$t('Index.Menu24')" v-if="companyId!=57">
            <el-menu-item index="Automatic" @click="saveNavState('Automatic',['Index.Menu22','Index.Menu24'])">
              <i class="el-icon-thumb"></i>
              <span slot="title">{{ $t('Index.Menu24') }}</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group :title="$t('Index.Control')">
            <!-- 假期表 -->
            <el-menu-item index="ControllerDate" @click="saveNavState('ControllerDate',['Index.Menu17','Index.Menu18'])">
              <i class="el-icon-date"></i>
              <span slot="title">{{ $t('Index.Menu18') }}</span>
            </el-menu-item>
            <!-- 时间表 -->
            <el-menu-item index="ControllerTime" @click="saveNavState('ControllerTime',['Index.Menu17','Index.Menu19'])">
              <i class="el-icon-time"></i>
              <span slot="title">{{ $t('Index.Menu19') }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <!-- 访客管理 -->
        <el-submenu index="Visitor" v-if="module.Visitor == 1">
          <template slot="title">
            <i class="el-icon-s-check"></i>
            <span slot="title">{{ $t('Index.Menu13') }}</span>
          </template>
          <!-- 访客申请 -->
          <!-- <el-menu-item index="Visitor" @click="saveNavState('Visitor',['Index.Menu13','Index.Menu14'])">
            <i class="el-icon-coordinate"></i>
            <span slot="title">{{ $t('Index.Menu14') }}</span>
          </el-menu-item> -->
          <!-- 访客区域 -->
          <el-menu-item index="VisitirArea" @click="saveNavState('VisitirArea',['Index.Menu13','Index.Menu15'])">
            <i class="el-icon-school"></i>
            <span slot="title">{{ $t('Index.Menu15') }}</span>
          </el-menu-item>
          <!-- 访客配置 -->
          <el-menu-item index="VisitorConfig" @click="saveNavState('VisitorConfig',['Index.Menu13','Index.Menu16'])">
            <i class="el-icon-edit"></i>
            <span slot="title">{{ $t('Index.Menu16') }}</span>
          </el-menu-item>
        </el-submenu>

        <!-- 梯控管理 -->
        <el-submenu index="Lift" v-if="0 == 1">
          <template slot="title">
            <i class="el-icon-c-scale-to-original"></i>
            <span slot="title">{{ $t('Index.Menu9') }}</span>
          </template>
          <!-- 电梯组 -->
          <el-menu-item index="LiftArea" @click="saveNavState('LiftArea',['Index.Menu9','Index.Menu10'])">
            <i class="el-icon-discount"></i>
            <span slot="title">{{ $t('Index.Menu10') }}</span>
          </el-menu-item>
          <!-- 梯控权限 -->
          <el-menu-item index="LiftAreaAuthority" @click="saveNavState('LiftAreaAuthority',['Index.Menu9','Index.Menu11'])">
            <i class="el-icon-key"></i>
            <span slot="title">{{ $t('Index.Menu11') }}</span>
          </el-menu-item>
          <!-- 黑名单 -->
          <el-menu-item index="LiftFloorBlacklist" @click="saveNavState('LiftFloorBlacklist',['Index.Menu9','Index.Menu12'])">
            <i class="el-icon-s-release"></i>
            <span slot="title">{{ $t('Index.Menu12') }}</span>
          </el-menu-item>
        </el-submenu>

        <!-- 进出明显 -->
        <el-menu-item index="CardRecords" @click="saveNavState('CardRecords',['Index.Menu17','Index.Menu20'])">
          <i class="el-icon-tickets"></i>
          <span slot="title">{{ $t('Index.Menu20') }}</span>
        </el-menu-item>

        <!-- 实时监控 -->
        <el-menu-item index="Monitoring" @click="saveNavState('Monitoring',['Index.Menu22','Index.Menu25'])">
          <i class="el-icon-video-camera-solid"></i>
          <span slot="title">{{ $t('Index.Menu25') }}</span>
        </el-menu-item>

        <!-- 系统设置 -->
        <el-submenu index="Userslog">
          <template slot="title">
            <i class="el-icon-s-tools"></i>
            <span slot="title">{{ $t('Index.Menu26') }}</span>
          </template>
          <!-- 登录用户 -->
          <el-menu-item index="User" @click="saveNavState('User',['Index.Menu26','Index.Menu27'])">
            <i class="el-icon-user"></i>
            <span slot="title">{{ $t('Index.Menu27') }}</span>
          </el-menu-item>
          <!-- 日志记录 -->
          <el-menu-item index="LogRecord" @click="saveNavState('LogRecord',['Index.Menu26','Index.Menu30'])">
            <i class="el-icon-tickets"></i>
            <span slot="title">{{ $t('Index.Menu30') }}</span>
          </el-menu-item>
          <!-- 公司信息 -->
          <el-menu-item index="Company" @click="saveNavState('Company',['Index.Menu26','Index.Menu28'])">
            <i class="el-icon-office-building"></i>
            <span slot="title">{{ $t('Index.Menu28') }}</span>
          </el-menu-item>
          <!-- 其他配置 -->
          <el-menu-item index="OtherSettings" @click="saveNavState('OtherSettings',['Index.Menu26','Index.Menu29'])">
            <i class="el-icon-set-up"></i>
            <span slot="title">{{ $t('Index.Menu29') }}</span>
          </el-menu-item>
          <!-- 手动备份数据库 -->
          <el-menu-item index="ManuallyBackupData" @click="saveNavState('ManuallyBackupData',['Index.Menu26','Index.Menu33'])">
            <i class="el-icon-set-up"></i>
            <span slot="title">{{ $t('Index.Menu33') }}</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>
    <!-- 右侧 -->
    <el-container>
      <!-- 头部 -->
      <el-header height="70px" class="top flex-items-center">
        <div class="top_left flex-items-center">
          <el-button
            type="text"
            :class="!collapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
            @click="collapse = !collapse"
          ></el-button>
          <el-button
            type="text"
            class="el-icon-full-screen"
            @click="handleFullScreen()"
          ></el-button>
          <el-breadcrumb style="margin-left:15px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="items in topData" :key="items">{{$t(items)}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <div class="top_right flex-items-center">
          <div class="flex-items-center">
            <el-dropdown trigger="click" @command="ClickLanguage">
              <span class="el-dropdown-link">
                {{ $t('Index.LanguageName')
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">简体中文</el-dropdown-item>
                <el-dropdown-item command="2">繁體中文</el-dropdown-item>
                <el-dropdown-item command="3">English</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div>
            <el-button
              type="text"
              class="el-icon-postcard top_right_button"
              @click="drawer = !drawer"
            ></el-button>
          </div>
          <div>
            <el-popover placement="bottom" width="150" trigger="hover">
                <div class="user_bottom">
                  <el-button size="medium"  class="el-icon-user" @click="ativePath='User';$router.push('/User')"> {{UserName}}</el-button>
                </div>
              <el-button class="user_head" slot="reference" :style="{'background-image':'url('+userUrl+')'}"></el-button>
            </el-popover>
          </div>
          <div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('Index.Quit')"
              :enterable="false"
              placement="bottom"
            >
              <el-button
                type="text"
                class="el-icon-switch-button top_right_button"
                @click="logout()"
              ></el-button>
            </el-tooltip>
          </div>
        </div>
      </el-header>
      <!-- 内容 -->
      <el-main>
        <transition name="slide-fade">
          <router-view></router-view>
        </transition>
      </el-main>
    </el-container>

    <!-- 右侧抽屉 -->
    <el-drawer
      :with-header="false"
      :modal="false"
      :visible.sync="drawer"
      size="330px"
    >
      <div class="drawer_from">
        <div class="drawer_from_title">
          {{ $t('Index.MessageCard') }}
        </div>
        <div class="drawer_message flex-center">
          <div>{{ $t('Index.NoMessage') }}</div>
        </div>
        <el-card shadow="hover" class="drawer_card">
          <div class="drawer_card_div flex-center">
            <div>
              <div class="drawer_card_title">
                <h3>{{ $t('Index.System') }}</h3>
              </div>
              <div class="drawer_card_versions">
                {{ $t('Index.Version') }}: 2.5.0_231019
              </div>
              <div class="drawer_card_authorizationEndTime">
                {{ $t('Index.EndTime') }}: {{ authorizationEndTime }}
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </el-drawer>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 菜单数据
      menuData: [],
      //左侧菜单显示
      collapse: false,
      //是否显示右侧抽屉
      drawer: false,
      //是否显示右侧抽屉
      drawer: false,
      //公司名称
      CompanyName: "",
      //公司Id
      companyId:0,
      //公司logo
      logoUrl: "",
      //用户头像
      userUrl: "",
      //用户名
      UserName: "",
      //链接激活地址
      ativePath: "",
      //有效结束时间
      authorizationEndTime: "",
      //语言类型
      languageType: 1,
      //模块权限
      module: {
        //访客
        Visitor: 0,
        //梯控
        Lift: 0,
      },
      topData:[]
    };
  },
  //创建实例时执行
  created() {},
  //页面加载后执行
  mounted: function () {
    //加载激活链接
    this.activate();
    //获取公司信息及权限
    this.RoleCompany();
  },
  methods: {
    //加载激活链接
    activate: function () {
      var ativePath = window.sessionStorage.getItem("ativePath");
      if (ativePath != null) {
        this.ativePath = ativePath;
      } else {
        this.ativePath = "Home";
      }

      //面包屑
      var topData = window.sessionStorage.getItem("topData");
       if (topData != null) {
        this.topData = topData.split(',');
      } else {
        this.topData = ['Index.Menu1'];
      }
    },

    //语言选择
    ClickLanguage(command) {
      if (command == 1) {
        (this.$i18n.locale = "J"), localStorage.setItem("languages", "J");
      } else if (command == 2) {
        (this.$i18n.locale = "F"), localStorage.setItem("languages", "F");
      } else {
        (this.$i18n.locale = "E"), localStorage.setItem("languages", "E");
      }
    },

    //保存当前链接
    saveNavState(ativePath,topData) {
      this.topData = topData;
      window.sessionStorage.setItem("topData", topData);
      window.sessionStorage.setItem("ativePath", ativePath);
    },

    //获取公司信息及权限
    async RoleCompany() {
      var { data: res } = await this.$http.post("CorporateInformation");
      //判断返回的state状态 0-session过期,1-正常
      if (res.state == 1) {
        //检测Session是否过期
        this.SessionIsLateInterval();
        //有效结束时间
        this.authorizationEndTime = res.authorizationEndTime;
        //公司Id
        this.companyId = res.companyId;
        //公司名称
        this.CompanyName = res.companyName;
        //用户名称
        this.UserName = res.userName;
        
        //公司logo
        this.logoUrl = this.$image + "/Logo/" + res.companyLogo;
        //用户头像
        this.userUrl =
          res.userPortrait != "" && res.userPortrait != undefined
            ? this.$image + "/User/" + res.userPortrait
            : "";
        //功能模块
        for (var i = 0; i < res.roleCompanyEntities.length; i++) {
          var moduleName = res.roleCompanyEntities[i].funName;
          this.module[moduleName] = res.roleCompanyEntities[i].funValue;
        }
        //权限组
        var struserGroupDetails = JSON.stringify(res.userGroupDetailsEntities)
        window.sessionStorage.setItem("userGroupDetails",struserGroupDetails);
      } else if (res.state == 0) {
        this.$confirm(this.$t('Index.PastDue'), this.$t('Index.Hint'), {
          confirmButtonText: this.$t('Index.Confirm'),
          showClose: false,
          type: "warning",
          showCancelButton: false,
          callback: (action) => {
            this.logout();
          },
        });
      } //异常
      else {
        this.$message({
          message: this.$t('Index.Error'),
          type: "error",
          offset: 10,
        });
      }
    },

    //全屏
    handleFullScreen: function () {
      let element = document.documentElement;
      // 判断是否已经是全屏
      // 如果是全屏，退出
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },

    //注销
    async logout() {
      try {
        //清空本地信息
        clearInterval(this.TimerSession);
        window.sessionStorage.clear();
        this.$router.push("/Login");
        //注销服务端session
        var { data: res } = await this.$http.post("logout");
      } catch (e) {
        //清空本地信息
        clearInterval(this.TimerSession);
        window.sessionStorage.clear();
        this.$router.push("/Login");
      }
    },

    //定时器（查询Session是否过期）
    SessionIsLateInterval() {
      var that = this;
      clearInterval(this.TimerSession);
      this.TimerSession = window.setInterval(function () {
        that.SessionIsLate();
      }, 1000 * 10);
    },

    //查询Session是否过期
    async SessionIsLate() {
      try {
        var { data: res } = await this.$http.post("SessionIsLate");
        if (res.state == 0) {
          this.$notify({
            title: this.$t('Index.Information'),
            message: this.$t('Index.PastDue'),
            type: "warning",
          });
          clearInterval(this.TimerSession);
          window.sessionStorage.clear();
          this.$router.push("/Login");
        }
      } catch (e) {
        clearInterval(this.TimerSession);
        window.sessionStorage.clear();
        this.$router.push("/Login");
      }
    },
  },
};
</script>

<style scoped>
.el-container {
  height: 100%;
  background-color: #f5f5f5;
}

/* 左侧 */
.el-aside {
  display: flex;
  flex-direction: column;
  background-color: #2b3a4a;
  color: #333;
}

/* 公司logo */
.logo {
  flex-wrap: nowrap;
  height: 70px;
  box-shadow: 0px 0px 6px 0px #273442;
  margin-bottom: 5px;
}

.logo_img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin: 10px 10px 10px 11px;
  background-color: #4a5b6e;
  background-size: 100% 100%;
}

.company_name {
  font-size: 17px;
  font-weight: 600;
  overflow: hidden;
  color: #ffffff;
}

.company_name > div {
  width: 160px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;/*文字占几行*/
  -webkit-box-orient: vertical;
}

.menu_style {
  height: 100%;
  border: 0px;
}

.menu_style:not(.el-menu--collapse) {
  width: 240px;
}

/* 头部 */
.el-header {
  background-color: #ffffff;
  color: #333;
  box-shadow: 0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%);
}

.top {
  padding: 0px 10px;
  justify-content: space-between;
}

.top_left {
  display: flex;
  height: 100%;
}

.top_left > button {
  height: 100%;
  width: 35px;
  font-weight: 600;
  font-size: 20px;
  color: #555b6d;
}

.top_right > div {
  margin: 0px 10px;
}

.el-dropdown-link {
  background-color: #f8f9fa;
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12.5px;
  color: #555b6d;
}

.top_right > div > button {
  color: #555b6d;
}

.top_right_button {
  height: 100%;
  width: 35px;
  font-size: 25px;
}

.user_head {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f5f6fa;
  background-size: 100% 100%;
}

.user_bottom>>>.el-button{
  border:0px;
  width: 100%;
}

/* 内容 */
.el-main {
  color: #333;
  overflow-y: auto;
	overflow-x: hidden;
}

/* 页面切换动画 */
	.slide-fade{
	 position: absolute;
	 left:0;
	 right: 0;
	}
	
	.slide-fade-enter-active {
	 transition: all 1s ease;
	}
	
	.slide-fade-leave-active {
	 transition: all .1s cubic-bezier(2.0, 0.5, 0.8, 1.0);
	}
	
	.slide-fade-enter, .slide-fade-leave-to{
	 left:0;
	 right: 0;
	 transform: translateX(30px);
	 opacity: 0;
	}

/* 抽屉布局 */
.drawer_from {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  width: 90%;
  height: 100%;
  font-size: 14px;
}

/* 抽屉标题 */
.drawer_from_title {
  height: 60px;
  display: flex;
  align-items: center;
  color: #333;
  font-size: 14px;
}

.drawer_message {
  flex-grow: 1;
}

.drawer_message > div {
  color: #8c939d;
}

.drawer_card {
  margin-bottom: 15px;
}

.drawer_card_div {
  height: 130px;
}

.drawer_card_title {
  text-align: center;
  color: #409eff;
}

.drawer_card_versions {
  text-align: center;
  color: #8c939d;
  font-size: 14px;
}

.drawer_card_authorizationEndTime {
  text-align: center;
  margin-top: 5px;
  color: #8c939d;
  font-size: 14px;
}

/* 抽屉刷卡数据详情 */
.card_details {
  margin-left: 10px;
}

.card_details > div {
  display: flex;
  align-items: center;
  height: 25%;
}
</style>
