<template>
	<div class="CardRecords">
		<!-- 头部 -->
		<div class="Top_menu">
			<div class="Card1 flex-items-center boxshadow">
				<!-- 左 -->
				<div class="card1_left flex-items-center" v-if="Data.Type!=4">
					<i class="el-icon-search card_icon"></i>
					<el-input size="medium" v-model="Data.Value" :placeholder="$t('CardRecords.SearchTips')"></el-input>
				</div>
				<div class="flex-items-center" v-else>
					<select-tree size="medium" style="cursor:pointer;height: 36px;" :placeholder="$t('SelectTree.Select')" v-if="Data.Type==4" v-model="Data.Value" :options="SelectDepartmentList" :props="DepartmentProps" />
				</div>
				<div class="card1_right flex-items-center">
					<el-button slot="append" size="mini" icon="el-icon-search" @click="SearchBrushcardacs"></el-button>
					<div class="BreakUp"></div>
					<el-select v-model="Data.Type" size="medium" @change="Data.Value=''">
						<el-option :label="$t('CardRecords.SearchCondition1')" value="1"></el-option>
						<el-option :label="$t('CardRecords.SearchCondition2')" value="2"></el-option>
						<el-option :label="$t('CardRecords.SearchCondition3')" value="3"></el-option>
						<el-option :label="$t('CardRecords.SearchCondition4')" value="4"></el-option>
					</el-select>
				</div>
			</div>

			<div class="Card2 boxshadow">
				<el-date-picker size="medium" @change="SearchBrushcardacs()" v-model="Data.ChargeTime"
					type="datetimerange"
					align="center"
					value-format="yyyy-MM-dd HH:mm:ss"
					:start-placeholder="$t('CardRecords.StartDate')" 
					:end-placeholder="$t('CardRecords.EndDate')"
					:default-time="['00:00:00', '23:59:59']">
				</el-date-picker>
			</div>
			<div class="Card3 boxshadow">
				<el-button @click="Brushcardacs()" icon="el-icon-refresh-right" size="small"  style="margin-right: 10px;">{{$t('CardRecords.Refresh')}}</el-button>
				<el-popover trigger="click" placement="bottom">
					<el-button size="small" @click="BrushcardacsExecl(1)" type="primary">{{$t('CardRecords.Export2')}}</el-button>
					<el-button size="small" @click="BrushcardacsExecl(2)" type="success">{{$t('CardRecords.Export3')}}</el-button>
					<el-button size="small" @click="BrushcardacsExecl(3)" type="info">{{$t('CardRecords.Export4')}}</el-button>
					<el-button size="small" class="el-icon-receiving" slot="reference">{{$t('CardRecords.Export1')}}</el-button>
				</el-popover>
			</div>
		</div>

		<!-- 中 -->
		<div class="content flex-center boxshadow">
			<div>
				<div class="content_bottom_title">{{$t('CardRecords.CardRecords')}}</div>
				<el-table :data="BrushCardACSList" height="100%">
					<el-table-column :label="$t('CardRecords.ControllerId')" align="center" prop="controllersId"></el-table-column>
					<el-table-column :label="$t('CardRecords.Name2')" align="center">
						<template slot-scope="scope">
							<el-popover v-if="scope.row.number!='' && scope.row.number!=undefined" trigger="click" placement="left" :open-delay="500">
								<p>
									<span>
										<div v-if="scope.row.photo ==''">{{$t('CardRecords.NoPhoto')}}</div>
										<div v-else class="Employeesimg">
											<img style="width:auto;height: auto;max-width: 100%;max-height: 100%;" :src="Employeesimg(scope.row.photo,1)" />
										</div>
									</span>
								</p>
								<p>{{$t('CardRecords.Number')}}: {{ scope.row.number }}</p>
								<p>{{$t('CardRecords.Name2')}}: {{ scope.row.name }}</p>
								<p>
									{{$t('CardRecords.Sex')}}: 
									<el-tag v-if="scope.row.sex==1" size="medium">{{$t('CardRecords.Sex1')}}</el-tag>
									<el-tag v-else-if="scope.row.sex==2" size="medium" type="danger">{{$t('CardRecords.Sex2')}}</el-tag>
								</p>
								<div slot="reference" class="name-wrapper">
									<span style="cursor:pointer;" v-if="scope.row.sex==1" type="primary">{{ scope.row.name }}</span>
									<span style="cursor:pointer;" v-else-if="scope.row.sex==2" type="danger">{{ scope.row.name }}</span>
									&nbsp;<i style="cursor:pointer;color:#409EFF" class="el-icon-search"></i>
								</div>
							</el-popover>
							<span v-else-if="scope.row.visitorName!='' && scope.row.visitorName!=undefined">
								{{scope.row.visitorName}} <span>&nbsp;({{$t('CardRecords.Visitor')}})</span>
							</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('CardRecords.ControllerName')" align="center" prop="controllerName"></el-table-column>
					<el-table-column :label="$t('CardRecords.ControllerArea')" align="center" prop="regionName"></el-table-column>
					<el-table-column :label="$t('CardRecords.ControllerLocation')" align="center" prop="location"></el-table-column>
					<el-table-column :label="$t('CardRecords.Department')" align="center" prop="departmentName">
						<template slot-scope="scope">
							<span>{{scope.row.departmentName}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('CardRecords.Card')" align="center" prop="card">
						<template slot-scope="scope">
							<span>{{scope.row.cardType!=4 ? scope.row.card:$t('CardRecords.Not')}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('CardRecords.Date')" align="center">
						<template slot-scope="scope">
							<span>{{SlicedTime(1,scope.row.brushTime)}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('CardRecords.Time')" align="center">
						<template slot-scope="scope">
							<span>{{SlicedTime(2,scope.row.brushTime)}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('CardRecords.VerifyWay')" align="center" prop="Status">
						<template slot-scope="scope">
							<el-tag v-if="scope.row.cardType==1" type="info">{{$t('CardRecords.Card1')}} <i class="el-icon-menu"></i></el-tag>
							<el-tag v-else-if="scope.row.cardType==2" type="info">{{$t('CardRecords.Card2')}} <i class="el-icon-bank-card"></i></el-tag>
							<el-tag v-else-if="scope.row.cardType==3" type="info">{{$t('CardRecords.Card3')}} <i class="el-icon-key"></i></el-tag>
							<el-popover v-else-if="scope.row.cardType==4" placement="left" trigger="hover">
								<div style="width: 180px; height: 180px; display: flex;align-items: center;justify-content: center;margin: 0px auto;">
									<img style="width:auto;height: auto;max-width: 100%;max-height: 100%;" :src="Employeesimg(scope.row.img,2)" />
								</div>
								<el-tag slot="reference" type="primary">{{$t('CardRecords.Card4')}} <i class="el-icon-picture"></i></el-tag>
							</el-popover>
							<el-tag v-else-if="scope.row.cardType==5" type="info">{{$t('CardRecords.Card5')}} <i class="el-icon-thumb"></i></el-tag>
						</template>
					</el-table-column>
					<el-table-column :label="$t('CardRecords.workType')" align="center" prop="workType">
						<template slot-scope="scope">
								<el-tag v-if="scope.row.workType == '0'" size="small" effect="dark" type="danger">-</el-tag>
								<el-tag v-if="scope.row.workType == '1'" size="small" effect="dark" type="success">{{$t('CardRecords.workTypeIn')}}</el-tag>
								<el-tag v-if="scope.row.workType == '2'" size="small" effect="dark" type="warning">{{$t('CardRecords.workTypeOut')}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column :label="$t('CardRecords.Temperature')" align="center" prop="temperature">	
						<template slot-scope="scope">
							<span>{{scope.row.temperature}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('CardRecords.ShareFlag')" align="center" prop="shareFlag">
						<template slot-scope="scope">
								<el-tag v-if="scope.row.shareFlag == '1'" size="small" effect="dark" type="success">{{$t('CardRecords.isShareFlag')}}</el-tag>
								<el-tag v-if="scope.row.shareFlag == '0'" size="small" effect="dark" type="danger">{{$t('CardRecords.noShareFlag')}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column :label="$t('CardRecords.Status')" align="center" prop="Status" width="150"  fixed="right">
						<template slot-scope="scope">
							<el-popover trigger="hover" placement="left" :open-delay="500">
								<div style="display: flex;justify-content: center;">
									<el-tag size="small" v-if="scope.row.verificationMode == 0 && scope.row.cardType != 4">{{$t('CardRecords.verificationMode1')}}</el-tag>
									<el-tag size="small" v-if="scope.row.verificationMode == 1">{{$t('CardRecords.verificationMode2')}}</el-tag>
									<el-tag size="small" v-if="scope.row.verificationMode == 3">{{$t('CardRecords.verificationMode3')}}</el-tag>
									<el-tag size="small" v-if="scope.row.verificationMode == 4">{{$t('CardRecords.verificationMode4')}}</el-tag>
									<el-tag size="small" v-if="scope.row.verificationMode == 5">{{$t('CardRecords.verificationMode5')}}</el-tag>
									<el-tag size="small" v-if="scope.row.verificationMode == 6">{{$t('CardRecords.verificationMode6')}}</el-tag>
									<el-tag size="small" v-if="scope.row.verificationMode == 0 && scope.row.cardType == 4">{{$t('CardRecords.verificationMode7')}}</el-tag>
								</div>
								<el-tag slot="reference" style="cursor:pointer;" v-if="scope.row.property==0" effect="dark" type="danger">{{$t('CardRecords.CardStatus1')}}</el-tag>
								<el-tag slot="reference" style="cursor:pointer;" v-if="scope.row.property==1" effect="dark" type="success">{{$t('CardRecords.CardStatus2')}}</el-tag>
								<el-tag slot="reference" style="cursor:pointer;" v-else-if="scope.row.property==2" effect="dark" type="warning">{{$t('CardRecords.CardStatus3')}}</el-tag>
								<el-tag slot="reference" style="cursor:pointer;" v-else-if="scope.row.property==4" effect="dark" type="warning">{{$t('CardRecords.CardStatus6')}}</el-tag>
								<el-tag slot="reference" style="cursor:pointer;" v-else-if="scope.row.property==11" effect="dark" type="warning">{{$t('CardRecords.CardStatus4')}}</el-tag>
								<el-tag slot="reference" style="cursor:pointer;" v-else-if="scope.row.property==12" effect="dark" type="warning">{{$t('CardRecords.CardStatus5')}}</el-tag>
						</el-popover>
						</template>
					</el-table-column>
				</el-table>
				<div class="content_bottom_page flex-items-center">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[20, 50, 100,200]"
					:page-size="Data.Count" :current-page="Data.Pages" layout="total, sizes, prev, pager, next, jumper" :total="BrushCardACSSize">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SelectTree from '../components/SelectTree.vue';
	
	export default {
		name: 'about',
		components: {
			SelectTree,
		},
		data() {
			return {
				Loading: false, //加载动画
				select:"1",//条件选择
				Data:{
					Count:20,
					Pages:1,
					Type:"1",
					Value:'',
					ChargeTime:['','']
				},
				SelectDepartmentList:[],//部门
				DepartmentProps: {
					parent: 'parentDepartmentId', // 父级唯一标识
					value: 'departmentCode', // 唯一标识
					label: 'departmentName', // 标签显示
					children: 'children', // 子级
				},
				BrushCardACSList: [], //刷卡记录
				BrushCardACSSize:0, //刷卡记录数量
				
			};
		},
		mounted() {
			//获取当前时间
			this.CurrentTimes();
			
			//查询部门
			this.Department();
			
			//查询刷卡记录
			this.Brushcardacs();
		},
		methods: {
			
			//人员照片
			Employeesimg(photo,Type) {
				try 
				{
					if(photo!=undefined)
					{
						if(Type==1)
						{
							return this.$image+"/Employees/"+photo;
						}
						else
						{
							return this.$image+"/TakePhotos/"+photo;
						}
					}
					else{
						return ''
					}
				}
				catch (e) 
				{
					return ''
				}
			},
			
			//查询部门
			async Department() {
				try 
				{
					var {data: res} = await this.$http.post('Departments')
					if (res.resultCode == 1)//成功
					{ 
						this.SelectDepartmentList = JSON.parse(res.body);
					}
				} 
				catch (err)
				{
					console.log(err)
				}
			},
			
			//搜索刷卡记录
			SearchBrushcardacs()
			{
				this.Data.Pages = 1;
				this.Brushcardacs();
			},
			
			//查询刷卡记录
			async Brushcardacs() {
				try 
				{
					var CopeData = {Count:20,Pages:1,Type:"1",Value:'',ChargeTime:['','']}
					CopeData.Count = this.Data.Count;
					CopeData.Pages = this.Data.Pages;
					CopeData.Type = this.Data.Type;
					CopeData.Value = this.Data.Value;
					CopeData.ChargeTime = this.Data.ChargeTime;
					if(CopeData.Type=="")
					{
						CopeData.Type = "0";
					}
					this.Loading = true;
					var {data: res} = await this.$http.post('Brushcardacs', CopeData);
					if (res.resultCode==1) {
						var body = JSON.parse(res.body);
						console.log(body)
						this.BrushCardACSList = body;
						if(body.length>0)
						{
							this.BrushCardACSSize = body[0].size;
						}
						else
						{
							this.BrushCardACSSize  = 0;
						}
					}
					this.Loading = false;
				}
				catch (err) 
				{
					console.log(err);
					this.Loading = false;
				}
			},
			
			
			//刷卡记录导出
			BrushcardacsExecl(ExportType){
				try
				{
					var StartTime = "";
					var EndTime = "";
					
					if(this.Data.ChargeTime!=null)
					{
						if(this.Data.ChargeTime.length==2)
						{
							StartTime = this.Data.ChargeTime[0];
							EndTime = this.Data.ChargeTime[1];
						}
					}
					var Data = this.$t('CardRecords.ControllerId')+","+this.$t('CardRecords.ControllerName')+","+this.$t('CardRecords.ControllerArea')+","+this.$t('CardRecords.ControllerLocation')+","+this.$t('CardRecords.Name2')+","+this.$t('CardRecords.Number')+","+this.$t('CardRecords.Sex')+","+this.$t('CardRecords.Department')+","+this.$t('CardRecords.Card')+","+this.$t('CardRecords.Date')+","+this.$t('CardRecords.VerifyWay')+" (1-"+this.$t('CardRecords.Card1')+") (2-"+this.$t('CardRecords.Card2')+") (3-"+this.$t('CardRecords.Card3')+"),"+this.$t('CardRecords.Status')+" (0-"+this.$t('CardRecords.CardStatus1')+") (1-"+this.$t('CardRecords.CardStatus2')+") (2-"+this.$t('CardRecords.CardStatus3')+") (11-"+this.$t('CardRecords.CardStatus4')+") (12-"+this.$t('CardRecords.CardStatus5')+")"
					var parameter = this.Data.Count +","+this.Data.Pages+","+this.Data.Type+","+this.Data.Value+","+StartTime+","+EndTime+","+ExportType;
					var url = this.$http.defaults.baseURL+"/BrushcardacsExecl?parameter="+parameter+"&head="+Data;
					window.location.href = url;
				}
				catch (err)
				{
					this.Tableload = false;
					console.log(err)
				}
			},
			
			//分页条数
			handleSizeChange(val) {
				this.Data.Count = val;
				this.Brushcardacs();
			},
			
			//分页页数
			handleCurrentChange(val) {
				this.Data.Pages = val;
				this.Page = val;
				this.Brushcardacs();
			},
			
			

			//分割时间
			SlicedTime(Type, Time) {
				var arr = Time.split(' ');
				if (Type == 1) {
					return arr[0];
				} else {
					return arr[1];
				}
			},
			
			//获取当前时间
			CurrentTimes(){
				var date = new Date();
				var year = date.getFullYear();
				var month = date.getMonth()+1;
				var day = date.getDate();
				var hour = date.getHours();
				var minute = date.getMinutes();
				var second = date.getSeconds();
				this.Data.ChargeTime = [year+'-'+month+'-'+day+' '+0+':'+0+':'+0,year+'-'+month+'-'+day+' '+23+':'+59+':'+59];
			}
		}
	};
</script>

<style scoped>
	.CardRecords{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	/* 头部 */
	.Top_menu{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.Card1{
		padding: 15px 16px;
		background-color: #FFFFFF;
		flex-grow: 1;
		justify-content: space-between;
	}

	.BreakUp{
		height: 20px;
		margin-left: 18px;
		border-right:1px solid #c7c7c7;
	}

	.card_icon{
		margin-top: -3px;
		font-size: 25px;
		margin-left: 5px;
		margin-right:10px;
		color: #909399;
	}

	.card1_left>>>.el-input__inner {
    	border: 0;
		font-size: 15px;
  	}

	.card1_right>>>.el-input__inner {
    	border: 0;
		font-size: 15px;
  	}

	.Area_input{
		display: flex;
	}

	.Card2{
		margin: 0px 20px;
		padding: 15px 16px;
		background-color: #FFFFFF;
	}

	.Card2>>>.el-input__inner {
    	border: 0;
		font-size: 15px;
  	}

	.Card3{
		padding: 15px 16px;
		background-color: #FFFFFF;
	}


	/* 中 */
	.content{
		flex-grow: 1;
		height: 0px;
		width: 100%;
		background-color: #FFFFFF;
	}

	.content>div{
		display: flex;
		flex-direction: column;
		width: 96%;
		height: 95%;
	}

	.content_bottom_title{
		font-weight: 600;
		color: #6f6f6f;
		font-size: 14.5px;
		margin-bottom: 10px;
	}

	.content_bottom_page{
		margin-top: 20px;
	}


	.Employeesimg
	{
		width: 260px;
		height: 350px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
