<template>
	<div class="frombut frombut">
		<el-form ref="form" label-width="140px" label-position="left">
			<el-form-item :label="$t('ManuallyBackupData.Label')">
				<el-button size="medium" :loading="loading.dataBackup" @click="manuallyBackupData">{{$t('ManuallyBackupData.Button')}}</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		data() {
			return {
                loading:{
                    dataBackup: false,
                }
			}
		},
		methods: {
			//手动备份数据库
			async manuallyBackupData(){
            //开启按钮加载
            this.loading.dataBackup = true;   
            this.$message({
                type: "warning",
                message: this.$t('ManuallyBackupData.tip1'),
                duration: 1500,
                 });
            //请求后台接口
            var {data: res} = await this.$http.post('manuallyBackupDataBase')
            if(res=='success'){
                this.loading.dataBackup = false;
                this.$message({
                    type: "success",
                    duration: 1500,
                    message: this.$t('ManuallyBackupData.tip2')
              });
            }else{
                this.loading.dataBackup = false;
                this.$message({
                    type: "error",
                    duration: 1500,
                    message: this.$t('ManuallyBackupData.tip3')
              });
            }
			}

		}
	}
</script>
<style scoped>

	.frombut{
		padding: 15px;
		background-color: #fff;
	}
	
	.frombut>>>.el-form-item{
		margin: 0px;
	}
</style>