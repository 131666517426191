<template>
	<div class="frombut frombut">
		<el-form ref="form" label-width="140px" label-position="left">
			<el-form-item :label="$t('OtherSettings.Label1')">
				<el-button size="medium" @click="Download">{{$t('OtherSettings.Button')}}</el-button>
			</el-form-item>
			<el-form-item :label="$t('OtherSettings.Label2')">
				<el-button size="medium" @click="FlushAll">{{$t('OtherSettings.Button2')}}</el-button>
			</el-form-item>
		</el-form>
	</div>
	
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		methods: {
			//下载
			Download(){
				window.location.href= this.$image+"/File/WriteCard.zip";
			},
			//清空所有Redis数据
			async FlushAll(){
			//请求后台接口
            var {data: res} = await this.$http.post('flushAll')
            if(res=='success'){
                this.$message({
                    type: "success",
                    duration: 1500,
                    message: this.$t('OtherSettings.tip1')
              });
            }else{
                this.$message({
                    type: "error",
                    duration: 1500,
                    message: this.$t('OtherSettings.tip2')
              });
            }
			}
		}
	}
</script>
<style scoped>

	.frombut{
		padding: 15px;
		background-color: #fff;
	}
	
	.frombut>>>.el-form-item{
		margin: 10px;
	}
</style>